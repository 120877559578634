import React from 'react';
import { BrowserRouter as Router, Link, useNavigate, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Logo from "../assets/Header Logo.png"
import "./css/Header.css";
import { origin } from "../config/backend"; 

function Header({ test }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const navigate = useNavigate()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const theme = createTheme({
        palette: {
            primary: {
                main: '#15101F',
            },
        },
    });

    const handleHash = (hash) => {
        localStorage.setItem('packagehash', hash)
        window.location.href = origin + "/signupPage?package=" + hash
    }

    const handleScroll = () => {
        if (test && test.current) {
            test.current?.scrollIntoView({ behavior: 'smooth' })
        }
        else {
            navigate("/", { state: { scroll: true } })
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <>

                <AppBar position="sticky">
                    <Toolbar className='header' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                    <div style={{ position: "relative", display: "flex", alignItems: "center" }} onClick={() => navigate("/")}>
                                <img style={{ width: "50px", height: "50px", marginRight: "20px" }} src={Logo} alt="Logo" />
                                <img style={{ height: "35px" }} src="Images/Logo.svg" alt="Logo" />
                                <div style={{ fontFamily: "Roboto", fontSize: "8px", position: "absolute", color: "whitesmoke", top: "0px", right: "-19px" }}>TM</div>
                            </div>
                        <IconButton
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                            sx={{ display: { sm: 'block', md: 'none' } }}
                            onClick={handleClick}
                        >
                            <MenuIcon />
                        </IconButton>
                        <div className="navbar-items">
                            <Button component={Link} to="/" color="inherit">
                                Resources
                            </Button>
                            <Button onClick={handleScroll} color="inherit">
                                Pricing
                            </Button>
                            <Button component={Link} to={origin + "/loginPage"} color="inherit">
                                Login
                            </Button>
                            <Button variant="contained" onClick={() => handleHash("7e956eaa-c3a0-4a73-82b7-36e41810e2df")} className='start'>
                                Start for free
                            </Button>

                        </div>

                    </Toolbar>
                </AppBar>


            </>
        </ThemeProvider>

    );
}

export default Header;