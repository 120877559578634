import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  Container,
} from "@material-ui/core";
import { toast } from "react-toastify";
import api from "../config/backend";

function Halltype() {
  const [halltype, setHalltype] = useState([]);
  const [newHalltype, setNewHalltype] = useState("");

  const token = localStorage.getItem("token")

  const fetchHalltype = async () => {
    const { data, status } = await axios.post(`${api}/halltype/get-halls`, { token })
    if (data.success) {
      setHalltype(data.halls)
    } else {
      toast.error(data.message)
    }
  }

  const createRole = async (e) => {
    e.preventDefault()
    const { data, status } = await axios.post(`${api}/halltype/create-hall`, {
      token,
      hall_title: newHalltype
    })

    if (data.success) {
      fetchHalltype()
      setNewHalltype("")
    } else {
      toast.error(data.message)
    }
  }

  const deleteHalltype = async (id) => {
    const { status } = await axios.delete(`${api}/halltype/delete/${id}`);
    if (status === 201 || status === 200) {
      toast.success("Hall type deleted successfully");
      setHalltype((prevRoles) =>
        prevRoles.filter((halltypes) => halltypes._id !== id)
      );
    }
  };

  useEffect(() => {
    fetchHalltype();
  }, []);

  const [editingHalltypeId, setEditingHalltypeId] = useState(null);
  const [updatedHalltype, setUpdatedHalltype] = useState("");

  const handleUpdate = async (hash) => {
    const token = localStorage.getItem("token")
    const { data, status } = await axios.post(`${api}/halltype/update-hall`, {token, hash, hall_title: updatedHalltype});
    if (data.success) {
      toast.success(data.message)
      fetchHalltype()
      setEditingHalltypeId(null)
      setUpdatedHalltype("")
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h5" align="center" gutterBottom>
        Hall Management
      </Typography>
      <form
        onSubmit={createRole}
        style={{ display: "flex", marginBottom: "20px" }}
      >
        <TextField
          label="Hall Title"
          variant="outlined"
          size="small"
          value={newHalltype}
          onChange={(e) => setNewHalltype(e.target.value)}
          style={{ flexGrow: 1, marginRight: "10px" }}
        />
        <Button type="submit" variant="contained" color="primary" size="small">
          Create Role
        </Button>
      </form>
      <List>
        {halltype.map((role) => (
          <ListItem
            key={role._id}
            style={{ border: "1px solid #ddd", marginBottom: "10px" }}
          >
            {editingHalltypeId === role._id ? (
              <>
                <TextField
                  label="Role Title"
                  variant="outlined"
                  size="small"
                  value={updatedHalltype}
                  onChange={(e) => setUpdatedHalltype(e.target.value)}
                  style={{ flexGrow: 1, marginRight: "10px" }}
                />
                <Button
                  onClick={() => handleUpdate(role.hash)}
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  Save
                </Button>
              </>
            ) : (
              <>
                <Typography variant="body1">{role.title}</Typography>
                <Button
                  onClick={() => {
                    setEditingHalltypeId(role._id)
                    setUpdatedHalltype(role.title)
                  }}
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: "auto", marginRight: "10px" }}
                >
                  Edit
                </Button>
                <Button
                  onClick={() => deleteHalltype(role._id)}
                  variant="contained"
                  color="secondary"
                  size="small"
                >
                  Delete
                </Button>
              </>
            )}
          </ListItem>
        ))}
      </List>
    </Container>
  );
}

export default Halltype;
