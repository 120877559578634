let api, origin

if (process.env.REACT_APP_MODE) {
    const env = process.env.REACT_APP_MODE.trim()

    if (env === "dev") {
        api = "http://localhost:6061/api"
        origin = "http://localhost:3000"
    }
    else if (env === "stage") {
        api = "https://staging.backend.venuemanagement.in/api"
        origin = "https://staging.portal.venuemanagement.in"
    }
    // Production
    else {
        api = "https://backend.venuemanagement.in/api"
        origin = "https://portal.venuemanagement.in"
    }
}
// Run in development mode
else {
    api = "http://localhost:6061/api"
    origin = "http://localhost:3000"
}

console.log(process.env.REACT_APP_MODE)

export default api

export {
    origin
}


