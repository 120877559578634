import React, { useState } from 'react'
import "../components/VenuePricing.css"
import { Link, useNavigate } from 'react-router-dom'


const VenuePricing = () => {
    const [toggle, setToggle] = useState(true)

    const navigate = useNavigate()

    


 const handleHash = (hash)=>{
  localStorage.setItem('packagehash', hash)
 navigate("/signup")
 }

    return (
        <>
            <div className='container'>
                <div className='maindiv'>
                    <h1 className='title'>Pricing planes for venue management</h1>
                </div>
                <div className='plandiv'>

                    <div onClick={() => setToggle(true)} className={toggle == true ? "butdiv_2" : "butdiv_1"}>Monthly</div>
                    <div onClick={() => setToggle(false)} className={toggle == false ? "butdiv_2" : "butdiv_1"}>Yearly</div>
                </div>
                <div className='container'>
                    <ul className='pricing-box'>
                        <li className='pricing_list'>
                            <div className='pricing_block'>
                                <h4>Free</h4>
                                <div className='plan-text-underline'></div>
                                <div className='amount_div'>
                                    <p>
                                        <span className='price_symbol'>₹</span>
                                        <span className='price_value'>0</span>
                                    </p>
                                    <p className='discription'>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.  delectus voluptatum praesentium!
                                    </p>
                                    <button  onClick={()=>handleHash('d25398db-b12d-4430-a70e-fba802110b6e')} className='button'>sign up</button>
                                </div>
                            </div>
                            <ul className='price_features'>
                                <li>Rajesh alachandra</li>
                                <li>Rajesh</li>
                                <li>Rajesh</li>
                                <li>Rajesh</li>
                                <li>Rajesh</li>
                                <li>Rajesh</li>
                                <li>Rajesh</li>
                            </ul>
                        </li>
                        <li className='pricing_list'>
                            <div className='pricing_block'>
                                <h4>Pro</h4>
                                <div className='plan-text-underline'></div>
                                <div className='amount_div'>
                                    <p>
                                        <span className='price_symbol'>₹</span>
                                        <span className='price_value'>499</span>
                                    </p>
                                    <p className='discription'>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.  delectus voluptatum praesentium!
                                    </p>
                                    {/* <Link onClick={displayRazorpay}>
                                        <button  className='button'>sign up</button>
                                    </Link> */}
                                    <button  onClick={()=>handleHash('c8b132be-9b2e-423a-a8f0-175defb69644')} className='button'>sign up</button>
                                </div>
                            </div>
                            <ul className='price_features'>
                                <li>Rajesh</li>
                                <li>Rajesh</li>
                                <li>Rajesh</li>
                                <li>Rajesh</li>
                                <li>Rajesh</li>
                                <li>Rajesh</li>
                                <li>Rajesh</li>
                            </ul>
                        </li>
                        <li className='pricing_list'>
                            <div className='pricing_block'>
                                <h4>premium</h4>
                                <div className='plan-text-underline'></div>
                                <div className='amount_div'>
                                    <p>
                                        <span className='price_symbol'>₹</span>
                                        <span className='price_value'>999</span>
                                    </p>
                                    <p className='discription'>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                    </p>
                                    <button  onClick={()=>handleHash('4958e800-27fd-4072-b6d2-bb05d5bfe7da')} className='button'>sign up</button>
                                </div>
                            </div>
                            <ul className='price_features'>
                                <li>Rajesh</li>
                                <li>Rajesh</li>
                                <li>Rajesh</li>
                                <li>Rajesh</li>
                                <li>Rajesh</li>
                                <li>Rajesh</li>
                                <li>Rajesh</li>
                            </ul>
                        </li>
                        <li className='pricing_list'>
                            <div className='pricing_block'>
                                <h4>Elite</h4>
                                <div className='plan-text-underline'></div>
                                <div className='amount_div'>
                                    <p>
                                        <span className='price_symbol'>₹</span>
                                        <span className='price_value'>1,999</span>
                                    </p>
                                    <p className='discription'>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.  delectus voluptatum praesentium!
                                    </p>
                                    <button  onClick={()=>handleHash('a1a49522-1222-4497-8e32-d9993832c755')} className='button'>sign up</button>
                                </div>
                            </div>
                            <ul className='price_features'>
                                <li>Rajesh</li>
                                <li>Rajesh</li>
                                <li>Rajesh</li>
                                <li>Rajesh</li>
                                <li>Rajesh</li>
                                <li>Rajesh</li>
                                <li>Rajesh</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default VenuePricing