import React, { useEffect, useState } from "react"
import {
  Container,
  Box,
  Typography,
  TextField,
  Grid,
  Checkbox,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  makeStyles
} from "@material-ui/core"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import api from "../../config/backend"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"

const useStyles = makeStyles((theme) => ({
  flexboxContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    borderRadius: 8,
    padding: theme.spacing(2),

  },
  tableContainer: {
    marginTop: theme.spacing(4)
  }
}))

const CreateUser = () => {
  const classes = useStyles()

  const [fullName, setFullName] = useState("")
  const [mobileNo, setMobileNo] = useState("")
  const [email, setEmail] = useState("")
  const [gender, setGender] = useState("")
  const [managerRole, setManagerRole] = useState("Manager")
  const [userList, setUserList] = useState([])
  const [roles, setRoles] = useState([])

  console.log(userList)

  const token = localStorage.getItem("token")

  const submit = async () => {

    const payload = {
      token,
      full_name: fullName,
      email,
      gender,
      phonenumber: mobileNo,
      role: managerRole,
    }

    const { data, status } = await axios.post(
      `${api}/Author/create-user`,
      payload
    )

    if (data.success) {
      toast.success(data.message)
      setFullName("")
      setEmail("")
      setGender("")
      setMobileNo("")
      getUsersList()
      console.log(email)
    } else {
      toast.error(data.message)
    }
  }

  const getRolesList = async () => {
    const { data, status } = await axios.post(`${api}/Role/role-access`, {
      token,
    })

    if (data.success) {
      setRoles(data.roles)
    } else {
      toast.error(data.message)
    }
  }


  const getUsersList = async () => {
    const { data, status } = await axios.post(`${api}/Author/get-users`, { token })
    console.log({ data })
    if (data.success) {
      setUserList(data.users)
    } else {
      toast.error(data.message)
    }
  }

  useEffect(() => {
    getUsersList()
    getRolesList()
  }, [])

  return (
    <Container maxWidth="md">
      <ToastContainer></ToastContainer>
      <Box className={classes.flexboxContainer}>
        <Typography variant="h5">Create User</Typography>
        <Typography variant="subtitle1" className='managebanquet'>
          Add a new user for managing banquet hall bookings
        </Typography>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={8}>
            <TextField
              fullWidth
              label="Full Name"
              variant="outlined"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </Grid>
          <Grid item xs={4} >
            <FormControl fullWidth >
              <InputLabel id="demo-simple-select-label">Gender</InputLabel>
              <Select
                label="Gender"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <MenuItem value="Male" style={{ display: 'block', padding: '8px' }}>
                  Male
                </MenuItem>
                <MenuItem value="Female" style={{ display: 'block', padding: '8px' }}>
                  Female
                </MenuItem>
                <MenuItem value="Other" style={{ display: 'block', padding: '8px' }}>
                  Other
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Mobile No."
              variant="outlined"
              value={mobileNo}
              onChange={(e) => setMobileNo(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Email ID"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">Select Role</Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              {roles.map((item, index) => <Box key={index} mt={2}>
                <Checkbox
                  id={`checkbox-${index}`}
                  color="primary"
                  name="managerRole"
                  checked={item.title === managerRole}
                  onChange={(e) => setManagerRole(item.title)}
                />
                <label htmlFor={`checkbox-${index}`} style={{ all: "unset", fontFamily: "Roboto" }}>{item.title}</label>
              </Box>)}
            </div>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={submit}
              className='sendinvitation'
            >
              Send Invitation
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Paper className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Role</TableCell>
              <TableCell>Full Name</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>Mobile No</TableCell>
              <TableCell>Email ID</TableCell>
              <TableCell>Email Verification</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userList.map((user, index) => {
              console.log({ userList })
              return (
                <TableRow key={index}>
                  <TableCell>{user.role}</TableCell>
                  <TableCell>{user.full_name}</TableCell>
                  <TableCell>{user.gender}</TableCell>
                  <TableCell>{user.phonenumber}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell style={{ color: "red" }}>
                    {String(user.email_verification.status)}
                  </TableCell>
                </TableRow>
              )
            }
            )}
          </TableBody>
        </Table>
      </Paper>
    </Container>
  )
}

export default CreateUser