import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Image = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [base64Image, setBase64Image] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [selectedHall, setSelectedHall] = useState(''); // State for selected hall
  const [myImages, setMyImages] = useState('');

  
  const [hallData, setHallData] = useState()

  const fetchBanquetHall = async () => {
    try {
      const response = await axios.get('http://192.46.209.97:6060/api/BanquetHall/list',{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsImZpcnN0X25hbWUiOiJTaXRhcmEiLCJsYXN0X25hbWUiOiJIb3RlbCIsImVtYWlsIjoic2l0YXJhQHRlc3QuY29tIiwiaXNfcGhvbmVfdmVyaWZpZWQiOmZhbHNlLCJwaG9uZV92ZXJpZmljYXRpb24iOm51bGwsImltYWdlIjpudWxsLCJyb2xlIjpbIjY0NjRhODk5NDQ5YWUwOTA2NGU1MjZmOCJdLCJnZW5kZXIiOiJNYWxlIiwiYWRkcmVzcyI6InN0cmluZyIsInBhc3N3b3JkIjoiJDJiJDEwJEZYSHJSQVFPbVRKZktPZmw2djZsQmVxQS85Smg1UzRvZk1iRTdaNS9FcWRpN1ByUEk4TjRDIiwicmVzZXRQYXdvcmRUb2tlbiI6bnVsbCwicmVmcmVzaFRva2VuIjoia0kydTMxQ3NjRjVscUgwNmRUNVUyNlJ0dzFEbk1FNWVVQnN6VzRBVzBnTXdaaXRNTzdrRnozc0F5dHJpaTI4eGVSb2VsSXhIQ0xrWnFxOGxsWVZseXRSQmF6SktKUHp4dWxCQ1A3TU5GbkpPSERaZ1Mzc1VYc2l4ekhaVjRESFM0dGlRUnQ5a28yWEU4bmhaY0w0N3IxaU5QalBXa0hGRmg2N0c4SkFOUmVlTmdJdjd4dkYyV0dwcVV6Uk15NWE4UmNJWG9CV2MyZmNsanBWelRkamVXNERaSDJ4Rkp3MXlveXlyaXZ3YWNjQUlxWHhSYXpQSm9ZcW9MTkpyQTU4ajY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsIm90cCI6MTIzNCwidmVudWUiOiI2NDY0YWUwMDgxMjM4NjFhZmM5YTUyNTQiLCJwaG9uZW51bWJlciI6Ijk2NDA4NjEwNzYiLCJkb2IiOiIyMDIzLTAxLTAxVDAwOjAwOjAwLjAwMFoiLCJjcmVhdGVkQXQiOiIyMDIzLTA1LTE3VDEwOjM1OjQ0LjU5NloiLCJ1cGRhdGVkQXQiOiIyMDIzLTA1LTE4VDA2OjE5OjI2LjQzOFoiLCJfX3YiOjB9LCJpYXQiOjE2ODQzOTA3NzcsImV4cCI6MTY4NTI1NDc3N30.5Se9vIgLFU1_tJL8wRe0EHp53ltgz6HkOF1rYZSuFMA',
        }
      })
      setHallData(response.data.results);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchImages = async () => {
    try {
      const response = await axios.get('http://192.46.209.97:6060/api/BanquetHallImage/list',{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsImZpcnN0X25hbWUiOiJTaXRhcmEiLCJsYXN0X25hbWUiOiJIb3RlbCIsImVtYWlsIjoic2l0YXJhQHRlc3QuY29tIiwiaXNfcGhvbmVfdmVyaWZpZWQiOmZhbHNlLCJwaG9uZV92ZXJpZmljYXRpb24iOm51bGwsImltYWdlIjpudWxsLCJyb2xlIjpbIjY0NjRhODk5NDQ5YWUwOTA2NGU1MjZmOCJdLCJnZW5kZXIiOiJNYWxlIiwiYWRkcmVzcyI6InN0cmluZyIsInBhc3N3b3JkIjoiJDJiJDEwJEZYSHJSQVFPbVRKZktPZmw2djZsQmVxQS85Smg1UzRvZk1iRTdaNS9FcWRpN1ByUEk4TjRDIiwicmVzZXRQYXdvcmRUb2tlbiI6bnVsbCwicmVmcmVzaFRva2VuIjoia0kydTMxQ3NjRjVscUgwNmRUNVUyNlJ0dzFEbk1FNWVVQnN6VzRBVzBnTXdaaXRNTzdrRnozc0F5dHJpaTI4eGVSb2VsSXhIQ0xrWnFxOGxsWVZseXRSQmF6SktKUHp4dWxCQ1A3TU5GbkpPSERaZ1Mzc1VYc2l4ekhaVjRESFM0dGlRUnQ5a28yWEU4bmhaY0w0N3IxaU5QalBXa0hGRmg2N0c4SkFOUmVlTmdJdjd4dkYyV0dwcVV6Uk15NWE4UmNJWG9CV2MyZmNsanBWelRkamVXNERaSDJ4Rkp3MXlveXlyaXZ3YWNjQUlxWHhSYXpQSm9ZcW9MTkpyQTU4ajY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsIm90cCI6MTIzNCwidmVudWUiOiI2NDY0YWUwMDgxMjM4NjFhZmM5YTUyNTQiLCJwaG9uZW51bWJlciI6Ijk2NDA4NjEwNzYiLCJkb2IiOiIyMDIzLTAxLTAxVDAwOjAwOjAwLjAwMFoiLCJjcmVhdGVkQXQiOiIyMDIzLTA1LTE3VDEwOjM1OjQ0LjU5NloiLCJ1cGRhdGVkQXQiOiIyMDIzLTA1LTE4VDA2OjE5OjI2LjQzOFoiLCJfX3YiOjB9LCJpYXQiOjE2ODQzOTA3NzcsImV4cCI6MTY4NTI1NDc3N30.5Se9vIgLFU1_tJL8wRe0EHp53ltgz6HkOF1rYZSuFMA',
        }
      })
      setMyImages(response.data.results);
      
    } catch (error) {
      console.error(error);
    }
  };

  const deleteImages = async (id) => {
    try {
      const response = await axios.delete(`http://192.46.209.97:6060/api/BanquetHallImage/delete/${id}`,{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsImZpcnN0X25hbWUiOiJTaXRhcmEiLCJsYXN0X25hbWUiOiJIb3RlbCIsImVtYWlsIjoic2l0YXJhQHRlc3QuY29tIiwiaXNfcGhvbmVfdmVyaWZpZWQiOmZhbHNlLCJwaG9uZV92ZXJpZmljYXRpb24iOm51bGwsImltYWdlIjpudWxsLCJyb2xlIjpbIjY0NjRhODk5NDQ5YWUwOTA2NGU1MjZmOCJdLCJnZW5kZXIiOiJNYWxlIiwiYWRkcmVzcyI6InN0cmluZyIsInBhc3N3b3JkIjoiJDJiJDEwJEZYSHJSQVFPbVRKZktPZmw2djZsQmVxQS85Smg1UzRvZk1iRTdaNS9FcWRpN1ByUEk4TjRDIiwicmVzZXRQYXdvcmRUb2tlbiI6bnVsbCwicmVmcmVzaFRva2VuIjoia0kydTMxQ3NjRjVscUgwNmRUNVUyNlJ0dzFEbk1FNWVVQnN6VzRBVzBnTXdaaXRNTzdrRnozc0F5dHJpaTI4eGVSb2VsSXhIQ0xrWnFxOGxsWVZseXRSQmF6SktKUHp4dWxCQ1A3TU5GbkpPSERaZ1Mzc1VYc2l4ekhaVjRESFM0dGlRUnQ5a28yWEU4bmhaY0w0N3IxaU5QalBXa0hGRmg2N0c4SkFOUmVlTmdJdjd4dkYyV0dwcVV6Uk15NWE4UmNJWG9CV2MyZmNsanBWelRkamVXNERaSDJ4Rkp3MXlveXlyaXZ3YWNjQUlxWHhSYXpQSm9ZcW9MTkpyQTU4ajY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsIm90cCI6MTIzNCwidmVudWUiOiI2NDY0YWUwMDgxMjM4NjFhZmM5YTUyNTQiLCJwaG9uZW51bWJlciI6Ijk2NDA4NjEwNzYiLCJkb2IiOiIyMDIzLTAxLTAxVDAwOjAwOjAwLjAwMFoiLCJjcmVhdGVkQXQiOiIyMDIzLTA1LTE3VDEwOjM1OjQ0LjU5NloiLCJ1cGRhdGVkQXQiOiIyMDIzLTA1LTE4VDA2OjE5OjI2LjQzOFoiLCJfX3YiOjB9LCJpYXQiOjE2ODQzOTA3NzcsImV4cCI6MTY4NTI1NDc3N30.5Se9vIgLFU1_tJL8wRe0EHp53ltgz6HkOF1rYZSuFMA',
        }
      })
      if(response.status === 204){
        fetchImages()
      }
      
    } catch (error) {
      console.error(error);
    }
  };

  
  const imageSubmit = async () => {
    try {
        const data = {
            title: inputValue,
  banquet_hall_id: selectedHall,
  image: base64Image
        }
      const response = await axios.post('http://192.46.209.97:6060/api/BanquetHallImage/create',data,{
        headers: {
            accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsImZpcnN0X25hbWUiOiJTaXRhcmEiLCJsYXN0X25hbWUiOiJIb3RlbCIsImVtYWlsIjoic2l0YXJhQHRlc3QuY29tIiwiaXNfcGhvbmVfdmVyaWZpZWQiOmZhbHNlLCJwaG9uZV92ZXJpZmljYXRpb24iOm51bGwsImltYWdlIjpudWxsLCJyb2xlIjpbIjY0NjRhODk5NDQ5YWUwOTA2NGU1MjZmOCJdLCJnZW5kZXIiOiJNYWxlIiwiYWRkcmVzcyI6InN0cmluZyIsInBhc3N3b3JkIjoiJDJiJDEwJEZYSHJSQVFPbVRKZktPZmw2djZsQmVxQS85Smg1UzRvZk1iRTdaNS9FcWRpN1ByUEk4TjRDIiwicmVzZXRQYXdvcmRUb2tlbiI6bnVsbCwicmVmcmVzaFRva2VuIjoia0kydTMxQ3NjRjVscUgwNmRUNVUyNlJ0dzFEbk1FNWVVQnN6VzRBVzBnTXdaaXRNTzdrRnozc0F5dHJpaTI4eGVSb2VsSXhIQ0xrWnFxOGxsWVZseXRSQmF6SktKUHp4dWxCQ1A3TU5GbkpPSERaZ1Mzc1VYc2l4ekhaVjRESFM0dGlRUnQ5a28yWEU4bmhaY0w0N3IxaU5QalBXa0hGRmg2N0c4SkFOUmVlTmdJdjd4dkYyV0dwcVV6Uk15NWE4UmNJWG9CV2MyZmNsanBWelRkamVXNERaSDJ4Rkp3MXlveXlyaXZ3YWNjQUlxWHhSYXpQSm9ZcW9MTkpyQTU4ajY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsIm90cCI6MTIzNCwidmVudWUiOiI2NDY0YWUwMDgxMjM4NjFhZmM5YTUyNTQiLCJwaG9uZW51bWJlciI6Ijk2NDA4NjEwNzYiLCJkb2IiOiIyMDIzLTAxLTAxVDAwOjAwOjAwLjAwMFoiLCJjcmVhdGVkQXQiOiIyMDIzLTA1LTE3VDEwOjM1OjQ0LjU5NloiLCJ1cGRhdGVkQXQiOiIyMDIzLTA1LTE4VDA2OjE5OjI2LjQzOFoiLCJfX3YiOjB9LCJpYXQiOjE2ODQzOTA3NzcsImV4cCI6MTY4NTI1NDc3N30.5Se9vIgLFU1_tJL8wRe0EHp53ltgz6HkOF1rYZSuFMA',
        }
      })
      if(response.status === 201){
        fetchImages()
      }
    } catch (error) {
      console.error(error);
    }
  };
  console.log(hallData)

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    // Check if a file is selected
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // Convert the image to BASE64 format
        const base64Data = reader.result;
        setBase64Image(base64Data);
      };

      reader.readAsDataURL(file);
      setSelectedImage(file);
    }
  };

  const handleImageSubmit = () => {
    // Perform API call with the base64Image data
    console.log(base64Image);
    // ...
  };

  useEffect(()=>{
    fetchBanquetHall()
    fetchImages()
  },[])

  return (
    <>
    {myImages.length > 0 ? (<>{myImages.map((hall) => (
          <div key={hall._id}>
            <h2>{hall.title}</h2>
            <img src={hall.image} alt={hall.title} style={{height:'150px', width:'150px'}}/>
            <div>
            <button onClick={()=>deleteImages(hall._id)}>delete</button>
            </div>
          </div>
        ))} </>): <p>no images</p>

    }
    
      <input type="text" value={inputValue} onChange={(e)=>setInputValue(e.target.value)} placeholder='title'/>
      {hallData?.length > 0 ? <select value={selectedHall} onChange={(e)=>setSelectedHall(e.target.value)}>
        {hallData.map((hall) => (
          <option key={hall._id} value={hall._id}>
            {hall.hall_title}
          </option>
        ))}
      </select> : <h1>no hall data</h1>}
      <input type="file" onChange={handleImageUpload} />
      <button onClick={imageSubmit}>Submit</button>
      {/* <div> */}
        {/* {images.map((image)=>(
            <>
            <p>{image}</p>
            </>
        ))} */}
      {/* </div> */}
    </>
  );
};

export default Image;
