import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Select, MenuItem, FormControl, InputLabel, Button } from '@mui/material';
import {
    Box,
    Grid,
    Stack,
    OutlinedInput,
  } from '@mui/material';

  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const names = ["Male", "Female", "Other"];

const SuperAdmin = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [image, setImage] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [address, setAddress] = useState('');
    const [dob, setDob] = useState('');
    const [gender, setGender] = useState('');
    const [role, setRole] = useState('Superadmin');
  
    const handleSubmit = (event) => {
      event.preventDefault();
  
      const data = {
        first_name: firstName,
        last_name: lastName,
        image: { description: image },
        email: email,
        phonenumber: parseInt(phoneNumber),
        password: password,
        address: address,
        dob: dob,
        gender: gender,
        role: role
      };
  
      axios
        .post('http://192.46.209.97:6060/api/Superadmin/create', data)
        .then((response) => {
          // Handle the API response
        })
        .catch((error) => {
          // Handle any errors that occurred during the API request
          console.error(error);
        });
    };
  
    return (
        <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Image Description"
              value={image}
              onChange={(e) => setImage(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Date"
                        value={dob}
                        onChange={(newValue) =>
                          setDob(newValue.format("YYYY-MM-DD"))
                        }
                      />
                    </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
          <Select
                      displayEmpty
                      value={gender}
                      onChange={(e)=>setGender(e.target.value)}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        if (!selected) {
                          return <em>Gender</em>;
                        }
                        return selected;
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {names.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <InputLabel id="role-label">Role</InputLabel>
              <Select
                labelId="role-label"
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <MenuItem value="Superadmin">Superadmin</MenuItem>
                <MenuItem value="Admin">Admin</MenuItem>
                <MenuItem value="SalesExecutive">Sales Executive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    );
  };
 

export default SuperAdmin