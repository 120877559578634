import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';


const names = [
    'Option 1',
    'Option 2',
    'Option 3',
    'Option 4',
    'Option 5',
  ];

function HallCourtReport (){
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);
  
    const handleChange2 = (event) => {
      const {
        target: { value },
      } = event;
      setPersonName(
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
      setAge(event.target.value);
    };
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    return(
        <>
            <div className="app-page-banquets-management">
                <div className="app-card">
                    <h1 className="app-card__title">Cancel Booking Report</h1>
                    <div className="app-card__content">
                          <Grid container style={{marginTop:'20px'}}>
                            <Grid item xs={12} md={6}>
                                <Grid container rowSpacing={2} columnSpacing={5}>
                                    <Grid item xs={12} md={6}>
                                    <div className="app-form-field">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker 
                                    label="Event From Date"
                                    />
                                  </LocalizationProvider>
                                    </div>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                    <div className="app-form-field">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker 
                                    label="Event From Date"
                                    />
                                  </LocalizationProvider>
                                    </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                        </Grid>
                        <Grid container style={{marginTop:'20px'}}>
                            <Grid item xs={12} md={6}>
                                <div className="app-card__button d-flex justify-content-center mt-20 w-full app-new-app-card__button">
                            <Button className="app-btn app-btn-primary app-btn2-primary" variant="contained">Reset</Button>
                            <Button style={{marginLeft:'20px'}} className="app-btn app-btn-primary" variant="contained">Submit</Button>
                            <Button style={{marginLeft:'20px'}} className="app-btn app-btn-primary" variant="contained">Print</Button>

                        </div>
                            </Grid>
                        </Grid>
                    </div> 
                </div>
                <div className="app-component-table app-new-component-table app-new-component-table2">
                <div className="app-component-table__header app-new-component-header">
                        <h2>Search Result - 20 Records</h2>
                        <h5>From 25th March to 28th March 2023</h5>
                        <h5>Booking ID :13276</h5>
                    </div>
                    <div className='Booking-details-content'>
                        <Grid container rowSpacing={2} columnSpacing={5}>
                            <Grid item xs={12} md={4}>
                                <div className="app-form-field">
                                    <p>Phone No : 9885193224</p>
                                    <p>Person Name : Mr.P.Srikanth</p>
                                    <p>Event From Date : 07-04-2023</p>
                                    <p>Event To Date : 08-04-2023</p>
                                    </div>
                             </Grid>
                                <Grid item xs={12} md={4}>
                                    <div className="app-form-field">
                                        <p>Alternate No : 9885193224</p>
                                        <p>Date of Booking : 06-04-2023</p>
                                        <p>Booking Type : Confirmed</p>
                                    </div>
                                </Grid>
                                  <Grid item xs={12} md={4}>
                                    <div className="app-form-field">
                                    <p>Referred By : Gn Srinivas</p>
                                    <p>Remarks : Dated 11-01-2023 Receipt No's 379262/89</p>
                                    </div>
                                   </Grid>
                                </Grid>
                          
                    </div>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>B</TableCell>
                                    <TableCell>L</TableCell>
                                    <TableCell>D</TableCell>
                                    <TableCell>Hall Type</TableCell>
                                    <TableCell>Pack</TableCell>
                                    <TableCell>Exp.Pack</TableCell>
                                    <TableCell>Hall</TableCell>
                                    <TableCell>Backup Hall</TableCell>
                                    <TableCell>Event</TableCell>
                                    <TableCell>Add</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                    <TableRow>
                                        <TableCell>07-04-2023</TableCell>
                                        <TableCell>Y</TableCell>
                                        <TableCell>Y</TableCell>
                                        <TableCell>Y</TableCell>
                                        <TableCell>In Door</TableCell>
                                        <TableCell>200</TableCell>
                                        <TableCell>200</TableCell>
                                        <TableCell>Ananda Annexe A</TableCell>
                                        <TableCell>-</TableCell>
                                        <TableCell>Res Get</TableCell>
                                        <TableCell>-</TableCell>
                                    </TableRow>
                            </TableBody>
                            <TableBody>
                                    <TableRow>
                                    <TableCell>07-04-2023</TableCell>
                                        <TableCell>Y</TableCell>
                                        <TableCell>Y</TableCell>
                                        <TableCell>Y</TableCell>
                                        <TableCell>In Door</TableCell>
                                        <TableCell>200</TableCell>
                                        <TableCell>200</TableCell>
                                        <TableCell>Ananda Annexe A</TableCell>
                                        <TableCell>-</TableCell>
                                        <TableCell>Res Get</TableCell>
                                        <TableCell>-</TableCell>
                                    </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            <img className="app-tree-bg" src="/assets/images/tree-bg.svg"/>
        </>
    )
}
export default HallCourtReport;