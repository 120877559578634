
import { Grid, Card, CardContent, Typography, Button, CardMedia, Container } from '@mui/material'
import React, { useState, useRef, useEffect } from 'react'
import { Tabs, Tab, Box } from '@mui/material'
import "./css/Home.css"
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Link, useLocation, useNavigate } from 'react-router-dom'

const TermsConditions = () => {
    const [tabValue, setTabValue] = useState(0)

    const pricingRef = useRef(null)

    const cancellationRef = useRef(null)

    const navigate = useNavigate()

    const { state } = useLocation()

    useEffect(() => {
        if (state?.scroll === "cancellation") {
            window.scrollTo({
                top: cancellationRef.current.offsetTop - 200,
                behavior: "smooth"
            })
        }
        else if (state?.scroll) {
            pricingRef.current?.scrollIntoView({ behavior: 'smooth' })
        }
    }, [state])

    return (
        <div className="_5pxd">
            <Header test={pricingRef} />
            <div className='privacy-policy'>
                <Container>
                    <div className='privacy-policy-content'>
                        <h1>Terms and Conditions</h1>
                    </div>
                </Container>
            </div>
            <div className='intro'>
                <Container>
                    <div className='intro-title'>
                        <p>Welcome to <Link to="/">https://venuemanagement.in/</Link>, a <strong>Venue Management</strong> online e-commerce website owned by SKOL TECH LLP
                            (Company), providing its users a one-stop solution to book the bestsuited venue for appropriate events and occasions saving users time.
                            Venue owners list their property on the Website and enable the users to
                            register or book a venue through this website. Use of this website
                            constitutes acceptance of all the mentioned Terms of Service (“TOS”)
                            and has legal effect on the parties in case of any dispute.</p>
                        <p style={{ marginTop: "20px" }}>The Company's Privacy Policy, at <Link to="/privacy_policy" style={{ marginRight: "5px" }}>https://venuemanagement.in/privacy_policy</Link>
                            (the <strong>Privacy Policy</strong>), describes the collection, use, and
                            disclosure of data and information (including location and usage data) by
                            the Company in connection with the Website. The Privacy Policy, as may
                            be updated by the Company from time to time in accordance with its
                            terms, is hereby incorporated into this Agreement, and you hereby agree
                            to the collection, use, and disclosure practices set forth therein. </p>
                        <p style={{ marginTop: "20px" }}>The Website allows users to upload content that may be viewed by other
                            users. If you see any prohibited content within the Website, please use the
                            flagging feature to notify the Company. Users who upload prohibited
                            content may have their accounts terminated permanently and add them to
                            black list. By accepting the terms and conditions and using the website, the
                            user agrees to the following: </p>
                    </div>
                    <div className='privacy-policy-main-content'>
                        <h4>1. Conditions</h4>
                        <p style={{ marginLeft: "20px" }}>
                            <div><strong>1.1</strong> Nothing in these TOS shall affect Your (also referred to as “User”) statutory rights as a Consumer/Customer. </div>
                            <div style={{ marginTop: "15px" }}><strong>1.2</strong> This TOS constitutes the entire and only agreement between you
                                and us and supersedes all prior or contemporaneous agreements,
                                representations, warranties, and understandings.</div>
                            <div style={{ marginTop: "15px" }}>
                                <strong>1.3</strong> The User agrees to use the Website only to book Reservations of
                                Venue for their personal use or for clients wherein, the said user
                                discloses the client name in the website (which shall be kept
                                confidential) and honor said Reservation(s) as per the rules,
                                regulations and policies of Venue Owners/ Management.
                            </div>
                            <div style={{ marginTop: "15px" }}>
                                <strong>1.4</strong> Resale or attempted resale of Reservations is prohibited and is
                                grounds for, among other grounds, cancellation of your
                                Reservations or termination of your access to the Website.
                            </div>
                            <div style={{ marginTop: "15px" }}>
                                <strong>1.5</strong> The website may permit you to submit reviews, comments, and
                                ratings; send emails and other communications; and submit
                                suggestions, ideas, comments, questions, or other information for
                                publication and distribution to Venues and other third parties (“Feedback Content”). Any such Feedback Content must not be
                                illegal, threatening, obscene, racist, defamatory, libelous,
                                pornographic, infringing of intellectual property rights, promoting
                                of illegal activity or harm to groups and/or individuals, invasive of
                                privacy, purposely false or otherwise injurious to third parties, or
                                objectionable and must not consist of or contain software,
                                computer viruses, commercial solicitation, political campaigning,
                                chain letters, mass mailings, any form of “spam” or references to
                                illegal activity, malpractice, purposeful overcharging, false
                                advertising, or health code violations (e.g., foreign objects in food,
                                food poisoning, etc.). Your Feedback Content should be unbiased
                                and objective. You may not submit reviews, comments, or ratings
                                of your own Venue, or any venue of your employer, friend,
                                relative, or competitor
                            </div>
                            <div style={{ marginTop: "15px" }}>
                                <strong>1.6</strong> Company reserves the right to monitor, remove, or edit Feedback
                                Content in the Company’s sole discretion, including if Feedback
                                Content violates any of the terms of this Agreement. If you do
                                submit Feedback Content, and unless we indicate otherwise, you
                                grant Company a nonexclusive, perpetual, royalty-free,
                                irrevocable, and fully sub-licensable (through multiple tiers) right
                                to use, modify, reproduce, adapt, translate, publish, create
                                derivative works from, distribute, display, and otherwise exploit
                                such Feedback Content throughout the world in any media, and,
                                where applicable, you hereby waive any moral or other rights you
                                may have in the Feedback Content you submit in favor of
                                Company. The company takes no responsibility and assumes no
                                liability for any Feedback Content submitted by you or any other
                                user or third party.
                            </div>
                            <div style={{ marginTop: "15px" }}>
                                <strong>1.7</strong> Any complaints or grievances should be addressed to the Company
                                by email to <strong>info@skoltech.in</strong> and a member of our team will deal
                                with your request within a reasonable time.
                            </div>
                        </p>
                    </div>
                    <div className='privacy-policy-main-content'>
                        <h4>2. Age of Consent</h4>
                        <p style={{ marginLeft: "20px" }}>
                            User can only use the website, only upon registration as user on the
                            website and by such registration, the user declares that he/she is not
                            below 18 years of age and at any point of time, company reserves its
                            right to demand the user to provide documents to verify the age of
                            the user and if found deviated, the company may suspend the user
                            registration and may be black listed. Use of the website by anyone
                            under the age of 18 is prohibited and by using the services on the
                            company’s website, you represent and warrant that you are above
                            the age of 18 years.
                        </p>
                    </div>
                    <div className='privacy-policy-main-content'>
                        <h4>3. Reservations and Bookings</h4>
                        <p style={{ marginLeft: "20px" }}>
                            <div style={{ marginTop: "15px" }}>
                                <strong>3.1</strong> All displayed venues on the website shall be deemed to be an offer
                                by the Venue Owners to reserve or book a Venue pursuant to these
                                Terms and Conditions and are subject to acceptance by the
                                Company/ Venue Owner. The Company/ Venue Owner is at liberty
                                to not accept an order for any reason.
                            </div>
                            <div style={{ marginTop: "15px" }}>
                                <strong>3.2</strong> To reserve or book a venue with the Company, the user has to go
                                through all technical steps as described in the order process selection
                                stages of the Website. Once the reservation or booking is
                                successfully made, a confirmation mockup will be generated to you
                                via Email and/or SMS and only after due approval, the reservation
                                will reach finality.
                            </div>
                        </p>
                    </div>
                    <div className='privacy-policy-main-content'>
                        <h4>4. Prohibited Use</h4>
                        <p style={{ marginLeft: "20px" }}>
                            <div style={{ marginTop: "15px" }}>
                                <strong>4.1</strong> The User can only use the website as expressly permitted by
                                Company and abstain from causing any harm to the website,
                                specifically, but not by way of limitation, user may not:
                                <ul>
                                    <li style={{ marginBottom: "8px" }}>Modify, create derivative works from reverse engineer,
                                        decompile or disassemble any technology.
                                    </li>
                                    <li style={{ marginBottom: "8px" }}>
                                        Interfere with website performance by using viruses or any other
                                        technology or program designed to affect performance, content,
                                        and appearance of the website.
                                    </li>
                                    <li>
                                        Collect any user information used for registration and cause
                                        voluntary harm to other Users of this website.
                                    </li>
                                </ul>
                            </div>
                            <div style={{ marginTop: "15px" }}>
                                <strong>4.2</strong> As a condition of your use of the website, you will not use the
                                website for any purpose that is unlawful or prohibited by this
                                Agreement. You may not use the website in any manner that in our
                                sole discretion could damage, disable, overburden, impair or
                                interfere with any other party’s use of it. You may not obtain or
                                attempt to obtain any materials or information through any means
                                not intentionally made available through the website. You agree
                                not to scrape or otherwise use automated means to access or
                                gather information from the website, and agree not to bypass any
                                robot exclusion measures we may put into place. In addition, you
                                agree not to use false or misleading information in connection with
                                your user account, and acknowledge that we reserve the right to
                                disable any user account with a profile which we believe (in our
                                sole discretion) is false or misleading (including a profile that
                                impersonates a third party).
                            </div>
                        </p>
                    </div>
                    <div className='privacy-policy-main-content'>
                        <h4>5. Changes</h4>
                        <p style={{ marginLeft: "20px" }}>
                            <div style={{ marginTop: "15px" }}>
                                <strong>5.1</strong> Company is at liberty to modify the site at any time without any
                                prior notice and will incur no liability for doing so. Such changes
                                may be temporary or permanent.
                            </div>
                            <div style={{ marginTop: "15px" }}>
                                <strong>5.2</strong> Company can at any time choose to change these terms and
                                conditions and if you do not accept these Terms and Conditions,
                                you must immediately stop using this Website. Your continued use
                                of the Website following any changes shall be deemed as your
                                implied acceptance of such changes and considered as consented.
                            </div>
                        </p>
                    </div>
                    <div className='privacy-policy-main-content'>
                        <h4>6. Reservation of Rights</h4>
                        <p style={{ marginLeft: "20px" }}>
                            <div style={{ marginTop: "15px" }}>
                                <strong>6.1</strong> The Company reserves the right to withdraw the Services offered
                                on the website at any point in time and shall not be liable to anyone
                                for withdrawing the services from the Website or for refusing to
                                reserve or book a venue
                            </div>
                            <div style={{ marginTop: "15px" }}>
                                <strong>6.2</strong> The Company reserves the right but does not assume the
                                obligation to monitor transactions and communications that occurs
                                through website. If Company determines, in its sole and absolute
                                discretion that the user breached a term or condition of this TOS,
                                the Company may cancel such transactions and restrict access.
                            </div>
                        </p>
                    </div>
                    <div className='privacy-policy-main-content'>
                        <h4>7. Transactions</h4>
                        <p style={{ marginLeft: "20px" }}>
                            <div style={{ marginTop: "15px" }}>
                                <strong>7.1</strong> By making payments through the website, you acknowledge that,
                                all transactions you enter into in connection with the website are
                                between you and venue owners and the Company is not a party to
                                such transactions. The Company’s sole involvement in user-to-user
                                transactions is to make available a marketplace for Listing Venues
                                and to make Reservations by users. Any agreement to a proposal
                                made with regards to the reservation of venue constitutes a
                                contract directly between the user and venue owner for the
                                provision of the applicable Venue at the stated price and subject to
                                such other terms as may be agreed to between the user and venue
                                owner within the venue reservation as a part of the agreed-upon
                                proposal (each such contract, a “Venue Agreement”).
                            </div>
                            <div style={{ marginTop: "15px" }}>
                                <strong>7.2</strong> You are solely responsible for investigating the quality,
                                appropriateness, space, cleanliness and credentials of any Venue
                                with respect to which you may communicate with a venue owner
                                through the website. You acknowledge that the Company:
                                <ul>
                                    <li style={{ marginBottom: "8px" }}>
                                        does not inspect any Venue,
                                    </li>
                                    <li style={{ marginBottom: "8px" }}>
                                        does not guarantee that services promised by Venue Owners will
                                        be performed or meet your needs,
                                    </li>
                                    <li>
                                        does not guarantee that Venues will conform with any provided
                                        descriptions or be similar in appearance to any provided
                                        photographs,
                                    </li>
                                    <li style={{ marginBottom: "8px" }}>does not investigate or screen Venue Owners or Venues in any
                                        way, including with respect to their quality, appropriateness,
                                        space, cleanliness and credentials and</li>
                                    <li style={{ marginBottom: "8px" }}> makes no warranties regarding the existence, safety, quality,
                                        adequacy, merchantability, or fitness for a particular purpose of
                                        any Venue or promised services, that a Venue Owner has the
                                        right to provide any Venue, or that a Venue Owner will
                                        consummate any transaction. You further acknowledge that the
                                        Company cannot and does not guarantee a Customer will pay
                                        amounts owed for a transaction</li>
                                    <li style={{ marginBottom: "8px" }}>You are solely responsible for determining your legal obligations
                                        in relation to any Venues or services you may offer or purchase
                                        or any Venue Agreement you may enter into, including as may
                                        relate to taxes, insurance or licensing, and credentialing
                                        requirements. The Company does not provide legal advice</li>


                                </ul>
                            </div>
                            <div style={{ marginTop: "15px" }}>  <strong>7.3</strong> While the Company does not control the acts or omissions of
                                users, the Company does desire for users to have a good
                                experience using the Service and interacting with other users.
                                As such, when the venue owner posts the venue on the website,
                                the venue owner agrees to: (i) accurately describe your Venue
                                and not make any false or misleading statements relating
                                thereto; and (ii) use your reasonable best efforts to timely
                                complete all of your obligations under the Venue Agreement and
                                perform any services in a professional and workmanlike
                                manner.
                            </div>
                            <div style={{ marginTop: "15px" }}>  <strong>7.4</strong> In addition, when you use the website, the user agree to (a)
                                promptly make payment to the venue owner in accordance with
                                the payment schedule agreed upon in the Venue Agreement; (b)
                                honor all terms and requirements in the Venue Agreement; and
                                (c) abide by all applicable laws and policies in force at the
                                applicable event space or venue. Without limiting the Company’s
                                other rights to terminate this Agreement or your use of website,
                                you acknowledge that the Company may terminate your use of
                                the website and this Agreement in the event that the Company
                                determines (in its sole discretion) that you have breached any
                                portion of this Section.
                            </div>
                            <div style={{ marginTop: "15px" }}>  <strong>7.5</strong> The company recommends that both users and Venue owners to
                                obtain appropriate insurance for their use of Venues by properly
                                going through the terms and conditions and exclusions in the
                                policies. The user understands that certain Venue owners may
                                require certain insurance under their Venue Agreements. </div>
                        </p>
                    </div>
                    <div className='privacy-policy-main-content'>
                        <h4>8. Representations and Warranties:</h4>
                        <p style={{ marginLeft: "20px" }}>
                            <div style={{ marginTop: "15px" }}>You represent, warrant and
                                covenant that, in connection with this website, you will not and will not
                                attempt to:</div>

                            <ui>
                                <li style={{ listStyle: "none", marginBottom: "8px" }}>(i) violate any laws, third-party rights, or our community guidelines
                                    and other policies;
                                </li>
                                <li style={{ listStyle: "none", marginBottom: "8px" }}>(ii) offer for sale or rent, sell or rent, purchase or otherwise transfer,
                                    deal in or dispose of illegal products or services or products or
                                    services that encourage illegal activities, controlled substances,
                                    offensive materials, stocks or other securities, pharmaceuticals,
                                    medical devices, firearms, weapons, explosives, hazardous materials,
                                    any item that has been subject to a recall or that you believe may be
                                    unsafe when used in an ordinary manner, alcohol, tobacco products,
                                    animals, plants or seeds;
                                </li>
                                <li style={{ listStyle: "none", marginBottom: "8px" }}>(iii) re-join or attempt to use the Website if the Company has banned
                                    or suspended you;
                                </li>
                                <li style={{ listStyle: "none", marginBottom: "8px" }}>(iv) defraud the Company or another user; or
                                </li>
                                <li style={{ listStyle: "none", marginBottom: "8px" }}>(v) use another user’s account or allow another person to use your
                                    user account. Any illegal activities undertaken in connection with the
                                    website may be referred to the authorities.
                                </li>
                            </ui>
                        </p>
                    </div>
                    <div className='privacy-policy-main-content'>
                        <h4>9. Ownership; Proprietary Rights:</h4>
                        <p style={{ marginLeft: "20px" }}>
                            <div style={{ marginTop: "15px" }}>
                                As between you and the Company,
                                the Company owns all worldwide rights, titles, and interests, including
                                all intellectual property and other proprietary rights, in and to the
                                Website and all usage and other data generated or collected in
                                connection with the use thereof (the “Company Materials”). The
                                information and User Content you upload, post, e-mail, transmit, or
                                otherwise make available to Us or on our Sites, including without
                                limitation reviews, trademarks, logos, screenshots, and videos is
                                accurate and free of third-party encumbrances
                            </div></p></div>

                    <div className='privacy-policy-main-content'>
                        <h4>10. Third-Party Sites:</h4>
                        <p style={{ marginLeft: "20px" }}>
                            <div style={{ marginTop: "15px" }}> The website provided by the company does not hold
                                any affiliations with links or references to third-party websites or thirdparty services. The company does not hold any affiliated partnerships
                                as to the structure or mention on the website.</div></p></div>

                    <div className='privacy-policy-main-content'>
                        <h4>11. Mobile Services; SMS:</h4>
                        <p style={{ marginLeft: "20px" }}>
                            <div style={{ marginTop: "15px" }}> Use of the App requires the usage of data and
                                messaging services provided by your wireless service carrier. In
                                particular, the App may use SMS messaging to provide you with
                                information relating to your use of the App. You hereby consent to
                                receiving such messages. You acknowledge and agree that you are
                                solely responsible for data usage fees, messaging fees, and any other
                                fees that your wireless service carrier may charge in connection with
                                your use of the App.</div></p></div>

                    <div ref={cancellationRef} className='privacy-policy-main-content'>
                        <h4>12. Payment:</h4>
                        <p style={{ marginLeft: "20px" }}>
                            <div style={{ marginTop: "15px" }}>
                                <ui>
                                    <li style={{ listStyle: "none", marginBottom: "8px" }}>
                                        12.1 The company accepts payment via modes of Credit card, Debit card,
                                        Netbanking and UPI. The company does not collect or store your credit
                                        card information. You can find out more about both our privacy
                                        practices in our Privacy Policy. By providing a credit card or other
                                        payment method accepted by the Company, you represent and
                                        warrant that you are authorized to use the designated payment
                                        method. If the payment method you provide cannot be verified, is
                                        invalid, or is otherwise not acceptable, your account may be suspended
                                        or canceled. You must resolve any problem the Payment Processor
                                        encounters in order to proceed with your use of your account. The
                                        pricing of venues available on the website is determined through a
                                        dynamic pricing mechanism and it is applicable to venues all over the
                                        globe.
                                    </li>
                                    <li style={{ listStyle: "none", marginBottom: "8px" }}>12.2 THE COMPANY STRICTLY PRACTICES NO REFUND POLICY. Any
                                        payments made for reservations or booking of a venue, the company
                                        or its Venue Owners are NON-REFUNDABLE at any instance and the
                                        user by making applicable payment consents to this condition of the
                                        company, the Venue Owner or the company cannot be put to loss by
                                        the customer booking for the venue through the company “Venue
                                        Management” website.</li>
                                </ui>

                            </div>





                        </p></div>

                    <div className='privacy-policy-main-content'>
                        <h4>13. Disclaimers; No Warranties:</h4>
                        <p style={{ marginLeft: "20px" }}>
                            <div style={{ marginTop: "15px" }}>The company, venue owners, its
                                licensors, service providers, and partners do not warrant that the
                                features and functionality of the website will be uninterrupted or errorfree, that defects will be corrected, or that the website or the servers
                                that make available the features and functionality thereof are free of
                                viruses or other harmful components. Certain state laws do not allow
                                limitations on implied warranties. If these laws apply to you, some or
                                all of the foregoing disclaimers, exclusions, or limitations may not apply
                                to you, and you might have additional rights.</div></p></div>

                    <div className='privacy-policy-main-content'>
                        <h4>14. Indemnification: </h4>
                        <p style={{ marginLeft: "20px" }}>
                            <div style={{ marginTop: "15px" }}>You agree to indemnify and hold the Company,
                                venue owners and its affiliated companies, and each of their officers,
                                directors and employees, harmless from any claims, losses, damages,
                                liabilities, costs and expenses, including reasonable attorney’s fees,
                                (any of the foregoing, a “Claim”) arising out of or relating to your use
                                or misuse of the website, entry into or performance of any Venue
                                Agreement (including, breach of a Venue Agreement), breach of this
                                Agreement or infringement, misappropriation or violation of the
                                intellectual property or other rights of any other person or entity,
                                provided that the foregoing does not obligate you to the extent the
                                Claim arises out of the Company’s willful misconduct or gross
                                negligence. The Company reserves the right, at our own expense, to
                                assume the exclusive defense and control of any matter for which you
                                are required to indemnify us and you agree to cooperate with our
                                defense of these claims. </div></p></div>


                    <div className='privacy-policy-main-content'>
                        <h4>15. Limitation of Liability and Damages:</h4>
                        <p style={{ marginLeft: "20px" }}>
                            <div style={{ marginTop: "15px" }}> Under no circumstances,
                                including, but not limited to, negligence, shall the company or its
                                affiliates, contractors, employees, officers, directors, agents, or
                                partners, licensors, service providers or venue owners, be liable to you
                                for any special, indirect, incidental, consequential, or exemplary
                                damages that arise out of or relate to the website, including your use
                                thereof, or any other interactions with the company, your visit to any
                                venue, any events (including events booked through the website), any
                                venue policies or the acts or omissions of event attendees, organizers
                                or venue personnel or the performance, non-performance, conduct, or
                                policies of any venue or Venue Owner in connection with the website,
                                even if the company or a company authorized representative has been
                                advised of the possibility of such damages. Applicable law may not
                                allow the limitation or exclusion of liability or incidental or consequential
                                damages, so the above limitation or exclusion may not apply to you, in
                                which case the company’s liability will be limited to the extent permitted
                                by law. </div></p></div>
                    <div className='privacy-policy-main-content'>
                        <h4>16. Arbitration:</h4>
                        <p style={{ marginLeft: "20px" }}>
                            <div style={{ marginTop: "15px" }}>
                                <ui>
                                    <li style={{ listStyle: "none", marginBottom: "8px" }}><b>16.1</b> In the rare event of a dispute, all disputes arising out of, relating to,
                                        or connected with these TOS or your use of any part of the
                                        _______email_____ will be exclusively resolved under confidential
                                        binding arbitration in accordance with the commercial arbitration as
                                        per the Arbitration and Conciliation Act, rather than in court. In case
                                        the aggrieved opts to institute a suit in the court of law, only the
                                        courts in Hyderabad, Telangana shall have exclusive jurisdiction to
                                        try the matter. Each party will be responsible for all other fees it
                                        incurs in connection with the arbitration, and filing, including without
                                        limitation, all attorney fees. Each party will be responsible for all other
                                        fees it incurs in connection with the arbitration, including without
                                        limitation, all attorney fees. </li>
                                    <li style={{ listStyle: "none", marginBottom: "8px" }}><b>16.2</b> CLASS ACTION WAIVER: The parties agree that each party may bring
                                        claims against the other only on an individual basis and not as a
                                        plaintiff or class member in any purported class or representative
                                        action or proceeding. Unless both parties agree otherwise, the
                                        arbitrator may not consolidate or join more than one person’s or
                                        party’s claims and may not otherwise preside over any form of a
                                        consolidated, representative, or class proceeding. Also, the arbitrator
                                        may award relief (including monetary, injunctive, and declaratory
                                        relief) only in favor of the individual party seeking relief and only to the extent necessary to provide relief necessitated by that party’s
                                        individual claim(s). </li>
                                    <li style={{ listStyle: "none", marginBottom: "8px" }}><b>16.3</b> Governing Law. These terms and conditions shall be governed by and
                                        construed in accordance with the law of India and you hereby submit
                                        to the exclusive jurisdiction of the Courts of Hyderabad, Telangana. </li>
                                    <li style={{ listStyle: "none", marginBottom: "8px" }}><b>16.4</b> Confidentiality. All aspects of the arbitration proceeding, and any
                                        ruling, decision or award by the arbitrator, will be strictly confidential
                                        for the benefit of all parties. </li>
                                </ui>
                            </div></p></div>
                    <div className='privacy-policy-main-content'>
                        <h4>17. Miscellaneous:</h4>
                        <p style={{ marginLeft: "20px" }}>
                            <div style={{ marginTop: "15px" }}>
                                <ui>
                                    <li style={{ listStyle: "none", marginBottom: "8px" }}><b>17.1</b> These TOS will be binding upon each party hereto and its successors
                                        and permitted assigns, and governed by and construed in accordance
                                        with the laws of India without reference to conflict of law principles.
                                        These TOS will not be assignable transferable by the user without the
                                        prior written consent of the Company. These TOS contain the entire
                                        understanding of the parties regarding its subject matter and
                                        supersedes all prior and contemporaneous agreements and
                                        understanding between the parties regarding its subject matter. User,
                                        venue owners, and Company are independent contractors, and no
                                        agency, partnership, joint venture, or employee-employer
                                        relationship is intended or created by these TOS. </li>
                                    <li style={{ listStyle: "none", marginBottom: "8px" }}><b>17.2</b> A waiver of any breach of any provision of this Agreement shall not
                                        be construed as a continuing waiver of other breaches of the same or
                                        other provisions of this Agreement. If any provision of this Agreement
                                        shall be unlawful, void, or for any reason unenforceable, then that
                                        provision shall be deemed severable from this Agreement and shall
                                        not affect the validity and enforceability of any remaining provisions. </li>
                                    <li style={{ listStyle: "none", marginBottom: "8px" }}><b>17.3</b> This Agreement, and any rights and licenses granted hereunder, may
                                        not be transferred or assigned by you but may be assigned by the
                                        Company without restriction. This is the entire agreement between
                                        us relating to the subject matter herein and shall not be modified
                                        except in a writing, signed by both parties or by a change to this
                                        Agreement made by the Company as set forth herein. </li>



                                </ui></div></p></div>
                    <div className='privacy-policy-main-content'>
                        <h4>18. INTELLECTUAL PROPERTY:</h4>
                        <p style={{ marginLeft: "20px" }}>
                            <div style={{ marginTop: "15px" }}>
                                <ui>
                                    <li style={{ listStyle: "none", marginBottom: "8px" }}><b>18.1</b>. The content, logo, images and other intellectual property on the
                                        website are managed and owned by <b>Skol Tech LLP.</b> The company
                                        respects the intellectual property rights of third parties and responds
                                        to allegations that copyrighted material has been posted, uploaded,
                                        or shared on or through the website without authorization from the
                                        copyright holder in accordance with the safe harbor set forth in the
                                        appropriate IPR Acts. The Company will also, in appropriate
                                        circumstances and at its discretion, disable and/or terminate the
                                        accounts of users who may infringe or repeatedly infringe the
                                        copyrights of others.  </li>
                                    <li style={{ listStyle: "none", marginBottom: "8px" }}><b>18.2</b> All the content including but not limited to Design, graphics,
                                        organization, and digital conversion related to site are protected
                                        under applicable copyright, trademarks and other property rights.
                                        Copying, downloading or redistribution of any such matters or any
                                        part of site, except outlined in this TOS, is strictly prohibited. The
                                        company may at any point when it comes to its knowledge with
                                        respect to the infringement of the Intellectual Property Rights
                                        terminate the business with the Infringer service provideror venue
                                        owner or user thereof.      </li>
                                    <li style={{ listStyle: "none", marginBottom: "8px" }}><b>18.3</b> User will retain ownership of the content that are used, referred or
                                        uploaded by the user during the use of the site. While using any
                                        outside content, the user grants the following licenses to Company,
                                        the non-exclusive, worldwide, transferable, sub-licensable right to
                                        copy, crop, reproduce, publicly display, sell, and distribute design in
                                        or on products and in advertising, marketing, samples, and
                                        promotional materials for Company and the right to make
                                        modifications to users' design. We will not distribute it without prior
                                        approval from your side.</li>
                                    <li style={{ listStyle: "none", marginBottom: "8px" }}><b>18.4</b> User may seek for removal of the listed venue or the venue owner
                                        may remove the listed venue, and retain any trademark/copyright
                                        and other intellectual property rights in that Content. If the User
                                        chooses to remove the listed venue that is designed or uploaded and
                                        notify the Company of his/her/their intention to terminate the
                                        licenses described in the above paragraph, those licenses will
                                        terminate, except that the Company may fulfill all
                                        orders/bookings/reservations that are in any way derived from that
                                        Content placed prior to notice of termination and may continue to use
                                        said in marketing and promotional materials. </li>
                                </ui>
                                <div style={{ marginBottom: "8px" }}><b>18.5</b> If the user believes that his/her/their work has been copied in a way
                                    that constitutes infringement of Intellectual property rights, please
                                    provide our agent (info@skoltech.in) the following information.
                                    <ul>
                                        <li>Written document with signature of the person authorized to
                                            act on behalf of the owner of the copyright interest.</li>
                                       <li >Detail on material that is claimed as infringed.</li>
                                        <li >Contact information.
                                            Our Agent will quickly act on any such notice received. </li>
                                                                            </ul>
                                </div>
                            </div></p></div>

                    <div className='privacy-policy-main-content'>
                        <h4>19.NOTICE/ MORE INFORMATION/ COMPLAINTS :</h4>
                        <p style={{ marginLeft: "20px" }}>
                            <div style={{ marginTop: "15px" }}>All notices
                                required or permitted to be given under these TOS will be in writing
                                and delivered to the other party by any of the following methods: (a)
                                India mail or Courier (b) electronic mail. All the notices to the Company
                                should be sent to<b> email:info@skoltech.in</b> and also hard copy with
                                signature and contact information should be sent to the company
                                address. The company requires user to respond to all information or
                                proof approval requests in three days. If the user fails to respond to
                                the request for any reason, the Company will use its best judgment on
                                if and how to proceed</div>


                        </p></div>
                    <div className='privacy-policy-main-content'>
                        <h4>20. FORCE MAJEURE: </h4>
                        <p style={{ marginLeft: "20px" }}>
                            <div style={{ marginTop: "15px" }}>The Company shall not be liable for any delay or
                                failure to perform any of its obligations if the delay or failure results
                                from events or circumstances outside its reasonable control, including
                                but not limited to acts of God, strikes, lockouts, accidents, war, fire or
                                failure of any communications, telecommunications or computer
                                system, and the Company shall be entitled to a reasonable extension
                                of its obligations</div>


                        </p></div>
                    <div className='privacy-policy-main-content'>
                        <h4>21. SEVERANCE:  </h4>
                        <p style={{ marginLeft: "20px" }}>
                            <div style={{ marginTop: "15px" }}>If any of these Terms and Conditions is held invalid,
                                illegal, or unenforceable for any reason by any court of competent
                                jurisdiction such provision shall be severed and the remainder of the
                                provisions hereof shall continue in full force and effect as if these Terms
                                and Conditions had been agreed with the invalid illegal or unenforceable
                                provision eliminated.</div>


                        </p></div>
                    <div className='privacy-policy-main-content'>
                        <h4>22. GOVERNING LAWS:  </h4>
                        <p style={{ marginLeft: "20px" }}>
                            <div style={{ marginTop: "15px" }}>These terms and conditions shall be governed by
                                and construed in accordance with the law of India and you hereby
                                submit to the exclusive jurisdiction of the Courts of Hyderabad,
                                Telangana.</div>

                        </p></div>




               
                </Container>
            </div>


            <Footer />
        </div>

    )
}

export default TermsConditions
