import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
// import OutlinedInput from '@mui/material/OutlinedInput';
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import axios from "axios";
import api from "../../config/backend"
import { toast } from "react-toastify";
import { AccessContext } from "../../App";

function Department() {
  const [departmentName, setDepartmentName] = React.useState("");
  const [displayName, setDisplayName] = React.useState("");
  const [departmentList, setDepartmentList] = React.useState([]);
  const [editingDepartmentId, setEditingDepartmentId] = React.useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault()

    const token = localStorage.getItem("token")

    const { data, status } = await axios.post(`${api}/Designation/create-designation`, {
      token,
      designation_name: departmentName,
      display_name: displayName,
    })

    if (data.success) {
      getDepartmentList();
      setDepartmentName(""); // Clear the input field for departmentName
      setDisplayName("");
    } else {
      toast.error(data.message)
    }
  }

  const getDepartmentList = async () => {
    const token = localStorage.getItem("token")
    const { data, status } = await axios.post(`${api}/Designation/get-designations`, { token })
    if (data.success) {
      setDepartmentList(data.designations)
    }
  }

  React.useEffect(() => {
    getDepartmentList();
  }, []);

  const [editMode, setEditMode] = React.useState(false);
  const [currentDepartment, setCurrentDepartment] = React.useState(null);

  const handleEdit = (departmentId) => {
    const department = departmentList.find((dept) => dept._id === departmentId);
    if (department) {
      setDepartmentName(department.title);
      setDisplayName(department.display_title);
      setEditingDepartmentId(departmentId); // Set the departmentId in state for update
      setEditMode(true); // Enable edit mode
      setCurrentDepartment(department);
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    const { status } = await axios.patch(`${api}/Designation/update/${editingDepartmentId}`, {
      title: departmentName,
      display_title: displayName,
    },);
    if (status === 200 || status === 201) {
      setDepartmentList((prevState) =>
        prevState.map((dept) =>
          dept._id === editingDepartmentId
            ? { ...dept, title: departmentName, display_title: displayName }
            : dept
        )
      );

      getDepartmentList();
      setDepartmentName(""); // Clear the input field for departmentName
      setDisplayName("");

    }
  };

  const deleteObject = async (id) => {
    try {
      const confirmed = window.confirm(
        "Are you sure you want to delete this object?"
      );
      if (confirmed) {
        const { status } = await axios.delete(`${api}/Designation/delete/${id}`,);
        if (status === 204) {
          getDepartmentList();
        }

      }
    } catch (error) {
      console.error("Error deleting object:", error);
      // Display an error message to the user or trigger UI behavior
    }
  };


  const { state } = React.useContext(AccessContext)
  const data = state.access ? state.access.designation : undefined

  return (
    <>

      <div className="app-page-banquets-management">
        {data && data.edit ? (
          <div className="app-card">
            <h1 className="app-card__title">Designation</h1>
            <div className="app-card__content">
              <Grid container>
                <form onSubmit={editMode ? handleUpdate : handleSubmit}>
                  <Grid item xs={12} md={12}>
                    <Grid container rowSpacing={2} columnSpacing={5}>
                      <Grid item xs={12} md={12}>
                        <div className="app-form-field">
                          {/* <TextField label="Department Name" variant="outlined" placeholder="Enter Department Name" /> */}
                          <TextField
                            label="Designation Name"
                            variant="outlined"
                            placeholder="Enter Designation Name"
                            value={departmentName}
                            onChange={(event) =>
                              setDepartmentName(event.target.value)
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <div className="app-form-field">
                          {/* <TextField label="Display Name" variant="outlined" placeholder="Enter Display Name" /> */}
                          <TextField
                            label="Display Name"
                            variant="outlined"
                            placeholder="Enter Display Name"
                            value={displayName}
                            onChange={(event) =>
                              setDisplayName(event.target.value)
                            }
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <div className="app-card__button d-flex justify-content-center mt-20 w-full">
                      {/* <Button className="app-btn app-btn-primary" variant="contained" type='submit'>Submit Details</Button> */}
                      <Button
                        className="app-btn app-btn-primary"
                        variant="contained"
                        type="submit"
                      >
                        {editMode ? "Save Changes" : "Submit Details"}
                      </Button>
                    </div>
                  </Grid>
                </form>
              </Grid>
            </div>
          </div>
        ) : undefined}

        {data && data.view ? (
          <div className="app-component-table" style={{ marginTop: "30px" }}>
            <div className="app-component-table__header">
              <h2>List Of Designation Names</h2>
              <div className="app-component-table__header__search">
                <TextField label="Search" variant="outlined" />
                <img src="/assets/icons/search.svg" />
              </div>
            </div>

            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Designation Name</TableCell>
                    <TableCell>Display Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {departmentList.map((department) => (
                    <TableRow key={department.id}>
                      <TableCell>{department.title}</TableCell>
                      <TableCell>{department.display_title}</TableCell>
                      <TableCell>
                        <div className="app-table-status">
                          <Stack direction="row" spacing={1}>
                            <IconButton aria-label="delete">
                              {/* <img style={{ width: '20px' }} src='/assets/icons/edit.svg' /> */}
                              <IconButton
                                aria-label="edit"
                                //   onClick={() => {
                                //     setDepartmentName(department.title);
                                //     setDisplayName(department.display_title);
                                //     setEditMode(true);
                                //     setCurrentDepartment(department);
                                //   }}
                                onClick={() => handleEdit(department._id)}
                              >
                                <img
                                  style={{ width: "20px" }}
                                  src="/assets/icons/edit.svg"
                                />
                              </IconButton>
                            </IconButton>
                          </Stack>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="app-table-status">
                          <Stack direction="row" spacing={1}>
                            <IconButton
                              aria-label="delete"
                              onClick={() => deleteObject(department._id)}
                            >
                              <img
                                style={{ width: "20px" }}
                                src="/assets/icons/remove.svg"
                              />
                            </IconButton>
                          </Stack>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : undefined}


      </div>
      {/* <img className="app-tree-bg" src="/assets/images/tree-bg.svg" /> */}
    </>
  );
}
export default Department;
