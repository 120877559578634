import React, { useEffect, useState } from 'react'
import './css/LoginPage.css'
import StepperHeader from '../components/StepperHeader'
import { Button, Checkbox, FormControlLabel, Grid, Paper, TextField, Typography, InputAdornment, IconButton } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { withStyles } from "@material-ui/core/styles"
import Slider from 'react-slick'
import img from "../assets/LoginImage.png"
import logo from "../assets/google.png"
import api from '../config/backend'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'


const CheckboxWithBlackCheck = withStyles({
    root: {
        "& .MuiSvgIcon-root": {
            color: "default",
            "&:hover": {
                backgroundColor: "white"
            }
        },
        "&$checked": {
            color: "yellow",
            "& .MuiIconButton-label": {
                position: "relative",
                zIndex: 0,
            },
            "& .MuiIconButton-label:after": {
                content: '""',
                left: 4,
                top: 4,
                height: 15,
                width: 15,
                position: "absolute",
                backgroundColor: "black",
                zIndex: -1,
                borderColor: "transparent"
            }
        }
    },
    checked: {}
})(Checkbox)

const LoginPage = () => {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [message, setMessage] = useState("")
    const [showPassword, setShowPassword] = useState(false)

    const navigate = useNavigate()

    const handleTogglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword)
    }

    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: false
    })

    const onRegisterClick = () => {
        navigate("/signupPage")
    }

    const handleChange = (name) => (event) => {
        setState({ ...state, [name]: event.target.checked })
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    }

    useEffect(() => {
        let timeout

        if (message) {
            timeout = setTimeout(() => {
                setMessage(undefined)
            }, 5000)
        }

        return () => clearTimeout(timeout)
    }, [message])

    const handleLogin = async (e) => {
        e.preventDefault()
        const { data, status } = await axios.post(`${api}/Author/sign-in`, { username, password })

        if (data.success) {
            localStorage.setItem("token", data.token)

            if (data.pending) {
                navigate("/email-verification")
            }
            else {
                navigate("/dashboard")
            }
        } else {
            setMessage(data.message)
        }
    }

    return (
        <div className="_2nye">
            <StepperHeader />
            <Grid className="_5hdf" container spacing={2}>
                <Grid item xs={12} sm={6} md={8} className='image'>
                    <Slider {...settings} >
                        <div>
                            <img src={img} alt="Slide 1" />
                        </div>
                        <div>
                            <img src={img} alt="Slide 2" />
                        </div>
                        <div>
                            <img src={img} alt="Slide 3" />
                        </div>
                    </Slider>
                    <div className='need-help-main'>
                        <Grid container className='need-help'>
                            <Grid item xs={6} md={8} className='needhelp-left'>
                                <h3>Need Help?</h3>
                                <p>Get the information you're looking for right now</p>
                            </Grid>
                            <Grid item xs={6} md={4} className='contactttus'>
                                <a href='mailto:info@skoltech.in'>
                                    <button>Contact Us</button>
                                </a>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} component={Paper} elevation={6} square className='paper'>
                    <div>
                        <Typography variant="h5" className='loginlogin'>Login</Typography>
                        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                            Please enter username and password
                        </Typography>
                        <form onSubmit={handleLogin} className="form" >
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="username"
                                label="Username"
                                name="username"
                                autoComplete="username"
                                autoFocus
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                className="custom-username-textfield"
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="password"
                                label="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                autoComplete="current-password"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                className="custom-textfield"
                            />
                            {message ? <p className="_5vzd">{message}</p> : undefined}
                            <div className="stay-signed-in-container">
                                <FormControlLabel
                                    control={
                                        <CheckboxWithBlackCheck
                                            checked={state.checkedA}
                                            onChange={handleChange("checkedA")}
                                            value="checkedA"
                                        />
                                    }
                                    label="Stay signed in"
                                />
                                <span className="forgot-username">
                                    <Button onClick={() => navigate("/forgot-password")}>Forgot password?</Button>
                                </span>
                            </div>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className="submit"
                            >
                                Login
                            </Button>
                        </form>
                        <Typography variant="body2" className='dont-have' color="textSecondary" align="center" gutterBottom>
                            Don't have an account?<span className='registerr'><Button onClick={onRegisterClick}>Register</Button></span>
                        </Typography>
                        <Typography className='continue-with' variant="body2" color="textSecondary" align="center" gutterBottom>
                            Or Continue with
                        </Typography>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            className="google"
                            startIcon={<img src={logo} alt="Google" className="google-icon" />}
                        >
                            Continue with Google
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default LoginPage