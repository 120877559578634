import { Routes, Route, Navigate, Link } from "react-router-dom"
import { useState, useEffect, createContext } from "react"
import { styled } from "@mui/material/styles"
import Drawer from "@mui/material/Drawer"
import SimpleBarReact from "simplebar-react"
import "./styles/main.scss"
import "simplebar-react/dist/simplebar.min.css"
import IconButton from "@mui/material/IconButton"
import Dashboard from "./pages/Dashboard"
import Sidebar from "./layouts/Sidebar"
import { Box, Button, Grid, Modal, TextField, Tooltip, Typography } from "@mui/material"
import PaxAndHallSelect from "./components/PaxAndHallSelect"
import MenuIcon from "@mui/icons-material/Menu"
import UserRegistration from "./pages/admin/UserRegistration"
import AddBanquetHall from "./pages/banquet/AddBanquetHall"
import EditUser from "./pages/admin/EditUser"
import EmployeeRegistration from "./pages/admin/EmployeeRegistration"
import Department from "./pages/admin/Department"
import Designation from "./pages/admin/Designation"
import Booking from "./pages/banquet/Booking"
import BookingSearch from "./pages/banquet/BookingSearch"
import BookingReference from "./pages/reports/BookingReference"
import BookingGuest from "./pages/reports/BookingGuest"
import BookingCompany from "./pages/reports/BookingCompany"
import CancelReference from "./pages/reports/CancelReference"
import CancelGuest from "./pages/reports/CancelGuest"
import CancelStatus from "./pages/reports/CancleStatus"
import CancelCompany from "./pages/reports/CancelCompany"
import HallReport from "./pages/reports/HallReport"
import BookingData from "./pages/reports/BookingData"
import CancelBookingData from "./pages/reports/CancleBookingData"
import AllBookingType from "./pages/reports/AllBookingType"
import BookingSearch1 from "./pages/banquet/BookingSearch1"
import HallCourtReport from "./pages/reports/HallCourtReport"
import decode from "jwt-decode"

//
// import Role from "./pages/Role";
import UserData from "./pages/UserData"
import Halltype from "./pages/Halltype"
import Branch from "./pages/Branch"
import UserVenue from "./pages/UserVenue"
import Package from "./pages/Package"
import SuperAdmin from "./pages/SuperAdmin"
// import Login from './pages/super admin/Login';
import RolePermission from "./pages/RolePermission"
import AuthorPackage from "./pages/AuthorPackage"
import Login from "./authorization/Login"
import SuperAdminLogin from "./authorization/SuperAdminLogin"

import { useNavigate } from "react-router-dom"
import Image from "./pages/Image"
import { UserRoleContextConsumer } from "./services/userAuthorization"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import RolesManage from "./pages/RolesManage"
import axios from "axios"
import api from "./config/backend"
import Pricing from "./components/Pricing"
import VenuePricing from "./components/VenuePricing"
import ImageSlider from "./components/ImageSlider"
import SignUp from "./authorization/Signup"
import Password from "./authorization/Password"
import ChangePassword from "./authorization/ChangePassword"
import EmailVerification from "./authorization/EmailVerification"
import Onboarding from "./pages/Onboarding"
import Verification from "./authorization/Verification"
import MainPage from "./components/MainPage"
import Logo from "./assets/Header Logo.png"
import BanquetBookings from "./pages/BanquetBookings"
import ConfirmPackage from "./authorization/ConfirmPackage"
import PaymentSuccess from "./authorization/PaymentSuccess"
import BookingReport from "./pages/reports/BookingReport"
import Home from "./pages/Home"
import LoginPage from "./authorization/LoginPage"
import OnboardingSteps from "./pages/Onboarding/OnboardingSteps"
import SignUpPage from "./authorization/SignupPage"
import CreateRole from "./pages/Onboarding/CreateRole"
import Roles from "./pages/Roles"
import "./App.css"
import UpgradePopup from "./components/UpgradePopup"
import ConfirmUpgrade from "./components/ConfirmUpgrade"
import UpgradeSuccess from "./components/UpgradeSuccess"
import BanquetInformation from "./pages/banquet/BanquetInformation"
import BookingAvailability from "./pages/banquet/BookingAvailability"
import Privacypolicy from "./pages/Privacypolicy"
import TermsConditions from "./pages/TermsConditions"
import DefaultProfile from "./assets/DefaultProfile.png"
import { MdLogout } from "react-icons/md"

import ContactSupportSharpIcon from '@mui/icons-material/ContactSupportSharp'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LogoutIcon from '@mui/icons-material/Logout'
import Account from "./pages/Account"
import SupportPopup from "./components/SupportPopup"
import SuperAdminDashboard from "./pages/SuperAdminDashboard"
import Version from "./pages/Version"
import Logs from "./pages/Logs"

export const AccessContext = createContext()

//

const drawerWidth = 240
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
	({ theme, open }) => ({
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: `-${drawerWidth}px`,
		...(open && {
			transition: theme.transitions.create("margin", {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginLeft: 0,
		}),
	})
)

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
}

function App() {
	const [open, setOpen] = useState(true)
	const [access, setAccess] = useState(undefined)
	const [authenticated, setAuthenticated] = useState(false)
	const [onboarding, setOnboarding] = useState(true)
	const [showDropdown, setShowDropdown] = useState(false)
	const [refresh, setRefresh] = useState(0)
	const handleDrawerOpen = () => {
		setOpen(true)
	}
	const [upgragePopup, setUpgradePopup] = useState(false)
	const [isAdmin, setIsAdmin] = useState(false)

	const [role, setRole] = useState('')
	const token = localStorage.getItem("token")

	const value = {
		state: { access, authenticated, onboarding },
		actions: { setAccess, setAuthenticated, setOnboarding },
	}

	useEffect(() => {
		const getacess = async () => {
			const { data, status } = await axios.post(`${api}/Role/get-access`, {
				token,
			})

			if (data.success) {
				setAccess(data.access)
			}
		}
		if (token !== null) {
			const decoded = decode(token)
			const verified = decoded.user.email_verification.status
			if (verified & decoded.role !== 'superadmin') {
				getacess()
			}
			setAuthenticated(verified)
			setOnboarding(decoded.user.onboarding)
			setRole(decoded.role)
			setIsAdmin(decoded.admin)
		} else {
			setAccess(undefined)
			setAuthenticated(false)
		}
	}, [token])

	const handleDrawerClose = () => {
		setOpen(false)
	}

	// Get the refresh token from local storage
	const refreshToken = localStorage.getItem("refreshToken")

	const navigate = useNavigate()

	const handleLogout = () => {
		// Clear the token from local storage
		localStorage.removeItem("token")
		localStorage.removeItem("refreshToken")

		// Navigate to the login page
		navigate("/loginPage")
	}

	const [helpModal, setHelpModal] = useState(false)

	const onboardingRoutes = (
		<AccessContext.Provider value={value}>
			<Routes>
				{/* <Route path="/" element={<Onboarding />} /> */}
				<Route path="/" element={<OnboardingSteps />} />
			</Routes>
		</AccessContext.Provider>
	)

	const dashboardRoutes = (
		<div className="_9log app-full-page">
			<Modal
				open={upgragePopup}
				onClose={() => setUpgradePopup(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				onClick={() => setUpgradePopup(false)}
			>
				<Box style={{ all: "unset", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>

					<UpgradePopup setUpgradePopup={setUpgradePopup} setRefresh={setRefresh} />


				</Box>

			</Modal>
			<Modal
				open={helpModal}
				onClose={() => setHelpModal(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				onClick={() => setHelpModal(false)}
			>
				<Box style={{ all: "unset", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }} >
					<SupportPopup setHelpModal={setHelpModal} />
				</Box>

			</Modal>
			<div style={{ display: "flex", alignItems: "center", backgroundColor: "#15101F", height: "70px", justifyContent: "space-between" }} >
				<div style={{ display: "flex", justifyContent: "center", marginLeft: "20px" }} >
					<div style={{ width: "fit-content" }} className="app-component-topheader__action">
						<div className="d-flex justify-content-between align-items-center w-full">
							<IconButton
								style={{ color: "#F9C623" }}
								aria-label="open drawer"
								onClick={handleDrawerOpen}
								sx={{
									color: "#ffffff",
									...(open && {
										display: "none !important",
									}),
								}}
							>
								<MenuIcon />
							</IconButton>
							<IconButton
								style={{ fill: "#A8A196", color: "#A8A196" }}
								onClick={handleDrawerClose}
								sx={{
									color: "#ffffff",
									display: "none !important",
									...(open && { display: "flex" }),
								}}
							>
								<MenuIcon />
							</IconButton>
						</div>
					</div>
					<div style={{ display: "flex", padding: "5px", marginLeft: "12px" }} >
						<Link style={{ textDecoration: "none" }} to={"/"}>
							<div style={{ position: "relative", display: "flex", alignItems: "center" }} onClick={() => navigate("/")}>
								<img style={{ width: "35px", height: "35px", marginRight: "14px" }} src={Logo} alt="Logo" />
								<img style={{ height: "24px" }} src="Images/Logo.svg" alt="Logo" />
								<div style={{ fontFamily: "Roboto", fontSize: "7px", position: "absolute", color: "whitesmoke", top: "2px", right: "-18px" }}>TM</div>
							</div>
						</Link>
					</div>
				</div>
				<div className="logout-login">
					{token && (
						<div onClick={() => setHelpModal(true)} style={{ display: "flex", justifyContent: "center", alignItems: "center", border: "3px solid rgb(249, 198, 35)", borderRadius: "5px", width: "120px", height: "37px", cursor: "pointer" }}>
							<IconButton sx={{ margin: "0px", padding: "0px" }} size='large'>
								<ContactSupportSharpIcon sx={{ color: '#F9C623', fontSize: '21px' }} />
								<Typography color='#F9C623' fontWeight={500} ml='4px'>Support</Typography>
							</IconButton>
						</div>
					)}
					{isAdmin && <Button className="app-btn app-btn-primary"
						variant="contained" onClick={() => setUpgradePopup(true)}>Upgrade</Button>}
					{token ? (
						<div style={{ margin: "0px 10px 0px 20px", position: 'relative' }}>
							<img
								style={{ width: "40px", borderRadius: "50%", cursor: "pointer" }}
								src={DefaultProfile}
								alt="Profile picture placeholder"
								onClick={() => setShowDropdown(!showDropdown)}
							/>
							{showDropdown && (
								<div style={{ position: "absolute", top: '100%', right: 0, background: '#fff', borderRadius: "8px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", width: "200px", padding: "8px", zIndex: 1 }}>
									<div
										style={{ display: "flex", alignItems: "center", gap: "8px", padding: "5px 8px", cursor: 'pointer' }}
										className="logout-style"
										onClick={() => {
											setShowDropdown(false)
											navigate('/account')
										}}
									>
										<AccountCircleIcon sx={{ fontSize: '21px' }} />
										Account

									</div>
									<div
										style={{ display: "flex", alignItems: "center", gap: "8px", padding: "5px 8px", cursor: 'pointer' }}
										className="logout-style"
										onClick={() => {
											handleLogout()
											setShowDropdown(false)
										}}
									>
										{/* <MdLogout /> */}
										<LogoutIcon sx={{ fontSize: '21px' }} />
										Logout
									</div>
								</div>
							)}
						</div>
					) : (
						<Button>
							<img src="/assets/icons/add-user.svg" alt="" />{" "}
							<span>Login</span>
						</Button>
					)}
				</div>
			</div>
			<div className="app-page-wrapper">
				<div className="app-page-sidebar">
					<Drawer
						sx={{
							width: drawerWidth,
							flexShrink: 0,
							"& .MuiDrawer-paper": {
								width: drawerWidth,
							},
						}}
						variant="persistent"
						anchor="left"
						open={open}
					>
						<Sidebar />
					</Drawer>
				</div>
				<Main className="app-main" open={open}>
					<SimpleBarReact className="app-page-scroll">
						<div className="app-page-content">
							<Routes>
								<Route path="/" element={<Dashboard />} />
								<Route path="/logs" element={<Logs />} />
								<Route path="/account" element={<Account />} />
								<Route path="/confirm-package" element={<ConfirmUpgrade />} />
								<Route path="/payment-success" element={<UpgradeSuccess />} />
								<Route path="/dashboard" element={<Dashboard />} />
								<Route path="/booking-availability" element={<BookingAvailability />} />
								<Route path="/banquet-bookings" element={<BanquetBookings />} />
								<Route
									path="/users"
									element={<UserRegistration />}
								/>
								<Route path="/role-management" element={<Roles />} />
								<Route path="/edit-user" element={<EditUser />} />
								<Route
									path="/employee-registration"
									element={<EmployeeRegistration />}
								/>
								<Route path="/add-banquet-hall" element={<AddBanquetHall />} />
								<Route path="/department" element={<Department />} />
								<Route path="/designation" element={<Designation />} />

								{/* there routes and pages are created during api integration */}
								{/* <Route path="/role" element={<Role />} /> */}
								<Route path="/halltype" element={<Halltype />} />
								<Route path="/userdata" element={<UserData />} />
								<Route path="/branch" element={<Branch />} />
								<Route path="/uservenue" element={<UserVenue />} />
								<Route path="/package" element={<Package />} />
								<Route path="/superadmin" element={<SuperAdmin />} />
								<Route path="/image" element={<Image />} />
								<Route path="/role-permission" element={<RolePermission />} />
								<Route path="/author-package" element={<AuthorPackage />} />

								{/*  */}

								<Route path="/booking" element={<Booking />} />
								<Route path="/booking-search" element={<BookingSearch />} />
								<Route
									path="/booking-reference"
									element={<BookingReference />}
								/>
								<Route path="/booking-report" element={<BookingReport />} />
								<Route path="/booking-guest" element={<BookingGuest />} />
								<Route path="/booking-company" element={<BookingCompany />} />
								<Route path="/cancel-reference" element={<CancelReference />} />
								<Route path="/cancel-guest" element={<CancelGuest />} />
								<Route path="/cancel-status" element={<CancelStatus />} />
								<Route path="/cancel-company" element={<CancelCompany />} />
								<Route path="/hall-report" element={<HallReport />} />
								<Route path="/booking-data" element={<BookingData />} />
								<Route
									path="/cancel-booking-data"
									element={<CancelBookingData />}
								/>
								<Route path="/all-booking-type" element={<AllBookingType />} />
								<Route path="/banquet-details" element={<BanquetInformation />} />
								<Route
									path="/hall-court-report"
									element={<HallCourtReport />}
								/>
								<Route path="*" element={<Navigate to="/" />} />
							</Routes>
						</div>
					</SimpleBarReact>
				</Main>
			</div>
		</div>

	)

	const notLoggedIn = (
		<>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/login" element={<Login />} />
				<Route path="/loginPage" element={<LoginPage />} />
				<Route path="/signup" element={<SignUp />} />
				<Route path="/signupPage" element={<SignUpPage />} />
				<Route path="/forgot-password" element={<Password />} />
				<Route path="/main_page" element={<MainPage />} />
				<Route path="/on_page" element={<OnboardingSteps />} />
				<Route
					path="/account/reset-password/:hash"
					element={<ChangePassword />}
				/>

				<Route path="/email-verification" element={<EmailVerification />} />
				<Route path="/payment-success" element={<PaymentSuccess />} />
				<Route path="/confirm-package" element={<ConfirmPackage />} />
				<Route path="/account/verify-email/:token" element={<Verification />} />
				<Route path="/slider" element={<ImageSlider />} />
				<Route path="/venue-pricing" element={<VenuePricing />} />
				<Route path="/adminlogin" element={<SuperAdminLogin />} />
				<Route path="/privacy_policy" element={<Privacypolicy />} />
				<Route path="/terms" element={<TermsConditions />} />
				<Route path="/version" element={<Version />} />
				<Route path="*" element={<Navigate to="/" />} />
			</Routes>
		</>
	)

	if (authenticated && onboarding) {
		return onboardingRoutes
	}

	const superAdmin = (
		<>
			<div style={{ display: "flex", alignItems: "center", backgroundColor: "#15101F", height: "70px", justifyContent: "space-between" }} >
				<div style={{ display: "flex", justifyContent: "center", marginLeft: "20px" }} >
					<div style={{ display: "flex", padding: "5px", marginLeft: "12px" }} >
						<Link style={{ textDecoration: "none" }} to={"/"}>
							<div style={{ position: "relative", display: "flex", alignItems: "center" }} onClick={() => navigate("/")}>
								<img style={{ width: "35px", height: "35px", marginRight: "14px" }} src={Logo} alt="Logo" />
								<img style={{ height: "24px" }} src="Images/Logo.svg" alt="Logo" />
								<div style={{ fontFamily: "Roboto", fontSize: "7px", position: "absolute", color: "whitesmoke", top: "2px", right: "-18px" }}>TM</div>
							</div>
						</Link>
					</div>
				</div>
				<div className="logout-login">
					{token ? (
						<div style={{ margin: "0px 10px 0px 20px", position: 'relative' }}>
							<img
								style={{ width: "40px", borderRadius: "50%", cursor: "pointer" }}
								src={DefaultProfile}
								alt="Profile picture placeholder"
								onClick={() => setShowDropdown(!showDropdown)}
							/>
							{showDropdown && (
								<div style={{ position: "absolute", top: '100%', right: 0, background: '#fff', borderRadius: "8px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", width: "200px", padding: "8px", zIndex: 1 }}>
									<div
										style={{ display: "flex", alignItems: "center", gap: "8px", padding: "5px 8px", cursor: 'pointer' }}
										className="logout-style"
										onClick={() => {
											handleLogout()
											setShowDropdown(false)
										}}
									>
										{/* <MdLogout /> */}
										<LogoutIcon sx={{ fontSize: '21px' }} />
										Logout
									</div>
								</div>
							)}
						</div>
					) : (
						<Button>
							<img src="/assets/icons/add-user.svg" alt="" />{" "}
							<span>Login</span>
						</Button>
					)}
				</div>
			</div>
			<Routes>
				<Route path="/" element={<SuperAdminDashboard />} />
				<Route path="*" element={<Navigate to="/" />} />
			</Routes>
		</>
	)

	return (
		<>
			<AccessContext.Provider value={value}>
				<UserRoleContextConsumer>
					{(value) => {
						return (
							<>
								{authenticated ? (role === 'superadmin' ? superAdmin : dashboardRoutes) : notLoggedIn}

								<ToastContainer />
							</>
						)
					}}
				</UserRoleContextConsumer>
			</AccessContext.Provider>
		</>
	)
}

export default App
