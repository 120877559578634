import React from 'react'
import Box from '@mui/material/Box'
import { useNavigate } from 'react-router-dom'
import Logo from "../assets/Header Logo.png"
import { Button } from "@mui/material"
import { MdLogout } from "react-icons/md"

const Headstyle = {
    backgroundColor: "#15101F",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "80px",
    paddingLeft: "20px"
}
// const navigate = useNavigate()

const StepperHeader = ({ forcehideLogout }) => {
    const navigate = useNavigate()

    const token = localStorage.getItem("token")

    const handleLogout = () => {
        // Clear the token from local storage
        localStorage.removeItem('token')
        localStorage.removeItem('refreshToken')

        // Navigate to the login page
        navigate('/loginPage')
    }

    return (
        <Box style={Headstyle}>
            <div style={{ position: "relative", display: "flex", alignItems: "center" }} onClick={() => navigate("/")}>
                                <img style={{ width: "50px", height: "50px", marginRight: "20px" }} src={Logo} alt="Logo" />
                                <img style={{ height: "35px" }} src="Images/Logo.svg" alt="Logo" />
                                <div style={{ fontFamily: "Roboto", fontSize: "8px", position: "absolute", color: "whitesmoke", top: "0px", right: "-19px" }}>TM</div>
                            </div>
            {forcehideLogout ? undefined : token && (
                <div style={{ marginRight: "30px" }}>
                    <Button onClick={handleLogout}>
                        <MdLogout /> <span style={{ marginLeft: "4px" }}>Logout</span>
                    </Button>
                </div>
            )}
        </Box>
    )
}


export default StepperHeader