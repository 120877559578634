import * as React from "react"
import { useState, useEffect } from "react"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { Button, FormControl, InputLabel, Tooltip } from "@mui/material"
import OutlinedInput from "@mui/material/OutlinedInput"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import Checkbox from "@mui/material/Checkbox"
import IconButton from "@mui/material/IconButton"
import Stack from "@mui/material/Stack"
import dayjs from "dayjs"
import axios from "axios"
import { format } from "date-fns"
import api from "../config/backend"
import "./css/Booking.css"
import { ToastContainer, toast } from "react-toastify"
import { IoIosInformationCircle } from "react-icons/io"

const BookingEdit = ({ bookingdetails, setPopup, editpermission }) => {
	const token = localStorage.getItem("token")
	const currentDate = new Date()
	const formattedDate = format(currentDate, "yyyy-MM-dd") // Adjust the formatting as needed
	const [halltype, setHalltype] = useState(["Indoor", "Outdoor"])
	const [banquets, setBanquets] = useState([])
	const [search, setSearch] = useState("")
	const [bookings, setBookings] = useState([])
	const [filteredbookings, setFilteredbookings] = useState([])
	const [filteredbanquets, setFilteredbanquets] = useState([])
	const [phoneNumbers, setPhoneNumbers] = useState("")
	const [alternativePhoneNumbers, setAlternativePhoneNumbers] = useState("")
	const [contactPersonNames, setContactPersonNames] = useState([])
	const [eventFromDates, setEventFromDates] = useState(null)
	const [eventToDates, setEventToDates] = useState(null)
	const [companyName, setCompanyName] = useState("")
	const [bookingType, setBookingType] = useState("")
	const [referredBy, setReferredBy] = useState("")
	const [advancePaid, setAdvancePaid] = useState(0)
	const [givenRate, setGivenRate] = useState(0)
	const [bookingGivenBy, setBookingGivenBy] = useState("")
	const [isCocktailsAvailable, setIsCocktailsAvailable] = useState("")
	const [isAdvancedPaid, setIsAdvancedPaid] = useState("")
	const [totalAdvanceAmount, setTotalAdvanceAmount] = useState(0)
	const [remarks, setRemarks] = useState("")
	const [email, setEmail] = useState("")
	const [bookingHalls, setBookingHalls] = useState([])
	const [events, setEvents] = useState([])
	const [editmode, setEditmode] = useState(false)
	const [dateSelected, setDateSelected] = useState(false)
	const [bookedSlots, setBookedSlots] = useState([])
	const [prebooked, setPrebooked] = useState([])
	const [slotfreedom, setSlotFreedom] = useState({})
	const [backupEvents, setBackupEvents] = useState([])

	const fetchPrebooked = async () => {
		const { data, status } = await axios.post(`${api}/Booking/fetch-prebooked`, {
			token,
			eventfrom: eventFromDates,
			eventend: eventToDates,
			ignore: bookingdetails.hash
		})

		if (data.success) {
			setPrebooked(data.booked)
		}
	}

	// Filter banquets
	// Remove inactive banquets, show banquets with matching halltype
	const filterBanquets = (date, halltype, index) => {
		const banquetcopy = JSON.parse(JSON.stringify(filteredbanquets))

		banquetcopy[index] = banquets.filter((item) => {
			if (slotfreedom[date] && slotfreedom[date][item.hash]) {
				const notempty = slotfreedom[date][item.hash].includes("B") && slotfreedom[date][item.hash].includes("L") && slotfreedom[date][item.hash].includes("D")

				console.log(notempty)

				console.log(slotfreedom[date][item.hash])

				return item.is_active && item.hall_type === halltype && notempty === false
			}
			return item.is_active && item.hall_type === halltype
		})

		setFilteredbanquets(banquetcopy)
	}

	const fetchSlotDetails = async () => {
		const { data, status } = await axios.post(`${api}/Booking/get-slots`, {
			token,
			bookinghash: bookingdetails.hash
		})

		if (data.success) {
			setBookedSlots(data.bookedslots)
		}
		else {
			toast.error(data.message)
		}
	}

	const handleEditBooking = async () => {
		const { data, status } = await axios.post(`${api}/Booking/update-booking`, {
			token,
			bookinghash: bookingdetails.hash,
			phone: phoneNumbers,
			alternatephone: alternativePhoneNumbers,
			contactname: contactPersonNames,
			contactemail: email,
			eventfrom: eventFromDates,
			eventend: eventToDates,
			contactcompany: companyName,
			advance: isAdvancedPaid === "true" ? advancePaid : [],
			rate: givenRate,
			bookingby: bookingGivenBy,
			referredby: referredBy,
			booking_given_by: bookingGivenBy,
			is_cocktails_available: isCocktailsAvailable,
			remarks: remarks,
			bookingstatus: bookingType,
			bookings: events
		})

		if (data.success) {
			toast.success(data.message)
			setPhoneNumbers("")
			setAlternativePhoneNumbers("")
			setContactPersonNames("")
			setEmail("")
			setEventFromDates(undefined)
			setEventToDates(undefined)
			setCompanyName("")
			setAdvancePaid(0)
			setGivenRate(0)
			setBookingGivenBy("")
			setReferredBy("")
			setIsCocktailsAvailable("")
			setBookingType("")
			setRemarks("")
			setEvents([])
			setPopup(false)
		} else {
			toast.error(data.message)
		}
	}

	const handleSelectChange = (event) => {
		setIsAdvancedPaid(event.target.value)

		if (event.target.value === "true") {
			setAdvancePaid([{
				date: "",
				amount: "",
				remark: ""
			}])
		}
	}

	function handleAddRow(index, row) {
		const selecteddate = row.date

		setEvents(prev => {
			const copy = JSON.parse(JSON.stringify(prev))

			const firstpart = copy.slice(0, index + 1)
			let secondpart

			if (copy.length > 1) {
				secondpart = copy.slice(index + 1, copy.length)
			}

			const insert = {
				date: selecteddate,
				breakfast: false,
				lunch: false,
				dinner: false,
				halltype: '',
				pax: 1,
				expectedpax: 1,
				banquethallhash: "",
				eventtitle: ''
			}

			return secondpart ? [...firstpart, insert, ...secondpart] : [...firstpart, insert]
		})
	}

	function handleRemove(index) {
		setEvents((prevBookingHalls) => {
			const updatedBookingHalls = [...prevBookingHalls]
			updatedBookingHalls.splice(index, 1)
			return updatedBookingHalls
		})
	}

	const fetchBanquets = async () => {
		try {
			const response = await axios.post(`${api}/BanquetHall/get-banquets`, {
				token,
			})
			setBanquets(response.data.banquets)
		} catch (error) {
			console.error(error)
		}
	}

	useEffect(() => {
		let total = 0
		if (advancePaid.length) {
			advancePaid.map(item => {
				if (item.amount) {
					total += Number(item.amount)
				}
			})
		}
		setTotalAdvanceAmount(total)
	}, [advancePaid])

	useEffect(() => {
		let timedifference = undefined
		let daysinbetween = undefined

		if (eventFromDates && eventToDates) {
			// Prevent default date setting from being reset
			if (dateSelected === false) {
				return
			}

			timedifference =
				new Date(eventToDates).getTime() - new Date(eventFromDates).getTime()
			daysinbetween = timedifference / (1000 * 3600 * 24) + 1

			// Preserve filtered banquets for existing bookings
			setFilteredbanquets(Array(daysinbetween).fill([]).map((item, index) => {
				const d = new Date(eventFromDates)
				d.setDate(d.getDate() + index)

				const existingevent = events.find(ev => String(new Date(ev.date)) === String(new Date(d)))

				if (existingevent) {
					const applicablebanquet = banquets.filter(ba => ba.hall_type === existingevent.halltype)
					return applicablebanquet
				}

				return []
			}))

			// Add new event
			// Preserve existing bookings events
			setEvents(
				Array(daysinbetween)
					.fill({
						date: eventFromDates,
						breakfast: false,
						lunch: false,
						dinner: false,
						halltype: "",
						pax: 1,
						expectedpax: 1,
						banquethallhash: "",
						eventtitle: ""
					})
					.map((item, index) => {
						const copy = JSON.parse(JSON.stringify(item))
						const d = new Date(eventFromDates)
						copy.date = d.setDate(d.getDate() + index)

						// Add details for existing events ignoring cancelled bookings
						const existingevent = events.find(ev => {
							if ((String(new Date(ev.date)) === String(new Date(d)) && ev.iscancelled === false)) {
								return true
							}
							return false
						})

						if (existingevent) {
							copy.iscancelled = existingevent.iscancelled
							copy.identifier = existingevent.identifier
							copy.breakfast = existingevent.breakfast
							copy.lunch = existingevent.lunch
							copy.dinner = existingevent.dinner
							copy.halltype = existingevent.halltype
							copy.pax = existingevent.pax
							copy.expectedpax = existingevent.expectedpax
							copy.banquethallhash = existingevent.banquethallhash
							copy.eventtitle = existingevent.eventtitle
						}

						return copy
					})
			)
		}
	}, [eventFromDates, eventToDates])

	useEffect(() => {
		if (search) {
			setFilteredbookings(
				bookings.filter((item) => {
					return (
						String(item.booking.phonenumber).includes(search) ||
						String(item.booking.alternative_phonenumber).includes(search) ||
						String(item.booking.contact_person_email).includes(search)
					)
				})
			)
		} else {
			setFilteredbookings(bookings)
		}
	}, [search, bookings])

	useEffect(() => {
		if (bookingdetails) {
			setPhoneNumbers(bookingdetails.phonenumber)
			setAlternativePhoneNumbers(bookingdetails.alternative_phonenumber)
			setContactPersonNames(bookingdetails.contact_person_name)
			setEmail(bookingdetails.contact_person_email)
			setCompanyName(bookingdetails.company_name)
			setIsAdvancedPaid(bookingdetails.advance_paid && bookingdetails.advance_paid.length > 0 ? "true" : "false")
			setAdvancePaid(bookingdetails.advance_paid)
			setBookingGivenBy(bookingdetails.booking_given_by)
			setReferredBy(bookingdetails.reffered_by)
			setBookingType(bookingdetails.booking_type)
			setRemarks(bookingdetails.remarks)
			setEventFromDates(bookingdetails.event_from_date)
			setEventToDates(bookingdetails.event_to_date)
		}
	}, [bookingdetails])

	useEffect(() => {
		fetchBanquets()
		fetchSlotDetails()
	}, [])

	useEffect(() => {
		if (bookedSlots.length && banquets.length) {
			setEvents(bookedSlots.map((item, index) => {
				return {
					iscancelled: item.cancelled.status,
					identifier: item._id,
					date: item.date,
					breakfast: item.is_breakfast_available,
					lunch: item.is_lunch_available,
					dinner: item.is_dinner_available,
					halltype: item.hall_type,
					pax: item.pax,
					expectedpax: item.exp_pax,
					banquethallhash: item.banquet_hall,
					eventtitle: item.event_title
				}
			}))

			bookedSlots.map((item, index) => {
				setFilteredbanquets(prev => {
					const banquetcopy = JSON.parse(JSON.stringify(prev))

					banquetcopy[index] = banquets.filter((banquet) => banquet.is_active && banquet.hall_type === item.hall_type)

					return banquetcopy
				})
			})
		}
	}, [bookedSlots, banquets])

	useEffect(() => {
		if (eventFromDates && eventToDates) {
			fetchPrebooked()
		}
	}, [eventFromDates, eventToDates])

	// Manage filter banquet hall if booked on same date
	// freedom contains "{date}": { "{hash}": "BL" } i.e. two slots are booked on date, rest one (dinner) is free
	useEffect(() => {
		// fallback will be true in case of collapsing events
		// e.g. booking on same date same hall
		let fallback = false

		const freedom = {}

		events.forEach(item => {
			const hallhash = item.banquethallhash

			if (item.date && hallhash) {
				// Check if freedom already has that date
				if (freedom[item.date]) {
					if (freedom[item.date][hallhash]) {
						const slotsofrow = []
						if (item.breakfast) {
							slotsofrow.push("B")
						}
						if (item.lunch) {
							slotsofrow.push("L")
						}
						if (item.dinner) {
							slotsofrow.push("D")
						}
						// Check if collapse happened, i.e. same slot booking in same hall same date
						const collided = slotsofrow.some(slot => {
							if (freedom[item.date][hallhash].includes(slot)) {
								return true
							}
						})

						if (collided) {
							fallback = true
							toast.error("Slot already booked for this date")
						} else {
							freedom[item.date][hallhash] = [...freedom[item.date][hallhash], ...slotsofrow]
							console.log(freedom[item.date][hallhash])
						}
					} else {
						const contains = []
						if (item.breakfast) {
							contains.push("B")
						}
						if (item.lunch) {
							contains.push("L")
						}
						if (item.dinner) {
							contains.push("D")
						}
						freedom[item.date] = {}
						freedom[item.date][hallhash] = contains
					}
				} else {
					const contains = []
					if (item.breakfast) {
						contains.push("B")
					}
					if (item.lunch) {
						contains.push("L")
					}
					if (item.dinner) {
						contains.push("D")
					}
					freedom[item.date] = {}
					freedom[item.date][hallhash] = contains
				}
			}
		})

		setSlotFreedom(freedom)

		if (fallback) {
			setEvents(backupEvents)
		} else {
			setBackupEvents(events)
		}
	}, [events])

	// Disable event from and to dates
	// Booked event dates can be edited but not removed
	const minEventToDate = new Date(bookingdetails.event_to_date)

	const maxEventFromDate = new Date(bookingdetails.event_from_date)

	return (
		<>
			<ToastContainer></ToastContainer>
			<div className="_1ywv app-page-banquets-management">
				<div className="cardDiv">
					<div className="app-card">
						<h1 className="app-card__title">Banquet User</h1>
						<div className="app-card__content">
							<Grid container rowSpacing={2} columnSpacing={5}>
								<Grid item xs={12} md={4}>
									<div className="app-form-field">
										<TextField
											disabled
											type="number"
											label="Phone"
											variant="outlined"
											placeholder="Enter Phone"
											value={phoneNumbers}
											onChange={(e) => setPhoneNumbers(e.target.value)}
										/>
									</div>
								</Grid>
								<Grid item xs={12} md={4}>
									<div className="app-form-field">
										<TextField
											disabled
											type="number"
											label="Alternate No"
											variant="outlined"
											placeholder="Enter Alternate No"
											value={alternativePhoneNumbers}
											onChange={(e) =>
												setAlternativePhoneNumbers(e.target.value)
											}
										/>
									</div>
								</Grid>
								<Grid item xs={12} md={4}>
									<div className="app-form-field">
										<TextField
											disabled
											label="Contact Person Name"
											variant="outlined"
											placeholder="Enter Contact Person Name"
											value={contactPersonNames}
											onChange={(e) => setContactPersonNames(e.target.value)}
										/>
									</div>
								</Grid>
							</Grid>
						</div>
						<div className="app-card__content">
							<Grid container rowSpacing={2} columnSpacing={5}>
								<Grid item xs={12} md={4}>
									<div className="app-form-field">
										<TextField
											disabled
											label="company"
											variant="outlined"
											placeholder="Enter company"
											value={companyName}
											onChange={(event) => setCompanyName(event.target.value)}
										/>
									</div>
								</Grid>

								<Grid item xs={12} md={4}>
									<div className="app-form-field">
										<TextField
											disabled
											label="Email"
											type="email"
											variant="outlined"
											placeholder="Enter Email"
											value={email}
											onChange={(event) => setEmail(event.target.value)}
										/>
									</div>
								</Grid>
								<Grid item xs={12} md={4}>
									<div className="app-form-field">
										<TextField
											disabled
											label="Booking Given By"
											variant="outlined"
											placeholder="Enter Booking Given By"
											value={bookingGivenBy}
											onChange={(event) =>
												setBookingGivenBy(event.target.value)
											}
										/>
									</div>
								</Grid>
							</Grid>
						</div>
						<div className="app-card__content">
							<Grid container rowSpacing={2} columnSpacing={5}>
								<Grid item xs={12} md={4}>
									<div className="app-form-field">
										<TextField
											disabled
											type="text"
											label="Reffered By"
											variant="outlined"
											placeholder="Reffered by"
											value={referredBy}
											onChange={(e) => setReferredBy(e.target.value)}
										/>
									</div>
								</Grid>
								<Grid item xs={12} md={4}>
									<div className="app-form-field">
										<FormControl>
											<InputLabel id="demo-simple-select-label">
												Select status
											</InputLabel>
											<Select
												disabled={editpermission ? false : (bookingType === "Confirmed" ? true : false)}
												label="Select status"
												labelId="booking-type-label"
												id="booking-type-select"
												value={bookingType}
												onChange={(e) => setBookingType(e.target.value)}
											>
												<MenuItem value="Confirmed">Confirmed</MenuItem>
												<MenuItem value="Tentative">Tentative</MenuItem>
												<MenuItem value="Waitlist">Waitlist</MenuItem>
											</Select>
										</FormControl>
									</div>
								</Grid>
								<Grid item xs={12} md={4}>
									<div className="app-form-field">
										<FormControl>
											<InputLabel id="demo-simple-select-label">
												Advance paid
											</InputLabel>
											<Select
												disabled={editpermission ? false : true}
												label="select Cocktails"
												value={isAdvancedPaid}
												onChange={handleSelectChange}
											>
												<MenuItem value="true">Yes</MenuItem>
												<MenuItem value="false">No</MenuItem>
											</Select>
										</FormControl>
									</div>
								</Grid>
							</Grid>
						</div>
						<div className="app-card__content">
							<Grid container rowSpacing={2} columnSpacing={5}>
								<Grid item xs={12} md={12}>
									<div className="app-form-field" style={{ padding: 0 }}>
										<TextField
											disabled
											id="outlined-multiline-static"
											label="Remarks"
											multiline
											variant="outlined"
											placeholder="Enter remarks"
											value={remarks}
											onChange={(event) => setRemarks(event.target.value)}
										/>
									</div>
								</Grid>
							</Grid>
						</div>
						<div className="app-component-table app-new-component-table">
							<h1 className="app-card__title">Banquet Booking </h1>
							<div className="app-new-main-form-field">
								<div className="app-form-field">
									<Grid container>
										<Grid item xs={12} md={6}>
											<div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
												<Grid container rowSpacing={2} columnSpacing={5}>
													<Grid item xs={12} md={6}>
														<LocalizationProvider dateAdapter={AdapterDayjs}>
															<DatePicker
																disablePast
																label="Event From Date"
																value={dayjs(eventFromDates)}
																onChange={(newValue) => {
																	setDateSelected(true)
																	setEventFromDates(newValue.$d)
																}}
																format="DD-MM-YYYY"
															/>
														</LocalizationProvider>
													</Grid>
													<Grid item xs={12} md={6}>
														<div className="app-form-field">
															<LocalizationProvider dateAdapter={AdapterDayjs}>
																<DatePicker
																	disablePast
																	minDate={dayjs(eventFromDates)}
																	label="to date"
																	value={dayjs(eventToDates)}
																	onChange={(newValue) => {
																		setDateSelected(true)
																		setEventToDates(newValue.$d)
																	}}
																	format="DD-MM-YYYY"
																/>
															</LocalizationProvider>
														</div>
													</Grid>

												</Grid>
												{/* <div>
													<Tooltip title={(
														<div style={{ display: "flex", flexDirection: "column" }}>
															<div>Edit mode can't be used to remove an existing booking</div>
															<div>✅ Please use option "cancel" to discard a booking</div>
														</div>
													)}>
														<div>
															<IoIosInformationCircle
																size="20"
																color="grey"
																cursor="pointer"
															/>
														</div>
													</Tooltip>
												</div> */}
											</div>
										</Grid>
									</Grid>
								</div>
							</div>
							<TableContainer>
								<Table stickyHeader>
									<TableHead>
										<TableRow>
											<TableCell>Date</TableCell>
											<TableCell>B</TableCell>
											<TableCell>L</TableCell>
											<TableCell>D</TableCell>
											<TableCell>Hall Type</TableCell>
											<TableCell>Pax</TableCell>
											<TableCell>Exp.Pax</TableCell>
											<TableCell>BanquetHall</TableCell>
											<TableCell>Events</TableCell>
											{/* <TableCell>Add</TableCell> */}
										</TableRow>
									</TableHead>
									<TableBody className="seamers-arms">
										{events.length
											? events.map((booking, index) => {
												const bookedslots = {
													breakfast: false,
													lunch: false,
													dinner: false
												}

												prebooked.forEach(item => {
													// Booking for same date and selected banquet
													if ((new Date(item.date).toDateString() === new Date(booking.date).toDateString()) && (item.banquet_hall === booking.banquethallhash)) {
														if (item.is_breakfast_available) {
															bookedslots.breakfast = true
														}
														if (item.is_lunch_available) {
															bookedslots.lunch = true
														}
														if (item.is_dinner_available) {
															bookedslots.dinner = true
														}
													}
												})

												return (
													<Tooltip disableFocusListener key={index}>
														<TableRow className={booking.iscancelled ? "disabled" : undefined}>
															<TableCell>
																{format(new Date(booking.date), "dd-MM-yyyy")}
															</TableCell>
															<TableCell className="table-check-box">
																<Tooltip disableHoverListener={bookedslots.breakfast === false} title="⚠️ Slot already booked for selected banquet hall">
																	<Checkbox
																		sx={{
																			color: bookedslots.breakfast ? "rgb(255, 157, 157)" : undefined,
																			"&.Mui-checked": {
																				color: bookedslots.breakfast ? "rgb(255, 157, 157) !important" : "rgb(249, 198, 35) !important"
																			}
																		}}
																		name="is_breakfast_available"
																		checked={booking.breakfast}
																		onChange={(e) =>
																			setEvents((prev) => {
																				const copy = JSON.parse(
																					JSON.stringify(prev)
																				)

																				copy[index].breakfast =
																					!copy[index].breakfast
																				return copy
																			})
																		}
																	/>
																</Tooltip>
															</TableCell>
															<TableCell className="table-check-box">
																<Tooltip disableHoverListener={bookedslots.lunch === false} title="⚠️ Slot already booked for selected banquet hall">
																	<Checkbox
																		sx={{
																			color: bookedslots.lunch ? "rgb(255, 157, 157)" : undefined,
																			"&.Mui-checked": {
																				color: bookedslots.lunch ? "rgb(255, 157, 157) !important" : "rgb(249, 198, 35) !important"
																			}
																		}}
														
																		name="is_lunch_available"
																		checked={booking.lunch}
																		onChange={(e) =>
																			setEvents((prev) => {
																				const copy = JSON.parse(
																					JSON.stringify(prev)
																				)

																				copy[index].lunch = !copy[index].lunch
																				return copy
																			})
																		}
																	/>
																</Tooltip>
															</TableCell>
															<TableCell className="table-check-box">
																<Tooltip disableHoverListener={bookedslots.dinner === false} title="⚠️ Slot already booked for selected banquet hall">
																	<Checkbox
																		sx={{
																			color: bookedslots.dinner ? "rgb(255, 157, 157)" : undefined,
																			"&.Mui-checked": {
																				color: bookedslots.dinner ? "rgb(255, 157, 157) !important" : "rgb(249, 198, 35) !important"
																			}
																		}}
																		
																		name="is_dinner_available"
																		checked={booking.dinner}
																		onChange={(e) =>
																			setEvents((prev) => {
																				const copy = JSON.parse(
																					JSON.stringify(prev)
																				)

																				copy[index].dinner = !copy[index].dinner
																				return copy
																			})
																		}
																	/>
																</Tooltip>
															</TableCell>
															<TableCell className="hall-cell">
																<div className="app-form-field">
																	<Select
																		
																		displayEmpty
																		value={booking.halltype}
																		onChange={(e) => {
																			setEvents((prev) => {
																				const copy = JSON.parse(
																					JSON.stringify(prev)
																				)
																				copy[index].halltype = e.target.value

																				// Filter list of banquet halls according to hall type
																				filterBanquets(booking.date, e.target.value, index)

																				return copy
																			})
																		}}
																		input={<OutlinedInput />}
																		MenuProps={""}
																		inputProps={{ "aria-label": "Without label" }}
																	>
																		<MenuItem value="" disabled>
																			Select Halltype
																		</MenuItem>
																		{halltype.map((item, index) => (
																			<MenuItem key={index} value={item}>
																				{item}
																			</MenuItem>
																		))}
																	</Select>
																</div>
															</TableCell>
															<TableCell className="hall-cell2">
																<div className="app-form-field">
																	<TextField
																		
																		label="Pax"
																		variant="outlined"
																		placeholder=""
																		name="pax"
																		value={booking.pax} // Assuming you have an index for the current row
																		onChange={(e) =>
																			setEvents((prev) => {
																				const copy = JSON.parse(
																					JSON.stringify(prev)
																				)
																				copy[index].pax = e.target.value
																				return copy
																			})
																		}
																	/>
																</div>
															</TableCell>
															<TableCell className="hall-cell2">
																<div className="app-form-field">
																	<TextField
																		
																		label="Exp Pax"
																		variant="outlined"
																		placeholder=""
																		name="exp_pax"
																		value={booking.expectedpax} // Assuming you have an index for the current row
																		onChange={(e) =>
																			setEvents((prev) => {
																				const copy = JSON.parse(
																					JSON.stringify(prev)
																				)
																				copy[index].expectedpax = e.target.value
																				return copy
																			})
																		}
																	/>
																</div>
															</TableCell>
															<TableCell className="hall-cell">
																<div className="app-form-field">
																	<Select
																		
																		displayEmpty
																		value={booking.banquethallhash}
																		onChange={(e) =>
																			setEvents((prev) => {
																				const copy = JSON.parse(
																					JSON.stringify(prev)
																				)
																				copy[index].banquethallhash =
																					e.target.value

																				return copy
																			})
																		}
																	>
																		{filteredbanquets[index] && filteredbanquets[index].map((item) => {
																			return (
																				<MenuItem
																					key={item._id}
																					value={item.hash}
																				>
																					{item.hall_title}
																				</MenuItem>
																			)
																		})}
																	</Select>
																</div>
															</TableCell>

															<TableCell className="hall-cell2">
																<div className="app-form-field">
																	<TextField
																		
																		label="Event"
																		variant="outlined"
																		placeholder=""
																		name="event_title"
																		value={booking.eventtitle}
																		onChange={(e) =>
																			setEvents((prev) => {
																				const copy = JSON.parse(
																					JSON.stringify(prev)
																				)
																				copy[index].eventtitle = e.target.value
																				return copy
																			})
																		}
																	/>
																</div>
															</TableCell>
															<TableCell>
															<div
																style={{
																	display: "flex",
																	alignItems: "center",
																}}
															>
																<Stack direction="row" spacing={1}>
																	<IconButton
																		aria-label="add"
																	>
																		<img
																			style={{ width: "20px" }}
																			src="/assets/icons/add.png"
																			onClick={() => handleAddRow(index, booking)}
																		/>
																	</IconButton>
																</Stack>
																<Stack direction="row" spacing={1}>
																	<IconButton aria-label="delete">
																		<img
																			style={{ width: "20px" }}
																			src="/assets/icons/remove.svg"
																			onClick={() => handleRemove(index)}
																		/>
																	</IconButton>
																</Stack>
															</div>
														</TableCell>
														</TableRow>
													</Tooltip>
												)
											})
											: undefined}
									</TableBody>
								</Table>
							</TableContainer>
							<div style={{ marginTop: "18px", display: "flex", alignItems: "center", gap: "10px" }}>
								<Button style={{ color: "white", backgroundColor: "#F9C623", width: "100px" }} onClick={handleEditBooking}>Update</Button>
								<Button
									style={{ color: "white", backgroundColor: "rgb(178, 178, 178)", width: "100px" }}
									onClick={() => {
										setPhoneNumbers("")
										setAlternativePhoneNumbers("")
										setContactPersonNames("")
										setEmail("")
										setEventFromDates(undefined)
										setEventToDates(undefined)
										setCompanyName("")
										setAdvancePaid([])
										setGivenRate(0)
										setBookingGivenBy("")
										setReferredBy("")
										setIsCocktailsAvailable("")
										setBookingType("")
										setRemarks("")
										setEvents([])
										setPopup(false)
									}}>
									Go back
								</Button>
							</div>
						</div>
						{isAdvancedPaid === "true" ? <div style={{ pointerEvents: editpermission ? undefined : "none" }} className="_3qtm">
							<div className="container">
								<div className="table-header">
									<span>Date</span>
									<span>Amount</span>
									<span>Remarks</span>
									<span style={{ width: "100px" }}>Actions</span>
								</div>
								<div className="body">
									{advancePaid.map((item, index) => {
										return (
											<div key={index}>
												<span className="payment_no_border_bottom">
													<LocalizationProvider dateAdapter={AdapterDayjs}>
														<DatePicker
															label=""
															disableFuture
															slotProps={{ textField: { variant: 'standard', } }}
															value={item.date ? dayjs(item.date) : null}
															onChange={newValue => setAdvancePaid(prev => {
																const copy = JSON.parse(JSON.stringify(prev))
																copy[index].date = newValue.$d
																return copy
															})}
															format="DD-MM-YYYY"
														/>
													</LocalizationProvider>
												</span>
												<span>
													<input type="number" value={item.amount} onChange={e => setAdvancePaid(prev => {
														const copy = JSON.parse(JSON.stringify(prev))
														copy[index].amount = e.target.value
														return copy
													})} />
												</span>
												<span>
													<input value={item.remark} onChange={e => setAdvancePaid(prev => {
														const copy = JSON.parse(JSON.stringify(prev))
														copy[index].remark = e.target.value
														return copy
													})} />
												</span>
												<span style={{ width: "100px", cursor: "pointer", color: "orange", fontSize: "14px" }} onClick={() => setAdvancePaid(prev => {
													const copy = JSON.parse(JSON.stringify(prev))
													copy.splice(index, 1)
													return copy
												})}>Remove</span>
											</div>
										)
									})}
								</div>
							</div>
							<button style={{ marginTop: "15px" }} onClick={() => setAdvancePaid(prev => [...prev, {
								date: "",
								amount: "",
								remark: ""
							}])}>Add row</button>
							<div className="triazine-cob">
								<strong>Total advance paid: </strong> <strong>₹</strong> {totalAdvanceAmount}
							</div>
						</div> : undefined}
					</div>
				</div>
			</div >
		</>
	)
}
export default BookingEdit