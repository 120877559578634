import { useState, useEffect, useRef } from "react"
import { useTheme } from "@mui/material/styles"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { DownloadTableExcel } from "react-export-table-to-excel"
import "./BookingReport.css"
import axios from "axios"
import { format } from "date-fns"
import api from "../../config/backend"
import "./Booking.css"
import { ToastContainer, toast } from "react-toastify"
import TablePagination from "@mui/material/TablePagination"
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import dayjs from "dayjs"

function BookingReport() {
    const [selectedbanquet, setSelectedBanquet] = useState("")
    const [selectedstatus, setSelectedStatus] = useState("")
    const [selectedactive, setSelectedActive] = useState("")
    const [startdate, setStartDate] = useState(null)
    const [enddate, setEndDate] = useState(null)
    const [filename, setFileName] = useState("Reports")

    const token = localStorage.getItem("token")

    const tableRef = useRef()

    const [banquets, setBanquets] = useState([])
    const [search, setSearch] = useState("")
    const [bookings, setBookings] = useState([])
    const [filteredbookings, setFilteredbookings] = useState([])
    const [company, setCompany] = useState(undefined)

    const fetchCompany = async () => {
        const { data, status } = await axios.post(`${api}/company/get-company`, {
            token,
        })

        if (data.success) {
            setCompany(data.company)
        } else {
            toast.error(data.message)
        }
    }

    const logger = async () => {
        const link = window.location.href

        const { data, status } = await axios.post(`${api}/Logs/viewed-page`, { token, link })
    }

    useEffect(() => {
        fetchBanquets()
        fetchCompany()
        logger()
    }, [])

    const fetchBanquets = async () => {
        try {
            const response = await axios.post(`${api}/BanquetHall/get-banquets`, {
                token,
            })
            setBanquets(response.data.banquets)
        } catch (error) {
            console.error(error)
        }
    }

    const fetchBookings = async () => {
        try {
            const { data, status } = await axios.post(`${api}/Booking/get-bookings`, {
                token,
            })

            if (data.success) {
                setBookings(data.bookings)
            } else {
                toast.error(data.message)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const [eventFromDates, setEventFromDates] = useState(undefined)
    const [eventToDates, setEventToDates] = useState(undefined)
    const [events, setEvents] = useState([])

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value)
        setPage(0)
    }

    const handleReset = () => {
        setSelectedBanquet("")
        setSelectedStatus("")
        setSelectedActive("")
        setStartDate(null)
        setEndDate(null)
        setSearch("")
    }

    useEffect(() => {
        fetchBookings()
    }, [company])

    useEffect(() => {
        let timedifference = undefined
        let daysinbetween = undefined

        if (eventFromDates && eventToDates) {
            timedifference =
                new Date(eventToDates).getTime() - new Date(eventFromDates).getTime()
            daysinbetween = timedifference / (1000 * 3600 * 24) + 1

            setEvents(
                Array(daysinbetween)
                    .fill({
                        date: eventFromDates,
                        breakfast: false,
                        lunch: false,
                        dinner: false,
                        halltype: "",
                        pax: 1,
                        expectedpax: 1,
                        banquethallhash: "",
                        eventtitle: "",
                    })
                    .map((item, index) => {
                        const copy = JSON.parse(JSON.stringify(item))
                        const d = new Date(eventFromDates)
                        copy.date = d.setDate(d.getDate() + index)
                        return copy
                    })
            )
        }
    }, [eventFromDates, eventToDates])

    useEffect(() => {
        setFilteredbookings(
            bookings.map((booking) => {
                let filtered = { ...booking }
                filtered.events = booking.events.filter((event) => {
                    let filters = true

                    if (search) {
                        filters = filters && (
                            String(booking.phonenumber).includes(search) ||
                            String(booking.alternative_phonenumber).includes(search) ||
                            String(booking.contact_person_email).includes(search) ||
                            String(company.name.slice(0, 3).toUpperCase() + String(booking.bookingserial).padStart(5, "0")).toLowerCase().includes(search)
                        )
                    }

                    if (selectedbanquet) {
                        filters = filters && (event.banquet_hall === selectedbanquet)
                    }

                    if (selectedstatus) {
                        filters = filters && (booking.booking_type === selectedstatus)
                    }

                    if (selectedactive) {
                        filters = filters && (String(event.cancelled.status) === selectedactive)
                    }

                    if (startdate) {
                        filters = filters && (new Date(event.date) >= startdate.$d)
                    }

                    if (enddate) {
                        filters = filters && (new Date(event.date) <= enddate.$d)
                    }

                    return filters
                })
                return filtered
            }).filter((booking) => {
                return booking.events.length > 0
            })
        )

        let name = "Reports"

        if (search) {
            name += " search"
        }

        if (selectedbanquet) {
            name += ` banquet-${banquets.find(item => item.hash === selectedbanquet).hall_title}`
        }

        if (selectedstatus) {
            name += ` status-${selectedstatus}`
        }

        if (selectedactive) {
            name += ` active-${selectedactive === "true" ? "cancelled" : "booked"}`
        }

        if (startdate) {
            name += ` startdate-${new Date(startdate.$d).toDateString()}`
        }

        if (enddate) {
            name += ` startdate-${new Date(enddate.$d).toDateString()}`
        }

        setFileName(name)
    }, [search, bookings, selectedbanquet, selectedstatus, selectedactive, startdate, enddate])

    return (
        <div className="_3qty">
            <div>
                <h2 style={{ fontFamily: "Roboto", marginBottom: "8px", color: "#a48901" }}>Booking reports</h2>
            </div>
            <div style={{ display: "flex", flexDirection: "column", marginTop: "40px", marginBottom: "20px", backgroundColor: "white", padding: "25px", borderRadius: "10px", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
                <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: "30px" }}>
                    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                        <span style={{ fontFamily: "Roboto", fontSize: "16px", fontWeight: "500", color: "rgb(49, 49, 49)" }}>Search</span>
                        <input
                            style={{
                                height: "40px",
                                width: "450px",
                                outline: "none",
                                borderRadius: "20px",
                                border: "1px solid grey",
                                padding: "0px 15px",
                            }}
                            placeholder="Find bookings by phone number, e-mail or serial"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />

                    </div>

                    <DownloadTableExcel
                        filename={filename}
                        sheet="users"
                        currentTableRef={tableRef.current}
                    >
                        <button style={{ all: "unset", color: "white", backgroundColor: "#F9C623", fontFamily: "Roboto", fontSize: "14px", padding: "12px 18px", borderRadius: "5px", cursor: "pointer", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>Download report</button>
                    </DownloadTableExcel>
                </div>
                <div style={{ marginBottom: "10px", display: "flex", flexDirection: "column", gap: "20px" }}>
                    <span style={{ fontFamily: "Roboto", fontSize: "16px", fontWeight: "500", color: "rgb(49, 49, 49)" }}>Filters</span>
                    <div className="glosseme-rant">
                        <FormControl style={{ width: "120px", border: "none" }}>
                            <InputLabel id="demo-simple-select-label">Banquet</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedbanquet}
                                label="Banquet"
                                onChange={e => setSelectedBanquet(e.target.value)}
                            >
                                <MenuItem value={undefined}>None</MenuItem>
                                {banquets.map(banquet => {
                                    return (
                                        <MenuItem value={banquet.hash}>{banquet.hall_title}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <FormControl style={{ width: "120px", border: "none" }}>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                                // labelId="demo-simple-select-label"
                                // id="demo-simple-select"
                                value={selectedstatus}
                                label="Status"
                                onChange={e => setSelectedStatus(e.target.value)}
                            >
                                <MenuItem value="">None</MenuItem>
                                {["Confirmed", "Tentative", "Waitlist"].map((status, index) => {
                                    return (
                                        <MenuItem value={status}>{status}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <FormControl style={{ width: "120px", border: "none" }}>
                            <InputLabel id="demo-simple-select-label">Active</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedactive}
                                label="Active"
                                onChange={e => setSelectedActive(e.target.value)}
                            >
                                <MenuItem value={undefined}>None</MenuItem>
                                {[{ name: "Booked", value: "false" }, { name: "Cancelled", value: "true" }].map(item => {
                                    return (
                                        <MenuItem value={item.value}>{item.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <Grid item xs={12} md={5}>
                            <div className="app-form-field">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Start date"
                                        value={startdate}
                                        onChange={(date) => setStartDate(date)}
                                        renderInput={(params) => <input {...params} />}
                                        format="DD-MM-YYYY"
                                    />
                                </LocalizationProvider>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <div className="app-form-field">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="End date"
                                        value={enddate}
                                        onChange={(date) => setEndDate(date)}
                                        renderInput={(params) => <input {...params} />}
                                        format="DD-MM-YYYY"
                                    />
                                </LocalizationProvider>
                            </div>
                        </Grid>
                    </div>
                    <button className="bedabble-ions" onClick={() => handleReset()}>Reset</button>
                </div>

            </div>

            <TableContainer style={{ backgroundColor: "white" }}>
                <Table ref={tableRef} stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell style={{ paddingRight: "100px" }}>Date</TableCell>
                            <TableCell style={{ paddingRight: "80px" }}>Banquet Hall</TableCell>
                            {/* <TableCell> */}
                            <TableCell>Hall Type</TableCell>
                            <TableCell style={{ paddingRight: "30px" }}>Session</TableCell>
                            <TableCell style={{ paddingRight: "90px" }}>Event</TableCell>
                            <TableCell>Pax</TableCell>
                            <TableCell>Expected Pax</TableCell>
                            <TableCell style={{ paddingRight: "60px" }}>Active</TableCell>
                            <TableCell style={{ paddingRight: "40px" }}>Reason for cancellation</TableCell>
                            {/* </TableCell> */}
                            <TableCell>Customer Name</TableCell>
                            <TableCell>Phone Number</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Company</TableCell>
                            <TableCell style={{ minWidth: "300px" }}>Advance Paid</TableCell>
                            <TableCell>Total Amount (₹)</TableCell>
                            <TableCell>Booking By</TableCell>
                            <TableCell>Referred By</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Remarks</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredbookings
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((booking) => {
                                let totaladvance = 0

                                if (booking.advance_paid) {
                                    booking.advance_paid.forEach(advance => {
                                        totaladvance += Number(advance.amount)
                                    })
                                }

                                return (
                                    <TableRow key={booking._id}>
                                        <TableCell >
                                            {company && company.name.slice(0, 3).toUpperCase() + String(booking.bookingserial).padStart(5, "0")}
                                        </TableCell>
                                        <TableCell className="_0sgv_table">
                                            {booking.events.map((event, index = 0) => {
                                                index++
                                                return (
                                                    <div style={{ borderBottom: index !== booking.events.length ? '1px solid rgba(220,220,220,0.7)' : '' }}>
                                                        {dayjs(event.date).format("DD-MM-YYYY")}
                                                    </div>)
                                            })}
                                        </TableCell>
                                        <TableCell className="_0sgv_table">
                                            {booking.events.map((event, index = 0) => {
                                                index++
                                                return (
                                                    <div style={{ borderBottom: index !== booking.events.length ? '1px solid rgba(220,220,220,0.7)' : '' }}>
                                                        {event.banquet.hall_title}
                                                    </div>)
                                            })}
                                        </TableCell>
                                        <TableCell className="_0sgv_table">
                                            {booking.events.map((event, index = 0) => {
                                                index++
                                                return (
                                                    <div style={{ borderBottom: index !== booking.events.length ? '1px solid rgba(220,220,220,0.7)' : '' }}>
                                                        {event.banquet.hall_type}
                                                    </div>)
                                            })}
                                        </TableCell>
                                        <TableCell className="_0sgv_table">
                                            {booking.events.map((event, index = 0) => {
                                                index++
                                                return (
                                                    <div style={{ borderBottom: index !== booking.events.length ? '1px solid rgba(220,220,220,0.7)' : '' }}>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                gap: "20px",
                                                                fontWeight: "900",
                                                            }}
                                                        >
                                                            <span>
                                                                {event.is_breakfast_available ? "B" : undefined}
                                                            </span>
                                                            <span>
                                                                {event.is_lunch_available ? "L" : undefined}
                                                            </span>
                                                            <span>
                                                                {event.is_dinner_available ? "D" : undefined}
                                                            </span>
                                                        </div>
                                                    </div>)
                                            })}
                                        </TableCell>
                                        <TableCell className="_0sgv_table">
                                            {booking.events.map((event, index = 0) => {
                                                index++
                                                return (
                                                    <div style={{ borderBottom: index !== booking.events.length ? '1px solid rgba(220,220,220,0.7)' : '' }}>
                                                        {event.event_title}
                                                    </div>)
                                            })}
                                        </TableCell>
                                        <TableCell className="_0sgv_table">
                                            {booking.events.map((event, index = 0) => {
                                                index++
                                                return (
                                                    <div style={{ borderBottom: index !== booking.events.length ? '1px solid rgba(220,220,220,0.7)' : '' }}>
                                                        {event.pax}
                                                    </div>)
                                            })}
                                        </TableCell>
                                        <TableCell className="_0sgv_table">
                                            {booking.events.map((event, index = 0) => {
                                                index++
                                                return (
                                                    <div style={{ borderBottom: index !== booking.events.length ? '1px solid rgba(220,220,220,0.7)' : '' }}>
                                                        {event.exp_pax}
                                                    </div>)
                                            })}
                                        </TableCell>
                                        <TableCell className="_0sgv_table">
                                            {booking.events.map((event, index = 0) => {
                                                index++
                                                return (
                                                    <div style={{ borderBottom: index !== booking.events.length ? '1px solid rgba(220,220,220,0.7)' : '', color: event.cancelled.status ? "red" : "green" }}>
                                                        {event.cancelled.status ? "Cancelled" : "Booked"}
                                                    </div>)
                                            })}
                                        </TableCell>
                                        <TableCell className="_0sgv_table">
                                            {booking.events.map((event, index = 0) => {
                                                index++
                                                return (
                                                    <div style={{ borderBottom: index !== booking.events.length ? '1px solid rgba(220,220,220,0.7)' : '' }}>
                                                        {event.cancelled.reason}
                                                    </div>)
                                            })}
                                        </TableCell>
                                        <TableCell>{booking.contact_person_name}</TableCell>
                                        <TableCell>{booking.phonenumber}</TableCell>
                                        <TableCell>
                                            {booking.contact_person_email}
                                        </TableCell>
                                        <TableCell>{booking.company_name}</TableCell>
                                        <TableCell style={{ minWidth: "300px" }}>
                                            {booking.advance_paid && booking.advance_paid.length ? (
                                                <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                                    {booking.advance_paid.map(advance => {
                                                        return (
                                                            <div style={{ display: "flex" }} key={advance._id}>
                                                                <span style={{ backgroundColor: "rgb(250, 235, 215)", padding: "2px 8px", marginRight: "3px" }}>{dayjs(advance.date).format("DD-MM-YYYY")}</span>
                                                                <span> - ₹{advance.amount}</span>
                                                                <span style={{ marginLeft: "3px" }}> - {advance.remark}</span>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            ) : "NIL"}
                                        </TableCell>
                                        <TableCell>{totaladvance}</TableCell>
                                        <TableCell>{booking.booking_given_by}</TableCell>
                                        <TableCell>{booking.reffered_by}</TableCell>
                                        <TableCell>{booking.booking_type}</TableCell>
                                        <TableCell>{booking.remarks}</TableCell>

                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
                <TablePagination
                    style={{ display: "flex", flexDirection: "row" }}
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={filteredbookings.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </div>
    )
}
export default BookingReport