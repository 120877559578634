import React, { useEffect, useState } from 'react'
import "./css/Password.css"
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import api from '../config/backend'
import { toast } from 'react-toastify'
import { Box, CircularProgress } from '@mui/material'
import StepperHeader from "../components/StepperHeader"


const Password = () => {
    const [email, setEmail] = useState("")
    const [error, setError] = useState(false)
    const [message, setMessage] = useState(false)
    const [loading, setloading] = useState(false)

    useEffect(() => {
        let timeout

        if (error) {
            timeout = setTimeout(() => {
                setError(false)
            }, 15000)
        }

        return () => clearTimeout(timeout)
    }, [error, message])

    const handleSubmit = async (event) => {
        event.preventDefault()
        setloading(true)
        const { data, status } = await axios.post(`${api}/Author/forgot-password`, { email })

        if (data.success) {
            setMessage(data.message)
        } else {
            setError(data.message)
        }
        setloading(false)
    }

    return (
        <div>
            <StepperHeader forcehideLogout={true} />
            <div className='Pass_div'>
                <div className='Box_div'>
                    <h1 style={{ color: "#F9C623" }}>Forgot your <br />password?</h1>
                    <p style={{ color: "grey" }}>To reset your password, please enter the email address of your Todoist account.</p>
                    <form onSubmit={handleSubmit}>
                        <div className='field_div'>
                            <input type='email' id='email' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Enter Register Email' />
                            {error ? <span>{error}</span> : undefined
                            }
                            {message ? <span style={{ color: "green" }}>{message}</span> : undefined}
                            <button type='submit' style={{ backgroundColor: "#F9C623", marginTop: "10px" }} className='btn'>{loading ? <Box sx={{ display: 'flex', color: "white", }}>
                                <CircularProgress size={20} />
                            </Box> : "Reset Password"}</button>
                        </div>
                    </form>
                    <span className='link'><Link style={{ color: "#F9C623" }} to="/loginPage">Go to Login</Link></span>
                </div>
                <div className='Img_div'>
                    <img style={{ width: 400, height: 400 }} src='assets/images/shield.jpg' />
                </div>
            </div>
        </div>
    )
}

export default Password