import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const FormContainer = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '300px',
  margin: 'auto',
  padding: '20px',
});


const SuperAdminLogin = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [isWithOTP, setIsWithOTP] = useState(false);

  const [responseData, setResponseData] = useState([])

  let navigate = useNavigate()

  const apiUrl = `http://192.46.209.97:6060/api/Superadmin/login?is_with_otp=${isWithOTP}`

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(apiUrl, {
        phonenumber: phoneNumber,
        password: password,
        is_with_otp: isWithOTP,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
        },
      });
      console.log(response)
      const { Token, refreshToken } = response.data;

      const userId = response.data.results._id
      if(response.status === 201){
        navigate('/dashboard')
      }

      // Store the token and refresh token in local storage
      localStorage.setItem('token', Token);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('userId', userId);

    } catch (error) {
      // Handle any errors that occurred during the API request
      console.error(error);
    }
  };

  return (
    <FormContainer onSubmit={handleLogin}>
      <TextField
        label="Phone Number"
        type="number"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        margin="normal"
      />
      <TextField
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        margin="normal"
      />
      <br />
      <Button variant="contained" type="submit">
        Login
      </Button>
    </FormContainer>
  );
};

export default SuperAdminLogin;

