import React, { useContext, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import "./Css/OnboardingSteps.css"
import AddCompany from './AddCompany'
import AddBanquet from './AddBanquet'
import CreateRole from './CreateRole'
import AddDepartment from './AddDepartment'
import CreateUser from './CreateUser'
import FinishOnboard from './FinishOnboard'
import StepperHeader from '../../components/StepperHeader'
import { AccessContext } from '../../App'
import axios from "axios"
import api from "../../config/backend"
import { ToastContainer, toast } from "react-toastify"
import { MdOutlineMailOutline } from "react-icons/md"

const AdditionalButton = () => {
  return (
    <div className='foot'>
      <h3>Having Trouble</h3>
      <p style={{ fontSize: "12px", fontWeight: 700 }}>
        Get in touch with us — 
        <span style={{ color: "grey", fontWeight: 500 }}> info@skoltech.in</span>
      </p>
      <a style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "4px", width: "fit-content", padding: "7px 10px", borderRadius: "6px", backgroundColor: "#F9C623", color: "black", cursor: "pointer" }} href='mailto:info@skoltech.in'>
        <MdOutlineMailOutline />
        <span style={{ fontSize: "12px" }}>Send e-mail</span>
      </a>
    </div>
  )
}


export default function OnboardingSteps() {
  const [approved, setApproved] = useState(false)
  const [iseditmode, setIseditmode] = useState(false)

  const { state, actions } = useContext(AccessContext)

  const token = localStorage.getItem("token")

  const handleNext = () => {
    if (iseditmode) {
      setActiveStep(5)
      setIseditmode(false)
    } else {
      setOnboardingStep()
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const handleBack = (stepstogoback) => {
    console.log({ stepstogoback })
    if (stepstogoback) {
      setIseditmode(true)
      setActiveStep((prevActiveStep) => prevActiveStep - stepstogoback)
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }
  }

  const steps = [
    {
      label: 'Add Company',
      para: 'Add your company details',
      description: (
        <AddCompany handleNext={handleNext} />
      ),
    },
    {
      label: 'Add banquet hall',
      para: 'Add banquet hall details',
      description: (
        <AddBanquet />
      ),
    },
    {
      label: 'Create Role',
      para: 'Manage roles and permissions',
      description: (
        <CreateRole />
      ),
    },
    {
      label: 'Add location',
      para: 'Add locations for venue',
      description: (
        <AddDepartment />
      ),
    },
    {
      label: 'Add a user',
      para: 'Add a user for management',
      description: (
        <CreateUser />
      ),
    },
    {
      label: 'Finish',
      para: 'Review the provided information',
      description: (
        <FinishOnboard approved={approved} setApproved={setApproved} setStep={handleBack} />
      ),
    },
  ]
  const [activeStep, setActiveStep] = useState(0)

  const setOnboardingStep = async () => {
    const { data, status } = await axios.post(
      `${api}/Author/step-onboarding`,
      {
        token,
        currentstep: activeStep + 1
      }
    )

    if (data.success === false) {
      console.error(data.message)
    }
  }

  const fetchOnboardingStep = async () => {
    const { data, status } = await axios.post(`${api}/Author/fetch-step`, { token })

    if (data.success) {
      setActiveStep(data.step)
    } else {
      console.error(data.message)
    }
  }

  const handleFinish = async () => {
    if (approved) {
      const { data, status } = await axios.post(
        `${api}/Author/toggle-onboarding`,
        { token, status: false }
      )

      if (data.success) {
        localStorage.setItem("token", data.token)
        actions.setOnboarding(false)
      }
    } else {
      toast.error("Confirm the information is correct")
    }
  }

  useEffect(() => {
    fetchOnboardingStep()
  }, [])


  return (
    <div className="_6ivr">
      <ToastContainer></ToastContainer>
      <React.Fragment>
        <StepperHeader />
        <Box >
          <Grid container spacing={4}>
            <Grid item xs={3} className='leftcolor'>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel
                      optional={
                        <Typography variant="caption">{step.para}</Typography>
                      }
                    >
                      {step.label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              <AdditionalButton />
            </Grid>
            <Grid item xs={9} className='rightcolor'>
              <Box sx={{ p: 2 }}>
                {steps.map((step, index) => (
                  <React.Fragment key={step.label}>
                    {activeStep === index && (
                      <React.Fragment>
                        {step.label === 'Add banquet hall' && (
                          <AddBanquet
                          />

                        )}
                        {step.label !== 'Add banquet hall' && step.description}
                        {index === 0 ? undefined : <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                          {index > 1 && (
                            <Button className='previous' disabled={index === 0} onClick={() => handleBack()} sx={{ mt: 1, ml: 1 }}>
                              Previous
                            </Button>
                          )}
                          <Button
                            variant="contained"
                            onClick={index === steps.length - 1 ? handleFinish : handleNext}
                            className='saveandproceed'
                            sx={{ mt: 1, ml: 1 }}
                          >
                            {index === steps.length - 1 ? 'Finish' : 'Save and Proceed'}
                          </Button>
                        </Box>}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ))}
              </Box>
            </Grid>
          </Grid>
          {/* {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
      
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )} */}
        </Box>
      </React.Fragment>
    </div>
  )
}
