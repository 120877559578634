import React, { useEffect, useState } from 'react'
import api from '../config/backend';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import image from "../assets/original.webp"

const Verification = () => {
    const { token } = useParams()
    let navigate = useNavigate()

    const [redirect, setRedirect] = useState(false)

    useEffect(() => {
        let timeout

        if (redirect) {
            timeout = setTimeout(() => {
                navigate("/loginPage")
            }, 5000)
        }

        return () => clearTimeout(timeout)
    }, [redirect])
    // Decode the String
    var decodedString = atob(token);
        
    const [jsonwebtoken, code] = decodedString.split('|')

    useEffect(() => {
        handle()
    },[])

    const handle = async () =>{
        const {data, status} = await axios.post(`${api}/Author/email-verification`, {token: jsonwebtoken, code})
        if(data.success){
            setRedirect(true)
        }

    }

    return (
        <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <img style={{width:700, height:500}} src={image} alt='image'/>
            <div>
            <h1 style={{color:'green'}}>Email Verified Successfully</h1>
            </div>
        </div>
    )
}

export default Verification
