import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Button } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

import axios from "axios";
import { toast } from "react-toastify";
import api from "../../config/backend"
import { useContext } from "react"
import { AccessContext } from "../../App";

const names = ["Male", "Female", "Other"];

function EmployeeRegistration() {
  const [firstName, setFirstName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [selectedGender, setSelectedGender] = React.useState("");
  const [designationList, setDesignationList] = React.useState([]);
  const [departmentList, setDepartmentList] = React.useState([]);
  const [selectedDepartment, setSelectedDepartment] = React.useState("");
  const [selectedDesignation, setSelelectedDesignation] = React.useState("");

  const handleDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
  };

  const handleDesignationChange = (e) => {
    setSelelectedDesignation(e.target.value);
  };

  const getDepartmentList = async () => {
    const token = localStorage.getItem("token")
    const { data, status } = await axios.post(`${api}/Department/get-departments`, { token })
    if (data.success) {
      setDepartmentList(data.departments)
    }
  }

  const getDesignationList = async () => {
    const token = localStorage.getItem("token")
    const { data, status } = await axios.post(`${api}/Designation/get-designations`, { token })
    if (data.success) {
      setDesignationList(data.designations)
    }
  };

  React.useEffect(() => {
    fetchData()
    getDepartmentList()
    getDesignationList()
  }, [])


  // post the employee reg data to employee create API
  const handleSubmit = async (event) => {
    event.preventDefault()
    const token = localStorage.getItem("token")
    const payload = {
      token,
      full_name: firstName,
      phonenumber: phoneNumber,
      email,
      gender: selectedGender,
      designation: selectedDepartment,
      department: selectedDesignation,
    }

    // Make the API request
    const { data, status } = await axios.post(`${api}/Employee/create-employee`, payload)

    if (data.success) {
      toast.success(data.message)
      fetchData()
    } else {
      toast.error(data.message)
    }
  }

  const handleChange2 = (event) => {
    setSelectedGender(event.target.value);
  };

  const [employeeList, setEmployeeList] = React.useState([]);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token")
      const { data, status } = await axios.post(`${api}/Employee/get-employees`, { token })

      if (data.success) {
        setEmployeeList(data.employees)
      } else {
        toast.error(data.message)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const deleteObject = async (hash) => {
    try {
      const confirmed = window.confirm(
        "Are you sure you want to delete this object?"
      );
      if (confirmed) {
        const token = localStorage.getItem("token")
        const response = await axios.post(`${api}/Employee/remove-employee`, { token, hash })

        if (response.data.success) {
          fetchData()
        }
      }
    } catch (error) {
      console.error("Error deleting object:", error);
      // Display an error message to the user or trigger UI behavior
    }
  }

  const [editMode, setEditMode] = React.useState(false);
  const [selectedHash, setSelectedHash] = React.useState(undefined);

  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  React.useEffect(() => {
    if (
      employeeList.length > 0 &&
      selectedIndex >= 0 &&
      selectedIndex < employeeList.length
    ) {
      const selectedItem = employeeList[selectedIndex];
      setFirstName(selectedItem.full_name)
      setEmail(selectedItem.email)
      setPhoneNumber(selectedItem.phonenumber)
      setSelectedGender(selectedItem.gender)
      setSelectedDepartment(selectedItem.department)
      setSelelectedDesignation(selectedItem.designation)
      setSelectedHash(selectedItem.hash)
    }
  }, [employeeList, selectedIndex])

  // Needs fix
  // It is fired even on register new employee
  const updateEmployee = async (event) => {
    event.preventDefault()

    const token = localStorage.getItem("token")
    const payload = {
      token,
      full_name: firstName,
      phonenumber: phoneNumber,
      email,
      gender: selectedGender,
      designation: selectedDepartment,
      department: selectedDesignation,
      hash: selectedHash
    }

    const { data, status } = await axios.post(`${api}/Employee/update-employee`, payload)

    if (data.success) {
      toast.success(data.message)
      fetchData()
    } else {
      toast.error(data.message)
    }
  }


  const { state } = React.useContext(AccessContext)
  const data = state.access ? state.access.employees : undefined
  console.log(state)

  return (
    <>
      <div className="app-page-user-registration">
        {data && data.edit ? (
          <div className="app-card app-card-550">
            <h1 className="app-card__title">Employee Registration</h1>
            <form onSubmit={editMode ? updateEmployee : handleSubmit}>
              <div className="app-card__content">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={8}>
                    <div className="app-form-field">
                      {/* <TextField label="Full Name" type="number" variant="outlined" placeholder="Enter Mobile No" /> */}
                      <TextField
                        label="Full Name"
                        variant="outlined"
                        placeholder="Enter Full Name"
                        value={firstName}
                        onChange={(event) => setFirstName(event.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div className="app-form-field">
                      <Select
                        displayEmpty
                        value={selectedGender}
                        onChange={handleChange2}
                        input={<OutlinedInput />}
                        renderValue={(selected) => {
                          if (!selected) {
                            return <em>Gender</em>;
                          }
                          return selected;
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {names.map((name) => (
                          <MenuItem key={name} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className="app-form-field">
                      <Select
                        displayEmpty
                        value={selectedDepartment}
                        onChange={handleDepartmentChange}
                        input={<OutlinedInput />}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="" disabled>
                          Select Department
                        </MenuItem>
                        {departmentList.map((item) => (
                          <MenuItem key={item._id} value={item.title}>
                            {item.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className="app-form-field">
                      <Select
                        displayEmpty
                        value={selectedDesignation} // Assuming you have a state variable for the selected designation
                        onChange={handleDesignationChange} // Assuming you have a function to handle designation changes
                        input={<OutlinedInput />}
                        MenuProps={""}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="" disabled>
                          Select Designation
                        </MenuItem>
                        {designationList.map((item) => (
                          <MenuItem key={item._id} value={item.title}>
                            {item.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="app-form-field">
                      {/* <TextField label="Mobile No" variant="outlined" placeholder="Enter Mobile No" /> */}
                      <TextField
                        label="Mobile No"
                        type="number"
                        variant="outlined"
                        placeholder="Enter Mobile No"
                        value={phoneNumber}
                        onChange={(event) => setPhoneNumber(event.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="app-form-field">
                      {/* <TextField label="Email ID" type="email" variant="outlined" placeholder="Enter Email ID" /> */}
                      <TextField
                        label="Email ID"
                        type="email"
                        variant="outlined"
                        placeholder="Enter Email ID"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                      />
                    </div>
                  </Grid>
                </Grid>

                <div className="app-card__button d-flex justify-content-end mt-20 w-full">

                  <Button
                    className="app-btn app-btn-primary"
                    variant="contained"
                    type="submit"
                  > {editMode ? "Save changes" : "Create"}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        ) : undefined}

        {data && data.view ? (
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>E-mail</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Department</TableCell>
                  <TableCell>Designation</TableCell>
                  <TableCell>Edit</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {employeeList.map((employee, index) => (
                  <TableRow key={employee.hash}>
                    <TableCell>{employee.full_name}</TableCell>
                    <TableCell>{employee.email}</TableCell>
                    <TableCell>{employee.phonenumber}</TableCell>
                    <TableCell>{employee.department}</TableCell>
                    <TableCell>{employee.designation}</TableCell>
                    <TableCell>
                      <div className="app-table-status">
                        <Stack direction="row" spacing={1}>
                          <IconButton aria-label="delete">
                            {/* <img style={{ width: '20px' }} src='/assets/icons/edit.svg' /> */}
                            <IconButton
                              aria-label="edit"
                              //   onClick={() => {
                              //     setDepartmentName(department.title);
                              //     setDisplayName(department.display_title);
                              //     setEditMode(true);
                              //     setCurrentDepartment(department);
                              //   }}
                              onClick={() => {
                                setSelectedIndex(index, employee._id)
                                setEditMode(true);
                              }}
                            >
                              <img
                                style={{ width: "20px" }}
                                src="/assets/icons/edit.svg"
                              />
                            </IconButton>
                          </IconButton>
                        </Stack>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="app-table-status">
                        <Stack direction="row" spacing={1}>
                          <IconButton
                            aria-label="delete"
                            onClick={() => deleteObject(employee.hash)}
                          >
                            <img
                              style={{ width: "20px" }}
                              src="/assets/icons/remove.svg"
                            />
                          </IconButton>
                        </Stack>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : undefined}

      </div>
      {/* <img className="app-tree-bg" src="/assets/images/tree-bg.svg" /> */}
    </>
  );
}
export default EmployeeRegistration;
