import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";
import api from "../../config/backend";

const AddDepartment = () => {

  // States
  const [department, setDepartment] = useState("");
  const [departmentList, setDepartmentList] = useState([]);
  const [editmode, setEditmode] = useState({
    page: undefined,
    edit: false,
    data: undefined,
  });

  //token 
  const token = localStorage.getItem("token")

  // Get -departmentlist
  const getDepartmentList = async () => {
    const { data, status } = await axios.post(
      `${api}/Department/get-departments`,
      { token }
    );
    if (data.success) {
      setDepartmentList(data.departments);
    }
  };

  // Create and edit api's
  const createDepartment = async () => {
    const { data, status } = await axios.post(
      `${api}/Department/create-department`,
      {
        token,
        department_name: department,
        display_name: department,
      }
    );

    if (data.success) {
      setDepartment("");
      toast.success(data.message);
      getDepartmentList("");
    } else {
      toast.error(data.message);
    }
  };

  const updateDepartment = async () => {
    const { data, status } = await axios.post(
      `${api}/Department/update-department`,
      {
        token,
        identifier: editmode.data,
        department_name: department,
        display_name: department,
      }
    );

    if (data.success) {
      setDepartment("");
      toast.success(data.message);
      getDepartmentList("");
      setEditmode({ edit: false, page: undefined, data: undefined });
    } else {
      toast.error(data.message);
    }
  };

  useEffect(() => {
    getDepartmentList()
  }, [])


  return (
    <Box display="flex" flexDirection="column" alignItems="left" p={3}>
      <Box bgcolor="white" boxShadow={3} p={2}>
        <Typography variant="h5" gutterBottom>Location</Typography>
        <Typography variant="subtitle1">Create the location of banquet hall</Typography>
        <TextField
          className="departmentttt"
          label="Location Name"
          value={department}
          onChange={(e) => setDepartment(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <Box mt={3} className='department-button'>
          <Button variant="contained" onClick={() =>
            editmode.edit && editmode.page === "department"
              ? updateDepartment()
              : createDepartment()
          } fullWidth>
            {editmode.edit && editmode.page === "department"
              ? "Update Location"
              : "Add Location"}
          </Button>
        </Box>
      </Box>
      <Box width="100%" mt={3}>
        <TableContainer component={Paper} elevation={3}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Location Name</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {departmentList.map((department) => (
                <TableRow key={department._id}>
                  <TableCell>{department.title}</TableCell>
                  <TableCell>
                    <span
                      style={{ color: "blueviolet", cursor: "pointer" }}
                      onClick={() => {
                        setDepartment(department.title);
                        setEditmode({
                          page: "department",
                          edit: true,
                          data: department._id,
                        });
                      }}
                    >
                      Edit
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default AddDepartment;