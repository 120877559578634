import { useState, useEffect, useContext } from "react";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, Button, FormControl, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import axios from "axios";
import api from "../../config/backend"
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { CgArrowsExchangeV } from "react-icons/cg"
import Slider from '@mui/material/Slider';
import { AccessContext } from "../../App";

const names = ["Option 1", "Option 2", "Option 3", "Option 4", "Option 5"];
const seatingCapacityOptions = [
  { label: '0-150', value: "150" },
  { label: '0-500', value: "500" },
  { label: '0-1000', value: "1000" },
  { label: '0-2000', value: "2000" },
];

function AddBanquetHall() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [hallData, setHallData] = useState([])
  const [halltype, setHalltype] = useState(["Indoor", "Outdoor"])

  const [editmode, setEditmode] = useState({ page: undefined, edit: false, data: undefined })

  const { state, actions } = useContext(AccessContext)

  console.log(state)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const theme = useTheme();
  const [personName, setPersonName] = useState([]);

  const token = localStorage.getItem("token")

  const handleChange2 = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const [age, setAge] = useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [hallName, setHallName] = useState("");
  const [area, setArea] = useState("");
  const [capacitySpace, setCapacitySpace] = useState("");
  // const [edit, setEdit] = useState(true)
  const [selectedHalltype, setSelectedHalltype] = useState("");

  const handleHalltypeChange = (e) => {
    setSelectedHalltype(e.target.value);
  }

  const getBanquetList = async () => {
    const { data, status } = await axios.post(`${api}/BanquetHall/get-banquets`, { token })
    if (data.success) {
      setHallData(data.banquets)
    } else {
      toast.error(data.message)
    }
  }

  const handleFormSubmit = async () => {
    const { data, status } = await axios.post(`${api}/BanquetHall/add-banquet`, {
      token,
      hall_title: hallName,
      hall_type: selectedHalltype,
      area: area,
      capacity_space: capacitySpace
    })
    if (data.success) {
      toast.success(data.message)
      setHallName("")
      setSelectedHalltype("")
      setArea("")
      setCapacitySpace("")
      getBanquetList()
    } else {
      toast.error(data.message)
    }
  }

  const updateBanquethall = async () => {
    const { data, status } = await axios.post(`${api}/BanquetHall/update-banquet`, {
      token,
      hash: editmode.data,
      hall_title: hallName,
      hall_type: selectedHalltype,
      area: area,
      capacity_space: capacitySpace
    })

    if (data.success) {
      toast.success(data.message)
      setHallName("")
      setSelectedHalltype("")
      setArea("")
      setCapacitySpace("")
      setEditmode({ page: undefined, edit: false, data: undefined })
      getBanquetList()
    } else {
      toast.error(data.message)
    }
  }

  const toggleStatus = async (hash, currentstatus) => {
    const { data, status } = await axios.post(`${api}/BanquetHall/toggle-status`, {
      token,
      hash,
      status: !currentstatus
    })

    console.log(hash, currentstatus)

    if (data.success) {
      toast.success(data.message)
      getBanquetList()
    } else {
      toast.error(data.message)
    }
  }

  const handleDeleteHall = async (id, e) => {
    const { data, status } = await axios.delete(`${api}/BanquetHall/delete/${id}`);
    if (status === 200 || status === 201 || status === 204) {
      toast.error("Banquet hall removed ")
      getBanquetList()
    }
  }

  useEffect(() => {
    // getHallTypeList()
    getBanquetList()
    // getBranches()
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({});

  return (
    <>
      <div className="_0sci app-page-banquets-management">
        <div className="app-card">
          <h1 className="app-card__title">Add Banquet Hall</h1>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="app-card__content">
              <Grid container rowSpacing={2} columnSpacing={5}>
                <Grid item xs={12} md={6}>
                  <div className="app-form-field">
                    {/* <TextField label="Hall Name" variant="outlined" placeholder="Enter Hall Name" /> */}
                    <TextField
                      label="Hall Name"
                      variant="outlined"
                      placeholder="Enter Hall Name"
                      value={hallName}
                      onChange={e => setHallName(e.target.value)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6} style={{ display: "flex", gap: "16px" }}>

                  <div className="app-form-field">
                    <Select
                      displayEmpty
                      value={selectedHalltype}
                      onChange={handleHalltypeChange}
                      input={<OutlinedInput />}
                      MenuProps={""}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="" disabled>
                        Select Halltype
                      </MenuItem>
                      {halltype.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid item xs={12} >
                      <div className="app-form-field">
                        <TextField
                          label="Selected Capacity"
                          value={capacitySpace}
                          onChange={(event) => setCapacitySpace(event.target.value)}
                          fullWidth
                        />
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl fullWidth>
                            <ToggleButtonGroup
                              value={capacitySpace}
                              exclusive
                              onChange={(e) => setCapacitySpace(e.target.value)}
                              aria-label="seating capacity options"
                              fullWidth
                            >
                              {seatingCapacityOptions.map((option) => (
                                <ToggleButton key={option.label} value={option.value}>
                                  {option.label}
                                </ToggleButton>
                              ))}
                            </ToggleButtonGroup>
                          </FormControl>
                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ width: 350 }}>
                    <Typography id="input-slider" gutterBottom>
                      Select area
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs>
                        <div className="app-form-field">
                          <TextField
                            required
                            label="Area"
                            variant="outlined"
                            placeholder="Slect Area"
                            value={area}
                            onChange={(e) => setArea(e.target.value)}
                          />
                        </div>
                        <Slider
                          value={area}
                          onChange={(e) => setArea(e.target.value)}
                          aria-labelledby="input-slider"
                          min={0}
                          max={1500}
                          color="warning"

                        />
                      </Grid>
                      <Box sx={{ mt: 5, marginLeft: "10px", border: '1px solid #ccc', padding: '4px 8px', borderRadius: '4px' }}>
                        <Typography variant="body1">
                          {area} Sq Ft
                        </Typography>
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              <div className="app-card__button d-flex justify-content-center mt-20 w-full">
                {editmode.edit && editmode.page === "banquet" ? <Button
                  className="app-btn app-btn-primary"
                  variant="contained"
                  onClick={() => updateBanquethall()}
                >
                  Update details
                </Button> : <Button
                  className="app-btn app-btn-primary"
                  variant="contained"
                  type="submit"
                >
                  Submit details
                </Button>}
                {editmode.edit && editmode.page === "banquet" ? (
                  <Button
                    style={{ color: "red", cursor: "pointer" }}
                    onClick={() => {
                      setEditmode({
                        page: undefined,
                        edit: false,
                        data: undefined,
                      });
                      setHallName("");
                      setSelectedHalltype("");
                      setArea("");
                      setCapacitySpace("")
                    }}
                  >
                    cancel
                  </Button>
                ) : undefined}
              </div>
            </div>
          </form>
        </div>
        <div className="app-component-table" style={{ marginTop: "30px" }}>
          <div className="app-component-table__header">
            <h2>List Of Banquet Names</h2>
            {/* <div className="app-component-table__header__search">
              <TextField label="Search" variant="outlined" />
              <img src="/assets/icons/search.svg" />
            </div> */}
          </div>

          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Banquet Name</TableCell>
                  <TableCell>Hall Type</TableCell>
                  <TableCell>Area</TableCell>
                  <TableCell>Persons capacity</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Edit</TableCell>
                  {/* <TableCell>Delete</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {hallData.map((hall, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{hall.hall_title}</TableCell>
                      <TableCell>{hall.hall_type}</TableCell>
                      <TableCell>{hall.area}</TableCell>
                      <TableCell>{hall.capacity_space}</TableCell>
                      <TableCell>
                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                          <span>{hall.is_active ? "Active" : "Inactive"}</span>
                          <div
                            style={{ width: "25px", height: "25px", borderRadius: "50%", backgroundColor: "Green", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                            onClick={() => toggleStatus(hall.hash, hall.is_active)}
                          >
                            <CgArrowsExchangeV style={{ fontSize: "20px", color: "white", fill: "white" }} />
                          </div>

                        </div>
                      </TableCell>
                      <TableCell>
                        <span
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => {
                            setHallName(hall.hall_title);
                            setSelectedHalltype(hall.hall_type);
                            setArea(hall.area);
                            setCapacitySpace(hall.capacity_space);
                            setEditmode({
                              page: "banquet",
                              edit: true,
                              data: hall.hash,
                            });
                          }}
                        >
                          Edit
                        </span>
                      </TableCell>
                      {/* <TableCell>
                        <div
                          className="app-table-status"
                          onClick={() => handleDeleteHall(hall._id)}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src="/assets/icons/success-check.svg"
                            alt="Success check"
                          />
                          <span>delete</span>
                        </div>
                      </TableCell> */}
                    </TableRow>
                  )

                })}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
      {/* <img className="app-tree-bg" src="/assets/images/tree-bg.svg" /> */}
    </>
  );
}
export default AddBanquetHall;
