import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Button } from "@mui/material";
import axios from "axios";
import { useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";


function Package() {

  const [title, setTitle] = React.useState("");
  const [basePrice, setBasePrice] = React.useState("");
  const [sellingPrice, setSellingPrice] = React.useState("");
  const [hallsNumber, setHallsNumber] = React.useState("");
  const [description, setDescription] = React.useState("");

  let packageId; // Variable to store the package ID


  const handleSubmit = (event) => {
    event.preventDefault();

    const data = {
      title: title,
      base_price: parseInt(basePrice),
      selling_price: parseInt(sellingPrice),
      no_of_halls_access: parseInt(hallsNumber),
      description: description
    };

    // Make the API request
    axios
      .post("http://192.46.209.97:6060/api/Package/create", data, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          'authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsImZpcnN0X25hbWUiOiJTaXRhcmEiLCJsYXN0X25hbWUiOiJIb3RlbCIsImVtYWlsIjoic2l0YXJhQHRlc3QuY29tIiwiaXNfcGhvbmVfdmVyaWZpZWQiOmZhbHNlLCJwaG9uZV92ZXJpZmljYXRpb24iOm51bGwsImltYWdlIjpudWxsLCJyb2xlIjpbIjY0NjRhODk5NDQ5YWUwOTA2NGU1MjZmOCJdLCJnZW5kZXIiOiJNYWxlIiwiYWRkcmVzcyI6InN0cmluZyIsInBhc3N3b3JkIjoiJDJiJDEwJEZYSHJSQVFPbVRKZktPZmw2djZsQmVxQS85Smg1UzRvZk1iRTdaNS9FcWRpN1ByUEk4TjRDIiwicmVzZXRQYXdvcmRUb2tlbiI6bnVsbCwicmVmcmVzaFRva2VuIjoia0kydTMxQ3NjRjVscUgwNmRUNVUyNlJ0dzFEbk1FNWVVQnN6VzRBVzBnTXdaaXRNTzdrRnozc0F5dHJpaTI4eGVSb2VsSXhIQ0xrWnFxOGxsWVZseXRSQmF6SktKUHp4dWxCQ1A3TU5GbkpPSERaZ1Mzc1VYc2l4ekhaVjRESFM0dGlRUnQ5a28yWEU4bmhaY0w0N3IxaU5QalBXa0hGRmg2N0c4SkFOUmVlTmdJdjd4dkYyV0dwcVV6Uk15NWE4UmNJWG9CV2MyZmNsanBWelRkamVXNERaSDJ4Rkp3MXlveXlyaXZ3YWNjQUlxWHhSYXpQSm9ZcW9MTkpyQTU4ajY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsIm90cCI6MTIzNCwidmVudWUiOiI2NDY0YWUwMDgxMjM4NjFhZmM5YTUyNTQiLCJwaG9uZW51bWJlciI6Ijk2NDA4NjEwNzYiLCJkb2IiOiIyMDIzLTAxLTAxVDAwOjAwOjAwLjAwMFoiLCJjcmVhdGVkQXQiOiIyMDIzLTA1LTE3VDEwOjM1OjQ0LjU5NloiLCJ1cGRhdGVkQXQiOiIyMDIzLTA1LTE4VDA2OjE5OjI2LjQzOFoiLCJfX3YiOjB9LCJpYXQiOjE2ODQzOTA3NzcsImV4cCI6MTY4NTI1NDc3N30.5Se9vIgLFU1_tJL8wRe0EHp53ltgz6HkOF1rYZSuFMA',
        },
      })
      .then((response) => {
        packageId = response.data._id
        // Handle the API response
        //   setResponseData(response.data); // You can do something with the response data here
      })
      .catch((error) => {
        // Handle any errors that occurred during the API request
        console.error(error);
      });
  };

  const [authors, setAuthors] = useState([]);

  React.useEffect(() => {
    // Make the API call and save the data to the state
    axios.get('http://192.46.209.97:6060/api/Package/list')
      .then(response => {
        const data = response.data.results;
        setAuthors(data);
      })
      .catch(error => {
        // Handle the error
      });
  }, []);

  const handleRemove1 = async (packageId) => {
    // Make the API request
    await axios
      .delete(`http://192.46.209.97:6060/api/Package/delete/${packageId}`, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsImZpcnN0X25hbWUiOiJTaXRhcmEiLCJsYXN0X25hbWUiOiJIb3RlbCIsImVtYWlsIjoic2l0YXJhQHRlc3QuY29tIiwiaXNfcGhvbmVfdmVyaWZpZWQiOmZhbHNlLCJwaG9uZV92ZXJpZmljYXRpb24iOm51bGwsImltYWdlIjpudWxsLCJyb2xlIjpbIjY0NjRhODk5NDQ5YWUwOTA2NGU1MjZmOCJdLCJnZW5kZXIiOiJNYWxlIiwiYWRkcmVzcyI6InN0cmluZyIsInBhc3N3b3JkIjoiJDJiJDEwJEZYSHJSQVFPbVRKZktPZmw2djZsQmVxQS85Smg1UzRvZk1iRTdaNS9FcWRpN1ByUEk4TjRDIiwicmVzZXRQYXdvcmRUb2tlbiI6bnVsbCwicmVmcmVzaFRva2VuIjoia0kydTMxQ3NjRjVscUgwNmRUNVUyNlJ0dzFEbk1FNWVVQnN6VzRBVzBnTXdaaXRNTzdrRnozc0F5dHJpaTI4eGVSb2VsSXhIQ0xrWnFxOGxsWVZseXRSQmF6SktKUHp4dWxCQ1A3TU5GbkpPSERaZ1Mzc1VYc2l4ekhaVjRESFM0dGlRUnQ5a28yWEU4bmhaY0w0N3IxaU5QalBXa0hGRmg2N0c4SkFOUmVlTmdJdjd4dkYyV0dwcVV6Uk15NWE4UmNJWG9CV2MyZmNsanBWelRkamVXNERaSDJ4Rkp3MXlveXlyaXZ3YWNjQUlxWHhSYXpQSm9ZcW9MTkpyQTU4ajY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsIm90cCI6MTIzNCwidmVudWUiOiI2NDY0YWUwMDgxMjM4NjFhZmM5YTUyNTQiLCJwaG9uZW51bWJlciI6Ijk2NDA4NjEwNzYiLCJkb2IiOiIyMDIzLTAxLTAxVDAwOjAwOjAwLjAwMFoiLCJjcmVhdGVkQXQiOiIyMDIzLTA1LTE3VDEwOjM1OjQ0LjU5NloiLCJ1cGRhdGVkQXQiOiIyMDIzLTA1LTE4VDA2OjE5OjI2LjQzOFoiLCJfX3YiOjB9LCJpYXQiOjE2ODQzOTA3NzcsImV4cCI6MTY4NTI1NDc3N30.5Se9vIgLFU1_tJL8wRe0EHp53ltgz6HkOF1rYZSuFMA"
        },
      })
      .then((response) => {
        // Handle the API response
        // Perform any necessary actions after the package is successfully deleted
      })
      .catch((error) => {
        // Handle any errors that occurred during the API request
        console.error(error);
      });
  };



  return (
    <>
      <div className="app-page-user-registration">
        <div className="app-card">
          <h1 className="app-card__title">Packages</h1>
          <form onSubmit={handleSubmit}>
            <div className="app-card__content">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} sm={6}>
                  <div className="app-form-field">
                    <TextField
                      label="Title"
                      variant="outlined"
                      placeholder="Enter Title"
                      value={title}
                      onChange={(event) => setTitle(event.target.value)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="app-form-field">
                    <TextField
                      label="Base Price"
                      type="number"
                      variant="outlined"
                      placeholder="Enter Base Price"
                      value={basePrice}
                      onChange={(event) => setBasePrice(event.target.value)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="app-form-field">
                    <TextField
                      label="Selling Price"
                      variant="outlined"
                      placeholder="Enter Selling Price"
                      value={sellingPrice}
                      onChange={(event) => setSellingPrice(event.target.value)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="app-form-field">
                    <TextField
                      label="No of Halls"
                      //   type="email"
                      variant="outlined"
                      placeholder="Enter no of Halls"
                      value={hallsNumber}
                      //   onChange={(event) => setHallsNumber(event.target.value)}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const parsedValue = parseInt(inputValue);

                        if (parsedValue <= 0) {
                          setHallsNumber("");
                        } else if (parsedValue <= 3) {
                          setHallsNumber(parsedValue.toString());
                        } else if (parsedValue <= 5) {
                          setHallsNumber("5");
                        } else if (parsedValue <= 7) {
                          setHallsNumber("7");
                        } else if (parsedValue <= 10) {
                          setHallsNumber("10");
                        } else if (parsedValue <= 20) {
                          setHallsNumber("20");
                        } else {
                          setHallsNumber("20"); // Set a default value (maximum limit) if input exceeds 20
                        }
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="app-form-field">
                    <TextField
                      label="Description"
                      //   type="password"
                      variant="outlined"
                      placeholder="Enter Description"
                      value={description}
                      onChange={(event) => setDescription(event.target.value)}
                    />
                  </div>
                </Grid>
              </Grid>
              <div className="app-card__button d-flex justify-content-center mt-20">
                <Button
                  className="app-btn app-btn-primary"
                  variant="contained"
                  type="submit"
                >
                  Submit Details
                </Button>
              </div>
            </div>
          </form>

          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Base Price</TableCell>
                  <TableCell>Selling Price</TableCell>
                  <TableCell>Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                {authors.map((author) => (
                  <TableRow key={author._id}>
                    <TableCell className="hall-cell2">
                      <div className="app-form-field">
                        <h1>{author.title}</h1>
                      </div>
                    </TableCell>
                    <TableCell className="hall-cell2">
                      <div className="app-form-field">
                        <h1>{author.base_price}</h1>
                      </div>
                    </TableCell>
                    <TableCell className="hall-cell">
                      <div className="app-form-field">
                        <h1>{author.selling_price}</h1>
                      </div>
                    </TableCell>
                    <TableCell className="hall-cell">
                      <div className="app-form-field">
                        <h1>{author.no_of_halls_access}</h1>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Stack direction="row" spacing={1}>
                          <IconButton aria-label="delete">
                            <img
                              style={{ width: "20px" }}
                              src="/assets/icons/add.png"
                            // onClick={handleAddRow}
                            />
                          </IconButton>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                          <IconButton aria-label="delete">
                            <img
                              style={{ width: "20px" }}
                              src="/assets/icons/remove.svg"
                              onClick={() => handleRemove1(author._id)}
                            />
                          </IconButton>
                        </Stack>
                      </div>
                    </TableCell>
                  </TableRow>


                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      {/* <img className="app-tree-bg" src="/assets/images/tree-bg.svg" /> */}
    </>
  )
}

export default Package