import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css"
import React from 'react'


const ImageSlider = () => {

	const images = [{
		id: 1,
		src: "assets/images/sample.mp4",
		alt: "Image 1"
	},
	{
		id: 2,
		src: "assets/images/sample.mp4",
		alt: "Image 2 "
	},
	{
		id: 3,
		src: "assets/images/sample.mp4",
		alt: "Image 3"
	}
	];


	const settings = {
		infinite: true,
		dots: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		lazyLoad: true,
		autoplay: true,
		autoplaySpeed: 10000,

	};
	return (
		<>
			<div className="imgslider">
				<Slider {...settings}>
					{images.map((item) => (
						<div key={item.id}>
							{/* <img src={item.src} alt={item.alt} /> */}
							<video src={item.src} type="video/mp4" autoPlay >
								{/* <source src={item.src} type="video/mp4"/> */}
							</video>
						</div>
					))}
				</Slider>
			</div>
		</>
	)
}
export default ImageSlider;
