import { useEffect, useState } from "react"
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import axios from "axios"
import api from '../config/backend'
import VerifiedIcon from '@mui/icons-material/Verified'
import { Grid, Typography } from '@mui/material'
import '../styles/components/superadmindashboard.scss'
import custimg from '../assets/packs/free.png'
import custim1 from '../assets/packs/pro.png'
import custim2 from '../assets/packs/preimum.png'
import custim3 from '../assets/packs/elite.png'

const steps = {
    0: "Add Company",
    1: "Add Banquet Hall",
    2: "Create Role",
    3: "Add Location",
    4: "Add a user",
    5: "Finish"
}

const columns = [
    { id: 'full_name', label: 'Name', minWidth: 170 },
    { id: 'gender', label: 'Gender', minWidth: 100 },
    {
        id: 'phonenumber',
        label: 'Phone',
        minWidth: 100,
        Element: ({ row }) => {
            return (
                <p style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                    {row.phonenumber} {row.is_phone_verified && <VerifiedIcon sx={{ color: 'green', fontSize: '14px' }} />}
                </p>
            )
        }
    },
    {
        id: 'email',
        label: 'Email',
        minWidth: 100,
        Element: ({ row }) => {
            return (
                <p style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                    {row.email} {row.email_verification.status && <VerifiedIcon sx={{ color: 'green', fontSize: '14px' }} />}
                </p>
            )
        }
    },
    {
        id: 'company',
        label: 'Company',
        minWidth: 350,
        Element: ({ row }) => {
            return (
                <div>
                    <p style={{ margin: '0px' }}>{row.company?.name}</p>
                    <p style={{ margin: '0px', fontSize: '11px', color: "gray" }}>{row.company?.address}</p>
                </div>
            )
        }
    },
    {
        id: 'activeusers',
        label: 'Active Users',
        minWidth: 80,
        Element: ({ row }) => {
            return (
                <div>
                    {row.customers.length}
                </div>
            )
        }
    },
    {
        id: 'subscription',
        label: 'Package',
        minWidth: 250,
        Element: ({ row }) => {
            return (
                <div>
                    <p style={{ margin: '0px', display: 'flex', alignItems: 'center', gap: '5px' }}>
                        {row.subscription ? row.subscription.package.title : "Free"}
                        {row.subscription ? <span style={{  fontSize: '10px', color: "rgb(55, 55, 55)", background: "#edd24b", padding: '2px 5px', borderRadius: "2px" }}>
                            {row.subscription?.package.plan}ly
                        </span> : undefined}
                    </p>
                    <div style={{ fontSize: "10px", marginTop: "3px", marginBottom: "3px" }}>Expires: {row.subscription ? new Date(row.subscription.end).toDateString() : "Never Expires"}</div>
                    <div style={{ width: "fit-content", fontSize: '10px', background: row.subscription ? (row.ispackageactive ? 'green' : 'grey') : "green", color: 'white', padding: '2px 5px' }}>
                        {row.subscription ? (row.ispackageactive ? 'Active' : 'Inactive') : "Active"}
                    </div>
                </div>
            )
        }
    },
    {
        id: 'lastlogin',
        label: 'Last Login',
        minWidth: 80,
        Element: ({ row }) => {
            return (
                <div style={{ width: '150px', textAlign: 'center', textWrap: "nowrap", padding: "4px", fontSize: "12px" }}>
                    {row.lastlogin ? new Date(row.lastlogin).toLocaleTimeString('en-US', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true
                    }) : "N/A"}
                </div>
            )
        }
    },
    {
        id: 'onboarding',
        label: 'Onboarding',
        minWidth: 80,
        Element: ({ row }) => {
            return (
                <div style={{ width: '80px', textAlign: 'center' }}>
                    <p style={{ background: row.onboarding ? row.onboardingstep > 0 ? '#F9C623' : '#D24545' : '#008000', borderColor: row.onboarding ? row.onboardingstep > 0 ? '#f0d911' : '#cc471f' : '#32f011', padding: '5px 5px', fontSize: '12px', color: '#FFF', fontWeight: '500', borderRadius: '3px', textWrap: "nowrap" }}>
                        {row.onboarding ? row.onboardingstep > 0 ? 'In Progress' : 'Registered' : 'Completed'}
                    </p>
                </div>
            )
        }
    },
    {
        id: 'onboardingstep',
        label: 'Onboarding Step',
        minWidth: 80,
        Element: ({ row }) => {
            return (
                <div style={{ width: '150px', textAlign: 'center', textWrap: "nowrap", backgroundColor: "rgb(240, 240, 240)", padding: "4px", fontSize: "12px" }}>
                    {steps[row.onboardingstep]}
                </div>
            )
        }
    }
]

export default function SuperAdminDashboard() {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [rows, setRows] = useState([])
    const [usersCount, setUsersCount] = useState({
        free: 0,
        pro: 0,
        premium: 0,
        elite: 0
    })


    const token = localStorage.getItem("token")

    async function fetchData() {
        try {
            const reponse = await axios.post(`${api}/Admin/organizations`, {
                token
            })

            const customers = reponse.data.customers

            const count = {
                "Free": 0,
                "Professional": 0,
                "Premium": 0,
                "Elite": 0
            }

            customers.forEach(item => {
                // Subscription
                if (item.subscription) {
                    const packagename = item.subscription.package.title

                    const today = new Date()
                    const expires = new Date(item.subscription.end)

                    if (today < expires) {
                        count[packagename] = count[packagename] + 1
                    }
                }
                // Free package
                else {
                    count["Free"] = count["Free"] + 1
                }
            })

            setUsersCount(count)
            setRows(customers)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    return (
        <div style={{ width: '95%', height: '80vh', margin: '1% auto', padding: '30px' }}>
            <div className='registerd_organisations'>
                <div className='customers'>
                    <h2>Dashboard</h2>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6} lg={3} xl={3} >
                            <div className='registerd_organisations_box'>
                                <img src={custimg} />
                                <h4>Free</h4>
                                <p>{usersCount.Free}</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3} xl={3} >
                            <div className='registerd_organisations_box pro'>
                                <   img src={custim1} />
                                <h4>Professional</h4>
                                <p>{usersCount.Professional}</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3} xl={3} >
                            <div className='registerd_organisations_box premium'>
                                <img src={custim2} />
                                <h4>Premium</h4>
                                <p>{usersCount.Premium}</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3} xl={3} >
                            <div className='registerd_organisations_box elite'>
                                <img src={custim3} />
                                <h4>Elite</h4>
                                <p>{usersCount.Elite}</p>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className='register_organisation_heading'>Registerd organisations</div>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 500 }}>
                    <Table className='registered_table' stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            {columns.map((column) => {
                                                const value = row[column.id]
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.Element ? <column.Element row={row} /> :
                                                            column.format && typeof value === 'number'
                                                                ? column.format(value)
                                                                : value
                                                        }
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    )
}