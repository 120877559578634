import React, { useEffect, useState } from 'react'
import './css/LoginPage.css'
import StepperHeader from '../components/StepperHeader'
import { Box, Select, Button, Card, CardActions, CardContent, CircularProgress, CssBaseline, Grid, IconButton, InputAdornment, Paper, TextField, Typography, MenuItem, InputLabel, FormControl } from '@mui/material'
import img from "../assets/LoginImage.png"
import Slider from 'react-slick'
import logo from "../assets/google.png"
import { useNavigate } from 'react-router-dom'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import axios from 'axios'
import api from '../config/backend'
import { toast } from 'react-toastify'

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
}

const SignUpPage = () => {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmpassword, setConfirmPassword] = useState("")
    const [phone, setPhone] = useState("")
    const [passmatch, setPassmatch] = useState(true)
    const [selectedpackage, setSelectedpackage] = useState(undefined)
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [loading, setloading] = useState(false)
    const [gender, setGender] = useState(null)

    const navigate = useNavigate()

    const queryParams = new URLSearchParams(window.location.search)

    const packagehash = queryParams.get("package") ? queryParams.get("package") : localStorage.getItem("packagehash")

    const handleTogglePasswordVisibility = () => {
        setPasswordVisible((prevPasswordVisible) => !prevPasswordVisible)
    }

    const numberToInternational = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    const handleLogin = () => {
        navigate("/loginPage")
    }

    const createDefaultRoles = async (token) => {
        await axios.post(`${api}/Role/create`, {
            roles: ["Manager", "Executive"],
        }, {
            headers: {
                'accept': 'application/json',
                'authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })

        await axios.post(`${api}/Role/set-defaults`, { token })
    }

    const handleSubmit = async (event) => {
        setloading(true)
        event.preventDefault()
        const dataa = {
            full_name: name,
            email,
            password,
            phone,
            gender,
            packagehash: selectedpackage.hash
        }
        if (confirmpassword === "" || password === "") {
            toast.error('enter password filed')
        }

        if (password === confirmpassword) {
            const { data, status } = await axios.post(`${api}/Author/register-user`, dataa)
            if (data.success) {
                localStorage.setItem("token", data.token)
                await createDefaultRoles(data.token)
                toast.success(data.message)
                navigate("/email-verification")
            } else {
                toast.error(data.message)
            }
        } else {
            toast.error('confirm password should be same')
        }


        setloading(false)

    }

    const init = async () => {
        const { data, status } = await axios.get(`${api}/Package/fetch-packages`)

        if (data.success === false) {
            toast.error(data.message)
        } else {
            const selected = data.packages.filter(item => {
                if (packagehash) {
                    return item.hash === packagehash
                }

                return item.hash === "19e79f4f-4bd4-496a-a4d5-2738bac3c74c"
            })

            if (selected.length) {
                setSelectedpackage(selected[0])
            }
        }

        // Set in local storage
        // Since token might be from the query params
        packagehash && localStorage.setItem("packagehash", packagehash)
    }

    useEffect(() => {
        init()
    }, [packagehash])

    console.log({ selectedpackage })

    return (
        <div className='_2nye'>
            <StepperHeader />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={8} className='image'>
                    <Slider {...settings} >
                        <div>
                            <img src={img} alt="Slide 1" />
                        </div>
                        <div>
                            <img src={img} alt="Slide 2" />
                        </div>
                        <div>
                            <img src={img} alt="Slide 3" />
                        </div>
                    </Slider>
                    <div className='need-help-main'>
                        <Grid container className='need-help'>
                            <Grid item xs={6} md={8} className='needhelp-left'>
                                <h3>Need Help?</h3>
                                <p>Get the information you're looking for right now</p>
                            </Grid>
                            <Grid item xs={6} md={4} className='contactttus'>
                                <a href='mailto:info@skoltech.in'>
                                    <button>Contact Us</button>
                                </a>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} component={Paper} elevation={6} square className='paper'>
                    <div>
                        <Typography variant="h5" className='signup'>Sign Up</Typography>
                        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                            Register for a new account
                        </Typography>
                        <Card sx={{ minWidth: 250 }}>
                            <CardContent>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary">
                                    {selectedpackage ? selectedpackage.title : undefined}
                                </Typography>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    Price
                                </Typography>

                                <Typography variant="h5" component="div">
                                    {selectedpackage ? `₹ ${numberToInternational(selectedpackage.price)}` : undefined}
                                </Typography>
                                <Typography variant="body2">
                                    {selectedpackage ? `Max banquet count : ${selectedpackage.maxbanquetcount}` : undefined}
                                    <br />
                                    {selectedpackage ? `Number of users : ${selectedpackage.maxuserscount}` : undefined}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button onClick={() => navigate("/", { state: { scroll: true } })} size="small">Change Plan</Button>
                            </CardActions>
                        </Card>
                        <CssBaseline />
                        <form className="form" onSubmit={handleSubmit} >
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="name"
                                label="Enter your name"
                                name="name"
                                autoComplete="name"
                                autoFocus
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <FormControl>
                            <InputLabel id="demo-simple-select-autowidth-label">Gender</InputLabel>
                            <Select
                                sx={{ width: "454px" }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={gender}
                                label="Gender"
                                onChange={e => setGender(e.target.value)}
                            >
                                <MenuItem value="Male">Male</MenuItem>
                                <MenuItem value="Female">Female</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                            </Select>
                            </FormControl>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="email"
                                label="Enter your email"
                                name="email"
                                autoComplete="email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="contact"
                                label="Enter your mobile number"
                                name="contact"
                                autoComplete="contact"
                                onChange={(e) => setPhone(e.target.value)}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="password"
                                label="Create password"
                                onChange={(e) => setPassword(e.target.value)}
                                type={passwordVisible ? 'text' : 'password'}
                                id="password"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                                                {passwordVisible ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                autoComplete="new-password"
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="confirmPassword"
                                label="Confirm Password"
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                type={passwordVisible ? 'text' : 'password'}
                                id="confirmPassword"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                                                {passwordVisible ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                autoComplete="new-password"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className="submit"
                            >
                                {loading === false ? "continue" : <Box sx={{ display: 'flex', color: "white", }}>
                                    <CircularProgress size={20} />
                                </Box>}
                            </Button>
                        </form>
                        <Typography variant="body2" className='already-have' color="textSecondary" align="center" gutterBottom>
                            Already have an account? <span className='loggedin'><Button onClick={handleLogin}>Log In</Button></span>
                        </Typography>
                        <Typography className='continue-with' variant="body2" color="textSecondary" align="center" gutterBottom>
                            Or Continue with
                        </Typography>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            className="google"
                            startIcon={<img src={logo} alt="Google" className="google-icon" />}
                        >
                            Continue with Google
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default SignUpPage