import { useState } from "react";
import "./css/LoginPassword.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import api from "../config/backend";
import { useEffect } from "react";
import { Icon } from "@mui/material";
import { FaEye, FaEyeSlash } from "react-icons/fa";


const LoginPassword = ({ username }) => {
  const [focused, setFocused] = useState(false);
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  // password showing function
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const navigate = useNavigate();

  useEffect(() => {
    let timeout;

    if (message) {
      timeout = setTimeout(() => {
        setMessage(undefined);
      }, 5000);
    }

    return () => clearTimeout(timeout);
  }, [message]);

  const handleLogin = async () => {
    const response = await axios.post(`${api}/Author/sign-in`, {
      username,
      password,
    });

    if (response.data.success) {
      localStorage.setItem("token", response.data.token);
      navigate("/dashboard");
    } else {
      setMessage(response.data.message);
    }
  };

  return (
    <div className="_9wkb">
      <div className="container">
        <div className="Logo">Logo</div>
        <div className="pythonic-ran">{username}</div>
        <div className="runnier-cap">
          <span className="big">Enter password</span>
          <span>to finish signing in</span>
        </div>
        <div className="agonised-men">
          <div className="container">
            <span className={focused || password ? "focused" : undefined}>
              Password
            </span>
            <input
              className={showPassword ? "Textfont" : "Passfont"}
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
            />
            <button className="Iconeye" onClick={handleTogglePassword}>
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          {message ? <div className="recipes-pis">{message}</div> : undefined}
          <button onClick={() => handleLogin()}>Login</button>
          <span className="firedamp-tag" onClick={() => navigate("/Password")}>Forgotten password?</span>
        </div>
      </div>
    </div>
  );
};

export default LoginPassword;
