import React from 'react';
import { Grid, Typography } from '@mui/material';
import "./css/Footer.css";
import logo from "../assets/Logo.png"
import { Link, useNavigate } from 'react-router-dom'


export const Footer = () => {
    const navigate = useNavigate()

    return (
        <div>
            <div className='bg-white '>
                <Grid container alignItems={'flex-start'} spacing={2} >
                    <Grid className="_5zsw" item xs={4}>
                        <img style={{ all: "unset", height: "50px" }} className="image" src={logo} />
                    </Grid>
                    <Grid className="_8vxq" item xs={8}>
                        <Grid item xs={12} className='dec'>
                            <ul>
                                <li>
                                    <h5>Features</h5>
                                </li>
                                <li>
                                    <Link> How it works</Link>
                                </li>
                                <li>
                                    <Link> For Teams</Link>
                                </li>
                                <li>
                                    <Link> Pricing</Link>
                                </li>
                            </ul>
                        </Grid>


                        <Grid item xs={12} className='dec'>

                            <ul>
                                <li>
                                    <h5>User Agreement</h5>
                                </li>
                                <li>
                                    <Link to="/privacy_policy">Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link to="/terms">Terms and Conditions</Link>
                                </li>
                                <li onClick={() => navigate("/terms", { state: { scroll: "cancellation" } })}>
                                    <Link to="#">Cancellation and Refund</Link>
                                </li>
                            </ul>
                        </Grid>


                        <Grid item xs={12} className='dec'>

                            <ul>
                                <li>
                                    <h5>Company</h5>
                                </li>
                                <li>
                                    <Link> About Us</Link>
                                </li>
                                <li>
                                    <Link> Careers</Link>
                                </li>
                                <li>
                                    <Link> Inspirations Hub</Link>
                                </li>
                                <li>
                                    <Link>Press</Link>
                                </li>
                            </ul>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div className='bg-col'>
                <div className="footer-container">
                    <Typography variant="body1" className="footer-text">
                        2023 venuemanagement. All Rights Reserved
                    </Typography>
                    <div className="social-media-icons">
                        <Link>  <img src="Images/facebook.png" /></Link>
                        <Link><img src="Images/instagram.png" /></Link>
                        <Link><img src="Images/linkedin.png" /></Link>
                        <Link><img src="Images/twitter.png" /></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;