import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';


const names = [
    'Option 1',
    'Option 2',
    'Option 3',
    'Option 4',
    'Option 5',
  ];

function CancelGuest (){
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);
  
    const handleChange2 = (event) => {
      const {
        target: { value },
      } = event;
      setPersonName(
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
      setAge(event.target.value);
    };
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    return(
        <>
            <div className="app-page-banquets-management">
                <div className="app-card">
                    <h1 className="app-card__title">Cancel Guest Report</h1>
                    <div className="app-card__content">
                          <Grid container style={{marginTop:'20px'}}>
                            <Grid item xs={12} md={6}>
                                <Grid container rowSpacing={2} columnSpacing={5}>
                                    <Grid item xs={12} md={6}>
                                    <div className="app-form-field">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker 
                                    label="Event From Date"
                                    />
                                  </LocalizationProvider>
                                    </div>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                    <div className="app-form-field">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker 
                                    label="Event From Date"
                                    />
                                  </LocalizationProvider>
                                    </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                        </Grid>
                        <Grid container style={{marginTop:'20px'}}>
                            <Grid item xs={12} md={6}>
                                <Grid container rowSpacing={2} columnSpacing={5}>
                                    <Grid item xs={12} md={6}>
                                    <div className="app-form-field">
                                    <Select
                                        displayEmpty
                                        value={personName}
                                        onChange={handleChange2}
                                        input={<OutlinedInput />}
                                        renderValue={(selected) => {
                                            if (selected.length === 0) {
                                            return <em>Guest Name</em>;
                                            }

                                            return selected.join(', ');
                                        }}
                                        MenuProps={""}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                        {names.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}
                                                style={(name, personName, theme)}
                                                >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                    <div className="app-form-field">
                                    <Select
                                        displayEmpty
                                        value={personName}
                                        onChange={handleChange2}
                                        input={<OutlinedInput />}
                                        renderValue={(selected) => {
                                            if (selected.length === 0) {
                                            return <em>Cancle Reason</em>;
                                            }

                                            return selected.join(', ');
                                        }}
                                        MenuProps={""}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                        {names.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}
                                                style={(name, personName, theme)}
                                                >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                                    </Grid>
                                </Grid>
                                <div className="app-card__button d-flex justify-content-center mt-20 w-full app-new-app-card__button">
                            <Button className="app-btn app-btn-primary app-btn2-primary" variant="contained">Reset</Button>
                            <Button style={{marginLeft:'20px'}} className="app-btn app-btn-primary" variant="contained">Submit</Button>
                        </div>
                            </Grid>
                            
                        </Grid>
                    </div> 
                </div>
                <div className="app-component-table app-new-component-table app-new-component-table2">
                <div className="app-component-table__header">
                        <h2>Search Result</h2>
                    </div>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Booking ID</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Company</TableCell>
                                    <TableCell>Booking Type</TableCell>
                                    <TableCell>From Date</TableCell>
                                    <TableCell>To Date</TableCell>
                                    <TableCell>Reference</TableCell>
                                    <TableCell>Reason</TableCell>
                                    <TableCell>Deleted On</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                    <TableRow>
                                        <TableCell>1234</TableCell>
                                        <TableCell>Sravan</TableCell>
                                        <TableCell>Asian Paints</TableCell>
                                        <TableCell>
                                        <div className="app-table-status">
                                                <img src="/assets/icons/success-check.svg"/>
                                                <span>Confirmed</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>25-04-2023</TableCell>
                                        <TableCell>26-04-2023</TableCell>
                                        <TableCell>Swaroop</TableCell>
                                        <TableCell>Not yet Confirmed</TableCell>
                                        <TableCell>28-05-2023</TableCell>
                                    </TableRow>
                            </TableBody>
                            <TableBody>
                                    <TableRow>
                                        <TableCell>1234</TableCell>
                                        <TableCell>Sravan</TableCell>
                                        <TableCell>Asian Paints</TableCell>
                                        <TableCell>
                                        <div className="app-table-status">
                                                <img src="/assets/icons/success-check.svg"/>
                                                <span>Confirmed</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>25-04-2023</TableCell>
                                        <TableCell>26-04-2023</TableCell>
                                        <TableCell>Swaroop</TableCell>
                                        <TableCell>Not yet Confirmed</TableCell>
                                        <TableCell>28-05-2023</TableCell>
                                    </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            <img className="app-tree-bg" src="/assets/images/tree-bg.svg"/>
        </>
    )
}
export default CancelGuest;