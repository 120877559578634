import React, { useEffect, useState } from 'react'
import "./css/EmailVerification.css"
import axios from "axios"
import api from "../config/backend"
import { Link, useNavigate } from "react-router-dom"
import { Box, CircularProgress } from '@mui/material'
import StepperHeader from "../components/StepperHeader"
import Logo from "../assets/Logo.png"

const EmailVerification = () => {
    const [otp, setOtp] = useState('')
    const [error, setError] = useState('')
    const [succes, setsucces] = useState('')
    const [redirect, setRedirect] = useState(false)

    const [loading, setloading] = useState(false)


    const token = localStorage.getItem("token")
    const packagehash = localStorage.getItem("packagehash")

    const navigate = useNavigate()

    useEffect(() => {
        let timeout

        if (redirect) {
            timeout = setTimeout(() => {
                if (packagehash === "7e956eaa-c3a0-4a73-82b7-36e41810e2df" || packagehash === "e982c376-099f-4d6a-830e-ab4d6cf4a599" || packagehash === null) {
                    localStorage.removeItem("token")
                    localStorage.removeItem("packagehash")
                    navigate("/loginPage")
                } else {
                    navigate('/confirm-package')
                }
            }, 3000)
        }

        return () => clearTimeout(timeout)
    }, [redirect])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setloading(true)
        const { data, status } = await axios.post(`${api}/Author/email-verification`, {
            token,
            code: otp
        })

        if (data.success) {
            setsucces(data.message)
            setRedirect(true)
        } else {
            setError(data.message)
        }
        setloading(false)
    }

    return (
        <div>
            <StepperHeader forcehideLogout={true} />
            <div className='email_div'>
                <div className='container_ele'>
                    <h1 className='Tittle'>
                        <img style={{ all: "unset", height: "50px" }} className="image" src={Logo} />
                    </h1>
                    <h1 className='Tittle' style={{ color: "#F9C623", fontSize: "18px", marginBottom: "20px" }} >Email verification</h1>
                    <span style={{ marginBottom: "20px", fontWeight: "500", width: "330px", lineHeight: "20px", color: "#435B66", fontSize: "13px" }}>Please enter the verification code received on registered e-mail</span>
                    <form className='form_ele' onSubmit={handleSubmit}>
                        <input type="text" placeholder='Enter OTP here' value={otp} onChange={e => setOtp(e.target.value)} />
                        {error && <div className="error">{error}</div>}
                        {succes && <div className="succes">{succes}</div>}
                        <button className='btn-ele' type="submit">{loading ? <Box sx={{ display: 'flex', color: "white" }}>
                            <CircularProgress size={20} />
                        </Box> : "Veriy OTP"}</button>
                        <div style={{ display: "flex", justifyContent: "center", fontSize: "12px", fontWeight: 400, fontFamily: "Roboto", marginBottom: "30px", marginTop: "-7px" }}>
                            <div style={{ color: "rgb(249, 198, 35)", textDecoration: "underline", cursor: "pointer" }} onClick={() => {
                                localStorage.removeItem("token")
                                navigate("/signupPage")
                            }}>Change E-mail</div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EmailVerification
