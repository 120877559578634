import { Button, Grid, TextField, Tooltip, Typography } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { useState } from "react";
import axios from "axios";
import api from '../config/backend'
import { toast } from "react-toastify"


export default function SupportPopup({setHelpModal}){

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [company, setCompany] = useState('');
    const [description, setDescription] = useState('');

    const token = localStorage.getItem("token")

    async function postSupportTicket(){
        try{
            const reponse = await axios.post(`${api}/Support/create-ticket`,{
                token,
                name,
                email,
                phonenumber:phone,
                company,
                description
            })
            console.log(reponse);
            toast.success('sent the ticket successfully');
            setHelpModal(false);
        }catch(error){
            toast.error(error);
        }
    }

    function handleSubmit (){
        if(!name | !email | !description){
            toast.error('please enter all the required feilds')
            return
        }else if(name && /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)){
            postSupportTicket();
        }else{
            toast.error('please enter a valid email');
        }
    }

    console.log(name,company);
    return(
        <Grid container sx={{backgroundColor:'white', width:'70%', borderRadius:'15px', padding:'20px'}} onClick={(e) => e.stopPropagation()}>
            <Grid item xs={12} sx={{marginLeft:'25px'}}>
                <Typography variant="h6" mb='5px' color='#F9C623'>Raise a Ticket</Typography>
            </Grid>
            <Grid item xs={6} sx={{padding:'10px 20px'}}>
                <div className="app-form-field" style={{position:'relative'}}>
                    <TextField
                        label="name"
                        required
                        variant="outlined"
                        placeholder="Enter contact email"
                        value={name}
                        onChange={(e) =>
                        	setName(e.target.value)
                        }
                    />
                </div>
            </Grid>
            <Grid item xs={6} sx={{padding:'10px 20px'}}>
                <div className="app-form-field" style={{position:'relative'}}>
                    <TextField
                        type="email"
                        required
                        label="Email"
                        variant="outlined"
                        placeholder="Enter contact email"
                        value={email}
                        onChange={(e) =>
                        	setEmail(e.target.value)
                        }
                    />
                    <Tooltip title='This email will be used for further conversation' sx={{position:'absolute', right:'6px', top:'8px', color:'gray'}}>
                        <InfoIcon/>
                    </Tooltip>
                </div>
            </Grid>
            <Grid item xs={6} sx={{padding:'10px 20px'}}>
                <div className="app-form-field" style={{position:'relative'}}>
                    <TextField
                        type="number"
                        label="Phone"
                        variant="outlined"
                        placeholder="Enter contact email"
                        value={phone}
                        onChange={(e) =>
                        	setPhone(e.target.value)
                        }
                    />
                </div>
            </Grid>
            <Grid item xs={6} sx={{padding:'10px 20px'}}>
                <div className="app-form-field" style={{position:'relative'}}>
                    <TextField
                        label="Company"
                        variant="outlined"
                        placeholder="Enter contact email"
                        value={company}
                        onChange={(e) =>
                        	setCompany(e.target.value)
                        }
                    />
                </div>
            </Grid>
            <Grid item xs={12}  sx={{padding:'10px 20px'}}>
                <div className="app-form-field">
                    <TextField
                        label="Description"
                        required
                        variant="outlined"
                        placeholder="Please enter the issue you are facing, we will look into it as soon as possible"
                        multiline
                        rows={5}
                        value={description}
                        onChange={(e) =>
                        	setDescription(e.target.value)
                        }
                    />
                </div>
            </Grid>
            <Grid item xs={4} sx={{marginLeft:'10px'}}>
                <Button className="app-btn app-btn-primary" variant="contained" sx={{width:'150px'}}
                onClick={handleSubmit}>Submit</Button>
            </Grid>
        </Grid>
    )
}