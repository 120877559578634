import React, { useEffect, useState } from 'react'
import api from '../config/backend'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import image from "../assets/original.webp"
import StepperHeader from "../components/StepperHeader"

const PaymentSuccess = () => {
    const navigate = useNavigate()

    useEffect(() => {
        const timeout = setTimeout(() => {
            navigate("/loginPage")
        }, 5000)

        return () => clearTimeout(timeout)
    }, [])

    return (
        <div>
            <StepperHeader forcehideLogout={true} />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img style={{ width: 700, height: 500 }} src={image} alt='image' />
                <div>
                    <h1 style={{ color: 'green' }}>Payment Successful</h1>
                    <a href="/loginPage" style={{ color: "grey", fontWeight: "500", fontsize: "14px" }}>Redirecting to login page</a>
                </div>
            </div>
        </div>
    )
}

export default PaymentSuccess