import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import axios from 'axios'
import { useEffect, useState, useContext } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import api from '../../config/backend'
import TablePagination from "@mui/material/TablePagination"
import { toast } from 'react-toastify'
import BookingEdit from '../../components/BookingEdit'
import { AccessContext } from "../../App"
import dayjs from "dayjs"

const names = [
	'Option 1',
	'Option 2',
	'Option 3',
	'Option 4',
	'Option 5',
]

function BookingSearch() {
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}
	const theme = useTheme()
	const [personName, setPersonName] = useState([])

	const { state, actions } = useContext(AccessContext)

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: "1200px",
		height: "100vh",
		bgcolor: 'transparent',
		p: 2,
		overflowY: "scroll"
	}

	const [age, setAge] = useState('')

	const handleChange = (event) => {
		setAge(event.target.value)
	}
	const label = { inputProps: { 'aria-label': 'Checkbox demo' } }

	const [eventFromDate, setEventFromDate] = useState(null)
	const [eventToDate, setEventToDate] = useState(null)
	const [phoneNumber, setPhoneNumber] = useState("")
	const [alternativePhoneNumber, setAlternativePhoneNumber] = useState('')

	const [halltype, setHalltype] = useState([])
	const [selectedHalltype, setSelectedHalltype] = useState('')

	const token = localStorage.getItem("token")


	const fetchHalltype = async () => {

		const { data, status } = await axios.post(`${api}/HallType/get-halls`, { token })
		if (data.success) {
			setHalltype(data.halls)
		} else {
			console.error(data.message)
		}
	}

	const [bookingData, setBookingData] = useState([])

	const [filteredbookings, setFilteredbookings] = useState([])
	const [bookings, setBookings] = useState([])
	const [search, setSearch] = useState("")
	const [open, setOpen] = useState(false)
	const [edit, setEdit] = useState(false)
	const [reason, SetReason] = useState("")
	const [email, setEmail] = useState("")
	const [identifier, setIdentifier] = useState(undefined)
	const [company, setCompany] = useState(undefined)

	const currentdate = new Date()

	const today = new Date(currentdate.getFullYear(), currentdate.getMonth(), currentdate.getDate())

	const handleClose = () => {
		setOpen(false)
	}
	const handleEditClose = () => {
		setEdit(false)
	}

	const handleEditOpen = (bookingid) => {
		setEdit(bookingid)
		console.log({ bookings, bookingid })
		const selectedbooking = bookings.filter(item => item._id === bookingid)
		console.log({ selectedbooking })
	}

	const fetchCompany = async () => {
		const { data, status } = await axios.post(`${api}/company/get-company`, {
			token,
		})

		if (data.success) {
			setCompany(data.company)
		} else {
			toast.error(data.message)
		}
	}

	const fetchBookings = async () => {
		try {
			const { data, status } = await axios.post(`${api}/Booking/get-bookings`, {
				token,
			})

			if (data.success) {
				// Show only future events
				// const futureevents = data.bookings.filter(item => {
				//   // Banquet hall was deleted
				//   if (item.banquethall === null) {
				//     return false
				//   }
				//   const bookingdate = new Date(item.date)
				//   return bookingdate >= today
				// })

				setBookings(data.bookings)
			} else {
				toast.error(data.message)
			}
		} catch (error) {
			console.error(error)
		}
	}

	useEffect(() => {
		// Fetch bookings when edit popup is closed
		if (edit === false) {
			fetchBookings()
		}
	}, [edit, company])
	const handleSubmit = async () => {
		const dataa = {
			token,
			reason,
			identifier,
		}
		const { data, status } = await axios.post(
			`${api}/Booking/cancel-booking`,
			dataa
		)
		if (data.success) {
			toast.success(data.message)
			setOpen(false)
			fetchBookings()
		} else {
			toast.error(data.message)
		}
	}
	const handleClickOpen = (id) => {
		setOpen(true)
		setIdentifier(id)
	}

	const handleFilter = () => {
		let eventfrom, eventto

		if (eventFromDate) {
			eventfrom = new Date(eventFromDate)
		}
		if (eventToDate) {
			eventto = new Date(eventToDate)
		}

		setFilteredbookings(
			bookings.map((booking) => {
				let filtered = { ...booking }
				filtered.events = booking.events.filter((event) => {

					const bookingdate = new Date(event.date)

					return booking.phonenumber.includes(phoneNumber)
						&& (personName ? booking.contact_person_name.includes(personName) : true)
						&& (email ? booking.contact_person_email.includes(email) : true)
						&& (selectedHalltype ? event.banquet.hall_type === selectedHalltype : true)
						&& (eventfrom ? bookingdate >= eventfrom : true)
						&& (eventto ? bookingdate <= eventto : true)
						&& bookingdate >= today
				})
				return filtered
			}).filter((booking) => {
				return booking.events.length > 0
			})
		)
	}

	useEffect(() => {
		if (bookings.length) {
			setFilteredbookings(bookings)
		}
	}, [bookings])

	const fetchData = async () => {
		try {

			let params = {}

			if (eventFromDate && eventToDate) {
				params.event_from_date = eventFromDate
				params.event_to_date = eventToDate
			}

			if (phoneNumber) {
				params.phonenumber = phoneNumber
			}

			if (alternativePhoneNumber) {
				params.alternative_phonenumber = alternativePhoneNumber
			}

			if (selectedHalltype) {
				params.hall_type = selectedHalltype
			}

			const response = await axios.get(`${api}/Booking/list`, {
				params,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			})
			//   bookingData(response.data.results)
			setBookingData(response.data.results)
			// Process the response data as needed
			console.log(response.data)
		} catch (error) {
			console.error(error)
		}
	}

	const logger = async () => {
		const link = window.location.href

		const { data, status } = await axios.post(`${api}/Logs/viewed-page`, { token, link })
	}

	useEffect(() => {
		fetchData()
		fetchHalltype()
		fetchCompany()
		logger()
	}, [])

	const handleReset = () => {
		setPhoneNumber('')
		setAlternativePhoneNumber('')
		setPersonName("")
		setEventFromDate(null)
		setEventToDate(null)
		setSelectedHalltype('')
		setFilteredbookings(bookings)
		setEmail("")
	}

	console.log({ filteredbookings, bookings })

	const editpermission = state && state.access && state.access.booking.edit
	const deletepermission = state && state.access && state.access.booking.delete
	console.log(filteredbookings)

	return (
		<>
			<div className="_6tmr app-page-banquets-management">
				<div className="app-card">
					<h1 className="app-card__title">Booking Search</h1>
					<div className="app-card__content">
						<Grid className="_8ghm" container rowSpacing={2} columnSpacing={5}>
							<Grid item xs={12} md={4}>
								<div className="app-form-field">
									{/* <TextField label="Phone" variant="outlined" placeholder="Enter Phone" /> */}
									<TextField
										type="number"
										label="Phone" variant="outlined"
										placeholder="Enter Phone"
										value={phoneNumber}
										onChange={(e) => setPhoneNumber(Number(e.target.value))}
									/>
								</div>
							</Grid>
							<Grid item xs={12} md={4}>
								<div className="app-form-field">
									<TextField label="Person Name" variant="outlined" placeholder="Enter Contact Person Name" value={personName} onChange={(e) => setPersonName(e.target.value)} />
								</div>
							</Grid>
							<Grid item xs={12} md={4}>
								<div className="app-form-field">
									<TextField label="Enter email" variant="outlined" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
								</div>
							</Grid>
						</Grid>
						<Grid container style={{ marginTop: '20px' }}>
							<Grid item xs={12} md={12}>
								<Grid container rowSpacing={2} columnSpacing={5}>
									<Grid item xs={12} md={5}>
										<div className="app-form-field">
											<LocalizationProvider dateAdapter={AdapterDayjs}>
												<DatePicker
													value={eventFromDate}
													onChange={(date) => setEventFromDate(date)}
													renderInput={(params) => <input {...params} />}
													format="DD-MM-YYYY"
												/>
											</LocalizationProvider>
										</div>
									</Grid>
									<Grid item xs={12} md={5}>
										<div className="app-form-field">
											<LocalizationProvider dateAdapter={AdapterDayjs}>
												<DatePicker
													value={eventToDate}
													onChange={(date) => setEventToDate(date)}
													renderInput={(params) => <input {...params} />}
													format="DD-MM-YYYY"
												/>
											</LocalizationProvider>
										</div>
									</Grid>
									<Grid item xs={12} md={6}>
										<div className="app-form-field">
											<Select
												displayEmpty
												value={selectedHalltype}
												onChange={(event) => setSelectedHalltype(event.target.value)}
											>
												<MenuItem value="" disabled>
													Select Halltype
												</MenuItem>
												<MenuItem value="Indoor">Indoor</MenuItem>
												<MenuItem value="Outdoor">Outdoor</MenuItem>
											</Select>
										</div>
										<div className="app-card__button d-flex justify-content-center mt-20 w-full app-new-app-card__button">
											<Button className="app-btn app-btn-primary app-btn2-primary" variant="contained" onClick={handleReset}>Reset</Button>
											<Button style={{ marginLeft: '20px' }} className="app-btn app-btn-primary" variant="contained" onClick={handleFilter}>Submit</Button>
										</div>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</div>
				</div>
				<div className="app-component-table app-new-component-table app-new-component-table2">
					<div className="app-component-table__header">
						<h2>Search Result</h2>
					</div>
					<TableContainer>
						<Table stickyHeader>
							<TableHead>
								<TableRow>
									<TableCell>ID</TableCell>
									<TableRow >
										<TableCell style={{ paddingRight: "100px" }}>Date</TableCell>
										<TableCell style={{ paddingRight: "80px" }}>Banquet Hall</TableCell>
										<TableCell>Hall Type</TableCell>
										<TableCell style={{ paddingRight: "30px" }}>Session</TableCell>
										<TableCell style={{ paddingRight: "90px" }}>Event</TableCell>
										<TableCell>Pax</TableCell>
										<TableCell>Expected Pax</TableCell>
									</TableRow>
									<TableCell>Customer Name</TableCell>
									<TableCell>Phone Number</TableCell>
									<TableCell style={{ paddingRight: "140px" }}>Email</TableCell>
									<TableCell>Company</TableCell>
									<TableCell style={{ minWidth: "300px" }}>Advance Paid</TableCell>
									<TableCell>Total Amount (₹)</TableCell>
									<TableCell>Booking By</TableCell>
									<TableCell>Referred By</TableCell>
									<TableCell>Status</TableCell>
									<TableCell>Remarks</TableCell>
									<TableCell style={{ paddingRight: "40px" }}>Reason for cancellation</TableCell>
									<TableCell style={{ paddingRight: "130px" }}>Actions</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{filteredbookings
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((booking) => {
										let totaladvance = 0

										if (booking.advance_paid) {
											booking.advance_paid.forEach(advance => {
												totaladvance += Number(advance.amount)
											})
										}

										return (
											<TableRow key={booking._id}>
												<TableCell style={{ fontSize: "12px" }}>
													{company && company.name.slice(0, 3).toUpperCase() + String(booking.bookingserial).padStart(5, "0")}
												</TableCell>
												{booking.events.map((event) => {
													return (
														<TableRow style={{ width: "100px" }}>
															<TableCell style={{ width: "150px" }}>
																{dayjs(event.date).format("DD-MM-YYYY")}
															</TableCell>
															<TableCell style={{ width: "200px" }}>{event.banquet.hall_title}</TableCell>
															<TableCell style={{ width: "100px" }}>{event.banquet.hall_type}</TableCell>

															<TableCell style={{ width: "100px" }}>
																<div
																	style={{
																		display: "flex",
																		gap: "20px",
																		fontWeight: "900",
																	}}
																>
																	<span>
																		{event.is_breakfast_available ? "B" : undefined}
																	</span>
																	<span>
																		{event.is_lunch_available ? "L" : undefined}
																	</span>
																	<span>
																		{event.is_dinner_available ? "D" : undefined}
																	</span>
																</div>
															</TableCell>
															<TableCell style={{ width: "150px" }}>{event.event_title}</TableCell>
															<TableCell style={{ width: "60px" }}>{event.pax}</TableCell>
															<TableCell style={{ width: "130px" }}>{event.exp_pax}</TableCell>
														</TableRow>)
												})}
												<TableCell>{booking.contact_person_name}</TableCell>
												<TableCell>{booking.phonenumber}</TableCell>
												<TableCell>
													{booking.contact_person_email}
												</TableCell>
												<TableCell>{booking.company_name}</TableCell>
												<TableCell style={{ minWidth: "300px" }}>
													{booking.advance_paid && booking.advance_paid.length ? (
														<div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
															{booking.advance_paid.map(advance => {
																return (
																	<div style={{ display: "flex", fontSize: "12px" }} key={advance._id}>
																		<div style={{ backgroundColor: "rgb(250, 235, 215)", padding: "2px 8px", marginRight: "3px" }}>{new Date(advance.date).toLocaleDateString("en-GB")}</div>
																		<div>₹{advance.amount}</div>
																		<div style={{ marginLeft: "3px" }}> - {advance.remark}</div>
																	</div>
																)
															})}
														</div>
													) : "NIL"}
												</TableCell>
												<TableCell>{totaladvance}</TableCell>
												<TableCell>{booking.booking_given_by}</TableCell>
												<TableCell>{booking.reffered_by}</TableCell>
												<TableCell>
													{booking.cancelled.status ? (
														<p style={{ color: "white", backgroundColor: "rgb(241, 87, 87)", fontSize: "12px", padding: "3px 7px", borderRadius: "4px" }}>Cancelled</p>
													) : (
														<p style={{ color: "white", backgroundColor: "rgb(71, 188, 71)", fontSize: "12px", padding: "3px 7px", borderRadius: "4px" }}>{booking.booking_type}</p>
													)}
												</TableCell>
												<TableCell>{booking.remarks}</TableCell>
												<TableCell>{booking.cancelled.reason}</TableCell>
												<TableCell>
													<div>
														<Button disabled={booking.cancelled.status} onClick={() => handleEditOpen(booking._id)}
														>
															Edit
														</Button>
														{edit === booking._id && <Modal
															keepMounted
															open={true}
															onClose={handleEditClose}
															aria-labelledby="keep-mounted-modal-title"
															aria-describedby="keep-mounted-modal-description"
														>
															<Box sx={style}>
																<BookingEdit bookingdetails={booking} setPopup={setEdit} editpermission={editpermission} />
															</Box>
														</Modal>}
														{deletepermission ? <Button disabled={booking.cancelled.status} onClick={() => handleClickOpen(booking._id)}>
															Cancel
														</Button> : undefined}
														<Dialog open={open} onClose={handleClose}>
															<DialogTitle>Reason of cancellation</DialogTitle>
															<DialogContent>
																<DialogContentText>
																	Please enter reason to cancel booking. We will
																	send updates occasionally.
																</DialogContentText>
																<TextField
																	autoFocus
																	margin="dense"
																	id="name"
																	label="Reason"
																	type="text"
																	fullWidth
																	value={reason}
																	onChange={(e) => SetReason(e.target.value)}
																	variant="standard"
																/>
															</DialogContent>
															<DialogActions>
																<Button onClick={handleClose}>Cancel</Button>
																<Button onClick={handleSubmit}>Submit</Button>
															</DialogActions>
														</Dialog>
													</div>
												</TableCell>
											</TableRow>
										)
									})}
							</TableBody>
						</Table>
						<TablePagination
							style={{ display: "flex", flexDirection: "row" }}
							rowsPerPageOptions={[10, 25, 100]}
							component="div"
							count={filteredbookings.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</TableContainer>
				</div>
			</div>
			{/* <img className="app-tree-bg" src="/assets/images/tree-bg.svg" /> */}
		</>
	)
}
export default BookingSearch