import { useState, useEffect } from "react"; 
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import SimpleBarReact from "simplebar-react";
import MenuItem from '@mui/material/MenuItem';
import axios from "axios";
import api from "../config/backend"

function BookingListMenu({ displayname, status }){
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const [bookings, setBookings] = useState([])

    const token = localStorage.getItem("token")

    const fetchList = async () => {
        const { data } = await axios.post(`${api}/Booking/bookings-status`, { token, status })

        if (data.success) {
            setBookings(data.bookings)
        }
    }

    console.log({bookings})

    useEffect(() => {
        fetchList()
    }, [])

    return(
        <>
        <div className="app-component-booking-list">
            <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
        >
            <p>{displayname} <span>{bookings.length}</span></p>
            <img src="/assets/icons/down-chevron.svg" alt=""/>
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                className="custom-menu"
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <SimpleBarReact style={{maxHeight: 200}}>
                    {bookings.map(booking => {
                        return (
                            <MenuItem>
                                <div className="app-component-booking-list-card">
                                    <div>
                                        <h4>{booking.data.contact_person_name}</h4>
                                        <p>{new Date(booking.booking.date).toDateString()}</p>
                                    </div>
                                    <ul>
                                        <li>
                                            <img src="/assets/icons/wedding.svg" alt=""/>
                                            <span style={{ display: "flex", gap: "4px" }}>
                                                {booking.booking.is_breakfast_available ? <strong>B</strong> : undefined}
                                                {booking.booking.is_lunch_available ? <strong>L</strong> : undefined}
                                                {booking.booking.is_dinner_available ? <strong>D</strong> : undefined}
                                            </span>
                                        </li>
                                        <li>
                                            <img src="/assets/icons/team.svg" alt=""/>
                                            <span>{booking.booking.pax}</span>
                                        </li>
                                    </ul>
                                </div>
                            </MenuItem>
                        )
                    })}
                </SimpleBarReact>
            </Menu>
        </div>
        </>
    )
}
export default BookingListMenu;