import React, { useState, useEffect } from "react";
import axios from "axios";
import api from "../config/backend";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { MenuItem, OutlinedInput, Select } from "@mui/material";
import { toast } from "react-toastify";

function RolePermission() {
  const [title, setTitle] = useState("");
  //   const [role, setRole] = useState('');
  const [isCreate, setIsCreate] = useState("");
  const [isView, setIsView] = useState("");
  const [isEdit, setIsEdit] = useState("");
  const [isDelete, setIsDelete] = useState("");
  const [selectedRoles, setSelectedRoles] = React.useState("");
  const [selectedRoleId,setSelectedRoleId] = React.useState("")
  const [roles, setRoles] = useState([]);

  React.useEffect(() => {
    getRolesList();
  }, []);

  const getRolesList = async () => {
    const { data, status } = await axios.get(`${api}/Role/list`);
    if (status === 200 || status === 201) {
      setRoles(data);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      title: title,
      role: selectedRoleId,
      is_create: isCreate,
      is_view: isView,
      is_edit: isEdit,
      is_delete: isDelete,
    };
    const { data, status } = await axios.post(`${api}/RolePermission/create`,payload);
    if (status === 200 || status === 201) {
      toast.success("Role permission created successfully")
    }
  };



  const [rolePermissions, setRolePermissions] = useState([]);

  useEffect(() => {
    fetchRolePermissions();
  }, []);


  const fetchRolePermissions = async () => {
    const { data, status } = await axios.get(`${api}/RolePermission/list`);
    if (status === 200 || status === 201) {
      setRolePermissions(data?.results);
    }
  };

  const deleteRolePermission = async (id) => {
    const { data, status } = await axios.get(`${api}/RolePermission/delete/${id}`);
    if (status === 200 || status === 201) {
      fetchRolePermissions();
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <label>
          Title:
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </label>
        <label>
          Role:
          {/* <input type="text" value={role} onChange={(e) => setRole(e.target.value)} /> */}
          <Select
            displayEmpty
            value={selectedRoles}
            onChange={(e) => {
              setSelectedRoleId(roles.results[roles.results.findIndex(item => item?.title == e?.target.value)]?._id)
              setSelectedRoles(e?.target.value)
            }}
            input={<OutlinedInput />}
            renderValue={(selected) => {
              if (!selected) {
                return <em>select role</em>;
              }
              return selected;
            }}
            inputProps={{ "aria-label": "Without label" }}
          >
            {roles?.results?.map((role) => (
              <MenuItem key={role._id} value={role.title}>
                {role.title}
              </MenuItem>
            ))}
          </Select>
          {/* {(roles && roles.results) ? roles.results.map((role) => (
    <li className="app-check-btns-group__list__item" key={role._id}>
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            name="role"
            checked={selectedRoles.includes(role._id)}
            onChange={(event) => handleRoleChange(event, role._id)}
          />
        }
        label={role.title}
      />
    </li>
  )) : null} */}
        </label>
        create:
        <select value={isCreate} onChange={(e) => setIsCreate(e.target.value)}>
          <option value="">Select an option</option>
          <option value="true">true</option>
          <option value="false">false</option>
        </select>
        view:
        <select value={isView} onChange={(e) => setIsView(e.target.value)}>
          <option value="">Select an option</option>
          <option value="true">true</option>
          <option value="false">false</option>
        </select>
        <label>
          Edit:
          <select value={isEdit} onChange={(e) => setIsEdit(e.target.value)}>
            <option value="">Select an option</option>
            <option value="true">true</option>
            <option value="false">false</option>
          </select>
        </label>
        <label>
          Delete:
          <select
            value={isDelete}
            onChange={(e) => setIsDelete(e.target.value)}
          >
            <option value="">Select an option</option>
            <option value="true">true</option>
            <option value="false">false</option>
          </select>
        </label>
        <button type="submit">Submit</button>
      </form>
      <div>
        <h2>Role Permissions:</h2>
        <ul>
          {rolePermissions.map((permission) => (
            <li key={permission._id}>
              <p>
                Title: {permission.title}
                {console.log(permission)}
              </p>
              {/* <p>Role: {permission.role}</p>
          <p>Create: {permission.is_create.toString()}</p>
          <p>View: {permission.is_view.toString()}</p>
          <p>Edit: {permission.is_edit.toString()}</p>
          <p>Delete: {permission.is_delete.toString()}</p> */}
              <p
                onClick={() => deleteRolePermission(permission._id)}
                style={{ cursor: "pointer" }}
              >
                delete
              </p>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default RolePermission;
