
import { Grid, Card, CardContent, Typography, Button, CardMedia } from '@mui/material'
import React, { useState, useRef, useEffect } from 'react'
import { Tabs, Tab, Box } from '@mui/material'
import { Link, useLocation, useNavigate } from "react-router-dom"
import './UpgradePopup.css'
import decode from "jwt-decode"
import axios from 'axios'
import api from '../config/backend'
import Logo from "../assets/Logo.png"
import Logohead from "../assets/Header Logo.png"
import { toast } from 'react-toastify';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const UpgradePopup = ({setUpgradePopup , setRefresh}) => {

    const token = localStorage.getItem("token");
    const [decodedtoken, setDecodedtoken] = useState(undefined);
    const [successfull, setSuccessfull] = useState(false);

    const decoded = decode(token)
    const userPackage = decoded.user.ispackageactive ? decoded.user.package : undefined

    const [period,setPeriod] = useState('Month');

    const pricingRef = useRef(null)

    const navigate = useNavigate()

    const { state } = useLocation()

    useEffect(() => {
        if (state?.scroll) {
            pricingRef.current?.scrollIntoView({ behavior: 'smooth' })
        }
    }, [state])

    useEffect(() => {
        if (token === null) {
            navigate("/signup")
        } else {
            const decoded = decode(token)
            setDecodedtoken(decoded)
        }
    }, [token])


    const cardsData = [
        {
            id: 2,
            title: 'Professional',
            content: 'Professional plan for small business',
            h5: 'Lorem Ipsum',
            includes: 'Includes',
            color: '#219C90',
            rupee: '₹',
            days: period === "Month" ? "1199" : "13999",
            month: period === "Month" ? 'Month' : 'Year',
            button: 'Get Started',
            hash: period === "Month" ? "c26adfee-e521-4c0a-b46c-9cf1368db163" : "a6dd1e9e-0d82-48f8-a9ab-c45ff7d13f8e",
            features: ["4 Banquet Halls", "2 Users"]
        },
        {
            id: 3,
            title: 'Premium',
            content: 'Premium plans for medium-sized business',
            h5: 'Lorem Ipsum',
            includes: 'Includes',
            color: '#E9B824',
            rupee: '₹',
            days: period === "Month" ? '1799' : '21000',
            month: period === "Month" ? 'Month' : 'Year',
            button: 'Get Started',
            hash: period === "Month" ? "b7c93a8d-b32c-4d55-9a8d-f201579cc544" : "b7b433e6-4802-4a8f-b1ce-257dfd0c3a92",
            features: ["6 Banquet Halls", "4 Users"]
        },
        {
            id: 4,
            title: 'Elite',
            content: 'Pick elite for large size business',
            h5: 'Lorem Ipsum',
            includes: 'Includes',
            color: '#EE9322',
            days: period === "Month" ? '2100' : '24000',
            rupee: '₹',
            month: period === "Month" ? "Month" : "Year",
            button: 'Get Started',
            hash: period === "Month" ? "e5b4fe12-6b19-4f43-9a04-43646c8b4c47" : "63c8269d-857c-4d1e-8b38-9463ba2f565d",
            features: ["8 Banquet halls", "6 Users"]
        },
    ]

    const handleHash = (hash) => {
        localStorage.setItem('packagehash', hash)
        navigate("/confirm-package")
    }

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function displayRazorpay(packagehash) {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        // creating a new order
        const result = await axios.post(`${api}/order/create`, { token, packagehash });

        if (!result) {
            alert("Server error. Are you online?");
            return;
        }
        

        // Getting the order details back
        const { amount, id: order_id, currency } = result.data.order;

        const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY,
            amount: amount.toString(),
            currency: currency,
            name: "Venue Management Inc",
            description: "Transaction for venue management",
            image: { Logo },
            order_id: order_id,
            handler: async function (response) {
                const data = {
                    token,
                    orderCreationId: order_id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                };

                const result = await axios.post(`${api}/payment/verify`, data);

                if (result.data.success) {
                    // setSuccessfull(true)
                    setUpgradePopup(false)
                    localStorage.setItem('token', result.data.token)
                    setRefresh(prev => prev+1)
                    // navigate("/payment-success")
                } else {
                    toast.error(result.data.message)
                }
            },
            prefill: {
                name: decodedtoken.user.full_name,
                email:  decodedtoken.user.email,
                contact:  "91" + String(decodedtoken.user.phonenumber),
            },
            notes: {
                address: "Venue Management Corporate Office",
            },
            theme: {
                color: "#61dafb",
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    return (
        <div className="_0jor _0jor1">
            {successfull ? 
            <Typography className='upgrade_container' style={{display:'flex', justifyContent:'center', alignContent:'center'}}>
                <CheckCircleIcon style={{color:'green', fontSize:"200px", marginBottom:'50px'}}/>
                <Typography variant='h2' color={"green"}>Upgrade Successfull</Typography>
            </Typography>:
            <Typography className='upgrade_container' onClick={(e) => e.stopPropagation()}>
                <Typography style={{display:'flex', alignItems:'center', marginTop:'40px', cursor:'pointer'}} fontSize={20}>
                    <Typography variant='body1' p={1} mr={1} width={100} fontSize={20} textAlign={'center'} className={period === 'Month' ? 'selected_period':''} onClick={() => setPeriod('Month')}>
                        Month
                    </Typography> | 
                    <Typography variant='body1' p={1} ml={1} width={100} fontSize={20} textAlign={'center'} className={period === 'Year' ? 'selected_period':''} onClick={() => setPeriod('Year')}>
                        Year
                    </Typography>
                </Typography>
                <Grid container className='upgrade_grid'>
                    {cardsData.map((card) => (
                        <Grid className='Upgrade_card' style={{ filter: card.hash === userPackage ? 'grayscale(1)':''}}>
                            <Card sx={{width:'100%', height:'100%', borderRadius:'15px'}}>
                                <div style={{height:'4%', backgroundColor:card.color}}>
                                </div>
                                <CardContent style={{height:'100%', fontSize:'18px'}}>
                                    <Typography variant="h6" component="div" className='titlee'>
                                        {card.title}
                                    </Typography>
                                    <Typography variant="body2" className='content' style={{height:"8%"}}>
                                        {card.content}
                                    </Typography>
                                    <div className="days">
                                        <div className="days-container" style={{ fontSize:'28px', display: "flex", justifyContent: "center", alignItems: "center", gap: "5px" }}>
                                            <span className="half-above-line">{card.rupee}</span>
                                            <p style={{fontWeight:'600'}}>{card.days}</p>
                                            <span>|</span> 
                                            <span style={{fontSize:'12px', display:'flex', flexDirection:'column'}}>
                                                <span style={{margin:'0'}}>per</span>
                                                <span>{card.month}</span>
                                            </span>
                                            
                                        </div>
                                    </div>
                                    <Typography variant='h6' className='includes'>
                                        {card.includes}
                                    </Typography>

                                    <Typography variant="body2" className="tick-marks">
                                        {card.features.map((item, index) => (
                                            <p key={index} style={{display:'flex', alignItems:'center'}}>
                                                {/* <FiberManualRecordIcon style={{color:card.color, fontSize:'13px', marginRight:'3px'}}/> */}
                                                <span className="tick-mark" style={{marginRight:'10px'}}>
                                                    <img src="Images/Vector.png" />
                                                </span>
                                                {item}
                                            </p>
                                        ))}
                                    </Typography>
                                    <div className='card-button' style={{display:'flex', justifyContent:'center'}}>
                                        <Button disabled={card.hash === userPackage} onClick={() => displayRazorpay(card.hash)} style={{ backgroundColor:card.color, color: '#ffffff', width:'100%', borderRadius:'8px'}}>
                                            {card.hash === userPackage ? 'Subscribed' : 'Upgrade'}
                                        </Button>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <Typography variant='body1' pl={2} pr={2} fontSize={18} mb={2} onClick={() => setUpgradePopup(false)} sx={{cursor:'pointer', color:'#40A2E3'}}>
                    close
                </Typography>

            </Typography>}
        </div>
    )
}

export default UpgradePopup
