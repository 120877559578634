import "./css/BookingSearch.css"
import { useState, useEffect } from "react"
import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Button } from '@mui/material'
import SimpleBarReact from "simplebar-react"
import Popover from '@mui/material/Popover'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import axios from 'axios'
import api from "../../config/backend"
import { toast } from "react-toastify"
import "./css/BanquetInformation.css"

function BanquetInformation() {
    const [banquets, setBanquets] = useState([])

    const token = localStorage.getItem("token")

    const getBanquetList = async () => {
        const { data, status } = await axios.post(
            `${api}/BanquetHall/get-banquets`, { token }
        )
        console.log({ data })
        if (data.success) {
            setBanquets(data.banquets)
        } else {
            toast.error(data.message)
        }
    }

    const logger = async () => {
        const link = window.location.href

        const { data, status } = await axios.post(`${api}/Logs/viewed-page`, { token, link })
    }

    useEffect(() => {
        getBanquetList()
        logger()
    }, [])


    return (
        <div className="_5gxx">
            <div className="flippy-yelm">


                <div className="retake-hymn">
                    <span>Banquet Halls</span>
                </div>
                <div className="tables_div">
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ backgroundColor: "#faebd7" }}>Banquet Name</TableCell>
                                    <TableCell sx={{ backgroundColor: "#faebd7" }}>Hall Type</TableCell>
                                    <TableCell sx={{ backgroundColor: "#faebd7" }}>Area</TableCell>
                                    <TableCell sx={{ backgroundColor: "#faebd7" }}>Persons Capacity</TableCell>
                                    <TableCell sx={{ backgroundColor: "#faebd7" }}>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {banquets.map((hall, index) => {
                                    console.log({ hall })
                                    return (<TableRow key={index}>
                                        <TableCell>{hall.hall_title}</TableCell>
                                        <TableCell>{hall.hall_type}</TableCell>
                                        <TableCell>{hall.area}</TableCell>
                                        <TableCell>{hall.capacity_space}</TableCell>
                                        <TableCell>{hall.is_active ? "Active" : "Inactive"}</TableCell>
                                    </TableRow>)
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
    )
}
export default BanquetInformation