import React, { useEffect, useState } from "react";
import {
    Container,
    Typography,
    Box,
    Modal,
    TextField,
    Grid,
    Button,
    makeStyles,
    Paper,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    FormControlLabel
} from "@material-ui/core";
import Manager from "../assets/profile.png"
import User from "../assets/user.png"
import Add_role from "../assets/plus-square.png"
import axios from "axios";
import api from "../config/backend";
import { toast } from "react-toastify";
import "./css/Roles.css"

const useStyles = makeStyles((theme) => ({
    mainHeading: {
        marginBottom: theme.spacing(2),
        padding: "10px 0px 0px",
        margin: 0
    },
    subHeading: {
        marginBottom: theme.spacing(2)
    },
    roleSelectionContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: theme.spacing(4)
    },
    squareBox: {
        width: 130,
        height: 130,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 16,
        cursor: "pointer",
        transition: "background-color 0.3s ease",
        "&.selectedRole": {
            backgroundColor: "#F9C623"
        }
    },
    roleText: {
        margin: 0,
        padding: "10px 0px"
    },
    permissionsContainer: {
        marginBottom: theme.spacing(2)
    },
    permissionBox: {
        padding: theme.spacing(2),
        border: "1px solid #ccc",
        borderRadius: 8,
        marginBottom: theme.spacing(2)
    },
    permissionHeading: {
        marginBottom: theme.spacing(1),
        fontWeight: "bold"
    },
    checkBoxLabel: {
        display: "flex",
        alignItems: "center",
        fontWeight: 700
    },
    modalContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    flexboxContainer: {
        width: "30%",
        backgroundColor: "white",
        borderRadius: 16,
        padding: theme.spacing(4),
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        alignItems: "left"
    },
    modalHeading: {
        marginBottom: theme.spacing(2)
    },
    modalTextField: {
        width: "100%",
        marginBottom: theme.spacing(2)
    },
    modalButton: {
        width: "100%",
        marginBottom: theme.spacing(4),
        backgroundColor: '#F9C623',

    },

    modalPermissionTableContainer: {
        marginTop: theme.spacing(2),
        width: "100%"
    },
    modalPermissionTable: {
        width: "100%"
    },
    modalSaveButton: {
        marginTop: theme.spacing(2),
        backgroundColor: '#F9C623',

    }
}));


const Roles = () => {
    const classes = useStyles();
    const [selectedRole, setSelectedRole] = useState(null);
    const [role, setRole] = useState("");
    const [showPermissionTable, setShowPermissionTable] = useState(false);
    const [otherRoles, setOtherRoles] = useState([])

    const token = localStorage.getItem("token")

    const handleSaveAndClose = () => {
        setShowPermissionTable(false);
    };
    const handleRoleClick = (role) => {
        setSelectedRole(role);
    };

    const handleAddRole = async (title) => {
        await axios.post(`${api}/Role/create`, {
            title,
        }, {
            headers: {
                'accept': 'application/json',
                'authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })

        fetchRoles()

        setShowPermissionTable(true);
        handleClose();
    };
    const handleClose = () => {
        setSelectedRole(null);
    };

    const [roles, setRoles] = useState([])

    const fetchRoles = async () => {
        try {
            const response = await axios.post(`${api}/Role/role-access`, { token })

            if (response.data.success) {
                setRoles(response.data.roles)
            } else {
                console.error(response.data.message)
            }
        } catch (error) {
            console.error(error)
        }
    };

    const updateRole = async (title, page, permission, value) => {
        const { data, status } = await axios.post(`${api}/Role/edit-permission`, {
            token,
            title,
            page,
            permission,
            value
        });

        if (data.success) {
            console.log({ data })

            setRoles(roles.map(item => {
                if (item.title === title) {
                    return data.role
                }
                return item
            }))
        } else {
            toast.error(data.message);
        }
    };

    console.log({ roles })

    useEffect(() => {
        fetchRoles()
    }, [])

    useEffect(() => {
        if (selectedRole === null) {
            setSelectedRole("Manager")
        }
    }, [selectedRole])

    useEffect(() => {
        if (roles.length > 2) {
            setOtherRoles(roles.filter(item => ["Manager", "Executive"].includes(item.title) === false))
        }
    }, [roles])

    console.log({ otherRoles })

    // useEffect(() => {
    //   if (roles.length) {
    //     setManagerAccess(roles.filter(item => item.title === "Manager")[0].access)
    //     setExecutiveAccess(roles.filter(item => item.title === "Executive")[0].access)
    //   }
    // }, [roles])

    const pageslist = ["Customers", "Booking", "Reports"]

    return (
        <Container maxWidth="md" className="_3iuh">
            <Box mt={4}>
                <Typography variant="h5" style={{ marginBottom: "5px" }} className={classes.mainHeading}>
                    Manage roles and permissions
                </Typography>
                <Typography variant="subtitle1" className="tumour-sew">
                    Lorem Ipsum is simply dummy text
                </Typography>
                <Box className={`_5jsm ${classes.roleSelectionContainer}`}>
                    <Grid className="_6ktd" >
                        <Paper
                            className={`${classes.squareBox} ${selectedRole === "Manager" ? "selectedRole" : ""
                                }`}
                            onClick={() => handleRoleClick("Manager")}
                        >
                            <img src={Manager} alt="Manager" />
                            <Typography variant="h6" className={classes.roleText}>
                                Manager
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid className="_6ktd" >
                        <Paper
                            className={`${classes.squareBox} ${selectedRole === "Executive" ? "selectedRole" : ""
                                }`}
                            onClick={() => handleRoleClick("Executive")}
                        >
                            <img src={User} alt="Executive" />
                            <Typography variant="h6" className={classes.roleText}>
                                Executive
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid className="_6ktd" >
                        <Paper
                            className={`${classes.squareBox} ${selectedRole === "AddRole" ? "selectedRole" : ""
                                }`}
                            onClick={() => handleRoleClick("AddRole")}
                        >
                            <img src={Add_role} alt="Add Role" />
                            <Typography variant="h6" className={classes.roleText}>
                                Add Roles
                            </Typography>
                        </Paper>
                    </Grid>
                </Box>
                {selectedRole && selectedRole !== "AddRole" && (
                    <Box>
                        <Typography variant="h6" className={classes.mainHeading}>
                            {`Access for ${selectedRole.toLowerCase()}`}
                        </Typography>
                        <Grid
                            container
                            spacing={2}
                            className={classes.permissionsContainer}
                        >
                            {pageslist.map((pagename, index) => (
                                <Grid item xs={4} key={index}>
                                    <Paper className={classes.permissionBox}>
                                        <Typography
                                            variant="h6"
                                            className={classes.permissionHeading}
                                        >
                                            {pagename}
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <label className={classes.checkBoxLabel} style={{ color: "grey" }}>
                                                    <Checkbox style={{ color: "grey" }} disabled={true} checked={
                                                        roles.length ? roles.filter(item => item.title === selectedRole)[0].access[pagename.toLowerCase()].view : false
                                                    }

                                                    />
                                                    View
                                                </label>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <label className={classes.checkBoxLabel}>
                                                    <Checkbox color="primary" checked={
                                                        roles.length ? roles.filter(item => item.title === selectedRole)[0].access[pagename.toLowerCase()].add : false
                                                    }
                                                        onClick={() => updateRole(selectedRole, pagename.toLowerCase(), "add", roles.length && !roles.filter(item => item.title === selectedRole)[0].access[pagename.toLowerCase()].add)}
                                                    />
                                                    Create
                                                </label>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <label className={classes.checkBoxLabel}>
                                                    <Checkbox color="primary" checked={
                                                        roles.length ? roles.filter(item => item.title === selectedRole)[0].access[pagename.toLowerCase()].edit : false
                                                    }
                                                        onClick={() => updateRole(selectedRole, pagename.toLowerCase(), "edit", roles.length && !roles.filter(item => item.title === selectedRole)[0].access[pagename.toLowerCase()].edit)}
                                                    />
                                                    Edit
                                                </label>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <label className={classes.checkBoxLabel}>
                                                    <Checkbox color="primary" checked={
                                                        roles.length ? roles.filter(item => item.title === selectedRole)[0].access[pagename.toLowerCase()].delete : false
                                                    }
                                                        onClick={() => updateRole(selectedRole, pagename.toLowerCase(), "delete", roles.length && !roles.filter(item => item.title === selectedRole)[0].access[pagename.toLowerCase()].delete)}
                                                    />
                                                    Delete
                                                </label>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                )}
            </Box>
            <Modal open={selectedRole === "AddRole"} onClose={() => setSelectedRole(null)} className={classes.modalContainer}>
                <Box className={classes.flexboxContainer}>
                    <Typography variant="h4" className={classes.modalHeading}>
                        Create User
                    </Typography>
                    <Typography variant="subtitle1" className={classes.modalHeading}>
                        Create a new role for banquet hall e.g. Manager, Executive, etc.
                    </Typography>
                    <TextField
                        label="Enter Role"
                        variant="outlined"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        className={classes.modalTextField}
                    />
                    <Button
                        variant="contained"

                        onClick={() => handleAddRole(role)}
                        className={classes.modalButton}
                    >
                        Add Role
                    </Button>
                </Box>
            </Modal>

            <Box>
                {roles.length > 2 && (
                    <Box className={classes.modalPermissionTableContainer}>
                        <Typography variant="h6" style={{ marginBottom: "5px" }} className={classes.heading}>
                            Manage Permission for custom roles
                        </Typography>
                        <Typography variant="subtitle1" className="tumour-sew">
                            Create and manage access rights for a particular role
                        </Typography>
                        <TableContainer
                            component={Paper}
                            className={classes.modalPermissionTable}
                            style={{ marginTop: "30px" }}
                        >
                            <Table aria-label="permission table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Role</TableCell>
                                        {pageslist.map(pagename => <TableCell>{pagename}</TableCell>)}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {otherRoles.map(item => {
                                        return (
                                            <TableRow>
                                                <TableCell>{item.title}</TableCell>
                                                {pageslist.map(pagename => {
                                                    return (
                                                        <TableCell>
                                                            <FormControlLabel
                                                                style={{ color: "grey" }}
                                                                control={<Checkbox style={{ color: "grey" }} disabled={true} checked={
                                                                    item.access[pagename.toLowerCase()].view
                                                                } />}
                                                                label="View"
                                                            />
                                                            <FormControlLabel
                                                                control={<Checkbox color="primary" checked={
                                                                    item.access[pagename.toLowerCase()].edit
                                                                }
                                                                    onClick={() => updateRole(item.title, pagename.toLowerCase(), "edit", !item.access[pagename.toLowerCase()].edit)}
                                                                />}
                                                                label="Edit"
                                                            />
                                                            <FormControlLabel
                                                                control={<Checkbox color="primary" checked={
                                                                    item.access[pagename.toLowerCase()].add
                                                                }
                                                                    onClick={() => updateRole(item.title, pagename.toLowerCase(), "add", !item.access[pagename.toLowerCase()].add)}
                                                                />}
                                                                label="Create"
                                                            />
                                                            <FormControlLabel
                                                                control={<Checkbox color="primary" checked={
                                                                    item.access[pagename.toLowerCase()].delete
                                                                }
                                                                    onClick={() => updateRole(item.title, pagename.toLowerCase(), "delete", !item.access[pagename.toLowerCase()].delete)}
                                                                />}
                                                                label="Delete"
                                                            />
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                )}
            </Box>
        </Container>
    );
};
export default Roles;