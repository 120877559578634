import React, { useEffect, useState } from "react"
import "./css/ChangePassword.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import { FaEye, FaEyeSlash } from "react-icons/fa"
import axios from "axios"
import api from "../config/backend"
import { toast } from "react-toastify"
import image from "../assets/shield.jpg"
import { Box, CircularProgress } from "@mui/material"
import StepperHeader from "../components/StepperHeader"

const ChangePassword = () => {
    // password showing function
    const [showPassword, setShowPassword] = useState(false)
    const [confirmshowPassword, setConfirmshowPassword] = useState(false)
    const [password, setPassword] = useState("")
    const [confirmpassword, setConfirmpassword] = useState("")

    // const [resetpassword, setResetpassword] = (true)
    const [redirect, setRedirect] = useState(false)
    const navigate = useNavigate()

    const [loading, setloading] = useState(false)

    useEffect(() => {
        let timeout

        if (redirect) {
            timeout = setTimeout(() => {
                navigate("/loginPage")
            }, 5000)
        }

        return () => clearTimeout(timeout)
    }, [redirect])

    const { hash } = useParams()

    const handleTogglePassword = () => {
        setShowPassword(!showPassword)
    }
    const handleToggleConfirmPassword = () => {
        setConfirmshowPassword(!confirmshowPassword)
    }

    const handlereset = async (event) => {
        event.preventDefault()
        setloading(true)
        // const dataa = {
        //     hash,
        //     password
        // }
        // const { data, status } = await axios.post(`${api}/Author/reset-password`, {hash , password})
        // if (status === 200 || status === 201) {
        //     toast.success("reset Password is updated")
        // }
        if (confirmpassword === password) {

            const { data, status } = await axios.post(`${api}/Author/reset-password`, { hash, password })

            if (data.success) {
                toast.success(data.message)
                setRedirect(true)
            } else {
                toast.error(data.message)
            }

        } else {
            toast.error("confirm password should be same")
        }
        setloading(false)
    }

    return (
        <div>
            <StepperHeader forcehideLogout={true} />
            <div className="ChangePass_div">
                <div className="ChangeBox_div">
                    <h1 style={{ color: "#F9C623" }}>Password reset</h1>
                    <p>Please enter a new password for your Venue Management account.</p>
                    <form onSubmit={handlereset}>
                        <div className="Changefield_div">
                            <div className="div_field">
                                <input
                                    className={showPassword ? "Textfont" : "Passfont"}
                                    type={showPassword ? "text" : "password"}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Enter New password"
                                />
                                <div className="iconeye" onClick={handleTogglePassword}>
                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                </div>
                            </div>
                            <div className="div_field">
                                <input
                                    className={confirmshowPassword ? "Textfont" : "Passfont"}
                                    type={confirmshowPassword ? "text" : "password"}
                                    value={confirmpassword}
                                    onChange={(e) => setConfirmpassword(e.target.value)}
                                    placeholder="Confirm New Password"
                                />
                                <div className="iconeye" onClick={handleToggleConfirmPassword}>
                                    {confirmshowPassword ? <FaEyeSlash /> : <FaEye />}
                                </div>
                            </div>
                            {/* {!resetpassword && <p>Passwords do not match</p>} */}
                            <p>
                                Your password must be at least 8 characters long. Avoid common words
                                or patterns.
                            </p>
                            <button style={{ backgroundColor: "#F9C623" }} type="submit" className="btn-btn">{
                                loading ? <Box sx={{ display: 'flex', color: "white", }}>
                                    <CircularProgress size={20} />
                                </Box> : "Reset Password"
                            }</button>
                        </div>
                    </form>
                    <span className="btn-link">
                        <Link style={{ color: "#F9C623" }} to="/loginPage">Go to Home</Link>
                    </span>
                </div>
                <div className="image_div">
                    <img
                        style={{ width: 400, height: 400 }}
                        src={image}
                        alt="image"
                    />
                </div>
            </div>
        </div>
    )
}

export default ChangePassword
