import * as React from "react"
import { useState, useEffect } from "react"
import { useTheme } from "@mui/material/styles"
import Grid from "@mui/material/Grid"
import dayjs from "dayjs"
import TextField from "@mui/material/TextField"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { Autocomplete, Box, Button, FormControl, InputLabel, Tooltip, Typography } from "@mui/material"
import OutlinedInput from "@mui/material/OutlinedInput"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import Checkbox from "@mui/material/Checkbox"
import IconButton from "@mui/material/IconButton"
import Stack from "@mui/material/Stack"
import axios from "axios"
import { format } from "date-fns"
import api from "../../config/backend"
import "./Booking.css"
import { ToastContainer, toast } from "react-toastify"
import { FaSquareMinus } from "react-icons/fa6"
// import UserData from "../UserData"
import { debounce } from 'lodash'
import { useCallback } from "react"
import { useMemo } from "react"
import jwtDecode from "jwt-decode"



function Booking() {
	const currentDate = new Date()
	const [halltype, setHalltype] = useState(["Indoor", "Outdoor"])
	const [banquets, setBanquets] = useState([])
	const [bookings, setBookings] = useState([])
	const [filteredbanquets, setFilteredbanquets] = useState([])
	const [prebooked, setPrebooked] = useState([])
	const [phoneNumbers, setPhoneNumbers] = useState("")
	const [alternativePhoneNumbers, setAlternativePhoneNumbers] = useState("")
	const [contactPersonNames, setContactPersonNames] = useState("")
	const [eventFromDates, setEventFromDates] = useState(null)
	const [eventToDates, setEventToDates] = useState(null)
	const [companyName, setCompanyName] = useState("")
	const [bookingType, setBookingType] = useState("")
	const [referredBy, setReferredBy] = useState("")
	const [advancePaid, setAdvancePaid] = useState(0)
	const [givenRate, setGivenRate] = useState(0)
	const [bookingGivenBy, setBookingGivenBy] = useState("")
	const [isAdvancedPaid, setIsAdvancedPaid] = useState("")
	const [remarks, setRemarks] = useState("")
	const [email, setEmail] = useState("")
	const [events, setEvents] = useState([])
	const [backupEvents, setBackupEvents] = useState([])
	const [editmode, setEditmode] = useState(false)
	const [slotfreedom, setSlotFreedom] = useState({})
	const [totalAdvanceAmount, setTotalAdvanceAmount] = useState(0)
	const [refreshAutocomplete, setRefreshAutocomplate] = useState(0)

	const token = localStorage.getItem("token")

	const decoded = jwtDecode(token)

	const fetchPrebooked = async () => {
		const { data, status } = await axios.post(`${api}/Booking/fetch-prebooked`, {
			token,
			eventfrom: eventFromDates,
			eventend: eventToDates
		})

		if (data.success) {
			setPrebooked(data.booked)
		}
	}

	const fetchBanquets = async () => {
		try {
			const response = await axios.post(`${api}/BanquetHall/get-banquets`, { token })
			setBanquets(response.data.banquets)
		} catch (error) {
			console.error(error)
		}
	}

	const fetchBookings = async () => {
		try {
			const { data, status } = await axios.post(`${api}/Booking/get-bookings`, {
				token,
			})

			if (data.success) {
				setBookings(data.bookings)
			} else {
				toast.error(data.message)
			}
		} catch (error) {
			console.error(error)
		}
	}

	// Manage filter banquet hall if booked on same date
	// freedom contains "{date}": { "{hash}": "BL" } i.e. two slots are booked on date, rest one (dinner) is free
	useEffect(() => {
		// fallback will be true in case of collapsing events
		// e.g. booking on same date same hall
		let fallback = false

		const freedom = {}

		events.forEach(item => {
			const hallhash = item.banquethallhash

			if (item.date && hallhash) {
				// Check if freedom already has that date
				if (freedom[item.date]) {
					if (freedom[item.date][hallhash]) {
						const slotsofrow = []
						if (item.breakfast) {
							slotsofrow.push("B")
						}
						if (item.lunch) {
							slotsofrow.push("L")
						}
						if (item.dinner) {
							slotsofrow.push("D")
						}
						// Check if collapse happened, i.e. same slot booking in same hall same date
						const collided = slotsofrow.some(slot => {
							if (freedom[item.date][hallhash].includes(slot)) {
								return true
							}
						})

						if (collided) {
							fallback = true
							toast.error("Slot already booked for this date")
						} else {
							freedom[item.date][hallhash] = [...freedom[item.date][hallhash], ...slotsofrow]
							console.log(freedom[item.date][hallhash])
						}
					} else {
						const contains = []
						if (item.breakfast) {
							contains.push("B")
						}
						if (item.lunch) {
							contains.push("L")
						}
						if (item.dinner) {
							contains.push("D")
						}
						freedom[item.date] = {}
						freedom[item.date][hallhash] = contains
					}
				} else {
					const contains = []
					if (item.breakfast) {
						contains.push("B")
					}
					if (item.lunch) {
						contains.push("L")
					}
					if (item.dinner) {
						contains.push("D")
					}
					freedom[item.date] = {}
					freedom[item.date][hallhash] = contains
				}
			}
		})

		setSlotFreedom(freedom)

		if (fallback) {
			setEvents(backupEvents)
		} else {
			setBackupEvents(events)
		}
	}, [events])

	// Filter banquets
	// Remove inactive banquets, show banquets with matching halltype
	const filterBanquets = (date, halltype, index) => {
		const banquetcopy = JSON.parse(JSON.stringify(filteredbanquets))

		banquetcopy[index] = banquets.filter((item) => {
			if (slotfreedom[date] && slotfreedom[date][item.hash]) {
				const notempty = slotfreedom[date][item.hash].includes("B") && slotfreedom[date][item.hash].includes("L") && slotfreedom[date][item.hash].includes("D")

				console.log(notempty)

				console.log(slotfreedom[date][item.hash])

				return item.is_active && item.hall_type === halltype && notempty === false
			}
			return item.is_active && item.hall_type === halltype
		})

		setFilteredbanquets(banquetcopy)
	}

	const handleEditBooking = async () => {
		const { data, status } = await axios.post(`${api}/Booking/update-booking`, {
			token,
			bookinghash: editmode.bookinghash,
			slotidentifier: editmode.bookedslotid,
			phone: phoneNumbers,
			alternatephone: alternativePhoneNumbers,
			contactname: contactPersonNames,
			contactemail: email,
			eventfrom: eventFromDates,
			eventend: eventToDates,
			contactcompany: companyName,
			advance: advancePaid,
			rate: givenRate,
			bookingby: bookingGivenBy,
			referredby: referredBy,
			booking_given_by: bookingGivenBy,
			is_cocktails_available: isAdvancedPaid,
			remarks: remarks,
			bookingstatus: bookingType,
			bookings: events
		})

		if (data.success) {
			fetchBookings()
			toast.success(data.message)
			setPhoneNumbers("")
			setAlternativePhoneNumbers("")
			setContactPersonNames("")
			setEmail("")
			setEventFromDates(null)
			setEventToDates(null)
			setCompanyName("")
			setAdvancePaid(0)
			setGivenRate(0)
			setBookingGivenBy("")
			setReferredBy("")
			setIsAdvancedPaid("")
			setBookingType("")
			setRemarks("")
			setEvents([])
			setEditmode(false)
		} else {
			toast.error(data.message)
		}
	}

	const handleFormSubmit = async (e) => {
		e.preventDefault()

		if (isAdvancedPaid === "true") {
			const invalid = advancePaid.some(item => {
				if (item.date === "" || item.amount === "" || item.remark === "") {
					return true
				}
				return false
			})

			if (invalid) {
				toast.warn("Please fill in advance paid details")
				return
			}
		}

		const bookingData = {
			token,
			phone: phoneNumbers,
			alternatephone: alternativePhoneNumbers,
			contactname: contactPersonNames,
			contactemail: email,
			eventfrom: eventFromDates,
			eventend: eventToDates,
			contactcompany: companyName,
			advance: advancePaid,
			rate: givenRate,
			bookingby: bookingGivenBy,
			referredby: referredBy,
			bookingby: decoded.user.full_name,
			is_cocktails_available: isAdvancedPaid,
			remarks: remarks,
			bookingstatus: bookingType,
			bookings: events,
		}

		try {
			const { data, status } = await axios.post(
				`${api}/Booking/create-booking`,
				bookingData
			)

			if (data.success) {
				fetchBookings()
				toast.success(data.message)
				setPhoneNumbers("")
				setAlternativePhoneNumbers("")
				setContactPersonNames("")
				setEmail("")
				setEventFromDates(null)
				setEventToDates(null)
				setCompanyName("")
				setAdvancePaid(0)
				setGivenRate(0)
				setBookingGivenBy("")
				setReferredBy("")
				setIsAdvancedPaid("")
				setBookingType("")
				setRemarks("")
				setEvents([])
				setRefreshAutocomplate(prev => prev + 1)
			} else {
				toast.error(data.message)
			}
		} catch (error) {
			console.error("Error:", error)
		}
	}

	const handleSelectChange = (event) => {
		setIsAdvancedPaid(event.target.value)

		if (event.target.value === "true") {
			setAdvancePaid([{
				date: "",
				amount: "",
				remark: ""
			}])
		}
	}

	function handleAddRow(index, row) {
		const selecteddate = row.date

		setEvents(prev => {
			const copy = JSON.parse(JSON.stringify(prev))

			const firstpart = copy.slice(0, index + 1)
			let secondpart

			if (copy.length > 1) {
				secondpart = copy.slice(index + 1, copy.length)
			}

			const insert = {
				date: selecteddate,
				breakfast: false,
				lunch: false,
				dinner: false,
				halltype: '',
				pax: 1,
				expectedpax: 1,
				banquethallhash: "",
				eventtitle: ''
			}

			return secondpart ? [...firstpart, insert, ...secondpart] : [...firstpart, insert]
		})
	}

	function handleRemove(index) {
		setEvents((prevBookingHalls) => {
			const updatedBookingHalls = [...prevBookingHalls]
			updatedBookingHalls.splice(index, 1)
			return updatedBookingHalls
		})
	}

	const logger = async () => {
        const link = window.location.href

        const { data, status } = await axios.post(`${api}/Logs/viewed-page`, { token, link })
    }

	useEffect(() => {
		fetchBookings()
		logger()
	}, [])

	useEffect(() => {
		let timedifference = undefined
		let daysinbetween = undefined

		if (eventFromDates && eventToDates) {
			timedifference =
				new Date(eventToDates).getTime() - new Date(eventFromDates).getTime()
			daysinbetween = timedifference / (1000 * 3600 * 24) + 1

			if (daysinbetween <= 0) {
				return
			}

			setFilteredbanquets(Array(daysinbetween).fill([]))

			setEvents(
				Array(daysinbetween)
					.fill({
						date: eventFromDates,
						breakfast: false,
						lunch: false,
						dinner: false,
						halltype: "",
						pax: 1,
						expectedpax: 1,
						banquethallhash: "",
						eventtitle: "",
					})
					.map((item, index) => {
						const copy = JSON.parse(JSON.stringify(item))
						const d = new Date(eventFromDates)
						copy.date = d.setDate(d.getDate() + index)
						return copy
					})
			)
		}
	}, [eventFromDates, eventToDates])

	useEffect(() => {
		let total = 0
		if (advancePaid.length) {
			advancePaid.map(item => {
				if (item.amount) {
					total += Number(item.amount)
				}
			})
		}
		setTotalAdvanceAmount(total)
	}, [advancePaid])

	useEffect(() => {
		if (eventFromDates && eventToDates) {
			fetchPrebooked()
		}
	}, [eventFromDates, eventToDates])

	useEffect(() => {
		fetchBanquets()
	}, [])

	const [userData, setUserData] = useState([]);

	const fetchUserData = useCallback( async (phoneNumbers) => {
		try {
			const response = await axios.post(`${api}/Customer/fetch-contact`, { token, phone:phoneNumbers})
			if(!phoneNumbers){
				setUserData([])
			}else{
				setUserData(response.data.customers)
			}
			
		} catch (error) {
			console.error(error)
		}
	},[setUserData])
	
	const debouncedFetch = useMemo(() => {
		return debounce(fetchUserData, 500)
	},[fetchUserData])
	useEffect(() => {
		debouncedFetch(phoneNumbers)
	},[phoneNumbers])

	function autoFillForm(){
		if (phoneNumbers?.length === 10 && userData.find((user => user.phonenumber.includes(phoneNumbers)))) {
			const user = userData.find((user => user.phonenumber === phoneNumbers))

			setEmail(user.email)
			setContactPersonNames(user.name)
			setAlternativePhoneNumbers(user.alternatephone)
			setCompanyName(user.company)
		}
	}

	useEffect(() => {
		autoFillForm()
	}, [phoneNumbers])

	return (
		<>
			<div className="_8abi app-page-banquets-management">
				<div className="cardDiv">
					<div className="app-card">
						<h1 className="app-card__title">Customer Details</h1>
						<div className="app-card__content">
							<Grid container >
								<Grid className="_3lqy" item xs={12} md={4}>
									<div className="app-form-field">
										<Autocomplete
											key={refreshAutocomplete}
											id="Phone"
											options={userData}
											freeSolo
											disableClearable
											className="phone-number-input"
											autoHighlight={true}								  
											getOptionLabel={(option) => option.phonenumber}
											renderOption={(props, option) => (
												<div style={{borderBottom:'1px solid #d4d6d5', display:'flex', flexDirection:'column', alignItems:'flex-start'}} {...props}>
												  <Typography variant='overline' textAlign='left'>{option.name} <span style={{fontSize:'10px'}}>{`(${option.email})`}</span></Typography>
												  <Typography variant='caption' >Phone: {option.phonenumber}</Typography> 
												</div>
											  )}
											renderInput={(params) => <TextField value={phoneNumbers} onChange={e => setPhoneNumbers(e.target.value)} {...params} sx={{padding:'0px !important'}}  label="Phone *" type="number" placeholder="Enter Phone" />}
											onChange={(event, newValue) => setPhoneNumbers(newValue.phonenumber)}
										/>
									</div>
									
								</Grid>
								<Grid className="_3lqy" item xs={12} md={4}>
									<div className="app-form-field">
										<TextField
											type="number"
											label="Alternate No"
											variant="outlined"
											placeholder="Enter Alternate No"
											value={alternativePhoneNumbers}
											onChange={(e) =>
												setAlternativePhoneNumbers(e.target.value)
											}
										/>
									</div>
								</Grid>
								<Grid className="_3lqy" item xs={12} md={4}>
									<div className="app-form-field">
										<TextField
											label="Customer Name *"
											variant="outlined"
											placeholder="Enter Contact Person Name"
											value={contactPersonNames}
											onChange={(e) => setContactPersonNames(e.target.value)}
										/>
									</div>
								</Grid>
							</Grid>
							<Grid container >
								<Grid className="_3lqy" item xs={12} md={4}>
									<div className="app-form-field">
										<TextField
											label="company"
											variant="outlined"
											placeholder="Enter company"
											value={companyName}
											onChange={(event) => setCompanyName(event.target.value)}
										/>
									</div>
								</Grid>
								<Grid className="_3lqy" item xs={12} md={4}>
									<div className="app-form-field">
										<TextField
											label="Email"
											type="email"
											variant="outlined"
											placeholder="Enter Email"
											value={email}
											onChange={(event) => setEmail(event.target.value)}
										/>
									</div>
								</Grid>
								<Grid className="_3lqy" item xs={12} md={4}>
									<div className="app-form-field">
										<TextField
											disabled={true}
											label="Booking By"
											variant="outlined"
											placeholder="Enter Booking Given By"
											value={decoded.user.full_name}
											onChange={(event) =>
												setBookingGivenBy(event.target.value)
											}
										/>
									</div>
								</Grid>
							</Grid>
							<Grid container >
								<Grid className="_3lqy" item xs={12} md={4}>
									<div className="app-form-field">
										<TextField
											type="text"
											label="Reffered By"
											variant="outlined"
											placeholder="Reffered by"
											value={referredBy}
											onChange={(e) => setReferredBy(e.target.value)}
										/>
									</div>
								</Grid>
								<Grid className="_3lqy" item xs={12} md={4}>
									<div className="app-form-field">
										<FormControl>
											<InputLabel id="demo-simple-select-label">
												Select status *
											</InputLabel>
											<Select
												label="Select status *"
												labelId="booking-type-label"
												id="booking-type-select"
												value={bookingType}
												onChange={(e) => setBookingType(e.target.value)}
											>
												<MenuItem value="Confirmed">Confirmed</MenuItem>
												<MenuItem value="Tentative">Tentative</MenuItem>
												<MenuItem value="Waitlist">Waitlist</MenuItem>
											</Select>
										</FormControl>
									</div>
								</Grid>
								<Grid className="_3lqy" item xs={12} md={4}>
									<div className="app-form-field">
										<FormControl>
											<InputLabel id="demo-simple-select-label">
												Advance paid
											</InputLabel>
											<Select
												label="select Cocktails"
												value={isAdvancedPaid}
												onChange={handleSelectChange}
											>
												<MenuItem value="true">Yes</MenuItem>
												<MenuItem value="false">No</MenuItem>
											</Select>
										</FormControl>
									</div>
								</Grid>
							</Grid>
							<Grid container >
								<Grid className="_3lqy" item xs={12} md={12}>
									<div className="app-form-field" style={{ padding: 0 }}>
										<TextField
											id="outlined-multiline-static"
											label="Remarks"
											multiline
											variant="outlined"
											placeholder="Enter remarks"
											value={remarks}
											onChange={(event) => setRemarks(event.target.value)}
										/>
									</div>
								</Grid>
							</Grid>
						</div>
						<div className="app-component-table app-new-component-table">
							<h1 className="app-card__title">Event Details</h1>
							<div className="app-new-main-form-field">
								<div className="app-form-field">
									<Grid container>
										<Grid item xs={12} md={6}>
											<Grid container rowSpacing={2} columnSpacing={5}>
												<Grid item xs={12} md={6}>
													<LocalizationProvider dateAdapter={AdapterDayjs}>
														<DatePicker
															disablePast
															label="Event From Date *"
															value={eventFromDates}
															onChange={(newValue) =>
																setEventFromDates(newValue.$d)
															}
															format="DD-MM-YYYY"
														/>
													</LocalizationProvider>
												</Grid>
												<Grid item xs={12} md={6}>
													<div className="app-form-field">
														<LocalizationProvider dateAdapter={AdapterDayjs}>
															<DatePicker
																disablePast
																minDate={dayjs(eventFromDates)}
																label="to date *"
																value={eventToDates}
																onChange={(newValue) =>
																	setEventToDates(newValue.$d)
																}
																format="DD-MM-YYYY"
															/>
														</LocalizationProvider>
													</div>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</div>
							</div>
							<TableContainer>
								<Table stickyHeader>
									<TableHead>
										<TableRow>
											<TableCell>Date</TableCell>
											<TableCell>B</TableCell>
											<TableCell>L</TableCell>
											<TableCell>D</TableCell>
											<TableCell>Hall Type</TableCell>
											<TableCell>Pax</TableCell>
											<TableCell>Exp.Pax</TableCell>
											<TableCell>BanquetHall</TableCell>
											<TableCell>Events</TableCell>
											<TableCell>Add</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{events.length
											? events.map((booking, index) => {
												const bookedslots = {
													breakfast: false,
													lunch: false,
													dinner: false
												}

												prebooked.forEach(item => {
													console.log({ item })
													// Booking for same date and selected banquet
													if ((new Date(item.date).toDateString() === new Date(booking.date).toDateString()) && (item.banquet_hall === booking.banquethallhash)) {
														console.log({ item })
														if (item.is_breakfast_available) {
															bookedslots.breakfast = true
														}
														if (item.is_lunch_available) {
															bookedslots.lunch = true
														}
														if (item.is_dinner_available) {
															bookedslots.dinner = true
														}
													}
												})

												return (
													<TableRow key={index}>
														<TableCell>
															{format(booking.date, "dd-MM-yyyy")}
														</TableCell>
														<TableCell className="table-check-box">
															<Tooltip disableHoverListener={bookedslots.breakfast === false} title="⚠️ Slot already booked for selected banquet hall">
																<Checkbox
																	sx={{
																		color: bookedslots.breakfast ? "rgb(255, 157, 157)" : undefined,
																		"&.Mui-checked": {
																			color: bookedslots.breakfast ? "rgb(255, 157, 157) !important" : "rgb(249, 198, 35) !important"
																		}
																	}}
																	name="is_breakfast_available"
																	checked={booking.breakfast}
																	onChange={(e) =>
																		setEvents((prev) => {
																			const copy = JSON.parse(
																				JSON.stringify(prev)
																			)
																			
																			copy[index].breakfast =
																				!copy[index].breakfast
																			return copy
																		})
																	}
																/>
															</Tooltip>
														</TableCell>
														<TableCell className="table-check-box">
															<Tooltip disableHoverListener={bookedslots.lunch === false} title="⚠️ Slot already booked for selected banquet hall">
																<Checkbox
																	sx={{
																		color: bookedslots.lunch ? "rgb(255, 157, 157)" : undefined,
																		"&.Mui-checked": {
																			color: bookedslots.lunch ? "rgb(255, 157, 157) !important" : "rgb(249, 198, 35) !important"
																		}
																	}}
																	name="is_lunch_available"
																	checked={booking.lunch}
																	onChange={(e) =>
																		setEvents((prev) => {
																			const copy = JSON.parse(
																				JSON.stringify(prev)
																			)
																			
																			copy[index].lunch = !copy[index].lunch
																			return copy
																		})
																	}
																/>
															</Tooltip>
														</TableCell>
														<TableCell className="table-check-box">
															<Tooltip disableHoverListener={bookedslots.dinner === false} title="⚠️ Slot already booked for selected banquet hall">
																<Checkbox
																	sx={{
																		color: bookedslots.dinner ? "rgb(255, 157, 157)" : undefined,
																		"&.Mui-checked": {
																			color: bookedslots.dinner ? "rgb(255, 157, 157) !important" : "rgb(249, 198, 35) !important"
																		}
																	}}
																	name="is_dinner_available"
																	checked={booking.dinner}
																	onChange={(e) =>
																		setEvents((prev) => {
																			const copy = JSON.parse(
																				JSON.stringify(prev)
																			)
																			
																			copy[index].dinner = !copy[index].dinner
																			return copy
																		})
																	}
																/>
															</Tooltip>
														</TableCell>
														<TableCell className="hall-cell">
															<div className="app-form-field">
																<Select
																	displayEmpty
																	value={booking.halltype}
																	onChange={(e) => {
																		setEvents((prev) => {
																			const copy = JSON.parse(
																				JSON.stringify(prev)
																			)
																			copy[index].halltype = e.target.value

																			// Filter list of banquet halls according to hall type
																			filterBanquets(booking.date, e.target.value, index)

																			return copy
																		})
																	}}
																	input={<OutlinedInput />}
																	MenuProps={""}
																	inputProps={{ "aria-label": "Without label" }}
																>
																	<MenuItem value="" disabled>
																		Select Halltype
																	</MenuItem>
																	{halltype ? halltype.map((item, index) => (
																		<MenuItem key={index} value={item}>
																			{item}
																		</MenuItem>
																	)) : undefined}
																</Select>
															</div>
														</TableCell>
														<TableCell className="hall-cell2">
															<div className="app-form-field">
																<TextField
																	label="Pax"
																	variant="outlined"
																	placeholder=""
																	name="pax"
																	value={booking.pax} // Assuming you have an index for the current row
																	onChange={(e) =>
																		setEvents((prev) => {
																			const copy = JSON.parse(
																				JSON.stringify(prev)
																			)
																			copy[index].pax = e.target.value
																			return copy
																		})
																	}
																/>
															</div>
														</TableCell>
														<TableCell className="hall-cell2">
															<div className="app-form-field">
																<TextField
																	label="Exp Pax"
																	variant="outlined"
																	placeholder=""
																	name="exp_pax"
																	value={booking.expectedpax} // Assuming you have an index for the current row
																	onChange={(e) =>
																		setEvents((prev) => {
																			const copy = JSON.parse(
																				JSON.stringify(prev)
																			)
																			copy[index].expectedpax = e.target.value
																			return copy
																		})
																	}
																/>
															</div>
														</TableCell>
														<TableCell className="hall-cell">
															<div className="app-form-field">
																<Select
																	displayEmpty
																	value={booking.banquethallhash}
																	onChange={(e) =>
																		setEvents((prev) => {
																			const copy = JSON.parse(
																				JSON.stringify(prev)
																			)
																			copy[index].banquethallhash =
																				e.target.value

																			return copy
																		})
																	}
																>
																	{filteredbanquets[index] ? filteredbanquets[index].map((item) => {
																		return (
																			<MenuItem
																				key={item._id}
																				value={item.hash}
																			>
																				{item.hall_title}
																			</MenuItem>
																		)
																	}) : undefined}
																</Select>
															</div>
														</TableCell>

														<TableCell className="hall-cell2">
															<div className="app-form-field">
																<TextField className="_4fnr"
																	label="Event"
																	variant="outlined"
																	placeholder=""
																	name="event_title"
																	value={bookings.eventtitle}
																	onChange={(e) =>
																		setEvents((prev) => {
																			const copy = JSON.parse(
																				JSON.stringify(prev)
																			)
																			copy[index].eventtitle = e.target.value
																			return copy
																		})
																	}
																/>
															</div>
														</TableCell>
														<TableCell>
															<div
																style={{
																	display: "flex",
																	alignItems: "center",
																}}
															>
																<Stack direction="row" spacing={1}>
																	<IconButton
																		aria-label="add"
																	>
																		<img
																			style={{ width: "20px" }}
																			src="/assets/icons/add.png"
																			onClick={() => handleAddRow(index, booking)}
																		/>
																	</IconButton>
																</Stack>
																<Stack direction="row" spacing={1}>
																	<IconButton aria-label="delete">
																		<img
																			style={{ width: "20px" }}
																			src="/assets/icons/remove.svg"
																			onClick={() => handleRemove(index)}
																		/>
																	</IconButton>
																</Stack>
															</div>
														</TableCell>
													</TableRow>
												)
											})
											: undefined}
									</TableBody>
								</Table>
							</TableContainer>
							<div style={{ display: "flex", justifyContent: "flex-end" }}>
								<Button className="app-btn app-btn-primary" style={{ backgroundColor: "#F9C623", color: "white", padding: "8px 20px", borderRadius: "20px", marginTop: "20px" }} onClick={editmode ? handleEditBooking : handleFormSubmit}>{editmode ? "update booking" : "Submit"}</Button>
								{editmode ? <Button onClick={() => {
									setPhoneNumbers("")
									setAlternativePhoneNumbers("")
									setContactPersonNames("")
									setEmail("")
									setEventFromDates(null)
									setEventToDates(null)
									setCompanyName("")
									setAdvancePaid(0)
									setGivenRate(0)
									setBookingGivenBy("")
									setReferredBy("")
									setIsAdvancedPaid("")
									setBookingType("")
									setRemarks("")
									setEvents([])
									setEditmode(false)
								}}>cancel</Button> : undefined}
							</div>
						</div>
						{isAdvancedPaid === "true" ? <div className="_3qtm">
							<div className="container">
								<div className="table-header">
									<span>Date</span>
									<span>Amount</span>
									<span>Remarks</span>
									<span style={{ width: "100px" }}>Actions</span>
								</div>
								<div className="body">
									{advancePaid.map((item, index) => {
										return (
											<div key={index}>
												<span className="payment_no_border_bottom">
													<LocalizationProvider dateAdapter={AdapterDayjs}>
														<DatePicker
															label=""
															disableFuture
															slotProps={{ textField: { variant: 'standard', } }}
															value={item.date}
															onChange={newValue => setAdvancePaid(prev => {
																const copy = JSON.parse(JSON.stringify(prev))
																copy[index].date = newValue.$d
																return copy
															})}
															format="DD-MM-YYYY"
														/>
													</LocalizationProvider>
													{/* <input value={item.date} onChange={e => setAdvancePaid(prev => {
														const copy = JSON.parse(JSON.stringify(prev))
														copy[index].date = e.target.value
														return copy
													})} /> */}
												</span>
												<span>
													<input type="number" value={item.amount} onChange={e => setAdvancePaid(prev => {
														const copy = JSON.parse(JSON.stringify(prev))
														copy[index].amount = e.target.value
														return copy
													})} />
												</span>
												<span>
													<input value={item.remark} onChange={e => setAdvancePaid(prev => {
														const copy = JSON.parse(JSON.stringify(prev))
														copy[index].remark = e.target.value
														return copy
													})} />
												</span>
												<span style={{ width: "100px", cursor: "pointer", color: "orange", fontSize: "14px" }} onClick={() => setAdvancePaid(prev => {
													const copy = JSON.parse(JSON.stringify(prev))
													copy.splice(index, 1)
													return copy
												})}>Remove</span>
											</div>
										)
									})}
								</div>
							</div>
							<button style={{ marginTop: "15px" }} onClick={() => setAdvancePaid(prev => [...prev, {
								date: "",
								amount: "",
								remark: ""
							}])}>Add row</button>
							<div className="triazine-cob">
								<strong>Total advance paid: </strong> <strong>₹</strong> {totalAdvanceAmount}
							</div>
						</div> : undefined}
					</div>
				</div>
			</div>
		</>
	)
}
export default Booking
