import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


const names = [
    'Option 1',
    'Option 2',
    'Option 3',
    'Option 4',
    'Option 5',
  ];

function AllBookingType (){
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);
  
    const handleChange2 = (event) => {
      const {
        target: { value },
      } = event;
      setPersonName(
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
      setAge(event.target.value);
    };
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    return(
        <>
            <div className="app-page-banquets-management">
                <div className="app-card">
                    <h1 className="app-card__title">Booking Report</h1>
                    <div className="app-card__content">
                          <Grid container style={{marginTop:'20px'}}>
                            <Grid item xs={12} md={6}>
                                <Grid container rowSpacing={2} columnSpacing={5}>
                                    <Grid item xs={12} md={6}>
                                    <div className="app-form-field">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker 
                                    label="Event From Date"
                                    />
                                  </LocalizationProvider>
                                    </div>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                    <div className="app-form-field">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker 
                                    label="Event From Date"
                                    />
                                  </LocalizationProvider>
                                    </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                        </Grid>
                      
                    </div> 
                </div>
                <div className="app-component-table app-new-component-table app-new-component-table2">
                <div className="app-component-table__header">
                        <h2>Search Result</h2>
                    </div>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Booking ID</TableCell>
                                    <TableCell>Guest Name</TableCell>
                                    <TableCell>Company</TableCell>
                                    <TableCell>Pax</TableCell>
                                    <TableCell>From Date</TableCell>
                                    <TableCell>To Date</TableCell>
                                    <TableCell>Booking Type</TableCell>
                                    <TableCell>Phone No</TableCell>
                                    <TableCell>Reference</TableCell>
                                    <TableCell>Cancellation Reason</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                    <TableRow>
                                        <TableCell>1234</TableCell>
                                        <TableCell>Sravan</TableCell>
                                        <TableCell>Asian Paints</TableCell>
                                        <TableCell>50</TableCell>
                                        <TableCell>25-04-2023</TableCell>
                                        <TableCell>26-04-2023</TableCell>
                                        <TableCell>
                                        <div className="app-table-status">
                                                <img src="/assets/icons/success-check.svg"/>
                                                <span>Confirmed</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>9787978792</TableCell>
                                        <TableCell>Swaroop</TableCell>
                                        <TableCell>Query</TableCell>
                                    </TableRow>
                            </TableBody>
                            <TableBody>
                                    <TableRow>
                                    <TableCell>1234</TableCell>
                                        <TableCell>Sravan</TableCell>
                                        <TableCell>Asian Paints</TableCell>
                                        <TableCell>50</TableCell>
                                        <TableCell>25-04-2023</TableCell>
                                        <TableCell>26-04-2023</TableCell>
                                        <TableCell>
                                        <div className="app-table-status">
                                                <img src="/assets/icons/success-check.svg"/>
                                                <span>Confirmed</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>9787978792</TableCell>
                                        <TableCell>Swaroop</TableCell>
                                        <TableCell>Query</TableCell>
                                    </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            <img className="app-tree-bg" src="/assets/images/tree-bg.svg"/>
        </>
    )
}
export default AllBookingType;