import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Button } from "@mui/material";
import axios from "axios";
import { useState } from "react";

const names = ["Male", "Female", "Other"];


function UserVenue() {



  //   const names = ["Male", "Female", "Other"];
  const [firstName, setFirstName] = React.useState("");
  const [dob, setDob] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [selectedRoles, setSelectedRoles] = React.useState([]);
  const [selectedGender, setSelectedGender] = React.useState("");

  const [roles, setRoles] = useState([]);

  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState("");
  const [totalPaidAmount, setTotalPaidAmount] = useState("");

  React.useEffect(() => {
    axios
      .get("http://192.46.209.97:6060/api/Role/list", {
        headers: {
          'accept': 'application/json',
          'authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsImZpcnN0X25hbWUiOiJTaXRhcmEiLCJsYXN0X25hbWUiOiJIb3RlbCIsImVtYWlsIjoic2l0YXJhQHRlc3QuY29tIiwiaXNfcGhvbmVfdmVyaWZpZWQiOmZhbHNlLCJwaG9uZV92ZXJpZmljYXRpb24iOm51bGwsImltYWdlIjpudWxsLCJyb2xlIjpbIjY0NjRhODk5NDQ5YWUwOTA2NGU1MjZmOCJdLCJnZW5kZXIiOiJNYWxlIiwiYWRkcmVzcyI6InN0cmluZyIsInBhc3N3b3JkIjoiJDJiJDEwJEZYSHJSQVFPbVRKZktPZmw2djZsQmVxQS85Smg1UzRvZk1iRTdaNS9FcWRpN1ByUEk4TjRDIiwicmVzZXRQYXdvcmRUb2tlbiI6bnVsbCwicmVmcmVzaFRva2VuIjoia0kydTMxQ3NjRjVscUgwNmRUNVUyNlJ0dzFEbk1FNWVVQnN6VzRBVzBnTXdaaXRNTzdrRnozc0F5dHJpaTI4eGVSb2VsSXhIQ0xrWnFxOGxsWVZseXRSQmF6SktKUHp4dWxCQ1A3TU5GbkpPSERaZ1Mzc1VYc2l4ekhaVjRESFM0dGlRUnQ5a28yWEU4bmhaY0w0N3IxaU5QalBXa0hGRmg2N0c4SkFOUmVlTmdJdjd4dkYyV0dwcVV6Uk15NWE4UmNJWG9CV2MyZmNsanBWelRkamVXNERaSDJ4Rkp3MXlveXlyaXZ3YWNjQUlxWHhSYXpQSm9ZcW9MTkpyQTU4ajY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsIm90cCI6MTIzNCwidmVudWUiOiI2NDY0YWUwMDgxMjM4NjFhZmM5YTUyNTQiLCJwaG9uZW51bWJlciI6Ijk2NDA4NjEwNzYiLCJkb2IiOiIyMDIzLTAxLTAxVDAwOjAwOjAwLjAwMFoiLCJjcmVhdGVkQXQiOiIyMDIzLTA1LTE3VDEwOjM1OjQ0LjU5NloiLCJ1cGRhdGVkQXQiOiIyMDIzLTA1LTE4VDA2OjE5OjI2LjQzOFoiLCJfX3YiOjB9LCJpYXQiOjE2ODQzOTA3NzcsImV4cCI6MTY4NTI1NDc3N30.5Se9vIgLFU1_tJL8wRe0EHp53ltgz6HkOF1rYZSuFMA',
        },
      })
      .then((response) => {
        setRoles(response.data); // Assuming the roles are returned as an array of objects
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  React.useEffect(() => {
    // Make the API call and save the data to the state
    axios.get('http://192.46.209.97:6060/api/Package/list')
      .then(response => {
        const data = response.data.results;
        setPackages(data);
      })
      .catch(error => {
        // Handle the error
      });
  }, []);


  const [responseData, setResponseData] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = {
      first_name: firstName,
      last_name: "",
      email: email,
      phonenumber: parseInt(phoneNumber),
      password: password,
      address: address,
      dob: dob,
      gender: selectedGender,
      role: selectedRoles,
      package_info: [{ 'package_id': selectedPackage, 'total_paid_amount': totalPaidAmount }]
    };

    // Make the API request
    axios
      .post("http://192.46.209.97:6060/api/Author/create/venue", data, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          'authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsImZpcnN0X25hbWUiOiJTaXRhcmEiLCJsYXN0X25hbWUiOiJIb3RlbCIsImVtYWlsIjoic2l0YXJhQHRlc3QuY29tIiwiaXNfcGhvbmVfdmVyaWZpZWQiOmZhbHNlLCJwaG9uZV92ZXJpZmljYXRpb24iOm51bGwsImltYWdlIjpudWxsLCJyb2xlIjpbIjY0NjRhODk5NDQ5YWUwOTA2NGU1MjZmOCJdLCJnZW5kZXIiOiJNYWxlIiwiYWRkcmVzcyI6InN0cmluZyIsInBhc3N3b3JkIjoiJDJiJDEwJEZYSHJSQVFPbVRKZktPZmw2djZsQmVxQS85Smg1UzRvZk1iRTdaNS9FcWRpN1ByUEk4TjRDIiwicmVzZXRQYXdvcmRUb2tlbiI6bnVsbCwicmVmcmVzaFRva2VuIjoia0kydTMxQ3NjRjVscUgwNmRUNVUyNlJ0dzFEbk1FNWVVQnN6VzRBVzBnTXdaaXRNTzdrRnozc0F5dHJpaTI4eGVSb2VsSXhIQ0xrWnFxOGxsWVZseXRSQmF6SktKUHp4dWxCQ1A3TU5GbkpPSERaZ1Mzc1VYc2l4ekhaVjRESFM0dGlRUnQ5a28yWEU4bmhaY0w0N3IxaU5QalBXa0hGRmg2N0c4SkFOUmVlTmdJdjd4dkYyV0dwcVV6Uk15NWE4UmNJWG9CV2MyZmNsanBWelRkamVXNERaSDJ4Rkp3MXlveXlyaXZ3YWNjQUlxWHhSYXpQSm9ZcW9MTkpyQTU4ajY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsIm90cCI6MTIzNCwidmVudWUiOiI2NDY0YWUwMDgxMjM4NjFhZmM5YTUyNTQiLCJwaG9uZW51bWJlciI6Ijk2NDA4NjEwNzYiLCJkb2IiOiIyMDIzLTAxLTAxVDAwOjAwOjAwLjAwMFoiLCJjcmVhdGVkQXQiOiIyMDIzLTA1LTE3VDEwOjM1OjQ0LjU5NloiLCJ1cGRhdGVkQXQiOiIyMDIzLTA1LTE4VDA2OjE5OjI2LjQzOFoiLCJfX3YiOjB9LCJpYXQiOjE2ODQzOTA3NzcsImV4cCI6MTY4NTI1NDc3N30.5Se9vIgLFU1_tJL8wRe0EHp53ltgz6HkOF1rYZSuFMA',
        },
      })
      .then((response) => {
        // Handle the API response
        setResponseData(response.data); // You can do something with the response data here
      })
      .catch((error) => {
        // Handle any errors that occurred during the API request
        console.error(error);
      });
  };

  const [authors, setAuthors] = useState([]);

  React.useEffect(() => {
    // Make the API call and save the data to the state
    axios.get('http://192.46.209.97:6060/api/Author/list')
      .then(response => {
        const data = response.data.results;
        setAuthors(data);
      })
      .catch(error => {
        // Handle the error
      });
  }, []);

  const handleRoleChange = (event, roleId) => {
    if (event.target.checked) {
      setSelectedRoles((prevSelectedRoles) => [...prevSelectedRoles, roleId]);
    } else {
      setSelectedRoles((prevSelectedRoles) =>
        prevSelectedRoles.filter((id) => id !== roleId)
      );
    }
  };



  const handleChange2 = (event) => {
    setSelectedGender(event.target.value);
  };

  return (
    <>
      <div className="app-page-user-registration">
        <div className="app-card app-card-550">
          <h1 className="app-card__title">Venue Registration</h1>
          <form onSubmit={handleSubmit}>
            <div className="app-card__content">
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <div className="app-form-field">
                    <TextField
                      label="Full Name"
                      variant="outlined"
                      placeholder="Enter Full Name"
                      value={firstName}
                      onChange={(event) => setFirstName(event.target.value)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className="app-form-field">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Date"
                        value={dob}
                        onChange={(newValue) =>
                          setDob(newValue.format("YYYY-MM-DD"))
                        }
                      />
                    </LocalizationProvider>
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className="app-form-field">
                    <Select
                      displayEmpty
                      value={selectedGender}
                      onChange={handleChange2}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        if (!selected) {
                          return <em>Gender</em>;
                        }
                        return selected;
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {names.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </Grid>
                <Grid item xs={12} md={8}>
                  <div className="app-form-field">
                    <TextField
                      label="Mobile No"
                      type="number"
                      variant="outlined"
                      placeholder="Enter Mobile No"
                      value={phoneNumber}
                      onChange={(event) => setPhoneNumber(event.target.value)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className="app-form-field">
                    <Select
                      labelId="package-label"
                      id="package"
                      value={selectedPackage}
                      onChange={(e) => setSelectedPackage(e.target.value)}
                    >
                      <MenuItem value="">
                        <em>Select a package</em>
                      </MenuItem>
                      {packages.map((pkg) => (
                        <MenuItem key={pkg.package_id} value={pkg._id}>
                          {pkg.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </Grid>
                {selectedPackage && (
                  <Grid item xs={12} md={8}>
                    <div className="app-form-field">
                      <TextField
                        label="Total Amount Paid"
                        type="number"
                        variant="outlined"
                        placeholder="Enter Total Amount Paid"
                        value={totalPaidAmount}
                        onChange={(event) => setTotalPaidAmount(event.target.value)}
                      />
                    </div>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <div className="app-form-field">
                    <TextField
                      label="Address"
                      variant="outlined"
                      placeholder="Enter Address"
                      value={address}
                      onChange={(event) => setAddress(event.target.value)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="app-form-field">
                    <TextField
                      label="Email ID"
                      type="email"
                      variant="outlined"
                      placeholder="Enter Email ID"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="app-form-field">
                    <TextField
                      label="Password"
                      type="password"
                      variant="outlined"
                      placeholder="Enter Password"
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                    />
                  </div>
                </Grid>
              </Grid>
              <div className="app-check-btns-group mt-20">
                <h3 className="app-check-btns-group__title">Select Role</h3>
                {/* <ul className="app-check-btns-group__list mt-5">
                            <li className="app-check-btns-group__list__item">
                                <FormControlLabel
                                    control={
                                    <Checkbox size="small" name="role" />
                                    }
                                    label="Edit User"
                                />
                            </li>
                            <li className="app-check-btns-group__list__item">
                                <FormControlLabel
                                    control={
                                    <Checkbox size="small" name="role" />
                                    }
                                    label="Admin"
                                />
                            </li>
                            <li className="app-check-btns-group__list__item">
                                <FormControlLabel
                                    control={
                                    <Checkbox size="small" name="role" />
                                    }
                                    label="Staff"
                                />
                            </li>
                            <li className="app-check-btns-group__list__item">
                                <FormControlLabel
                                    control={
                                    <Checkbox size="small" name="role" />
                                    }
                                    label="Developer"
                                />
                            </li>
                        </ul> */}
                <ul className="app-check-btns-group__list mt-5">
                  {(roles && roles.results) ? roles.results.map((role) => (
                    <li className="app-check-btns-group__list__item" key={role._id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            name="role"
                            checked={selectedRoles.includes(role._id)}
                            onChange={(event) => handleRoleChange(event, role._id)}
                          />
                        }
                        label={role.title}
                      />
                    </li>
                  )) : null}
                </ul>


              </div>
              <div className="app-card__button d-flex justify-content-end mt-20 w-full">
                <Button
                  className="app-btn app-btn-primary"
                  variant="contained"
                  type="submit"
                >
                  Submit Details
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* <img className="app-tree-bg" src="/assets/images/tree-bg.svg" /> */}
    </>
  )
}

export default UserVenue