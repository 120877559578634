import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Box from '@mui/material/Box';
import decode from "jwt-decode";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import axios from 'axios';
import api from '../../config/backend';

export const FinishOnboard = ({ approved, setApproved, setStep }) => {
    const [departmentList, setDepartmentList] = useState([]);
    const [roles, setRoles] = useState([]);
    const [banquets, setBanquets] = useState([]);
    const [users, setUsers] = useState([]);
    const [company, setCompany] = useState([])
    console.log(company)


    //
    const [packagetaken, setPackagetaken] = useState(undefined)
    console.log(packagetaken)

    const token = localStorage.getItem("token")

    const data = decode(token);

    console.log(data)

    const header = [
        "Role",
        "Customers",
        "Booking",
        "Reports"
    ];
    const rows = [
        "customers",
        "booking",
        "reports"
    ];

    //  get apis

    const fetchPackageDetails = async () => {
        const response = await axios.get(`${api}/Package/fetch-packages`)

        console.log({ d: data.user })


        if (response.data.success === false) {
            toast.error(data.message)

        } else {
            console.log({ t: response.data.packages })
            setPackagetaken(response.data.packages.find(item => item.hash === data.user.package))
        }
    }

    const getRolesList = async () => {
        const { data, status } = await axios.post(`${api}/Role/role-access`, {
            token,
        });

        if (data.success) {
            setRoles(data.roles);
        } else {
            toast.error(data.message);
        }
    };

    const getBanquetList = async () => {
        const { data, status } = await axios.post(
            `${api}/BanquetHall/get-banquets`,
            { token }
        );
        if (data.success) {
            setBanquets(data.banquets);
        } else {
            toast.error(data.message);
        }
    };

    const getUsersList = async () => {
        const { data, status } = await axios.post(`${api}/Author/get-users`, {
            token,
        });
        if (data.success) {
            setUsers(data.users);
        } else {
            toast.error(data.message);
        }
    };

    const getDepartmentList = async () => {
        const { data, status } = await axios.post(
            `${api}/Department/get-departments`,
            { token }
        );
        if (data.success) {
            setDepartmentList(data.departments);
        }
    };

    const fetchCompany = async () => {
        const { data, status } = await axios.post(`${api}/company/get-company`, {
            token,
        });

        console.log({ data })

        if (data.success) {
            setCompany(data.company);
        } else {
            toast.error(data.message);
        }
    };


    useEffect(() => {
        fetchPackageDetails()
        getRolesList();
        getBanquetList();
        getUsersList();
        getDepartmentList();
        fetchCompany()
    }, []);




    return (
        <div className="_0yet">
            <ToastContainer />

            <div className="_2sma">
                <div className="third-dox">
                    <h2>Finish onboarding</h2>
                    <p>Please verify the details of the company</p>
                </div>
                <div className="amelcorn-dors">
                    <div className="box">
                        <span>Company Name</span>
                        {console.log({ company })}
                        <span>{company && company.name}</span>
                    </div>
                    <div className="box">
                        <span>GST</span>
                        <span>{company && company.gst ? company.gst : "N/a"}</span>
                    </div>
                    <div className="box">
                        <span>Address</span>
                        <span>{company && company.address}</span>
                    </div>
                    <div className="box">
                        <span>E-mail address</span>
                        <span>{data.user.email}</span>

                    </div>
                    <div className="box">
                        <span>Phone number</span>
                        <span>{data.user.phonenumber}</span>

                    </div>
                    <div className="box">
                        <span>Payment</span>
                        <span>Online</span>
                    </div>
                    <div className="box">
                        <span>Package taken</span>
                        <span>{packagetaken && packagetaken.title}</span>
                    </div>
                    <div className="pinkies-alef"></div>
                </div>
            </div>

            <div className="tables_div">
                <div className="some_title">Banquets</div>
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Banquet Name</TableCell>
                                <TableCell>Hall Type</TableCell>
                                <TableCell>Area</TableCell>
                                <TableCell>Persons Capacity</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {banquets.map((hall, index) => (
                                <TableRow key={index}>
                                    <TableCell>{hall.hall_title}</TableCell>
                                    <TableCell>{hall.hall_type}</TableCell>
                                    <TableCell>{hall.area}</TableCell>
                                    <TableCell>{hall.capacity_space}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ width: "100%" }}>
                    <button onClick={() => setStep(4)} style={{ backgroundColor: "#f9c623", color: "black", border: "none", margin: "10px", padding: "5px 10px", borderRadius: "8px", cursor: "pointer" }}>Edit</button>
                </div>
            </div>
            
            <div className="tables_div">
                <div className="some_title">Roles Details</div>
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {header.map((item, index) => (
                                    <TableCell key={index}>{item}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {console.log(roles)}
                            {roles.length
                                ? roles.map((item, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>{item.title}</TableCell>
                                            {rows.map((page, i) => {
                                                return (
                                                    <TableCell key={i}>
                                                        <Box
                                                            sx={{ display: "flex", gap: "5px", ml: 2 }}
                                                        >
                                                            {item.access[page].edit ? (
                                                                <span className="view">Edit</span>
                                                            ) : undefined}
                                                            {item.access[page].view ? (
                                                                <span className="view">View</span>
                                                            ) : undefined}
                                                            {item.access[page].add ? (
                                                                <span className="view">Add</span>
                                                            ) : undefined}
                                                            {item.access[page].delete ? (
                                                                <span className="view">Delete</span>
                                                            ) : undefined}
                                                            {item.access[page].edit === false &&
                                                                item.access[page].view === false ? (
                                                                <span className="none">None</span>
                                                            ) : undefined}
                                                        </Box>
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })
                                : undefined}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ width: "100%" }}>
                    <button onClick={() => setStep(3)} style={{ backgroundColor: "#f9c623", color: "black", border: "none", margin: "10px", padding: "5px 10px", borderRadius: "8px", cursor: "pointer" }}>Edit</button>
                </div>
            </div>
            <div className="tables_div">
                <div className="some_title">Location Details</div>
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Location Name</TableCell>
                                <TableCell>Display Name</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {departmentList.map((department) => (
                                <TableRow key={department.id}>
                                    <TableCell>{department.title}</TableCell>
                                    <TableCell>{department.display_title}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ width: "100%" }}>
                    <button onClick={() => setStep(2)} style={{ backgroundColor: "#f9c623", color: "black", border: "none", margin: "10px", padding: "5px 10px", borderRadius: "8px", cursor: "pointer" }}>Edit</button>
                </div>
            </div>
            <div className="tables_div">
                <div className="some_title">User Details</div>
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Full Name</TableCell>
                                <TableCell>Gender</TableCell>
                                <TableCell>Mobile No</TableCell>
                                <TableCell>Email ID</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell>Email Verification</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map((roleuser, index) => {
                                console.log({ roleuser })
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{roleuser.full_name}</TableCell>
                                        <TableCell>{roleuser.gender}</TableCell>
                                        <TableCell>{roleuser.phonenumber}</TableCell>
                                        <TableCell>{roleuser.email}</TableCell>
                                        <TableCell>{roleuser.role}</TableCell>
                                        <TableCell>
                                            {roleuser.email_verification.status ? (
                                                <span style={{ color: "green" }}>Verified</span>
                                            ) : (
                                                <span style={{ color: "red" }}>Pending</span>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ width: "100%" }}>
                    <button onClick={() => setStep(1)} style={{ backgroundColor: "#f9c623", color: "black", border: "none", margin: "10px", padding: "5px 10px", borderRadius: "8px", cursor: "pointer" }}>Edit</button>
                </div>
            </div>
            
            <div className="chyme-jets">
                <span>Confirmation</span>
                <div>
                    <input
                        id="approved"
                        type="checkbox"
                        checked={approved}
                        onClick={() => setApproved((prev) => !prev)}
                    />
                    <label htmlFor="approved">
                        I have verified above information is correct
                    </label>
                </div>
            </div>

        </div>
    );
}
export default FinishOnboard;
