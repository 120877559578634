import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, TextField, Button, List, ListItem, Container } from '@material-ui/core';
import { toast } from 'react-toastify';
import api from "../config/backend";

function Branch() {
  const [branch, setBranch] = useState([]);
  const [newBranch, setNewBranch] = useState('');

  const token = localStorage.getItem("token")

  const fetchBranch = async () => {
    const { data, status } = await axios.post(`${api}/Branch/get-branches`, { token })
    if (data.success) {
      setBranch(data.branches)
    } else {
      toast.error(data.message)
    }
  }

  const createBranch = async (e) => {
    e.preventDefault()
    const { data, status } = await axios.post(`${api}/Branch/create-branch`,{
      token,
      branch: newBranch,
    })
    if (data.success) {
      toast.success(data.message)
      fetchBranch()
      setNewBranch('')
    }
  }

  const deleteBranch = async (id) => {
    const { status } = await axios.delete(`${api}/Branch/delete/${id}`);
    if (status === 200 || status === 201) {
      toast.success("Branch deleted successfully")
      setBranch(prevRoles => prevRoles.filter(role => role._id !== id));
    }
  };

  useEffect(() => {
    fetchBranch()
  }, []);

  const [editingRoleId, setEditingRoleId] = useState(null);
const [updatedRole, setUpdatedRole] = useState('');

const handleUpdate = async (id) => {
  const { status } = await axios.patch(`${api}/Branch/update/${id}`,{
    title: updatedRole,
  });
  if (status === 200 || status === 201) {
    toast.success("Branch updated successfully")
    const updatedRoles = branch.map(role => {
      if (role._id === id) {
        return { ...role, title: updatedRole };
      }
      return role;
    });

    setBranch(updatedRoles);
    setEditingRoleId(null);
    setUpdatedRole('');
  }
  };
  

  return (
    <Container maxWidth="sm">
      <Typography variant="h5" align="center" gutterBottom>
        Branch
      </Typography>
      <form onSubmit={createBranch} style={{ display: 'flex', marginBottom: '20px' }}>
        <TextField
          label="Role Title"
          variant="outlined"
          size="small"
          value={newBranch}
          onChange={(e) => setNewBranch(e.target.value)}
          style={{ flexGrow: 1, marginRight: '10px' }}
        />
        <Button type="submit" variant="contained" color="primary" size="small">
          Create Branch
        </Button>
      </form>
      <List>
        {branch.map(role => (
  <ListItem key={role._id} style={{ border: '1px solid #ddd', marginBottom: '10px' }}>
    {editingRoleId === role._id ? (
      <>
        <TextField
          label="Role Title"
          variant="outlined"
          size="small"
          value={updatedRole}
          onChange={(e) => setUpdatedRole(e.target.value)}
          style={{ flexGrow: 1, marginRight: '10px' }}
        />
        <Button onClick={() => handleUpdate(role._id)} variant="contained" color="primary" size="small">
          Save
        </Button>
      </>
    ) : (
      <>
        <Typography variant="body1">{role.title}</Typography>
        <Button onClick={() => setEditingRoleId(role._id)} variant="contained" color="primary" size="small" style={{ marginLeft: "auto", marginRight: "10px" }}>
          Edit
        </Button>
        <Button onClick={() => deleteBranch(role._id)} variant="contained" color="secondary" size="small">
          Delete
        </Button>
      </>
    )}
  </ListItem>
))}

      </List>
    </Container>
  );
}

export default Branch;
