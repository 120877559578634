import { TablePagination } from "@mui/material"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { useEffect, useState } from "react"
import axios from "axios"
import api from "../config/backend"

const Logs = () => {
    const [logs, setLogs] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const token = localStorage.getItem("token")

    const fetchLogs = async () => {
        const { data, status } = await axios.post(`${api}/Logs/view`, { token })

        if (data.success) {
            setLogs(data.logs)
        }
    }

    const logger = async () => {
        const link = window.location.href

        const { data, status } = await axios.post(`${api}/Logs/viewed-page`, { token, link })
    }

    useEffect(() => {
        fetchLogs()
        logger()
    }, [])

    return (
        <div className="_0mis">
            <TableContainer style={{ backgroundColor: "white" }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>User</TableCell>
                            <TableCell style={{ paddingRight: "120px" }}>Event</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell>Timestamp</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {logs
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((log) => {
                                const timestamp = new Date(log.createdAt).toLocaleString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    second: 'numeric',
                                    hour12: true
                                })

                                let color = "rgba(0, 0, 0, 0.87)"

                                if (log.event.includes("Visited")) {
                                    color = "grey"
                                }

                                if (log.event.includes("Login")) {
                                    color = "green"
                                }

                                return (
                                    <TableRow key={log._id}>
                                        <TableCell>
                                            {log.username}
                                        </TableCell>
                                        <TableCell style={{ color }} className="_0sgv_table">
                                            {log.event}
                                        </TableCell>
                                        <TableCell className="_0sgv_table">
                                            {log.userrole}
                                        </TableCell>
                                        <TableCell className="_0sgv_table">
                                            {timestamp}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
                <TablePagination
                    style={{ display: "flex", flexDirection: "row" }}
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={logs.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, page) => setPage(page)}
                    onRowsPerPageChange={(event) => {
                        setRowsPerPage(+event.target.value)
                        setPage(0)
                    }}
                />
            </TableContainer>
        </div>
    )
}

export default Logs
