
import { Grid, Card, CardContent, Typography, Button, CardMedia, Container } from '@mui/material'
import React, { useState, useRef, useEffect } from 'react'
import { Tabs, Tab, Box } from '@mui/material'
import "./css/Home.css"
import Header from '../components/Header'
import Footer from '../components/Footer'
import { useLocation, useNavigate } from 'react-router-dom'




const TabPanel = (props) => {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

const Privacypolicy = () => {
    const [tabValue, setTabValue] = useState(0)

    const pricingRef = useRef(null)

    const navigate = useNavigate()

    const { state } = useLocation()

    useEffect(() => {
        if (state?.scroll) {
            pricingRef.current?.scrollIntoView({ behavior: 'smooth' })
        }
    }, [state])

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue)
    }

    const tabStyles = {
        backgroundColor: 'white',
        '&.Mui-selected': {
            backgroundColor: '#D8232A',
            borderRadius: '20px',
            color: '#ffff',
        },
    }


    const handleHash = (hash) => {
        localStorage.setItem('packagehash', hash)
        navigate("/signupPage")
    }

    return (
        <div className="_5pxd">
            <Header test={pricingRef} />
            <div className='privacy-policy'>
                <Container>
                    <div className='privacy-policy-content'>
                        <h1>Privacy Policy</h1>
                    </div>
                </Container>
            </div>
            <div className='intro'>
                <Container>
                    <div className='intro-title'>
                        <p>Venue Management highly values your privacy rights and is dedicated to safeguarding them. We uphold the following principles and policies for handling all Personally Identifiable Information collected from you. These principles guide our everyday operations, ensuring your data is handled responsibly. This Privacy Policy outlines the specific policies that govern Venue Management's use of Personally Identifiable Information provided by our users. As our business evolves and adapts to new circumstances, we anticipate making occasional updates or improvements to these policies. We will maintain the most current version of our Privacy Policy on our website and promptly inform users and the public of any significant changes by posting a notice on our site for ten days following the modification. If any such alteration is unacceptable to you, we kindly request that you discontinue using our website and services. Continuing to use our website and services will be considered as your conclusive acceptance of the changes.</p>
                    </div>
                    <div className='privacy-policy-main-content'>
                        <h4>Managing Personal Information Updates</h4>
                        <p>Our Venue Management system empowers you to inspect, rectify, or delete any Personally Identifiable Information you've shared with us whenever you need to. If you have any inquiries regarding our Privacy Policy or wish to review or amend your provided information, you can take the following steps: log in to your online account, or send an email request to us at : <a href='mailto:info@skoltech.in'>info@skoltech.in</a> </p>
                    </div>
                    <div className='privacy-policy-main-content'>
                        <h4>What Information do we collect ?</h4>
                        <p>We gather Personally Identifiable Information solely when you voluntarily provide it to us through our Site. This means you can explore our Site without disclosing any Personally Identifiable Information. Similar to most websites, our web servers do collect certain visit-related data, but not personal information about you. This data may encompass the URL, IP address, browser specifications, language preference, and the date and time of your visit. To access specific features of the Site and Services, you may need to register. Any Personally Identifiable Information required during the registration process will remain confidential and not be displayed on our Site. Once registered, you have the option to furnish additional information in various sections of the site. Providing supplementary information beyond the registration fields is entirely voluntary, and such data may be visible to the public.</p>
                    </div>
                    <div className='privacy-policy-main-content'>
                        <h4>How do we Utilize Personally Identifiable Information ?</h4>
                        <p>The Personally Identifiable Information you provide may be used for the following purposes:</p>
                        <div className='privacy-policy-mini-content'>
                            <ul>
                                <li>Registering you for our Services.</li>
                                <li>Verifying your authorization to access specific features on our Site and utilize our Service</li>
                                <li>Enhancing and customizing the content and overall management of our Site and Services.</li>
                                <li>Depending on your chosen email preferences, deliver specific content, information, services, and notifications from Venue Management.</li>
                                <li>Sending communications related to information, events, content, products, and services from our third-party partners.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='privacy-policy-main-content'>
                        <h4>Sharing Your Personally Identifiable Information with Others</h4>
                        <p>Except for the instances explicitly outlined in this Privacy Policy, we are committed to safeguarding and not disclosing or marketing any Personally Identifiable Information collected through our Site to external parties.</p>
                    </div>
                    <div className='privacy-policy-main-content'>
                        <h4>Cookies, Websites, and Other Internet Data :</h4>
                        <p>Similar to the majority of websites, we may employ cookies, web log files, IP addresses, or web beacons to achieve several objectives, including recognizing you as a registered user, enhancing the overall user experience on our Site, personalizing and customizing the content you encounter, and monitoring Site usage patterns. This data may be transmitted between your computer, our Site, and the systems maintained by our infrastructure partners. Please rest assured that the connection between this data and your Personally Identifiable Information is never disclosed without your explicit consent, except when compelled by legal requirements.</p>
                    </div>
                    <div className='privacy-policy-main-content'>
                        <h4>Data Storage & Security :</h4>
                        <p>We have implemented a combination of procedural and technological security measures intended to protect your Personally Identifiable Information from potential risks such as loss, unauthorized access, disclosure, alteration, or destruction. Venue Management may employ encryption, password protection, and various other security protocols to actively deter and mitigate any unauthorized access to your Personally Identifiable Information.</p>
                    </div>
                    <div className='privacy-policy-main-content'>
                        <h4>Links to Third-Party Sites :</h4>
                        <p>This privacy policy exclusively pertains to the privacy of Venue Management's Site and Services users. By utilizing Venue Management's Site and Services, you acknowledge and consent to the provisions outlined in this privacy policy. In instances where you navigate away from Venue Management's Site to external websites owned or operated by different entities or individuals, it is advisable to meticulously review the privacy policies of those external websites. It's important to note that the terms governing the handling of your Personally Identifiable Information by the proprietors or operators of these external websites may diverge from Venue Management's practices and policies</p>
                    </div>
                    <div className='privacy-policy-main-content'>
                        <h4>Age Group Privacy :</h4>
                        <p>The Venue Management's website and services are designed for individuals who are 18 years of age and older. Venue Management does not aim its website and services at individuals under the age of 18, nor does it knowingly gather Personally Identifiable Information from them. Before registering on the E portal, Venue Management ensures that individuals must be 18 years of age or older</p>
                    </div>
                    <div className='privacy-policy-contact-content'>
                        <h5>Contact Us :</h5>
                        <p>If you have any questions, concerns, or feedback regarding this Privacy Policy or our data practices, please don't hesitate to contact us:</p>
                        <div className='contact-us'>
                            <ul>
                                <li>Email: <a href='mailto:info@skoltech.in'>info@skoltech.in</a></li>
                                {/* <li>Mailing Address: [Have to Place Address Here]</li>
                            <li>Phone: [Contact Number Here]</li> */}
                            </ul>
                        </div>
                        <p>We value your privacy and will make every effort to address your inquiries promptly and effectively.</p>
                    </div>
                </Container>
            </div>


            <Footer />
        </div>

    )
}

export default Privacypolicy