import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import api from '../config/backend'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import { Button, Container, Typography } from '@mui/material'
import { toast } from 'react-toastify'
import Logo from "../assets/Logo.png"
import decode from "jwt-decode"
import StepperHeader from "../components/StepperHeader"

const ConfirmPackage = () => {
    const [decodedtoken, setDecodedtoken] = useState(undefined)
    const [selectedpackage, setSelectedpackage] = useState(undefined)

    const navigate = useNavigate()

    const token = localStorage.getItem("token")

    useEffect(() => {
        if (token === null) {
            navigate("/signup")
        } else {
            const decoded = decode(token)
            setDecodedtoken(decoded)
        }
    }, [token])

    console.log({ decodedtoken })

    const packagehash = localStorage.getItem("packagehash")

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script")
            script.src = src
            script.onload = () => {
                resolve(true)
            }
            script.onerror = () => {
                resolve(false)
            }
            document.body.appendChild(script)
        })
    }

    async function displayRazorpay() {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        )

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?")
            return
        }

        // creating a new order
        const result = await axios.post(`${api}/order/create`, { token, packagehash })

        if (!result) {
            alert("Server error. Are you online?")
            return
        }

        // Getting the order details back
        const { amount, id: order_id, currency } = result.data.order

        const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY,
            amount: amount.toString(),
            currency: currency,
            name: "Venue Management Inc",
            description: "Transaction for venue management",
            image: { Logo },
            order_id: order_id,
            handler: async function (response) {
                const data = {
                    token,
                    orderCreationId: order_id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                }

                const result = await axios.post(`${api}/payment/verify`, data)

                if (result.data.success) {
                    localStorage.removeItem("token")
                    localStorage.removeItem("packagehash")
                    navigate("/payment-success")
                } else {
                    toast.error(result.data.message)
                }
            },
            prefill: {
                name: decodedtoken.user.full_name,
                email: decodedtoken.user.email,
                contact: "91" + String(decodedtoken.user.phonenumber),
            },
            notes: {
                address: "Venue Management Corporate Office",
            },
            theme: {
                color: "#61dafb",
            },
        }

        const paymentObject = new window.Razorpay(options)
        paymentObject.open()
    }


    useEffect(() => {
        const init = async () => {
            const { data, status } = await axios.get(`${api}/Package/fetch-packages`)

            if (data.success === false) {
                toast.error(data.message)
            } else {
                const selected = data.packages.filter(item => {
                    if (packagehash) {
                        return item.hash === packagehash
                    }

                    return item.hash === "d25398db-b12d-4430-a70e-fba802110b6e"
                })

                if (selected.length) {
                    setSelectedpackage(selected[0])
                }
            }
        }

        init()
    }, [packagehash])

    const numberToInternational = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    return (
        <div>
            <StepperHeader forcehideLogout={true} />
            <div style={{ display: "flex", alignItems: "center", height: "calc(100vh - 84px)" }}>
                <Container component="main" maxWidth="xs">
                    <Card sx={{ minWidth: 275, boxShadow: "none" }}>
                        <img style={{ all: "unset", height: "50px" }} className="image" src={Logo} />
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                {selectedpackage ? selectedpackage.title : undefined}
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                Price
                            </Typography>
                            <Typography variant="h5" component="div">
                                {selectedpackage ? `₹ ${numberToInternational(selectedpackage.price)}` : undefined}
                            </Typography>
                            <Typography variant="body2">
                                {selectedpackage ? `Max banquet count : ${selectedpackage.maxbanquetcount}` : undefined}
                                <br />
                                {selectedpackage ? `Number of users : ${selectedpackage.maxuserscount}` : undefined}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <button onClick={displayRazorpay} style={{ all: "unset", backgroundColor: 'green', color: "white", fontFamily: "'Roboto', sans-serif", padding: "8px 15px", borderRadius: "15px", fontSize: "12px", cursor: "pointer" }} size="small">Confirm Payment</button>
                        </CardActions>
                    </Card>
                </Container>
            </div>
        </div>
    )
}

export default ConfirmPackage