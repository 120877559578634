import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Button, Box, Stack } from "@mui/material";

import axios from 'axios';

const names = ["Male", "Female", "Other"];

function EditUser() {


const [userData, setUserData] = React.useState([]);

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [dob, setDob] = React.useState('');
  const [selectedGender, setSelectedGender] = React.useState('Male');
  const [selectedRoles, setSelectedRoles] = React.useState([]);

  // const userId = localStorage.getItem('userId');

    // Get the token from local storage
const token = localStorage.getItem('token');

// Get the refresh token from local storage
const refreshToken = localStorage.getItem('refreshToken');

const userId = localStorage.getItem('userId');


  const [roles, setRoles] = React.useState([]);

  React.useEffect(() => {
    axios
      .get("http://192.46.209.97:6060/api/Role/list", {
        headers: {
            'accept': 'application/json',
            'authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsImZpcnN0X25hbWUiOiJTaXRhcmEiLCJsYXN0X25hbWUiOiJIb3RlbCIsImVtYWlsIjoic2l0YXJhQHRlc3QuY29tIiwiaXNfcGhvbmVfdmVyaWZpZWQiOmZhbHNlLCJwaG9uZV92ZXJpZmljYXRpb24iOm51bGwsImltYWdlIjpudWxsLCJyb2xlIjpbIjY0NjRhODk5NDQ5YWUwOTA2NGU1MjZmOCJdLCJnZW5kZXIiOiJNYWxlIiwiYWRkcmVzcyI6InN0cmluZyIsInBhc3N3b3JkIjoiJDJiJDEwJEZYSHJSQVFPbVRKZktPZmw2djZsQmVxQS85Smg1UzRvZk1iRTdaNS9FcWRpN1ByUEk4TjRDIiwicmVzZXRQYXdvcmRUb2tlbiI6bnVsbCwicmVmcmVzaFRva2VuIjoia0kydTMxQ3NjRjVscUgwNmRUNVUyNlJ0dzFEbk1FNWVVQnN6VzRBVzBnTXdaaXRNTzdrRnozc0F5dHJpaTI4eGVSb2VsSXhIQ0xrWnFxOGxsWVZseXRSQmF6SktKUHp4dWxCQ1A3TU5GbkpPSERaZ1Mzc1VYc2l4ekhaVjRESFM0dGlRUnQ5a28yWEU4bmhaY0w0N3IxaU5QalBXa0hGRmg2N0c4SkFOUmVlTmdJdjd4dkYyV0dwcVV6Uk15NWE4UmNJWG9CV2MyZmNsanBWelRkamVXNERaSDJ4Rkp3MXlveXlyaXZ3YWNjQUlxWHhSYXpQSm9ZcW9MTkpyQTU4ajY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsIm90cCI6MTIzNCwidmVudWUiOiI2NDY0YWUwMDgxMjM4NjFhZmM5YTUyNTQiLCJwaG9uZW51bWJlciI6Ijk2NDA4NjEwNzYiLCJkb2IiOiIyMDIzLTAxLTAxVDAwOjAwOjAwLjAwMFoiLCJjcmVhdGVkQXQiOiIyMDIzLTA1LTE3VDEwOjM1OjQ0LjU5NloiLCJ1cGRhdGVkQXQiOiIyMDIzLTA1LTE4VDA2OjE5OjI2LjQzOFoiLCJfX3YiOjB9LCJpYXQiOjE2ODQzOTA3NzcsImV4cCI6MTY4NTI1NDc3N30.5Se9vIgLFU1_tJL8wRe0EHp53ltgz6HkOF1rYZSuFMA',
          },
      })
      .then((response) => {
        setRoles(response.data); // Assuming the roles are returned as an array of objects
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  


  React.useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.get("http://192.46.209.97:6060/api/Author/list", {
        headers: {
          accept: 'application/json',
          'authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsImZpcnN0X25hbWUiOiJTaXRhcmEiLCJsYXN0X25hbWUiOiJIb3RlbCIsImVtYWlsIjoic2l0YXJhQHRlc3QuY29tIiwiaXNfcGhvbmVfdmVyaWZpZWQiOmZhbHNlLCJwaG9uZV92ZXJpZmljYXRpb24iOm51bGwsImltYWdlIjpudWxsLCJyb2xlIjpbIjY0NjRhODk5NDQ5YWUwOTA2NGU1MjZmOCJdLCJnZW5kZXIiOiJNYWxlIiwiYWRkcmVzcyI6InN0cmluZyIsInBhc3N3b3JkIjoiJDJiJDEwJEZYSHJSQVFPbVRKZktPZmw2djZsQmVxQS85Smg1UzRvZk1iRTdaNS9FcWRpN1ByUEk4TjRDIiwicmVzZXRQYXdvcmRUb2tlbiI6bnVsbCwicmVmcmVzaFRva2VuIjoia0kydTMxQ3NjRjVscUgwNmRUNVUyNlJ0dzFEbk1FNWVVQnN6VzRBVzBnTXdaaXRNTzdrRnozc0F5dHJpaTI4eGVSb2VsSXhIQ0xrWnFxOGxsWVZseXRSQmF6SktKUHp4dWxCQ1A3TU5GbkpPSERaZ1Mzc1VYc2l4ekhaVjRESFM0dGlRUnQ5a28yWEU4bmhaY0w0N3IxaU5QalBXa0hGRmg2N0c4SkFOUmVlTmdJdjd4dkYyV0dwcVV6Uk15NWE4UmNJWG9CV2MyZmNsanBWelRkamVXNERaSDJ4Rkp3MXlveXlyaXZ3YWNjQUlxWHhSYXpQSm9ZcW9MTkpyQTU4ajY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsIm90cCI6MTIzNCwidmVudWUiOiI2NDY0YWUwMDgxMjM4NjFhZmM5YTUyNTQiLCJwaG9uZW51bWJlciI6Ijk2NDA4NjEwNzYiLCJkb2IiOiIyMDIzLTAxLTAxVDAwOjAwOjAwLjAwMFoiLCJjcmVhdGVkQXQiOiIyMDIzLTA1LTE3VDEwOjM1OjQ0LjU5NloiLCJ1cGRhdGVkQXQiOiIyMDIzLTA1LTE4VDA2OjE5OjI2LjQzOFoiLCJfX3YiOjB9LCJpYXQiOjE2ODQzOTA3NzcsImV4cCI6MTY4NTI1NDc3N30.5Se9vIgLFU1_tJL8wRe0EHp53ltgz6HkOF1rYZSuFMA',
        },
      });
      setUserData(response.data.results);
    } catch (error) {
      console.error(error);
    }
  };
  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  React.useEffect(() => {
    if (userData.length > 0 && selectedIndex >= 0 && selectedIndex < userData.length) {
      const selectedItem = userData[selectedIndex];
      setFirstName(selectedItem.first_name);
      setLastName(selectedItem.last_name);
      setEmail(selectedItem.email);
      setPhoneNumber(selectedItem.phonenumber);
      setPassword(selectedItem.password);
      setAddress(selectedItem.address);
      // setDob(selectedItem.dob);
      setSelectedGender(selectedItem.gender);
      setSelectedRoles(selectedItem.role);
    }
  }, [userData, selectedIndex]);
  

  const handleSubmit = (event) => {
    event.preventDefault();

    // const id = userData[selectedIndex]._id; // Get the id from the selected user data

  
    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phonenumber: phoneNumber,
      password: password,
      address: address,
      dob: dob,
      gender: selectedGender,
      role: selectedRoles.map((role) => role.id), // Assuming `selectedRoles` is an array of objects, extracting only the `id` property
    };
  
    axios
      .patch(`http://192.46.209.97:6060/api/Author/update/${userId}`, data, {
        headers: {
          'Content-Type': 'application/json',
          authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsImZpcnN0X25hbWUiOiJTaXRhcmEiLCJsYXN0X25hbWUiOiJIb3RlbCIsImVtYWlsIjoic2l0YXJhQHRlc3QuY29tIiwiaXNfcGhvbmVfdmVyaWZpZWQiOmZhbHNlLCJwaG9uZV92ZXJpZmljYXRpb24iOm51bGwsImltYWdlIjpudWxsLCJyb2xlIjpbIjY0NjRhODk5NDQ5YWUwOTA2NGU1MjZmOCJdLCJnZW5kZXIiOiJNYWxlIiwiYWRkcmVzcyI6InN0cmluZyIsInBhc3N3b3JkIjoiJDJiJDEwJEZYSHJSQVFPbVRKZktPZmw2djZsQmVxQS85Smg1UzRvZk1iRTdaNS9FcWRpN1ByUEk4TjRDIiwicmVzZXRQYXdvcmRUb2tlbiI6bnVsbCwicmVmcmVzaFRva2VuIjoia0kydTMxQ3NjRjVscUgwNmRUNVUyNlJ0dzFEbk1FNWVVQnN6VzRBVzBnTXdaaXRNTzdrRnozc0F5dHJpaTI4eGVSb2VsSXhIQ0xrWnFxOGxsWVZseXRSQmF6SktKUHp4dWxCQ1A3TU5GbkpPSERaZ1Mzc1VYc2l4ekhaVjRESFM0dGlRUnQ5a28yWEU4bmhaY0w0N3IxaU5QalBXa0hGRmg2N0c4SkFOUmVlTmdJdjd4dkYyV0dwcVV6Uk15NWE4UmNJWG9CV2MyZmNsanBWelRkamVXNERaSDJ4Rkp3MXlveXlyaXZ3YWNjQUlxWHhSYXpQSm9ZcW9MTkpyQTU4ajY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsIm90cCI6MTIzNCwidmVudWUiOiI2NDY0YWUwMDgxMjM4NjFhZmM5YTUyNTQiLCJwaG9uZW51bWJlciI6Ijk2NDA4NjEwNzYiLCJkb2IiOiIyMDIzLTAxLTAxVDAwOjAwOjAwLjAwMFoiLCJjcmVhdGVkQXQiOiIyMDIzLTA1LTE3VDEwOjM1OjQ0LjU5NloiLCJ1cGRhdGVkQXQiOiIyMDIzLTA1LTE4VDA2OjE5OjI2LjQzOFoiLCJfX3YiOjB9LCJpYXQiOjE2ODQzOTA3NzcsImV4cCI6MTY4NTI1NDc3N30.5Se9vIgLFU1_tJL8wRe0EHp53ltgz6HkOF1rYZSuFMA',
        },
      })
      .then((response) => {
        // Handle the response if needed
      })
      .catch((error) => {
        console.error(error);
      });
  };
  

  const handleRoleChange = (role) => {
    if (selectedRoles.includes(role)) {
      setSelectedRoles(selectedRoles.filter((selectedRole) => selectedRole !== role));
    } else {
      setSelectedRoles([...selectedRoles, role]);
    }
  };

  const handleChange2 = (event) => {
    setSelectedGender(event.target.value);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`http://192.46.209.97:6060/api/Author/delete/${userId}`, {
        headers: {
          accept: 'application/json',
          authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsImZpcnN0X25hbWUiOiJTaXRhcmEiLCJsYXN0X25hbWUiOiJIb3RlbCIsImVtYWlsIjoic2l0YXJhQHRlc3QuY29tIiwiaXNfcGhvbmVfdmVyaWZpZWQiOmZhbHNlLCJwaG9uZV92ZXJpZmljYXRpb24iOm51bGwsImltYWdlIjpudWxsLCJyb2xlIjpbIjY0NjRhODk5NDQ5YWUwOTA2NGU1MjZmOCJdLCJnZW5kZXIiOiJNYWxlIiwiYWRkcmVzcyI6InN0cmluZyIsInBhc3N3b3JkIjoiJDJiJDEwJEZYSHJSQVFPbVRKZktPZmw2djZsQmVxQS85Smg1UzRvZk1iRTdaNS9FcWRpN1ByUEk4TjRDIiwicmVzZXRQYXdvcmRUb2tlbiI6bnVsbCwicmVmcmVzaFRva2VuIjoia0kydTMxQ3NjRjVscUgwNmRUNVUyNlJ0dzFEbk1FNWVVQnN6VzRBVzBnTXdaaXRNTzdrRnozc0F5dHJpaTI4eGVSb2VsSXhIQ0xrWnFxOGxsWVZseXRSQmF6SktKUHp4dWxCQ1A3TU5GbkpPSERaZ1Mzc1VYc2l4ekhaVjRESFM0dGlRUnQ5a28yWEU4bmhaY0w0N3IxaU5QalBXa0hGRmg2N0c4SkFOUmVlTmdJdjd4dkYyV0dwcVV6Uk15NWE4UmNJWG9CV2MyZmNsanBWelRkamVXNERaSDJ4Rkp3MXlveXlyaXZ3YWNjQUlxWHhSYXpQSm9ZcW9MTkpyQTU4ajY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsIm90cCI6MTIzNCwidmVudWUiOiI2NDY0YWUwMDgxMjM4NjFhZmM5YTUyNTQiLCJwaG9uZW51bWJlciI6Ijk2NDA4NjEwNzYiLCJkb2IiOiIyMDIzLTAxLTAxVDAwOjAwOjAwLjAwMFoiLCJjcmVhdGVkQXQiOiIyMDIzLTA1LTE3VDEwOjM1OjQ0LjU5NloiLCJ1cGRhdGVkQXQiOiIyMDIzLTA1LTE4VDA2OjE5OjI2LjQzOFoiLCJfX3YiOjB9LCJpYXQiOjE2ODQzOTA3NzcsImV4cCI6MTY4NTI1NDc3N30.5Se9vIgLFU1_tJL8wRe0EHp53ltgz6HkOF1rYZSuFMA',
        }
      });
      console.log('Author deleted successfully');
    } catch (error) {
      console.error('Error deleting Author:', error);
    }
  };
  

  return (
    <>
    
    <div className="app-page-user-registration">
        <div className="app-card app-card-550">
          <h1 className="app-card__title">User Registration</h1>
          <form onSubmit={handleSubmit}>
            <div className="app-card__content">
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <div className="app-form-field">
                    <TextField
                      label="Full Name"
                      variant="outlined"
                      placeholder="Enter Full Name"
                      value={firstName}
                      onChange={(event) => setFirstName(event.target.value)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className="app-form-field">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Date"
                        value={dob}
                        onChange={(newValue) =>
                          setDob(newValue.format("YYYY-MM-DD"))
                        }
                      />
                    </LocalizationProvider>
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className="app-form-field">
                    <Select
                      displayEmpty
                      value={selectedGender}
                      onChange={handleChange2}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        if (!selected) {
                          return <em>Gender</em>;
                        }
                        return selected;
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {names.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </Grid>
                <Grid item xs={12} md={8}>
                  <div className="app-form-field">
                    <TextField
                      label="Mobile No"
                      type="number"
                      variant="outlined"
                      placeholder="Enter Mobile No"
                      value={phoneNumber}
                      onChange={(event) => setPhoneNumber(event.target.value)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="app-form-field">
                    <TextField
                      label="Address"
                      variant="outlined"
                      placeholder="Enter Address"
                      value={address}
                      onChange={(event) => setAddress(event.target.value)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="app-form-field">
                    <TextField
                      label="Email ID"
                      type="email"
                      variant="outlined"
                      placeholder="Enter Email ID"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="app-form-field">
                    <TextField
                      label="Password"
                      type="password"
                      variant="outlined"
                      placeholder="Enter Password"
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                    />
                  </div>
                </Grid>
              </Grid>
              <div className="app-check-btns-group mt-20">
                <h3 className="app-check-btns-group__title">Select Role</h3>
                <ul className="app-check-btns-group__list mt-5">
  {(roles && roles.results) ? roles.results.map((role) => (
    <li className="app-check-btns-group__list__item" key={role._id}>
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            name="role"
            checked={selectedRoles.includes(role._id)}
            onChange={(event) => handleRoleChange(event, role._id)}
          />
        }
        label={role.title}
      />
    </li>
  )) : null}
</ul>
              </div>
              <div className="app-card__button d-flex justify-content-end mt-20 w-full">
                <Button
                  className="app-btn app-btn-primary"
                  variant="contained"
                  type="submit"
                >
                  Submit Details
                </Button>
              </div>
            </div>
          </form>
        </div>
        <Grid container spacing={2}>
  {userData.map((author, index) => (
    <Grid item xs={12} sm={6} md={4} key={author._id}>
      <Box className="app-card app-card-550">
        <Stack direction="row" alignItems="center" spacing={1}>
          {author.first_name}
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          {author.phonenumber}
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          {author.email}
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          {author.gender}
        </Stack>
      </Box>
      <Button onClick={() => setSelectedIndex(index, userData._id)}>edit</Button>
      <Button onClick={() => handleDelete(author._id)} >delete</Button>
    </Grid>
  ))}
</Grid>
      </div>
    </>
  );
}

export default EditUser;
