import { Box, Grid, Modal, TextField, Tooltip, Typography } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit'
import { useEffect, useState } from "react"
import axios from "axios"
import api from '../config/backend'
import VerifiedIcon from '@mui/icons-material/Verified'
import decode from "jwt-decode"
import { BiSolidHotel } from "react-icons/bi"
import { FaUserLock } from "react-icons/fa6"
import { CiLock } from "react-icons/ci"
import { toast } from "react-toastify"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid grey',
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
  }

export default function Account() {
    const [data, setData] = useState({})
    const [passwordPopup, setPasswordPopup] = useState(false)
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const token = localStorage.getItem("token")
    const decoded = decode(token)

    const changePassword = async () => {
        if (password === "") {
            toast.error("Please enter a password")
            return
        }

        if (password !== confirmPassword) {
            toast.warn("Password and confirm password doesn't match")
            return
        }

        const { data, status } = await axios.post(`${api}/Author/change-password`, {
            token, password
        })

        if (data.success) {
            toast.success("Password set success")
            setPasswordPopup(false)
            setPassword("")
            setConfirmPassword("")
        }
        else {
            toast.error(data.message)
        }
    }

    async function fetchUserData() {
        try {
            const reponse = await axios.post(`${api}/Author/profile`, {
                token
            })

            setData(reponse.data)

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchUserData()
    }, [])

    return (
        <div style={{ backgroundColor: 'white', width: '70%', margin: 'auto', borderRadius: '15px', marginTop: '5%', padding: '30px' }}>
            <div style={{ padding: '20px 20px 0px 20px' }}>
                <Typography variant="h4" color='black' mb='20px'>Account details</Typography>
            </div>
            <Modal
                open={passwordPopup}
                onClose={() => {
                    setPasswordPopup(false)
                    setPassword("")
                    setConfirmPassword("")
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography style={{ marginBottom: "20px" }} id="modal-modal-title" variant="h6" component="h2">
                        Change password
                    </Typography>
                    <input 
                        style={{ width: "75%", height: "34px", border: "1px solid grey", borderRadius: "8px", paddingLeft: "10px", marginBottom: "10px" }}
                        type="password"
                        placeholder="Enter new password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    <input 
                        style={{ width: "75%", height: "34px", border: "1px solid grey", borderRadius: "8px", paddingLeft: "10px", marginBottom: "10px" }}
                        type="password"
                        placeholder="Confirm password"
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                    />
                    <div 
                        onClick={() => changePassword()} 
                        style={{ width: "fit-content", display: "flex", alignItems: "center", gap: "3px", fontSize: "14px", fontWeight: "500", padding: "10px 20px", borderRadius: "8px", color: "white", backgroundColor: "rgb(249, 198, 35)", marginTop: "10px", cursor: "pointer" }}
                    >
                        Save
                    </div>
                </Box>
            </Modal>
            {data.profile &&
                <Grid container>
                    <Grid sx={{ padding: '10px 30px' }} xs={6} lg={4}>
                        <Typography fontWeight={500}>Full Name</Typography>
                        <Typography>{data.profile.full_name}</Typography>
                    </Grid>
                    <Grid sx={{ padding: '10px 30px' }} xs={6} lg={4}>
                        <Typography fontWeight={500}>Gender</Typography>
                        <Typography>{data.profile.gender}</Typography>
                    </Grid>
                    <Grid sx={{ padding: '10px 30px' }} xs={6} lg={4}>
                        <Typography fontWeight={500}>Phone</Typography>
                        <Typography sx={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                            +91{data.profile.phonenumber} {data.profile.is_phone_verified && <VerifiedIcon sx={{ color: 'green', fontSize: '14px' }} />}
                        </Typography>
                    </Grid>
                    <Grid sx={{ padding: '10px 30px' }} xs={6} lg={4}>
                        <Typography fontWeight={500}>Company</Typography>
                        <div>
                            <Typography style={{ margin: '0px' }}>{data.company?.name}</Typography>
                            <Typography style={{ margin: '0px', fontSize: '11px', color: "gray" }}>{data.company?.address}</Typography>
                        </div>
                    </Grid>
                    <Grid sx={{ padding: '10px 30px' }} xs={6} lg={4}>
                        <Typography fontWeight={500}>Email address</Typography>
                        <Typography sx={{ display: 'flex', flexDirection: "column", gap: '5px', marginTop: "5px" }}>
                            <div>
                                {data.profile.email}
                            </div>
                        </Typography>
                        <div 
                            onClick={() => setPasswordPopup(true)} 
                            style={{ width: "fit-content", display: "flex", alignItems: "center", gap: "3px", fontSize: "12px", fontWeight: "500", padding: "8px 10px", borderRadius: "8px", color: "white", backgroundColor: "rgb(249, 198, 35)", marginTop: "10px", cursor: "pointer" }}
                        >
                            <CiLock style={{ width:"20px", height:"20px" }} />
                            Change Password
                        </div>
                    </Grid>
                    {decoded.role &&
                        <Grid sx={{ padding: '10px 30px' }} xs={6} lg={4}>
                            <Typography fontWeight={500}>Role</Typography>
                            <Typography>{decoded.role}</Typography>
                        </Grid>}
                </Grid>}


            {data.subscription &&
                <Grid sx={{ padding: '10px 30px' }} xs={6} lg={4}>
                    <Typography style={{ fontSize: "17px", fontWeight: "700" }}>Subscription</Typography>
                    <div>
                        <Typography style={{ margin: '0px', display: 'flex', alignItems: 'center', gap: '5px' }}>
                            {data.subscription.package.title}
                            <span style={{ fontSize: '10px', background: data.subscription.package.active ? 'green' : 'red', color: 'white', padding: '1.5px 5px', borderRadius: '5px' }}>
                                {data.subscription.package.active ? 'Active' : 'Inactive'}
                            </span>
                        </Typography>
                        <div style={{ fontSize: "15px", fontWeight: "500", marginTop: "10px" }}>
                            <div>
                                Plan: {data.subscription.package.plan}ly
                            </div>
                            <div style={{ display: "flex", gap: "4px", marginTop: "4px", color: "#007F73" }}>
                                <div>
                                    Expires:
                                </div>
                                <div>
                                    {new Date(data.subscription.end).toDateString()}
                                </div>
                            </div>
                        </div>
                        <div style={{ fontSize: "17px", fontWeight: "700", marginTop: "10px" }}>Includes</div>
                        <div style={{ fontFamily: "Roboto", color: "grey", fontSize: "15px", marginTop: "5px" }}>
                            <div style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "6px" }}>
                                <BiSolidHotel style={{ width: "18px", height: "18px" }} />
                                Maximun banquets: {data.subscription.package.maxbanquetcount}
                            </div>
                            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                <FaUserLock style={{ width: "18px", height: "18px" }} />
                                Maximun users: {data.subscription.package.maxuserscount}
                            </div>
                        </div>
                    </div>
                </Grid>
            }
        </div>
    )
}