import { useState, useEffect } from "react";
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Button } from '@mui/material';
import { startOfToday } from 'date-fns';
import axios from 'axios';
import api from '../config/backend';
import { toast } from 'react-toastify';
import { Box, Slider, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs"
import { v4 as uuid } from "uuid"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function PaxAndHallSelect() {
  const theme = useTheme();
  const [personName, setPersonName] = useState([])
  const [selecetedBanquet, setSelectedBanquet] = useState("")
  const [paxrange, setPaxrange] = useState([0, 0])
  const [rangevisible, setRangevisible] = useState(false)
  const [banquets, setBanquets] = useState([])
  const [eventfromdate, setEventfromdate] = useState("")
  const [eventtodate, setEventtodate] = useState("")
  const [maxpax, setMaxpax] = useState(0)

  const navigate = useNavigate()

  const token = localStorage.getItem("token")

  useEffect(() => {
    // First page load - paxrange[1] is 0 (get all banquets without filter)
    if (paxrange[1]) {
      fetchBanquets()
    } else {
      getBanquetList()
    }
  }, [paxrange[0], paxrange[1]])

  const fetchBanquets = async () => {
    const { data, status } = await axios.post(`${api}/BanquetHall/filter-banquetsbypax`, { 
      token, 
      minpax: paxrange[0], 
      maxpax: paxrange[1] 
    })
    
    if (data.success) {
      setBanquets(data.banquets)
    }else{
      toast.error(data.message)
    }
  }

  const getBanquetList = async () => {
    const { data, status } = await axios.post(`${api}/BanquetHall/get-banquets`, { token }
    );
    if (data.success) {
      setBanquets(data.banquets);
    } else {
      toast.error(data.message);
    }
  };

  useEffect(() => {
    if (banquets.length) {
      let max = 0

      banquets.forEach(banquet => {
        if (banquet.capacity_space > max) {
          max = banquet.capacity_space
        }
      })

      setMaxpax(max)
      setPaxrange([paxrange[0], max])
    }
  }, [banquets])

  return (
    <>
      <div className="app-component-pax-hall-select">
        <div className="app-component-pax-hall-select__wrapper" style={{ boxShadow: "none" }}>
          <div 
            className="app-component-pax-hall-select__pax" 
            style={{ cursor: "pointer" }}
            onClick={() => setRangevisible(prev => !prev)}
          >
            <img src="/assets/icons/team.svg" alt="" />
            <span style={{ fontFamily: "'Roboto', sans-serif", width: "80px", textAlign: "center", color: "#62626C", padding: "5px 10px", borderRadius: "20px" }}>
              {paxrange[0] + " - " + paxrange[1]}
            </span>
            {rangevisible ? <Box sx={{ width: 300 }} style={{ position: "absolute", top: "110px", left: "23px", backgroundColor: "white", zIndex: "2", padding: "5px 40px" }}>
            <Typography id="range-slider" gutterBottom>
                Select pax range
            </Typography>
              <Slider
                getAriaLabel={() => 'Temperature range'}
                value={paxrange}
                onClick={e => e.stopPropagation()}
                onChange={(e, range) => setPaxrange(range)}
                valueLabelDisplay="auto"
                min={0}
                max={maxpax}
              />
            </Box> : undefined}
          </div>
          <div className="app-component-pax-hall-select__halls">
            <Select
              displayEmpty
              renderValue={
                selecetedBanquet === "" ? () => "Select banquet" : undefined
              }
              value={selecetedBanquet}
              onChange={e => setSelectedBanquet(e.target.value)}
              input={<OutlinedInput />}
              MenuProps={MenuProps}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {banquets && banquets.map((hall) => (
                <MenuItem
                  key={hall._id}
                  value={hall._id}
                  style={getStyles(hall, personName, theme)}
                >
                  {hall.hall_title}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <div className="app-component-pax-hall-select__date">
          <div className="ml-15">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disablePast
                label="From Date"
                value={eventfromdate}
                onChange={event =>
                  setEventfromdate(event.$d)
                }
                format="DD-MM-YYYY"
              />
            </LocalizationProvider>
          </div>
          <div className="ml-15">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disablePast
                label="To Date"
                minDate={dayjs(eventfromdate)}
                value={eventtodate}
                onChange={event =>
                  setEventtodate(event.$d)
                }
                format="DD-MM-YYYY"
              />
            </LocalizationProvider>
          </div>
        </div>
        <Button 
          className="app-btn app-btn-primary" 
          variant="contained"
          onClick={() => navigate("/banquet-bookings", {
            state: { banquet: selecetedBanquet, eventfromdate, eventtodate, refresh: uuid() }}
          )}
        >Search</Button>
      </div>
    </>
  )
}
export default PaxAndHallSelect;