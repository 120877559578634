import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Button, Box, Stack } from "@mui/material";

import axios from 'axios';

function AuthorPackage() {

    const [userData, setUserData] = React.useState([]);

    console.log(userData)

    const [authors, setAuthors] = React.useState([]);

    console.log(authors)

  React.useEffect(() => {
    fetchData();
    fetchUserData();
  }, []);

  const fetchData = () => {
    axios.get('http://192.46.209.97:6060/api/Package/list')
      .then(response => {
        const data = response.data.results;
        setAuthors(data);
      })
      .catch(error => {
        // Handle the error
        console.error('Error fetching authors:', error);
      });
  };

      const fetchUserData = async (e) => {
        try {
          const response = await axios.get("http://192.46.209.97:6060/api/Author/list", {
            headers: {
              accept: 'application/json',
              'authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsImZpcnN0X25hbWUiOiJTaXRhcmEiLCJsYXN0X25hbWUiOiJIb3RlbCIsImVtYWlsIjoic2l0YXJhQHRlc3QuY29tIiwiaXNfcGhvbmVfdmVyaWZpZWQiOmZhbHNlLCJwaG9uZV92ZXJpZmljYXRpb24iOm51bGwsImltYWdlIjpudWxsLCJyb2xlIjpbIjY0NjRhODk5NDQ5YWUwOTA2NGU1MjZmOCJdLCJnZW5kZXIiOiJNYWxlIiwiYWRkcmVzcyI6InN0cmluZyIsInBhc3N3b3JkIjoiJDJiJDEwJEZYSHJSQVFPbVRKZktPZmw2djZsQmVxQS85Smg1UzRvZk1iRTdaNS9FcWRpN1ByUEk4TjRDIiwicmVzZXRQYXdvcmRUb2tlbiI6bnVsbCwicmVmcmVzaFRva2VuIjoiT3hTeER5ekVlYlpHRW1EaVBSTlM0VXc3YTFTa2dibThKR1pRclltZXFuaFY3Tk5URXF3S3VkeHFGRmFFaVJzSHN6M2tmSmxmdTVkcGFKNUx4cDBITEg2Z3pjNndJWHlaZXlWdzFpaDd6M0RKQ2puYlRiekVneGlKMjFteWFYbDc4NkRpWVhvcktIUElRYzNIb252bDZYMldieUIzQUU0UjdJUUl0NjBmQ2xsdHB1U2F3WXY3YXdBaU1DZVhQTjJMZ2J2SmsxZG4yN1QwZkRZV09RUGd4VzNudUN4RGxqbnFucU1lVUJVYTc1dk90Q2t2UFN3TGdPQWVLWTJYTWpYRjY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsIm90cCI6MTIzNCwidmVudWUiOiI2NDY0YWUwMDgxMjM4NjFhZmM5YTUyNTQiLCJwaG9uZW51bWJlciI6Ijk2NDA4NjEwNzYiLCJkb2IiOiIyMDIzLTAxLTAxVDAwOjAwOjAwLjAwMFoiLCJjcmVhdGVkQXQiOiIyMDIzLTA1LTE3VDEwOjM1OjQ0LjU5NloiLCJ1cGRhdGVkQXQiOiIyMDIzLTA1LTE4VDE1OjQ5OjU1LjcwM1oiLCJfX3YiOjB9LCJpYXQiOjE2ODQ0MjUwMTAsImV4cCI6MTY4NTI4OTAxMH0.amMaiyOdWHjqSSrPtq52a0zyiUz87niWS0b70mDoO4I',
            },
          });
          console.log(response)
          setUserData(response.data.results);
        } catch (error) {
          console.error(error);
        }
      };

      const [venue, setVenue] = React.useState("")
      const [packageId, setPackageId] = React.useState("")
      const [amount, setAmount] = React.useState("")

      const handleSubmit = event => {
        event.preventDefault();

        const data = {
            "author_id": venue,
  "package_id": packageId,
  "amount": parseInt(amount)
        }
    
        axios.post('http://192.46.209.97:6060/api/AuthorPackage/create', data,{
            accept: 'application/json',
  'Content-Type': 'application/json' ,
  authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsImZpcnN0X25hbWUiOiJTaXRhcmEiLCJsYXN0X25hbWUiOiJIb3RlbCIsImVtYWlsIjoic2l0YXJhQHRlc3QuY29tIiwiaXNfcGhvbmVfdmVyaWZpZWQiOmZhbHNlLCJwaG9uZV92ZXJpZmljYXRpb24iOm51bGwsImltYWdlIjpudWxsLCJyb2xlIjpbIjY0NjRhODk5NDQ5YWUwOTA2NGU1MjZmOCJdLCJnZW5kZXIiOiJNYWxlIiwiYWRkcmVzcyI6InN0cmluZyIsInBhc3N3b3JkIjoiJDJiJDEwJEZYSHJSQVFPbVRKZktPZmw2djZsQmVxQS85Smg1UzRvZk1iRTdaNS9FcWRpN1ByUEk4TjRDIiwicmVzZXRQYXdvcmRUb2tlbiI6bnVsbCwicmVmcmVzaFRva2VuIjoiT3hTeER5ekVlYlpHRW1EaVBSTlM0VXc3YTFTa2dibThKR1pRclltZXFuaFY3Tk5URXF3S3VkeHFGRmFFaVJzSHN6M2tmSmxmdTVkcGFKNUx4cDBITEg2Z3pjNndJWHlaZXlWdzFpaDd6M0RKQ2puYlRiekVneGlKMjFteWFYbDc4NkRpWVhvcktIUElRYzNIb252bDZYMldieUIzQUU0UjdJUUl0NjBmQ2xsdHB1U2F3WXY3YXdBaU1DZVhQTjJMZ2J2SmsxZG4yN1QwZkRZV09RUGd4VzNudUN4RGxqbnFucU1lVUJVYTc1dk90Q2t2UFN3TGdPQWVLWTJYTWpYRjY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsIm90cCI6MTIzNCwidmVudWUiOiI2NDY0YWUwMDgxMjM4NjFhZmM5YTUyNTQiLCJwaG9uZW51bWJlciI6Ijk2NDA4NjEwNzYiLCJkb2IiOiIyMDIzLTAxLTAxVDAwOjAwOjAwLjAwMFoiLCJjcmVhdGVkQXQiOiIyMDIzLTA1LTE3VDEwOjM1OjQ0LjU5NloiLCJ1cGRhdGVkQXQiOiIyMDIzLTA1LTE4VDE1OjQ5OjU1LjcwM1oiLCJfX3YiOjB9LCJpYXQiOjE2ODQ0MjUwMTAsImV4cCI6MTY4NTI4OTAxMH0.amMaiyOdWHjqSSrPtq52a0zyiUz87niWS0b70mDoO4I',
        })
          .then(response => {
            // Handle the successful response
            console.log('Post request successful:', response.data);
          })
          .catch(error => {
            // Handle the error
            console.error('Error creating AuthorPackage:', error);
          });
      };

      const [authorPackages, setAuthorPackages] = React.useState([]);

  React.useEffect(() => {
    // Make the API call and save the data to the state
    axios.get('http://192.46.209.97:6060/api/AuthorPackage/list',{
        accept: 'application/json',
        authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsImZpcnN0X25hbWUiOiJTaXRhcmEiLCJsYXN0X25hbWUiOiJIb3RlbCIsImVtYWlsIjoic2l0YXJhQHRlc3QuY29tIiwiaXNfcGhvbmVfdmVyaWZpZWQiOmZhbHNlLCJwaG9uZV92ZXJpZmljYXRpb24iOm51bGwsImltYWdlIjpudWxsLCJyb2xlIjpbIjY0NjRhODk5NDQ5YWUwOTA2NGU1MjZmOCJdLCJnZW5kZXIiOiJNYWxlIiwiYWRkcmVzcyI6InN0cmluZyIsInBhc3N3b3JkIjoiJDJiJDEwJEZYSHJSQVFPbVRKZktPZmw2djZsQmVxQS85Smg1UzRvZk1iRTdaNS9FcWRpN1ByUEk4TjRDIiwicmVzZXRQYXdvcmRUb2tlbiI6bnVsbCwicmVmcmVzaFRva2VuIjoiT3hTeER5ekVlYlpHRW1EaVBSTlM0VXc3YTFTa2dibThKR1pRclltZXFuaFY3Tk5URXF3S3VkeHFGRmFFaVJzSHN6M2tmSmxmdTVkcGFKNUx4cDBITEg2Z3pjNndJWHlaZXlWdzFpaDd6M0RKQ2puYlRiekVneGlKMjFteWFYbDc4NkRpWVhvcktIUElRYzNIb252bDZYMldieUIzQUU0UjdJUUl0NjBmQ2xsdHB1U2F3WXY3YXdBaU1DZVhQTjJMZ2J2SmsxZG4yN1QwZkRZV09RUGd4VzNudUN4RGxqbnFucU1lVUJVYTc1dk90Q2t2UFN3TGdPQWVLWTJYTWpYRjY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsIm90cCI6MTIzNCwidmVudWUiOiI2NDY0YWUwMDgxMjM4NjFhZmM5YTUyNTQiLCJwaG9uZW51bWJlciI6Ijk2NDA4NjEwNzYiLCJkb2IiOiIyMDIzLTAxLTAxVDAwOjAwOjAwLjAwMFoiLCJjcmVhdGVkQXQiOiIyMDIzLTA1LTE3VDEwOjM1OjQ0LjU5NloiLCJ1cGRhdGVkQXQiOiIyMDIzLTA1LTE4VDE1OjQ5OjU1LjcwM1oiLCJfX3YiOjB9LCJpYXQiOjE2ODQ0MjUwMTAsImV4cCI6MTY4NTI4OTAxMH0.amMaiyOdWHjqSSrPtq52a0zyiUz87niWS0b70mDoO4I'

    })
      .then(response => {
        console.log(response.data.results)
        const data = response.data.results;
        setAuthorPackages(data);
      })
      .catch(error => {
        // Handle the error
        console.error('Error fetching AuthorPackage list:', error);
      });
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`http://192.46.209.97:6060/api/AuthorPackage/delete/${id}`, {
        headers: {
          accept: 'application/json',
          authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsImZpcnN0X25hbWUiOiJTaXRhcmEiLCJsYXN0X25hbWUiOiJIb3RlbCIsImVtYWlsIjoic2l0YXJhQHRlc3QuY29tIiwiaXNfcGhvbmVfdmVyaWZpZWQiOmZhbHNlLCJwaG9uZV92ZXJpZmljYXRpb24iOm51bGwsImltYWdlIjpudWxsLCJyb2xlIjpbIjY0NjRhODk5NDQ5YWUwOTA2NGU1MjZmOCJdLCJnZW5kZXIiOiJNYWxlIiwiYWRkcmVzcyI6InN0cmluZyIsInBhc3N3b3JkIjoiJDJiJDEwJEZYSHJSQVFPbVRKZktPZmw2djZsQmVxQS85Smg1UzRvZk1iRTdaNS9FcWRpN1ByUEk4TjRDIiwicmVzZXRQYXdvcmRUb2tlbiI6bnVsbCwicmVmcmVzaFRva2VuIjoiT3hTeER5ekVlYlpHRW1EaVBSTlM0VXc3YTFTa2dibThKR1pRclltZXFuaFY3Tk5URXF3S3VkeHFGRmFFaVJzSHN6M2tmSmxmdTVkcGFKNUx4cDBITEg2Z3pjNndJWHlaZXlWdzFpaDd6M0RKQ2puYlRiekVneGlKMjFteWFYbDc4NkRpWVhvcktIUElRYzNIb252bDZYMldieUIzQUU0UjdJUUl0NjBmQ2xsdHB1U2F3WXY3YXdBaU1DZVhQTjJMZ2J2SmsxZG4yN1QwZkRZV09RUGd4VzNudUN4RGxqbnFucU1lVUJVYTc1dk90Q2t2UFN3TGdPQWVLWTJYTWpYRjY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsIm90cCI6MTIzNCwidmVudWUiOiI2NDY0YWUwMDgxMjM4NjFhZmM5YTUyNTQiLCJwaG9uZW51bWJlciI6Ijk2NDA4NjEwNzYiLCJkb2IiOiIyMDIzLTAxLTAxVDAwOjAwOjAwLjAwMFoiLCJjcmVhdGVkQXQiOiIyMDIzLTA1LTE3VDEwOjM1OjQ0LjU5NloiLCJ1cGRhdGVkQXQiOiIyMDIzLTA1LTE4VDE1OjQ5OjU1LjcwM1oiLCJfX3YiOjB9LCJpYXQiOjE2ODQ0MjUwMTAsImV4cCI6MTY4NTI4OTAxMH0.amMaiyOdWHjqSSrPtq52a0zyiUz87niWS0b70mDoO4I',
        }
      });
      authorPackages()
      console.log('AuthorPackage deleted successfully');
    } catch (error) {
      console.error('Error deleting AuthorPackage:', error);
    }
  };
  

  return (
    <>
    <form onSubmit={handleSubmit}>
                             <Select
  displayEmpty
  value={packageId} // Assuming you have a state variable for the selected designation
  onChange={(e)=>setPackageId(e.target.value)} // Assuming you have a function to handle designation changes
  input={<OutlinedInput />}
  MenuProps={""}
  inputProps={{ 'aria-label': 'Without label' }}
>
  <MenuItem value="" disabled>
    Select package
  </MenuItem>
  {authors.map((item) => (
    <MenuItem key={item._id} value={item._id}>
      {item.title}{console.log(item)}
    </MenuItem>
  ))}
</Select>
<Select
  displayEmpty
  value={venue} // Assuming you have a state variable for the selected designation
  onChange={(e)=>setVenue(e.target.value)} // Assuming you have a function to handle designation changes
  input={<OutlinedInput />}
  MenuProps={""}
  inputProps={{ 'aria-label': 'Without label' }}
>
  <MenuItem value="" disabled>
    Select author/ venue
  </MenuItem>
  {userData.map((item) => (
    <MenuItem key={item._id} value={item._id}>
      {item.first_name}
    </MenuItem>
  ))}
</Select>

<TextField
                      label="Total Amount"
                      variant="outlined"
                      placeholder="Enter Total Amount"
                      value={amount}
                      onChange={(event) => setAmount(event.target.value)}
                    />
                    <Button type="submit">submit</Button>
                    </form>
                    <div>
      <h1>Author Packages</h1>
      <ul>
        {authorPackages.map((authorPackage) => (
            <>
          <li key={authorPackage._id}>
            Author ID: {authorPackage.author_id}<br />
            Package ID: {authorPackage.payment_status}<br />
            Amount: {authorPackage.amount}<br />
            Package Information: {authorPackage.package_information.title}<br />
          </li>
          <button onClick={()=>handleDelete(authorPackage._id)}>delete</button>
          </>
        ))}
      </ul>
    </div>
    </>
  )
}

export default AuthorPackage