
import { Grid, Card, CardContent, Typography, Button, CardMedia } from '@mui/material'
import React, { useState, useRef, useEffect } from 'react'
import { Tabs, Tab, Box } from '@mui/material'
import "./css/Home.css"
import Header from '../components/Header'
import Footer from '../components/Footer'
import { useLocation, useNavigate } from 'react-router-dom'
import { origin } from "../config/backend"
import api from "../config/backend"
import axios from "axios"

const TabPanel = (props) => {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

const tabStyles = {
    backgroundColor: 'white',
    '&.Mui-selected': {
        backgroundColor: '#D8232A',
        borderRadius: '20px',
        color: '#ffff',
    },
}

const Home = () => {
    const [tabValue, setTabValue] = useState(0)
    const [pricing, setPricing] = useState(undefined)
    const [currentDuration, setCurrentDuration] = useState("Month")
    const [collage, setCollage] = useState([])

    const pricingRef = useRef(null)

    const navigate = useNavigate()

    const { state } = useLocation()

    const numberToInternational = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    const fetchPlans = async () => {
        const { data, status } = await axios.get(`${api}/Package/list`)

        if (status === 200) {
            setPricing(data.results)
        }
    }

    useEffect(() => {
        if (state?.scroll) {
            pricingRef.current?.scrollIntoView({ behavior: 'smooth' })
        }
    }, [state])

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue)
        setCurrentDuration(newValue === 0 ? "Month" : "Year")
    }

    useEffect(() => {
        if (pricing) {
            const data = pricing.filter(item => item.plan === currentDuration)

            setCollage(data)
        }
    }, [tabValue, pricing])

    const handleHash = (hash) => {
        localStorage.setItem('packagehash', hash)
        window.location.href = origin + "/signupPage?package=" + hash
    }

    useEffect(() => {
        fetchPlans()
    }, [])

    console.log({ tabValue, collage, currentDuration })

    return (
        <div className="_5pxd">
            <Header test={pricingRef} />
            <div className='master'>
                <div className='mast'>
                    <div className='image-container bg-image app-banner-content'>
                        <h1>Master your venue with our
                            <br>
                            </br>
                            <span >
                                Management Solutions!</span></h1>

                        <p>Unleash your venue's full potential by conquering challenges and streamlining processes effortlessly.</p>


                        <div className='app-banner-btn'>
                            <Button onClick={() => handleHash("7e956eaa-c3a0-4a73-82b7-36e41810e2df")}  >
                                Start for free
                            </Button>
                        </div>
                    </div>
                </div>

                <img src="Images/Banquet.jpg" />
            </div>
            <div className='back'>
                <div className='contain'>
                    <Grid container spacing={2} className="image">
                        <Grid item xs={12} md={6} order={{ xs: 1, sm: 1 }}>
                            <div className='cont'>
                                <h1>Hassle free onboarding process</h1>
                                <p>Our venue management website offers a streamlined and hassle-free onboarding process that allows you to set up your account, create your profile, and start managing your bookings in minutes.</p>

                                <div style={{ marginTop: "40px" }} className='start'>
                                    <Button onClick={() => handleHash("7e956eaa-c3a0-4a73-82b7-36e41810e2df")} >
                                        Start for free
                                    </Button>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} order={{ xs: 2, sm: 2 }}>
                            <div className='position2'>
                                <img src="Images/Image1.svg" alt="Image 1" />
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className='contain1'>
                    <Grid container spacing={2} className="image">
                        <Grid item xs={12} sm={6} order={{ xs: 4, sm: 3 }}>
                            <div className='position3'>
                                <img src="Images/Group 17380.svg" alt="Image 2" />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} order={{ xs: 3, sm: 4 }}>
                            <div className='cont'>
                                <h1>Affordable and competitive pricing</h1>
                                <p>Our pricing is designed to meet your financial needs and give you the best value for your money. We constantly monitor the market and adjust our rates to ensure that we are always offering you the lowest prices possible.</p>

                                <div style={{ marginTop: "40px" }} className='start'>
                                    <Button onClick={() => handleHash("7e956eaa-c3a0-4a73-82b7-36e41810e2df")} >
                                        Start for free
                                    </Button>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className='contain2'>
                    <Grid container spacing={2} className="image">
                        <Grid item xs={12} sm={6} order={{ xs: 5, sm: 5 }}>
                            <div className='cont'>
                                <h1>Effortless Reservations</h1>
                                <p>With our venue management platform, you can easily accept and manage reservations for your events. You can also view the availability and details of your venues in a graphical format.</p>
                                <div style={{ marginTop: "40px" }} className='start'>
                                    <Button onClick={() => handleHash("7e956eaa-c3a0-4a73-82b7-36e41810e2df")} >
                                        Start for free
                                    </Button>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} order={{ xs: 6, sm: 6 }}>
                            <div className='position4'>
                                <img src="Images/Image3.png" alt="Image 3" />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div ref={pricingRef} className='bg' >
                <h1 className='pricing' >Pricing</h1>
                <div className="borderRadiusTabsWrapper">
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        className='border'
                        centered
                        indicatorColor="transparent"
                    >
                        <Tab label="Monthly" sx={tabStyles} />
                        <Tab label="Yearly" sx={tabStyles} />
                    </Tabs>
                </div>
                <TabPanel value={tabValue} index={0}>
                    <Typography>
                        <Grid container spacing={2}>
                            {collage.sort((a, b) => a.price - b.price).map((card, index) => (
                                <Grid item xs={3} sm={6} md={3} lg={3} key={index}>
                                    <Card sx={{ height: "100%" }} className='card-size'>
                                        <div className='margin-top-color' style={{ backgroundColor: card.pallete }}>

                                        </div>
                                        <CardContent sx={{ height: "100%" }}>
                                            <Typography variant="h5" component="div" className='titlee'>
                                                {card.title}
                                            </Typography>
                                            <Typography variant="body2" className='content'>
                                                {card.subtitle}
                                            </Typography>
                                            <div className="days">
                                                <div className="days-container">
                                                    <span className="half-above-line">₹</span>
                                                    <p>{numberToInternational(card.price)}</p>
                                                    <span className='month'>Per / Month</span>
                                                </div>
                                            </div>
                                            <Typography className='includes'>
                                                Includes
                                            </Typography>
                                            <Typography variant="body2" className="tick-marks">
                                                {card.features.map((item, index) => (
                                                    <p key={index}>
                                                        <span className="tick-mark" >
                                                            <img src="Images/Vector.png" />
                                                        </span>
                                                        {item}
                                                    </p>
                                                ))}
                                            </Typography>
                                            <div className="oxeye-knur">
                                                <h4>Features</h4>
                                            </div>
                                            <Typography variant="body2" className="tick-marks">
                                                {card.assets.map((item, index) => (
                                                    <p key={index}>
                                                        <span className="tick-mark" >
                                                            <img src="Images/Vector.png" />
                                                        </span>
                                                        {item}
                                                    </p>
                                                ))}
                                            </Typography>
                                            <div className='card-button' style={{ marginTop: card.title === "Free" ? "47px" : undefined }}>
                                                <Button onClick={() => handleHash(card.hash)} style={{ backgroundColor: card.pallete, color: '#ffffff', fontSize: '12px' }}>
                                                    GET STARTED
                                                </Button>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Typography>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Typography>
                        <Grid container spacing={2}>
                            {collage.map((card, index) => (
                                <Grid item xs={3} sm={6} md={3} lg={3} key={index}>
                                    <Card sx={{ height: "100%" }} className='card-size'>
                                        <div className='margin-top-color' style={{ backgroundColor: card.pallete }}>

                                        </div>
                                        <CardContent sx={{ height: "100%" }}>
                                            <Typography variant="h5" component="div" className='titlee'>
                                                {card.title}
                                            </Typography>
                                            <Typography variant="body2" className='content'>
                                                {card.subtitle}
                                            </Typography>
                                            <div className="days">
                                                <div className="days-container">
                                                    <span className="half-above-line">₹</span>
                                                    <p>{numberToInternational(card.price)}</p>
                                                    <span className='month'>Per / Year</span>
                                                </div>
                                            </div>
                                            <Typography className='includes'>
                                                Includes
                                            </Typography>
                                            <Typography variant="body2" className="tick-marks">
                                                {card.features.map((item, index) => (
                                                    <p key={index}>
                                                        <span className="tick-mark" >
                                                            <img src="Images/Vector.png" />
                                                        </span>
                                                        {item}
                                                    </p>
                                                ))}
                                            </Typography>
                                            <div className="oxeye-knur">
                                                <h4>Features</h4>
                                            </div>
                                            <Typography variant="body2" className="tick-marks">
                                                {card.assets.map((item, index) => (
                                                    <p key={index}>
                                                        <span className="tick-mark" >
                                                            <img src="Images/Vector.png" />
                                                        </span>
                                                        {item}
                                                    </p>
                                                ))}
                                            </Typography>
                                            <div className='card-button' style={{ marginTop: card.title === "Free" ? "47px" : undefined }}>
                                                <Button onClick={() => handleHash(card.hash)} style={{ backgroundColor: card.pallete, color: '#ffffff', fontSize: '12px' }}>
                                                    GET STARTED
                                                </Button>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Typography>
                </TabPanel>
            </div>
            <div className='master'>
                <div className='mast app-new-unlock-bg'>
                    <h1>Unlock the potential of your venue with
                        <br>
                        </br>
                        <span>
                            powerful management solutions!</span></h1>
                    <p>Maximize your venue's success with our transformative management solutions.</p>
                    <div className="position unlock-btn" >
                        <Button onClick={() => handleHash("7e956eaa-c3a0-4a73-82b7-36e41810e2df")} >
                            Start for free
                        </Button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    )
}

export default Home