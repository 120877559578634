import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import axios from "axios";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Slider from '@mui/material/Slider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { toast } from 'react-toastify';
import api from '../../config/backend';


const seatingCapacityOptions = [
  { label: '0-150', value: "150" },
  { label: '0-500', value: "500" },
  { label: '0-1000', value: "1000" },
  { label: '0-2000', value: "2000" },
];

const style = {
  backgroundColor: " #F9C623",
  color: "black",
  paddingLeft: " 30px",
  paddingRight: "30px",
  paddingTop: "10px",
  paddingBottom: "10px",
  borderRadius: "10px",
}

const AddBanquet = () => {
  const [selectedCapacity, setSelectedCapacity] = useState('');
  const [selectedArea, setSelectedArea] = useState("");
  const [hallname, sethallName] = useState('');
  const [banquets, setBanquets] = useState([]);
  const [halltypes, setHalltypes] = useState("");
  const [editmode, setEditmode] = useState({
    page: undefined,
    edit: false,
    data: undefined,
  });

  const token = localStorage.getItem("token");

  console.log(token)


  console.log({ banquets })

  const getBanquetList = async () => {
    const { data, status } = await axios.post(
      `${api}/BanquetHall/get-banquets`, { token }
    );
    console.log({ data })
    if (data.success) {
      setBanquets(data.banquets);
    } else {
      toast.error(data.message);
    }
  };

  useEffect(() => {
    getBanquetList()
  }, [])

  const createBanquethall = async () => {
    const { data, status } = await axios.post(
      `${api}/BanquetHall/add-banquet`,
      {
        token,
        hall_title: hallname,
        hall_type: halltypes,
        area: selectedArea,
        capacity_space: selectedCapacity,
      }
    );

    if (data.success) {
      toast.success(data.message);
      getBanquetList();
      setSelectedArea("")
      sethallName("")
      setSelectedCapacity("")
      setHalltypes("")
    } else {
      toast.error(data.message);
    }
  };

  // update
  const updateBanquethall = async () => {
    const { data, status } = await axios.post(
      `${api}/BanquetHall/update-banquet`,
      {
        token,
        hash: editmode.data,
        hall_title: hallname,
        hall_type: halltypes,
        area: selectedArea,
        capacity_space: selectedCapacity,
      }
    );

    if (data.success) {
      toast.success(data.message);
      setHalltypes("");
      sethallName("");
      setSelectedArea("");
      setSelectedCapacity("");
      setEditmode({ page: undefined, edit: false, data: undefined });
      getBanquetList();
    } else {
      toast.error(data.message);
    }
  };


  return (
    <React.Fragment>
      <h3 className="">Add banquet hall</h3>
      <p style={{ color: '#7C7F82', marginBottom: '30px', fontWeight: 500 }}>
        Add a new banquet hall to our venue offerings by filling out the form below
      </p>
      <Grid container rowSpacing={2} columnSpacing={5}>
        <Grid item xs={12} md={6}>
          <div className="app-form-field">
            <TextField
              required
              label="Hall Name"
              variant="outlined"
              placeholder="Enter Company Name"
              value={hallname}
              onChange={(event) => sethallName(event.target.value)}
            />
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select Halltype</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={halltypes}
                label="Select Halltype"
                onChange={(e) => { setHalltypes(e.target.value) }}
              >
                <MenuItem value="Indoor">
                  Indoor
                </MenuItem >
                <MenuItem value="Outdoor">
                  Outdoor
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Selected Capacity"
            value={selectedCapacity}
            onChange={(event) => setSelectedCapacity(event.target.value)}
            fullWidth
          />
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <ToggleButtonGroup
                value={selectedCapacity}
                exclusive
                onChange={(e) => setSelectedCapacity(e.target.value)}
                aria-label="seating capacity options"
                fullWidth
              >
                {seatingCapacityOptions.map((option) => (
                  <ToggleButton key={option.label} value={option.value}>
                    {option.label}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </FormControl>
          </Box>

        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ width: 350 }}>
            <Typography id="input-slider" gutterBottom>
              Select area
            </Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <div className="app-form-field">
                  <TextField
                    required
                    label="Area"
                    variant="outlined"
                    placeholder="Slect Area"
                    value={selectedArea}
                    onChange={(e) => setSelectedArea(e.target.value)}
                  />
                </div>
                <Slider
                  value={selectedArea}
                  onChange={(e) => setSelectedArea(e.target.value)}
                  aria-labelledby="input-slider"
                  min={0}
                  max={1500}
                />
              </Grid>
              <Box sx={{ marginTop: "60px", border: '1px solid #ccc', padding: '4px 8px', borderRadius: '4px' }}>
                <Typography variant="body1">
                  {selectedArea} Sq Ft
                </Typography>
              </Box>
            </Grid>
          </Box>

        </Grid>
        <Grid item xs={12} md={6}>

          <Box mt={2}>
            <Button style={style}
              onClick={() =>
                editmode.edit && editmode.page === "banquet"
                  ? updateBanquethall()
                  : createBanquethall()
              }
            >
              {editmode.edit && editmode.page === "banquet"
                ? "Update details"
                : "Add banquet hall"}
            </Button>
            {editmode.edit && editmode.page === "banquet" ? (
              <Button
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => {
                  setEditmode({
                    page: undefined,
                    edit: false,
                    data: undefined,
                  });
                  sethallName("");
                  setHalltypes("");
                  setSelectedArea("");
                  setSelectedCapacity("")
                }}
              >
                cancel
              </Button>
            ) : undefined}
          </Box>

        </Grid>
      </Grid>
      <div className="tables_div">

        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Banquet Name</TableCell>
                <TableCell>Hall Type</TableCell>
                <TableCell>Area</TableCell>
                <TableCell>Capacity Space</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {banquets.map((hall, index) => {
                console.log({ hall })
                return (<TableRow key={index}>
                  <TableCell>{hall.hall_title}</TableCell>
                  <TableCell>{hall.hall_type}</TableCell>
                  <TableCell>{hall.area}</TableCell>
                  <TableCell>{hall.capacity_space}</TableCell>
                  <TableCell>
                    <span
                      style={{ color: "blueviolet", cursor: "pointer" }}
                      onClick={() => {
                        sethallName(hall.hall_title);
                        setHalltypes(hall.hall_type);
                        setSelectedArea(hall.area);
                        setSelectedCapacity(hall.capacity_space);
                        setEditmode({
                          page: "banquet",
                          edit: true,
                          data: hall.hash,
                        });
                      }}
                    >
                      Edit
                    </span>
                  </TableCell>
                </TableRow>)
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </React.Fragment>
  );
};


export default AddBanquet;