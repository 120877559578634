import * as React from "react";
import { useTheme } from "@mui/material/styles";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  TextField,
  Select,
  OutlinedInput,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
  List,
} from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import api from "../../config/backend";
import axios from "axios";

function UserRegistration() {


  const token = localStorage.getItem("token")

  const theme = useTheme();

  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);

  const names = ["Male", "Female", "Other"];

  const [firstname, setFirstname] = useState("");
  const [email, setEmail] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [selectedRole, setSelectedRole] = useState("");

  const getRolesList = async () => {
    const { data, status } = await axios.post(`${api}/Role/role-access`, {
      token,
    });

    if (data.success) {
      setRoles(data.roles);
    } else {
      toast.error(data.message);
    }
  };

  const submit = async () => {
    const payload = {
      token,
      full_name: firstname,
      email,
      gender: selectedGender,
      phonenumber,
      role: selectedRole,
    };

    const { data, status } = await axios.post(
      `${api}/Author/create-user`,
      payload
    );

    if (data.success) {
      toast.success(data.message);
      setFirstname("");
      setEmail("");
      setSelectedGender("");
      setPhonenumber("");
      getUserList();
      console.log(email);
    } else {
      toast.error(data.message);
    }
  };


  console.log(users);

  React.useEffect(() => {
    getUserList();
    getRolesList()
  }, []);

  const getUserList = async () => {
    const token = localStorage.getItem("token");
    const { data, status } = await axios.post(`${api}/Author/get-users`, {
      token,
    });
    if (data.success) {
      setUsers(data.users);
    } else {
      toast.error(data.message);
    }
  };

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return (
    <>
      <div className="app-page-user-registration">
        <div className="app-card">
          <h1 className="app-card__title" >Create user</h1>
          <Typography variant="h6" component={"p"} style={{ margin: "0px", padding: "0px", fontSize: "14px", marginBottom: "20px" }}>Add a new user for managing banquet hall bookings</Typography>
          <div className="app-card__content" style={{ margin: "0px", padding: "0px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <div className="app-form-field">
                  <TextField
                    required
                    label="Full Name"
                    variant="outlined"
                    placeholder="Enter Full Name"
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={4}>
                <div className="app-form-field">
                  <Select
                    required
                    displayEmpty
                    value={selectedGender}
                    onChange={(e) => setSelectedGender(e.target.value)}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                      if (!selected) {
                        return <em>Gender</em>;
                      }
                      return selected;
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {names.map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="app-form-field">
                  <TextField
                    required
                    label="Mobile No"
                    type="number"
                    variant="outlined"
                    placeholder="Enter Mobile No"
                    value={phonenumber}
                    onChange={(e) => setPhonenumber(e.target.value)}
                  />
                </div>
              </Grid>

              <Grid item xs={6}>
                <div className="app-form-field">
                  <TextField
                    required
                    label="Email ID"
                    type="email"
                    variant="outlined"
                    placeholder="Enter Email ID"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginTop: "20px" }}><span style={{ fontWeight: 600 }}>Select role</span></div>
          <div style={{ display: "flex" }} className="bologna-hip">
            {roles.length
              ? roles.map((role, index) => (
                <List
                  className="app-check-btns-group__list__item"
                  key={index}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={`checkbox-${index}`}
                        size="small"
                        name="role"
                        checked={selectedRole === role.title}
                        onChange={() => setSelectedRole(role.title)}
                      />
                    }
                    label={
                      <label htmlFor={`checkbox-${index}`}>
                        {role.title}
                      </label>
                    }
                  />
                </List>
              ))
              : undefined}
          </div>
          <Button
            className="app-btn app-btn-primary"
            variant="contained"
            onClick={submit}
          >
            Create User
          </Button>

        </div>
        <div className="app-component-table" style={{ marginTop: "30px" }}>
          <h1 className="app-card__title">User Details</h1>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Full Name</TableCell>
                  <TableCell>Gender</TableCell>
                  <TableCell>Mobile No</TableCell>
                  <TableCell>Email ID</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Email Verification</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users &&
                  users.map((user, index) => (
                    <TableRow key={index}>
                      {console.log(user)}
                      <TableCell>{user.full_name}</TableCell>

                      <TableCell>{user.gender}</TableCell>
                      <TableCell>{user.phonenumber}</TableCell>

                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.role}</TableCell>
                      <TableCell>
                        {user.email_verification.status ? (
                          <span style={{ color: "green" }}>Verified</span>
                        ) : (
                          <span style={{ color: "red" }}>Pending</span>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      {/* <img className="app-tree-bg" src="/assets/images/tree-bg.svg" /> */}
    </>
  );
}
export default UserRegistration;
