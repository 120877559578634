import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import SimpleBarReact from "simplebar-react";
import Popover from "@mui/material/Popover";
import BookingListMenu from "../../components/BookingListMenu";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import api from "../../config/backend";
import Grid from "@mui/material/Grid";
import PaxAndHallSelect from "../../components/PaxAndHallSelect";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import "./css/BookingAvailability.css"
import dayjs from "dayjs"

function BookingAvailability() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [banquets, setBanquets] = useState([])
    const [events, setEvents] = useState({})

    const navigate = useNavigate()

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const [slotpopupInfo, setSlotpopupInfo] = useState(undefined)

    // date is formatted in toDateString, slot is clicked slot i.e. breakfast, lunch or dinner
    const handleMouseEnter = (event, date, slot, showpopover) => {
        if (showpopover === false) {
            return
        }

        const slotdetails = prebooked.filter(item => {
            console.log(new Date(item.date).toDateString())
            if (new Date(item.date).toDateString() === date && item[slot]) {
                return true
            }
        })

        // Set priority for popup content (confirmed > tentative)
        if (slotdetails.length) {
            let priority = undefined
            const confirmed = slotdetails.filter(item => item.booking.booking_type === "Confirmed")

            if (confirmed.length) {
                priority = confirmed[0]
            } 
            
            if (priority === undefined) {
                const tentative = slotdetails.filter(item => item.booking.booking_type === "Tentative")

                if (tentative.length) {
                    priority = tentative[0]
                }
            }

            if (priority) {
                setAnchorEl(event.currentTarget);
                setSlotpopupInfo(priority)
            }
        }
    };

    const handleClose = () => {
        setAnchorEl(null)
        setSlotpopupInfo(undefined)
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [eventFromDate, setEventFromDate] = useState(null);
    const [eventToDate, setEventToDate] = useState(null);

    const [prebooked, setPrebooked] = useState([])
    const [triggerRefresh, setTriggerRefresh] = useState(0)

    const handleEventFromDateChange = (date) => {
        setEventFromDate(date.$d)
    }

    const handleEventToDateChange = (date) => {
        setEventToDate(date.$d)
    }

    const handleReset = () => {
        setEventFromDate(null)
        setEventToDate(null)
        setTriggerRefresh(prev => prev + 1)
    }

    const token = localStorage.getItem("token")

    const fetchBanquets = async () => {
        try {
            const response = await axios.post(`${api}/BanquetHall/get-banquets`, { token })
            setBanquets(response.data.banquets)
        } catch (error) {
            console.error(error)
        }
    }

    const fetchPrebooked = async () => {
        try {
            let start = new Date()

            if (eventFromDate !== null) {
                start = new Date(eventFromDate)
                start = start.setDate(start.getDate())
            }

            let end = new Date()

            if (eventToDate !== null) {
                end = new Date(eventToDate)
                end.setDate(eventToDate.getDate())
            } else {
                end.setDate(end.getDate() + 7)
            }

            const params = {
                token,
                eventfrom: new Date(start),
                eventend: new Date(end)
            }

            const timedifference = new Date(end).getTime() - new Date(start).getTime()

            const daysinbetween = (timedifference / (1000 * 3600 * 24)) + 1

            const banquetobject = {}

            banquets.forEach(item => {
                const hallname = item.hall_title

                if (banquetobject[hallname] === undefined) {
                    banquetobject[hallname] = {}
                }

                const date = new Date(start)

                Array(daysinbetween).fill(undefined).forEach((_, index) => {
                    banquetobject[hallname][date.toDateString()] = [false, false, false]
                    date.setDate(date.getDate() + 1)
                })
            })

            setEvents(banquetobject)

            const { data, status } = await axios.post(`${api}/Booking/fetch-prebooked`, params)

            if (data.success && data.booked) {
                // Filter deleted banquet halls' bookings
				const booked = data.booked.filter(item => item.banquethall)
				setPrebooked(booked)
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        fetchBanquets()

        if (banquets.length) {
            fetchPrebooked()
        }
    }, [banquets.length === 0, triggerRefresh])

    useEffect(() => {
        const sortedbybanquet = {}

        prebooked.forEach(item => {
            const banquethallname = item.banquethall.hall_title

            const has = sortedbybanquet[banquethallname]

            if (has) {
                const exists = has[new Date(item.date).toDateString()]

                if (exists) {
                    if (item.is_breakfast_available && item.booking.booking_type === "Confirmed") {
                        exists[0] = "confirmed"
                    } else if (item.is_breakfast_available && item.booking.booking_type === "Tentative" && exists[0] !== "confirmed") {
                        exists[0] = "booked"
                    }
                    if (item.is_lunch_available && item.booking.booking_type === "Confirmed") {
                        exists[1] = "confirmed"
                    } else if (item.is_lunch_available && item.booking.booking_type === "Tentative" && exists[1] !== "confirmed") {
                        exists[1] = "booked"
                    }
                    if (item.is_dinner_available && item.booking.booking_type === "Confirmed") {
                        exists[2] = "confirmed"
                    } else if (item.is_dinner_available && item.booking.booking_type === "Tentative" && exists[2] !== "confirmed") {
                        exists[2] = "booked"
                    }
                } else {
                    has[new Date(item.date).toDateString()] = [
                        item.is_breakfast_available ? (item.booking.booking_type === "Confirmed" ? "confirmed" : (item.booking.booking_type === "Tentative" ? "booked" : "available")) : false,
                        item.is_lunch_available ? (item.booking.booking_type === "Confirmed" ? "confirmed" : (item.booking.booking_type === "Tentative" ? "booked" : "available")) : false,
                        item.is_dinner_available ? (item.booking.booking_type === "Confirmed" ? "confirmed" : (item.booking.booking_type === "Tentative" ? "booked" : "available")) : false
                    ]
                }
            } else {
                sortedbybanquet[banquethallname] = {}

                sortedbybanquet[banquethallname][new Date(item.date).toDateString()] = [
                    item.is_breakfast_available ? (item.booking.booking_type === "Confirmed" ? "confirmed" : (item.booking.booking_type === "Tentative" ? "booked" : "available")) : false,
                    item.is_lunch_available ? (item.booking.booking_type === "Confirmed" ? "confirmed" : (item.booking.booking_type === "Tentative" ? "booked" : "available")) : false,
                    item.is_dinner_available ? (item.booking.booking_type === "Confirmed" ? "confirmed" : (item.booking.booking_type === "Tentative" ? "booked" : "available")) : false
                ]
            }
        })

        setEvents(prev => {
            const copy = JSON.parse(JSON.stringify(prev))

            for (const [key, value] of Object.entries(prev)) {
                if (sortedbybanquet[key]) {
                    for (const [bookedhalldate, bookedhalltimings] of Object.entries(sortedbybanquet[key])) {
                        copy[key][bookedhalldate] = bookedhalltimings
                    }
                }
            }

            return copy
        })
    }, [prebooked])

    let bookedslotsforpopup = []

    if (slotpopupInfo) {
        if (slotpopupInfo.is_breakfast_available) {
            bookedslotsforpopup.push("Breakfast")
        }
        if (slotpopupInfo.is_lunch_available) {
            bookedslotsforpopup.push("Lunch")
        }
        if (slotpopupInfo.is_dinner_available) {
            bookedslotsforpopup.push("Dinner")
        }
    }

    const slotsstringified = bookedslotsforpopup.join(", ")

    const logger = async () => {
        const link = window.location.href

        const { data, status } = await axios.post(`${api}/Logs/viewed-page`, { token, link })
    }

    useEffect(() => {
        logger()
    }, [])

    return (
        <div className="_7xqw">
            <div className="app-page-dashboard">
                <ul className="app-page-dashboard__header__list">
                    <li className="app-page-dashboard__header__list__item">
                        <div className="flex-box">
                            <div style={{ marginRight: 15 }}>
                                <BookingListMenu
                                    displayname="Confirmed List"
                                    status="Confirmed"
                                />
                            </div>
                            <div style={{ marginRight: 15 }}>
                                <BookingListMenu
                                    displayname="Waiting List"
                                    status="Waitlist"
                                />
                            </div>
                            <BookingListMenu
                                displayname="Tentative List"
                                status="Tentative"
                            />
                        </div>
                    </li>
                </ul>
                <div className="app-page-banquets-management">
                    <div className="_3ceu app-page-banquets-management">
                        <div className="app-card">
                            <h1 className="app-card__title">Booking Availability</h1>
                            <div className="app-card__content">
                                <Grid container style={{ marginTop: '20px' }}>
                                    <Grid item xs={12} md={6}>
                                        <Grid container rowSpacing={2} columnSpacing={5}>
                                            <Grid item xs={12} md={6}>
                                                <div className="app-form-field">
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            disablePast
                                                            label="Event From Date"
                                                            value={eventFromDate}
                                                            onChange={handleEventFromDateChange}
                                                            format="DD-MM-YYYY"
                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <div className="app-form-field">
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            disablePast
                                                            minDate={dayjs(eventFromDate)}
                                                            label="Event To Date"
                                                            value={eventToDate}
                                                            onChange={handleEventToDateChange}
                                                            format="DD-MM-YYYY"
                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid container style={{ marginTop: '20px' }}>
                                    <Grid item xs={12} md={6}>

                                        <div className="app-card__button d-flex justify-content-center mt-20 w-full app-new-app-card__button">
                                            <Button className="app-btn app-btn-primary app-btn2-primary" variant="contained" onClick={() => handleReset()}>Reset</Button>
                                            <Button style={{ marginLeft: '20px' }} className="app-btn app-btn-primary" variant="contained" onClick={fetchPrebooked}>Submit</Button>
                                        </div>
                                    </Grid>

                                </Grid>
                            </div>
                        </div>
                        <div className="app-page-dashboard">
                            <div className="harping-suck">
                                <div className="excreta-shun">
                                    <Button aria-describedby={id} variant="contained" className={`banquet-bookings-table_button --status-available`}></Button>
                                    <span>Available for booking</span>
                                </div>
                                <div className="excreta-shun">
                                    <Button aria-describedby={id} variant="contained" className={`banquet-bookings-table_button --status-booked`}></Button>
                                    <span>Tentative booking</span>
                                </div>
                                <div className="excreta-shun">
                                    <Button aria-describedby={id} variant="contained" className={`banquet-bookings-table_button --status-confirmed`}></Button>
                                    <span>Confirm booking</span>
                                </div>
                            </div>

                            <div className="app-page-dashboard__content">
                                <SimpleBarReact className="banquet-bookings-vertical-scroll">
                                    <div className="banquet-bookings-table">
                                        <div className="banquet-bookings-table__sidebar">
                                            <h1>Hall Name</h1>
                                            <ul className="banquet-bookings-table__sidebar__menu">
                                                {banquets ? banquets.map((banquet) => (
                                                    <li key={banquet._id} className="banquet-bookings-table__sidebar__menu__item">
                                                        <h6>{banquet.hall_title}</h6>
                                                    </li>
                                                )) : undefined}
                                            </ul>

                                        </div>

                                        <div className="banquet-bookings-table__table">
                                            <SimpleBarReact className="banquet-bookings-horizontal-scroll">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            {banquets[0] ? Object.keys(events[banquets[0].hall_title] ? events[banquets[0].hall_title] : {}).map((item, index) => {
                                                                return <th key={index} className="banquet-bookings-table__table__date" colSpan={3}>{item}</th>
                                                            }) : undefined}
                                                        </tr>
                                                        <tr className="banquet-bookings-table__table__type">
                                                            {banquets[0] ? Object.keys(events[banquets[0].hall_title] ? events[banquets[0].hall_title] : {}).map((item, index) => {
                                                                return (
                                                                    <>
                                                                        <th>Breakfast</th>
                                                                        <th>Lunch</th>
                                                                        <th>Dinner</th>
                                                                    </>
                                                                )
                                                            }) : undefined}
                                                        </tr>
                                                    </thead>
                                                    {banquets.map(item => {
                                                        const eventsofhall = events[item.hall_title]

                                                        return (
                                                            <tbody>
                                                                <tr>
                                                                    {Object.keys(eventsofhall ? eventsofhall : {}).map((i, index) => {
                                                                        return (
                                                                            <>
                                                                                <td align="center">
                                                                                    <Button aria-describedby={id} variant="contained" onClick={() => eventsofhall[i][0] ? undefined : navigate("/booking")} onMouseEnter={e => handleMouseEnter(e, i, "is_breakfast_available", eventsofhall[i][0])} className={`banquet-bookings-table_button --status-${eventsofhall[i][0] ? eventsofhall[i][0] : "available"}`}></Button>
                                                                                </td>
                                                                                <td align="center">
                                                                                    <Button aria-describedby={id} onClick={() => eventsofhall[i][1] ? undefined : navigate("/booking")} onMouseEnter={e => handleMouseEnter(e, i, "is_lunch_available", eventsofhall[i][1])} variant="contained" className={`banquet-bookings-table_button --status-${eventsofhall[i][1] ? eventsofhall[i][1] : "available"}`}></Button>
                                                                                </td>
                                                                                <td align="center">
                                                                                    <Button aria-describedby={id} onClick={() => eventsofhall[i][2] ? undefined : navigate("/booking")} onMouseEnter={e => handleMouseEnter(e, i, "is_dinner_available", eventsofhall[i][2])} variant="contained" className={`banquet-bookings-table_button --status-${eventsofhall[i][2] ? eventsofhall[i][2] : "available"}`}></Button>
                                                                                </td>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </tr>
                                                            </tbody>
                                                        )
                                                    })}
                                                </table>
                                            </SimpleBarReact>
                                        </div>
                                    </div>
                                </SimpleBarReact>
                            </div>
                        </div>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            className="custom-popper"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <div className="slot-popper-card" onMouseLeave={handleClose}>
                                <h1>{slotpopupInfo && slotpopupInfo.booking.contact_person_name}</h1>
                                <p>{slotpopupInfo && new Date(slotpopupInfo.date).toDateString()}</p>
                                <span>{slotpopupInfo && slotsstringified}</span>
                                <ul>
                                    <li>
                                        <img src="/assets/icons/team.svg" alt="" />
                                        <span>{slotpopupInfo && slotpopupInfo.pax}</span>
                                    </li>
                                    <li>
                                        <div className="status-badge status-available" style={{ backgroundColor: slotpopupInfo ? (slotpopupInfo.booking.booking_type === "Confirmed" ? "#FF5A5A" : "#ffbe40") : "#FF5A5A" }}></div>
                                    </li>
                                </ul>
                            </div>
                        </Popover>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default BookingAvailability;
