import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import "../components/css/Pricing.css"


const useStyles = makeStyles((theme) => ({
    section: {
        backgroundImage: 'url("nereus-assets/img/bg/pattern1.png")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    typography: { fontFamily: "objectivity" , fontWeight : 600 },
    cardHeader: {
        paddingTop: theme.spacing(2),
    },
}));

export default function Pricing() {
    const classes = useStyles();


    const [state, setState] = React.useState({
        checkbox: true,
    });

    const handleChange = (event) => {
        setState({ ...state, checkbox: event.target.checked });
    };

    return (
        <section className={classes.section}>
            <Container maxWidth="lg">
                <Box py={5} textAlign="center">
                    <Box mb={4}>
                        <Container maxWidth="sm">
                            {/* <Typography className= {classes.typography} variant="overline" color="textSecondary">PRICING</Typography> */}
                            <Typography  variant="h3" component="h2" gutterBottom={true}>
                                <Typography className= {classes.typography} variant="h3" component="span" color="primary">PRICING</Typography><br/>
                                <Typography className= {classes.typography} variant="h3" component="span">Plans For Venue Management</Typography>
                            </Typography>
                            {/* <Typography className= {classes.typography} variant="subtitle1" color="textSecondary" paragraph={true}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi neque veritatis quae nulla eius rerum saepe dolorum molestiae, hic vel similique excepturi quasi libero in blanditiis id, delectus voluptatum praesentium!</Typography> */}

                            <div>
                                <Typography className= {classes.typography} variant="subtitle1" component="span">Montly</Typography>
                                &nbsp; <Switch name="checkbox" color="primary" checked={state.checkbox} onChange={handleChange} /> &nbsp;
                                <Typography className= {classes.typography} variant="subtitle1" component="span">Yearly</Typography>
                            </div>
                        </Container>
                    </Box>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={3}>
                            <Card variant="outlined" className='card-1'>
                                <img className='pricing-image'
                                src="assets/images/20943426.jpg"
                                alt='nothing'
                                loading='lazy'
                                />
                                 <Typography variant="h4" component="h2" className={classes.typography}>FREE</Typography>
                                <CardContent>
                                    <Box px={1}>
                                        <Typography className={classes.typography} variant="h4" component="h2" gutterBottom={true}>
                                        $10000
                                            <Typography className={classes.typography} variant="h6" color="textSecondary" component="span">/mon</Typography>
                                        </Typography>
                                        <Typography className={classes.typography} color="textSecondary" variant="subtitle1" component="p">Lorem</Typography>
                                        <Typography className={classes.typography} color="textSecondary" variant="subtitle1" component="p">1 user</Typography>
                                        <Typography className={classes.typography} color="textSecondary" variant="subtitle1" component="p">1 admin</Typography>
                                        <Typography  className={classes.typography} color="textSecondary" variant="subtitle1" component="p" paragraph={true}></Typography>
                                    </Box>
                                        <Button variant="outlined" color="primary" className={classes.primaryAction}>Selet Plan</Button>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card variant="outlined" className='card-2'>
                                <img className='pricing-image'
                                src="assets/images/20943426.jpg"
                                alt='nothing'
                                loading='lazy'
                                />
                                 <Typography variant="h4" component="h2" className={classes.typography}>PRO</Typography>
                                <CardContent>
                                    <Box px={1}>
                                        <Typography className={classes.typography} variant="h4" component="h2" gutterBottom={true}>
                                        $10000
                                            <Typography className={classes.typography} variant="h6" color="textSecondary" component="span">/mon</Typography>
                                        </Typography>
                                        <Typography className={classes.typography} color="textSecondary" variant="subtitle1" component="p">Lorem</Typography>
                                        <Typography className={classes.typography} color="textSecondary" variant="subtitle1" component="p">1 user</Typography>
                                        <Typography className={classes.typography} color="textSecondary" variant="subtitle1" component="p">1 admin</Typography>
                                        <Typography  className={classes.typography} color="textSecondary" variant="subtitle1" component="p" paragraph={true}></Typography>
                                    </Box>
                                        <Button variant="outlined" color="primary" className={classes.primaryAction}>Selet Plan</Button>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card variant="outlined" className='card-3'>
                                <img className='pricing-image'
                                src="assets/images/20943426.jpg"
                                alt='nothing'
                                loading='lazy'
                                />
                                 <Typography variant="h4" component="h2" className={classes.typography}>PREMIUM</Typography>
                                <CardContent>
                                    <Box px={1}>
                                        <Typography className={classes.typography} variant="h4" component="h2" gutterBottom={true}>
                                        $10000
                                            <Typography className={classes.typography} variant="h6" color="textSecondary" component="span">/mon</Typography>
                                        </Typography>
                                        <Typography className={classes.typography} color="textSecondary" variant="subtitle1" component="p">Lorem</Typography>
                                        <Typography className={classes.typography} color="textSecondary" variant="subtitle1" component="p">1 user</Typography>
                                        <Typography className={classes.typography} color="textSecondary" variant="subtitle1" component="p">1 admin</Typography>
                                        <Typography  className={classes.typography} color="textSecondary" variant="subtitle1" component="p" paragraph={true}></Typography>
                                    </Box>
                                        <Button variant="outlined" color="primary" className={classes.primaryAction}>Selet Plan</Button>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card variant="outlined" className='card-4'>
                                <img className='pricing-image'
                                src="assets/images/20943426.jpg"
                                alt='nothing'
                                loading='lazy'
                                />
                                 <Typography variant="h4" component="h2" className={classes.typography}>ELITE</Typography>
                                <CardContent>
                                    <Box px={1}>
                                        <Typography className={classes.typography} variant="h4" component="h2" gutterBottom={true}>
                                        $10000
                                            <Typography className={classes.typography} variant="h6" color="textSecondary" component="span">/mon</Typography>
                                        </Typography>
                                        <Typography className={classes.typography} color="textSecondary" variant="subtitle1" component="p">Lorem</Typography>
                                        <Typography className={classes.typography} color="textSecondary" variant="subtitle1" component="p">1 user</Typography>
                                        <Typography className={classes.typography} color="textSecondary" variant="subtitle1" component="p">1 admin</Typography>
                                        <Typography  className={classes.typography} color="textSecondary" variant="subtitle1" component="p" paragraph={true}></Typography>
                                    </Box>
                                        <Button variant="outlined" color="primary" className={classes.primaryAction}>Selet Plan</Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </section>
    );
}