import React, { useEffect, useState } from 'react'
import api from '../config/backend';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import image from "../assets/original.webp"

const UpgradeSuccess = () => {

    const navigate = useNavigate()

    const [redirect, setRedirect] = useState(false)

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img style={{ width: 700, height: 500 }} src={image} alt='image' />
            <div>
                <h1 style={{ color: 'green' }}>Upgrade Successful</h1>
            </div>
        </div>
    )
}

export default UpgradeSuccess