import "./css/Login.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import LoginPassword from "../components/LoginPassword";
import api from "../config/backend"
import ImageSlider from "../components/ImageSlider";
import { Link, useNavigate } from "react-router-dom";
// import ImageSlider from "../components/ImageSlider";
// import Slider from "react-slick";


const Login = () => {
	const [username, setUsername] = useState("");
	const [focused, setFocused] = useState(false);
	const [needPassword, setNeedPassword] = useState(false);
	const [message, setMessage] = useState(undefined)
	const [staySigned, setStaySigned] = useState(true)

	const navigate = useNavigate()

	useEffect(() => {
		let timeout

		if (message) {
			timeout = setTimeout(() => {
				setMessage(undefined)
			}, 5000)
		}

		return () => clearTimeout(timeout)
	}, [message])

	const checkUsername = async () => {
		const response = await axios.post(`${api}/Author/check-username`, { username })

		if (response.data.success) {
			setNeedPassword(true)
		} else {
			setMessage(response.data.message)
		}
	}

	return (
		<div className="_5pwz">
			<header>
				<h3 onClick={() => navigate("/")}>Logo</h3>
				<ul>
					<div onClick={() => navigate("/", {state: { scroll: true }})} style={{ cursor: "pointer" }}><li><span>Pricing</span></li></div>
					<Link to="/signup"><li><span>Sign Up</span></li></Link>
					<li><span>Help</span></li>
				</ul>
			</header>
			<main>
				<div className="sauce-paid">
					<div className="container">
						{/* <h2>Book your next event today</h2>
						<p>
							Plan your next event with ease and confidence using
							our venue management booking website. We offer a
							wide variety of venues to choose from, all of which
							are available for booking online.
						</p> */}
						<ImageSlider />
						{/* <img src="assets/images/20943426.jpg" /> */}
					</div>
				</div>
				<div className="their-apex">
					{needPassword ? (
						<LoginPassword username={username} />
					) : (
						<div className="container">
							<div className="Logo">Logo</div>
							<div className="runnier-cap">
								<span className="big">Sign in to Logo</span>
								<span>Sign in using your Logo account</span>
							</div>
							<div className="agonised-men">
								<div className="container">
									<span
										className={
											focused || username
												? "focused"
												: undefined
										}
									>
										Email address or mobile number
									</span>
									<input
										className={
											focused ? "focused" : undefined
										}
										value={username}
										onChange={(e) =>
											setUsername(e.target.value)
										}
										onFocus={() => setFocused(true)}
										onBlur={() => setFocused(false)}
									/>
								</div>
								{message ? (
									<div className="bioclean-teed">
										{message}
									</div>
								) : undefined}
								<button onClick={() => checkUsername()}>
									Next
								</button>
								<div className="enactors-map">
									<div>
										<input
											id="stay-signed"
											type="checkbox"
											value={staySigned}
											onChange={e => setStaySigned(e.target.value)}
										/>
										<label htmlFor="stay-signed">Stay signed in</label>
									</div>
									<span>Forgotten username?</span>
								</div>
							</div>
						</div>
					)}
				</div>
			</main>
		</div>
	);
};

export default Login;
