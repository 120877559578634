import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import {useState, useContext, useEffect} from "react"
import { Button } from "@mui/material";
import { Link } from 'react-router-dom';
import { AccessContext } from "../App";
import {RiArrowDropDownLine} from "react-icons/ri";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import decode from "jwt-decode"

function Sidebar() {
    const [expanded, setExpanded] = React.useState(false);
    const [access, setAccess] = useState(undefined)

    const token = localStorage.getItem("token")

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }

    const { state } = useContext(AccessContext)

    console.log(state)

    useEffect(() => {
        if (state.access !== undefined) {
            setAccess(state.access)
        }
    }, [state.access === undefined])

    const adminList = [
        {
            name: "Banquet Hall",
            path: "/add-banquet-hall",
            codename: "banquethall"
        },
        {
            name: "Department",
            path: "/department",
            codename: "department"
        },
        {
            name: "Designation",
            path: "/role-management",
            codename: "designation"
        },
        {
            name: "Users",
            path: "/users",
            codename: "users"
        }
    ]
    
    let userdetails

    if (token) {
        userdetails = decode(token)
    }

    const hasreports = state.access ? (state.access.reports.view || state.access.reports.add || state.access.reports.edit || state.access.reports.delete) : undefined

    const haslogs = userdetails ? userdetails.admin : false
    
    return (
        <>
            <div className="app-layout-sidebar">
                <ul className="app-layout-sidebar__menu">
                    {userdetails && userdetails.admin ? <li className="app-layout-sidebar__menu__item">
                        <div className="app-layout-sidebar__accordion">
                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary  className="sidebar-dropdown" aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Button className="sidebar-btn">Admin Management</Button>
                               
                                </AccordionSummary>
                                <AccordionDetails>
                                    {access ? adminList.map((item, index)=>{
                                        const a = access[item.codename]
                            
                                        return a === undefined || a.view || a.edit || a.add || a.delete ? (
                                            <div key={index} className="app-layout-sidebar__accordion__content">
                                            <ul className="app-layout-sidebar__accordion__list">
                                                <li className="app-layout-sidebar__accordion__list__item">
                                                    <Link to={item.path}>
                                                        <Button className="sidebar-btn">{item.name}</Button>
                                                    </Link>
                                                </li>
                                                </ul>
                                            </div>) : undefined
                                    }) : undefined }
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </li> : undefined}
                    <li className="app-layout-sidebar__menu__item">
                        <div className="app-layout-sidebar__accordion">
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary   className="sidebar-dropdown"  aria-controls="panel2bh-content"
                                    id="panel2bh-header"
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Button className="sidebar-btn">Banquet Management</Button>
                                
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="app-layout-sidebar__accordion__content">
                                        <ul className="app-layout-sidebar__accordion__list"> 
                                            <li className="app-layout-sidebar__accordion__list__item">
                                                <Link to="/banquet-details">
                                                    <Button className="sidebar-btn">Banquet Details</Button>
                                                </Link>
                                            </li>
                                            {state && state.access && state.access.booking.add ? <li className="app-layout-sidebar__accordion__list__item">
                                                <Link to="/booking">
                                                    <Button className="sidebar-btn">Booking</Button>
                                                </Link>
                                            </li> : undefined}
                                            <li className="app-layout-sidebar__accordion__list__item">
                                                <Link to="/booking-availability">
                                                    <Button className="sidebar-btn">Booking Availability</Button>
                                                </Link>
                                            </li>
                                            <li className="app-layout-sidebar__accordion__list__item">
                                                <Link to="/booking-search">
                                                    <Button className="sidebar-btn">Booking Search</Button>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </li>
                    {hasreports ? <li className="app-layout-sidebar__menu__item">
                        <div className="app-layout-sidebar__accordion">
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary   className="sidebar-dropdown"  aria-controls="panel3bh-content"
                                    id="panel3bh-header"
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Button className="sidebar-btn">Reports</Button>
                              
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="app-layout-sidebar__accordion__content">
                                        <ul className="app-layout-sidebar__accordion__list">
                                            <li className="app-layout-sidebar__accordion__list__item">
                                                <Link to="/booking-report">
                                                    <Button className="sidebar-btn">Booking Report</Button>
                                                </Link>
                                                {haslogs && <Link to="/logs">
                                                    <Button className="sidebar-btn">Logs</Button>
                                                </Link>}
                                            </li>
                                            {/* <li className="app-layout-sidebar__accordion__list__item">
                                                <Link to="/booking-guest">
                                                    <Button className="sidebar-btn">Booking (Guest)</Button>
                                                </Link>
                                            </li>
                                            <li className="app-layout-sidebar__accordion__list__item">
                                                <Link to="/">
                                                    <Button className="sidebar-btn">Booking (Status)</Button>
                                                </Link>
                                            </li>
                                            <li className="app-layout-sidebar__accordion__list__item">
                                                <Link to="/booking-company">
                                                    <Button className="sidebar-btn">Booking (Company)</Button>
                                                </Link>
                                            </li>
                                            <li className="app-layout-sidebar__accordion__list__item">
                                                <Link to="/cancel-reference">
                                                    <Button className="sidebar-btn">Cancel (Reference)</Button>
                                                </Link>
                                            </li>
                                            <li className="app-layout-sidebar__accordion__list__item">
                                                <Link to="/cancel-guest">
                                                    <Button className="sidebar-btn">Cancel (Guest)</Button>
                                                </Link>
                                            </li>
                                            <li className="app-layout-sidebar__accordion__list__item">
                                                <Link to="/cancel-status">
                                                    <Button className="sidebar-btn">Cancel (Status)</Button>
                                                </Link>
                                            </li>
                                            <li className="app-layout-sidebar__accordion__list__item">
                                                <Link to="/cancel-company">
                                                    <Button className="sidebar-btn">Cancel (Company)</Button>
                                                </Link>
                                            </li>
                                            <li className="app-layout-sidebar__accordion__list__item">
                                                <Link to="/hall-report">
                                                    <Button className="sidebar-btn">Hall Report</Button>
                                                </Link>
                                            </li>
                                            <li className="app-layout-sidebar__accordion__list__item">
                                                <Link to="/booking-data">
                                                    <Button className="sidebar-btn">Booking Date</Button>
                                                </Link>
                                            </li>
                                            <li className="app-layout-sidebar__accordion__list__item">
                                                <Link to="/cancel-booking-data">
                                                    <Button className="sidebar-btn">Cancel Booking Date</Button>
                                                </Link>
                                            </li>
                                            <li className="app-layout-sidebar__accordion__list__item">
                                                <Link to="/all-booking-type">
                                                    <Button className="sidebar-btn">All Booking Type</Button>
                                                </Link>
                                            </li>
                                            <li className="app-layout-sidebar__accordion__list__item">
                                                <Link to="/hall-court-report">
                                                    <Button className="sidebar-btn">Halls Count Reports</Button>
                                                </Link>
                                            </li> */}
                                        </ul>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </li> : undefined}
                </ul>
            </div>
        </>
    )
}
export default Sidebar;