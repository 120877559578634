import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import api from '../../config/backend'
import axios from 'axios'
import { Autocomplete, Button } from '@mui/material'
import { toast } from 'react-toastify'
import { Country, State, City }  from 'country-state-city';

export const AddCompany = ({ handleNext }) => {
  const [companyname, setCompanyName] = useState("")
  const [gst, setGst] = useState("")
  const [companyaddress, setcompanyAddress] = useState("")
  const [companycity, setCompanycity] = useState("")
  const [companystate, setCompanystate] = useState("")
  const [company, setCompany] = useState(undefined)
  const [stateCode, setStateCode] = useState('')
  const [cities,setCities] = useState('')

  const token = localStorage.getItem("token")

  const state = State.getStatesOfCountry("IN")

  // const fetchCompany = async () => {
  //   const { data, status } = await axios.post(`${api}/company/get-company`, {
  //     token,
  //   })

  //   console.log({ data })

  //   if (data.success) {
  //     setCompany(data.company)
  //   } else {
  //     toast.error(data.message)
  //   }
  // }

  const handlecompany = async (e) => {
    e.preventDefault()
    const dataa = {
      token,
      name: companyname,
      gst,
      address: companyaddress,
      city: companycity?.name,
      state: companystate,
    }

    if (!companystate) {
      toast.error("Please select a state")
      return
    } 
    
    if (!companycity?.name ) {
      toast.error("Please select a city")
      return
    }

    const { data, status } = await axios.post(`${api}/company/create`, dataa)

    if (data.success) {
      toast.success(data.message)
      handleNext()
    } else {
      toast.error(data.message)
    }
  }

  useEffect(()=>{
    const cities = City.getCitiesOfState("IN",stateCode)
    setCities(cities);
    setCompanycity(cities[0])
  },[stateCode])

  console.log({ stateCode, companycity, cities })

  return (
    <React.Fragment>
      <h3 className="">Add Company</h3>
      <p style={{ color: '#7C7F82', marginBottom: "30px", fontSize: "10px", fontWeight: 700 }}>Please fill the company details for further usage</p>
      <form onSubmit={handlecompany}>
        <Grid container rowSpacing={2} columnSpacing={5}>
          <Grid item xs={12} md={6}>
            <div className="app-form-field">
              <TextField
                required
                label="Company Name"
                variant="outlined"
                placeholder="Enter Company Name"
                value={companyname}
                onChange={(e) => { setCompanyName(e.target.value) }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="app-form-field">
              <TextField
                label="GST (optional)"
                variant="outlined"
                placeholder="Enter GST"
                type="text"
                value={gst}
                onChange={(e) => { setGst(e.target.value) }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="app-form-field">
              <Autocomplete
                id="State"
                options={state}
                className="phone-number-input"
                onChange={(event, newValue) => {
                  setCompanystate(newValue?.name)
                  setStateCode(newValue?.isoCode)
                  }}	
                autoHighlight={true}								  
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} sx={{padding:'0px !important'}}  label="State *" placeholder="state" value={companystate} onChange={(e) => { setCompanystate(e.target.value); }}/>}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="app-form-field">
              <Autocomplete
                id="City"
                value={companycity}
                options={cities}
                disabled = {!companystate}
                className="phone-number-input"
                onChange={(event, newValue) => {
                  setCompanycity(newValue)
                }}	
                autoHighlight={true}								  
                getOptionLabel={option => option.name}
                renderInput={(params) => <TextField {...params} sx={{padding:'0px !important'}}  label="City *" placeholder="City" />}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="app-form-field">
              <TextField
                required
                label="Address"
                variant="outlined"
                placeholder="Enter Address"
                type="text"
                value={companyaddress}
                onChange={(e) => { setcompanyAddress(e.target.value) }}
              />
            </div>
          </Grid>
        </Grid>
        <Button 
          style={{ marginTop: "20px" }}
          type='submit'
          variant="contained"
          className='saveandproceed'
        >
          Save and Proceed
        </Button>
      </form>
    </React.Fragment>
  )
}

export default AddCompany