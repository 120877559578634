import React, { useState, useEffect } from "react";
import axios from "axios";
  
function UserData() {
  const [authors, setAuthors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const [roles, setRoles] = useState([]);

  const [titles, setTitles] = useState([]); 


  //   const [authors, setAuthors] = useState([]);

  useEffect( () => {

  const fetchAuthors = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "http://192.46.209.97:6060/api/Author/list",
        {
          headers: {
            accept: "application/json",
            authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsImZpcnN0X25hbWUiOiJTaXRhcmEiLCJsYXN0X25hbWUiOiJIb3RlbCIsImVtYWlsIjoic2l0YXJhQHRlc3QuY29tIiwiaXNfcGhvbmVfdmVyaWZpZWQiOmZhbHNlLCJwaG9uZV92ZXJpZmljYXRpb24iOm51bGwsImltYWdlIjpudWxsLCJyb2xlIjpbIjY0NjRhODk5NDQ5YWUwOTA2NGU1MjZmOCJdLCJnZW5kZXIiOiJNYWxlIiwiYWRkcmVzcyI6InN0cmluZyIsInBhc3N3b3JkIjoiJDJiJDEwJEZYSHJSQVFPbVRKZktPZmw2djZsQmVxQS85Smg1UzRvZk1iRTdaNS9FcWRpN1ByUEk4TjRDIiwicmVzZXRQYXdvcmRUb2tlbiI6bnVsbCwicmVmcmVzaFRva2VuIjoia0kydTMxQ3NjRjVscUgwNmRUNVUyNlJ0dzFEbk1FNWVVQnN6VzRBVzBnTXdaaXRNTzdrRnozc0F5dHJpaTI4eGVSb2VsSXhIQ0xrWnFxOGxsWVZseXRSQmF6SktKUHp4dWxCQ1A3TU5GbkpPSERaZ1Mzc1VYc2l4ekhaVjRESFM0dGlRUnQ5a28yWEU4bmhaY0w0N3IxaU5QalBXa0hGRmg2N0c4SkFOUmVlTmdJdjd4dkYyV0dwcVV6Uk15NWE4UmNJWG9CV2MyZmNsanBWelRkamVXNERaSDJ4Rkp3MXlveXlyaXZ3YWNjQUlxWHhSYXpQSm9ZcW9MTkpyQTU4ajY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsIm90cCI6MTIzNCwidmVudWUiOiI2NDY0YWUwMDgxMjM4NjFhZmM5YTUyNTQiLCJwaG9uZW51bWJlciI6Ijk2NDA4NjEwNzYiLCJkb2IiOiIyMDIzLTAxLTAxVDAwOjAwOjAwLjAwMFoiLCJjcmVhdGVkQXQiOiIyMDIzLTA1LTE3VDEwOjM1OjQ0LjU5NloiLCJ1cGRhdGVkQXQiOiIyMDIzLTA1LTE4VDA2OjE5OjI2LjQzOFoiLCJfX3YiOjB9LCJpYXQiOjE2ODQzOTA3NzcsImV4cCI6MTY4NTI1NDc3N30.5Se9vIgLFU1_tJL8wRe0EHp53ltgz6HkOF1rYZSuFMA",
          },
          params: {
            page: currentPage,
            limit: 10,
          },
        }
      );

      const newAuthors = response.data.results || [];
      setAuthors((prevAuthors) => [...prevAuthors, ...newAuthors]);

      if (newAuthors.length === 0) {
        setHasMore(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

    // Fetch the roles from the backend

      fetchAuthors()
  }, [currentPage, setAuthors, setHasMore]);

  const renderAuthors = (titles) => {
    return authors.map((author) => (
      <div key={author.id}>
        {/* Display author data */}
        <ul>
          <li>{author.first_name}</li>
          <li>{author.gender}</li>
          <li>{author.phonenumber}</li>
          <li>{author.email}</li>
          <li>{author.address}</li>
          <li>{author.dob}</li>
          <li>{titles.join(", ")}</li> {/* Display titles */}
        </ul>
      </div>
    ));
  };

  useEffect(() => {
    // Fetch the roles from the backend
    axios
      .get("http://192.46.209.97:6060/api/Role/list", {
        headers: {
          accept: "application/json",
          authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsImZpcnN0X25hbWUiOiJTaXRhcmEiLCJsYXN0X25hbWUiOiJIb3RlbCIsImVtYWlsIjoic2l0YXJhQHRlc3QuY29tIiwiaXNfcGhvbmVfdmVyaWZpZWQiOmZhbHNlLCJwaG9uZV92ZXJpZmljYXRpb24iOm51bGwsImltYWdlIjpudWxsLCJyb2xlIjpbIjY0NjRhODk5NDQ5YWUwOTA2NGU1MjZmOCJdLCJnZW5kZXIiOiJNYWxlIiwiYWRkcmVzcyI6InN0cmluZyIsInBhc3N3b3JkIjoiJDJiJDEwJEZYSHJSQVFPbVRKZktPZmw2djZsQmVxQS85Smg1UzRvZk1iRTdaNS9FcWRpN1ByUEk4TjRDIiwicmVzZXRQYXdvcmRUb2tlbiI6bnVsbCwicmVmcmVzaFRva2VuIjoia0kydTMxQ3NjRjVscUgwNmRUNVUyNlJ0dzFEbk1FNWVVQnN6VzRBVzBnTXdaaXRNTzdrRnozc0F5dHJpaTI4eGVSb2VsSXhIQ0xrWnFxOGxsWVZseXRSQmF6SktKUHp4dWxCQ1A3TU5GbkpPSERaZ1Mzc1VYc2l4ekhaVjRESFM0dGlRUnQ5a28yWEU4bmhaY0w0N3IxaU5QalBXa0hGRmg2N0c4SkFOUmVlTmdJdjd4dkYyV0dwcVV6Uk15NWE4UmNJWG9CV2MyZmNsanBWelRkamVXNERaSDJ4Rkp3MXlveXlyaXZ3YWNjQUlxWHhSYXpQSm9ZcW9MTkpyQTU4ajY0NjRhZTAwODEyMzg2MWFmYzlhNTI1NCIsIm90cCI6MTIzNCwidmVudWUiOiI2NDY0YWUwMDgxMjM4NjFhZmM5YTUyNTQiLCJwaG9uZW51bWJlciI6Ijk2NDA4NjEwNzYiLCJkb2IiOiIyMDIzLTAxLTAxVDAwOjAwOjAwLjAwMFoiLCJjcmVhdGVkQXQiOiIyMDIzLTA1LTE3VDEwOjM1OjQ0LjU5NloiLCJ1cGRhdGVkQXQiOiIyMDIzLTA1LTE4VDA2OjE5OjI2LjQzOFoiLCJfX3YiOjB9LCJpYXQiOjE2ODQzOTA3NzcsImV4cCI6MTY4NTI1NDc3N30.5Se9vIgLFU1_tJL8wRe0EHp53ltgz6HkOF1rYZSuFMA",
        },
      })
      .then((response) => {
        const roles = response.data.results;
        const titles = roles.map((item) => item.title);
        setTitles(titles); // Set the titles in state
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleLoadMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  // Render the authors
//   const renderAuthors = (titles) => {
//     return authors.map((author) => (
//       <div key={author.id}>
//         {/* Display author data */}
//         <ul>
//           <li>{author.first_name}</li>
//           <li>{author.gender}</li>
//           <li>{author.phonenumber}</li>
//           <li>{author.email}</li>
//           <li>{author.address}</li>
//           <li>{author.dob}</li>
//           <li>{titles.join(", ")}</li>
//         </ul>
//       </div>
//     ));
//   };

  return (
    <div>
      {renderAuthors(titles)}

      {loading && <p>Loading...</p>}

      {hasMore && !loading && (
        <button onClick={handleLoadMore}>Load More</button>
      )}
    </div>
  );
}

export default UserData;
