import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { Link, Navigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import "./css/Signup.css"
import axios from 'axios';
import api from '../config/backend';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { CircularProgress } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';





const defaultTheme = createTheme();


export default function SignUp() {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmpassword, setConfirmPassword] = useState("")
    const [phone, setPhone] = useState("")
    const [passmatch, setPassmatch] = useState(true)
    const [selectedpackage, setSelectedpackage] = useState(undefined)

    const navigate = useNavigate()

    const packagehash = localStorage.getItem("packagehash")


    const handleSubmit = async (event) => {
        setloading(true)
        event.preventDefault();
        const dataa = {
            full_name: name,
            email,
            password,
            phone,
            confirmpassword,
            packagehash: selectedpackage.hash
        }
        if (confirmpassword === "" || password === "") {
            // setPassmatch(true)
            toast.error('enter password filed')
        }

        if (password === confirmpassword) {
            const { data, status } = await axios.post(`${api}/Author/register-user`, dataa)
            if (data.success) {
                localStorage.setItem("token", data.token)
                toast.success(data.message)
                navigate("/email-verification")
            } else {
                toast.error(data.message)
            }
        } else {
            toast.error('confirm password should be same')
        }


        setloading(false)

    };



    const [loading, setloading] = useState(false);

    useEffect(() => {
        const init = async () => {
            const { data, status } = await axios.get(`${api}/Package/fetch-packages`)

            if (data.success === false) {
                toast.error(data.message)
            } else {
                const selected = data.packages.filter(item => {
                    if (packagehash) {
                        return item.hash === packagehash
                    }

                    return item.hash === "64ddce51dfd8af51449a09ee"
                })

                if (selected.length) {
                    setSelectedpackage(selected[0])
                }
            }
        }

        init()
    }, [packagehash])

    console.log({ selectedpackage })

    return (
        <ThemeProvider theme={defaultTheme}>
            <div>
                <Container component="main" maxWidth="xs">
                    <Grid> <img style={{ width: 70, height: 60 }} src='assets/images/tick.svg' alt='logo' /></Grid>

                    <Typography component="h1" variant="h4">
                        Venue Management
                    </Typography>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                {selectedpackage ? selectedpackage.title : undefined}
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                Price
                            </Typography>
                            <Typography variant="h5" component="div">
                                {selectedpackage ? `₹ ${selectedpackage.discounted_price}` : undefined}
                            </Typography>
                            <Typography variant="body2">
                                {selectedpackage ? `Max banquet count : ${selectedpackage.maxbanquetcount}` : undefined}
                                <br />
                                {selectedpackage ? `Number of users : ${selectedpackage.maxuserscount}` : undefined}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button onClick={() => navigate("/", { state: { scroll: true } })} size="small">Change Plan</Button>
                        </CardActions>
                    </Card>
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography component="h1" variant="h5">
                            Sign up
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        name="full_name"
                                        fullWidth
                                        id="full_name"
                                        label="Full Name"
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        name="phone"
                                        label="Phone Number"
                                        type="phone"
                                        id="phone"
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        name="password"
                                        label="Confirm Password"
                                        type="password"
                                        id="password"
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                </Grid>
                                {/* {!passmatch && <p>confirm password should be same</p>} */}
                            </Grid>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {loading === false ? "continue" : <Box sx={{ display: 'flex', color: "white", }}>
                                    <CircularProgress size={20} />
                                </Box>
                                }

                            </Button>
                            <Grid>
                                <Typography>By creating an account, you are agreeing to our <br />Terms of Service and Privacy Policy.</Typography>
                            </Grid>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Typography variant='h6' component="h1">Already have an account?</Typography>
                                    <Link to="/loginPage" variant="body2">
                                        Sign in
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Container>
            </div>
        </ThemeProvider>
    );
}
